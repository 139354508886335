export const GET_EMPYR_BALLANCE = 'GET_EMPYR_BALLANCE'
export const LOADING_EMPYR_BALLANCE = 'LOADING_EMPYR_BALLANCE'
export const LOADING_EMPYR_BALLANCE_FALSE = 'LOADING_EMPYR_BALLANCE_FALSE'

const initialState = {
    data: null,
    loading: false
}

const BallanceReducer = (state = initialState, action) => {
    switch(action.type){
        case GET_EMPYR_BALLANCE: {
            return{
                data: action.payload,
                loading: false
            }
        }
        case LOADING_EMPYR_BALLANCE: {
            return {
                ...state,
                loading: true
            }
        }
        case LOADING_EMPYR_BALLANCE_FALSE: {
            return {
                ...state,
                loading: false
            }
        }
        default:{
            return state
        }
    }
}

export default BallanceReducer