export const GET_EMPYR_PROFIL = 'GET_EMPYR_PROFIL'
export const LOADING_EMPYR_PROFIL = 'LOADING_EMPYR_PROFIL'
export const LOADING_EMPYR_PROFIL_FALSE = 'LOADING_EMPYR_PROFIL_FALSE'
export const RESET_EMPYR_PROFIL = 'RESET_EMPYR_PROFIL'

const initialState = {
    data: null,
    loading: false
}

const ProfilReducer = (state = initialState, action) => {
    switch(action.type){
        case GET_EMPYR_PROFIL: {
            return {
                ...state,
                data: action.payload,
                loading: false
            }
        }
        case LOADING_EMPYR_PROFIL: {
            return {
                ...state,
                loading: true
            }
        }
        case LOADING_EMPYR_PROFIL_FALSE: {
            return {
                ...state,
                loading: false
            }
        }
        case RESET_EMPYR_PROFIL: {
            return {
                data: null,
                loading: false
            }
        }
        default:{
            return state
        }
    }
}

export default ProfilReducer