// @flow
import About from './About'
import Features from './Features'
import FormDownload from './FormDownload'

let Guides = {}

Guides.About = About
Guides.Features = Features
Guides.FormDownload = FormDownload


export default Guides
