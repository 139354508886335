import React, { useState, useCallback, memo, useEffect, useRef } from "react";
import { DateObject, Calendar } from "react-multi-date-picker";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import "./calender.css";
import styles from "./index.module.scss";

const CustomInput = memo(({ type, setShow, value, label, placeholder }) => {
    const lang = useSelector((state) => state.language.lang);
    const [date, setDate] = useState({});

    const onInputFocus = useCallback(
        (_) => {
            setShow(true);
        },
        [setShow]
    );

    useEffect(() => {
        moment.locale(lang);
        if(value!=null){
            setDate(
                moment(`${value.year}/${value.month}/${value.day}`).format(
                    "MMMM DD, YYYY"
                )
            );
        }else{
            setDate("")
        }

    }, [value, lang]);

    return (
        <div
            className={`${
                type === "horizontal"
                    ? "d-flex w-100 " + styles.fromWrapper
                    : "row"
            }`}
        >
            <div
                className={`${type === "horizontal" ? "px-1" : "mb-4 col-12"}`}
            >
                <div
                    className={`${
                        type === "horizontal"
                            ? "form-group mb-0"
                            : "mb-0 pr-4 pr-md-5 form-group"
                    }`}
                >
                    <div class="input-group">
                        <input
                            type="text"
                            readOnly
                            class="has-grow underlined underlined--solid form-control"
                            value={date}
                            onFocus={onInputFocus}
                            placeholder={placeholder}
                        />
                        <label class="form-label">{label}</label>
                        <div onClick={() => setShow(true)} class="input-group-append">
                            <span class="icon input-group-text">
                                <i class="mi-calendar"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

const DatePicker2 = (props) => {
    const { t } = useTranslation();
    const calenderWrapperRef = useRef(null);
    const [show, setShow] = useState(false);

    const weekDays = [
        t("dayNameAbbv.su"),
        t("dayNameAbbv.mo"),
        t("dayNameAbbv.tu"),
        t("dayNameAbbv.we"),
        t("dayNameAbbv.th"),
        t("dayNameAbbv.fr"),
        t("dayNameAbbv.sa"),
    ];
    const months = [
        t("monthName.jan"),
        t("monthName.feb"),
        t("monthName.mar"),
        t("monthName.apr"),
        t("monthName.mei"),
        t("monthName.jun"),
        t("monthName.jul"),
        t("monthName.aug"),
        t("monthName.sep"),
        t("monthName.okt"),
        t("monthName.nov"),
        t("monthName.des"),
    ];

    const closeCalender = () => {
        setShow(false);
    };

    useEffect(() => {
        if (show) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }
    }, [show]);

    return (
        <div className="w-100 h-100 position-relative">
            <CustomInput
                value={props.value}
                setShow={setShow}
                placeholder={props.placeholder}
                label={props.label}
            />
            <div className={`${show ? "d-block" : "d-none"} position-relative`}>
                <div
                    className={`d-flex align-items-start justify-content-center ${styles.calenderWrapper}`}
                >
                    <div
                        onClick={closeCalender}
                        className={`${styles.calenderWrapper__bd}`}
                    ></div>
                    <div
                        ref={calenderWrapperRef}
                        className={`d-flex align-items-start justify-content-center py-4 position-relative ${styles.calenderWrapper__inner}`}
                    >
                        <div>
                            <Calendar
                                value={
                                    new DateObject({
                                        ...props.value,
                                    })
                                }
                                onChange={(e) => {
                                    props.onChange({
                                        day: e.day,
                                        month: e.month.index + 1,
                                        year: e.year,
                                    });
                                    setShow(false);
                                }}
                                className="custom-date-styles"
                                weekDays={weekDays}
                                months={months}
                                maxDate={
                                    new DateObject({
                                        day: props.maxDate?.day
                                            ? props.maxDate.day
                                            : new Date().getDate(),
                                        month: props.maxDate?.month
                                            ? props.maxDate.month
                                            : new Date().getMonth() + 1,
                                        year: props.maxDate?.year
                                            ? props.maxDate.year
                                            : new Date().getFullYear(),
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(DatePicker2);
