// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__8iduo {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.style_subheading__6l8p8 {
  max-width: 32rem;
  font-weight: 300;
}

.style_form__Uf9a6 {
  width: 100%;
  max-width: 32rem;
  margin-top: 2rem;
  margin-bottom: auto;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/views/ChangePassword/style.module.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,uBAAA;AADF;;AAIA;EACE,gBAAA;EACA,gBAAA;AADF;;AAIA;EACE,WAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;AADF;;AAGA;;EAEI,aAAA;AAAJ","sourcesContent":["@import 'scss/for-module';\n\n.container {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: flex-start;\n}\n\n.subheading {\n  max-width: 32rem;\n  font-weight: 300;\n}\n\n.form {\n  width: 100%;\n  max-width: 32rem;\n  margin-top: 2rem;\n  margin-bottom: auto;\n}\ninput::-ms-reveal,\n    input::-ms-clear {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__8iduo`,
	"subheading": `style_subheading__6l8p8`,
	"form": `style_form__Uf9a6`
};
export default ___CSS_LOADER_EXPORT___;
