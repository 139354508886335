//@flow
import clsx from 'clsx'
import * as React from 'react'
import { Button, Card, Fade } from 'react-bootstrap'

export const Alert = ({
  children,
  icon,
  closable,
  className,
  initialShow,
  ...restProps
}) => {
  const [shown, setShown] = React.useState(initialShow)

  return (
    <Fade in={shown} timeout={1000} unmountOnExit>
      <Card className={clsx('shadow-sm', className)}>
        {closable?(
          <Button variant="icon" className={clsx('card-close')} onClick={() => setShown(!shown)}>
          <i className={clsx('mi mi-cross')}></i>
          </Button>
        ):(<Button variant="icon" className={clsx('card-close')} >
        </Button>)}
        
        <Card.Header className={clsx('-left', '-bg-danger')}>
          <i className={clsx('mi xl', `mi-${icon ? icon : ''}`)}></i>
        </Card.Header>
        <Card.Body>{children}</Card.Body>
      </Card>
    </Fade>
  )
}

export default Alert
