import * as React from 'react'
import clsx from 'clsx'
import { SpinnerCircular } from 'spinners-react'
import styles from './styles.module.scss'

const Loading = () => {
  return (
    <div className={clsx(styles.loading, 'd-flex justify-content-center align-items-center')}>
        <SpinnerCircular thickness={180} size={40} />
    </div>
  )
}

export default Loading