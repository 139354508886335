import React, {useState, useEffect} from "react";
import { Redirect } from "react-router-dom";
import styles from './style.module.scss'
import clsx from 'clsx'
import { Form, Alert, AuthWrapper } from 'components'
import axios from "utils/axios";
import { API_MAINTENANCE_PASSKEY } from "utils/api.constant";
import { API_MAINTENANCE } from "utils/api.constant";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const MaintenanceMode = () => {
    const [passkey, setPasskey] = useState('');
    const [isLoggedIn, setLoggedIn] = useState(false);
    const history = useHistory();

    const handlePassKeyChange = (e) => {
        setPasskey(e.target.value);
    };

    const getMaintenance = ()=>{
        axios.get(API_MAINTENANCE)
        .then(response => {
           
            if (response.data.data === true) {
              // SetMaintenance(true)
                history.push('/maintenance');
            } 
            // else {
            //     history.push('/signin/employer');
            // }
           
        })
        .catch(error => {
            console.error('Error fetching maintenance mode:', error);
        });
   
    }

    const handleEnterPasskey = async () => {

        try {
            const responseData = await axios.post(API_MAINTENANCE_PASSKEY, {passkey});
            const correctPasskey = responseData.data.data.passkey;
            console.log(responseData.data)
            if (!correctPasskey) {
                alert('Wrong Passkey!');
            } else {
                history.push('/signin');
            }
        } catch (error) {
            console.error('Error:', error);
        }

        
    };

    React.useEffect(() => {
        if (!localStorage.getItem('maintenanceMode')) {
            history.push('/signin/employer')
        }
      }, [])

    return (
        <div className={clsx(styles.middle)}>
            <h1 className={clsx(styles.subheading, 'text-success', 'center')}>We're Sorry!</h1>
            <p id="desc-maintenance">This page is down for maintenance.</p>
            <p id="desc-maintenance">We are working to get it back up and running as soon as possible.</p>
            <hr/>

            <h2 className={clsx(styles.subheading, 'text-darkNavy', 'center')}>
                      <Form.Input
                        type="password"
                        name="passkey_code"
                        id="passkey_code"
                        underlined="dashed"
                        placeholder="Enter Passkey"
                        formGroupProps={{ className: clsx(styles.otpInput, 'ml-5', 'mr-5') }}
                        Required
                        onChange={handlePassKeyChange}
                      />
                      <button className='btn btn-success mtn-sm ml-2 pl-4 pt-1 pb-1 pr-4' onClick={()=>handleEnterPasskey()}>Ok</button>
                    </h2>
        </div>
    );
    
};

export default MaintenanceMode;