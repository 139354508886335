// @flow
import * as React from 'react'
import clsx from 'clsx'
import * as yup from 'yup'
import { has, keys } from 'lodash'
import { Form as FormComponent, Button, Alert } from 'components'
import { useForm } from 'react-hook-form'
import { Col, Form } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup'
import { CSSTransition } from 'react-transition-group'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import styles from './style.module.scss'
import { t } from 'i18next'



export const SetPassword = ({
  layoutPath,
  parentPath,
  history,
  title,
  description,
  onSubmit,
  loading,
  message
}) => {

  const { t } = useTranslation()
  const schema = yup.object().shape({
    password: yup
      .string()
      .min(8, t('resetPassword.setPassword.form.password.form.text1'))
      .matches(/^(?=.*[a-z])(?=.*[A-Z]).{1,}$/, {
        name: 'lettercases',
        message: t('resetPassword.setPassword.form.password.form.text2'),
      })
      .matches(/^(?=.*[0-9]).{1,}$/, {
        name: 'numeric',
        message: t('resetPassword.setPassword.form.password.form.text3'),
      })
      .matches(/^(?=.*[~!@#$%^&*()]).{1,}$/, {
        name: 'symbols',
        message:t('resetPassword.setPassword.form.password.form.text4'),
      }),
      password_confirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], t('resetPassword.setPassword.form.rewritePassword.error')),
  })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const [visiblePasswordCriteria, setVisiblePasswordCriteria] = React.useState(false)
  const { register, handleSubmit, errors, formState, watch } = useForm({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
  })

  React.useEffect(() => {
    if (!visiblePasswordCriteria && errors.password) setVisiblePasswordCriteria(true)
  }, [errors.password, visiblePasswordCriteria])

  return (
    <section className={clsx('container', 'py-4', styles.container)}>
      <div>
        <div className="headings">
          <h1 className="display-1">{title}</h1>
          <p className="mt-3 mb-1" dangerouslySetInnerHTML={{ __html: t("resetPassword.setPassword.description") }}></p>
          { message && (
            <Alert initialShow={true} closable className="mb-4 mr-lg-5">
              <p className="mb-0">
                { message.data } { message.success && <Link to="/signin"><strong>{t('resetPassword.haveAccount.link')}</strong></Link> }
              </p>
            </Alert>
          )}
        </div>
        <Form noValidate className={clsx(styles.form)} onSubmit={handleSubmit(onSubmit)}>
          <Form.Row>
            <FormComponent.Input
              disabled={loading}
              hasGrow
              password
              hasValidation
              ref={register}
              name="password"
              label={t('resetPassword.setPassword.form.password.label')}
              isInvalid={errors.password}
              isValid={!has(errors, 'password_confirmation') && !!watch('password')}
              formGroupProps={{ as: Col, sm: 12, className: clsx(visiblePasswordCriteria && 'mb-2') }}
            />

            <CSSTransition
              unmountOnExit
              timeout={500}
              in={visiblePasswordCriteria}
              classNames="transition-fade">
              <Col sm={12}>
                <ul className={clsx('cross-list', 'lg-icon')}>
                  {keys(schema.fields.password.exclusiveTests).map(test => (
                    <li
                      key={test}
                      className={clsx(
                        'font-weight-light',
                        !errors.password && 'checked',
                        errors.password && !keys(errors.password.types).includes(test)
                          ? 'checked'
                          : ''
                      )}>
                      {
                        schema.fields.password.tests.find(t => t.OPTIONS.name === test).OPTIONS
                          .message
                      }
                    </li>
                  ))}
                </ul>
              </Col>
            </CSSTransition>

            <FormComponent.Input
              disabled={loading}
              hasGrow
              password
              hasValidation
              ref={register}
              name="password_confirmation"
              label={t('resetPassword.setPassword.form.rewritePassword.label')}
              isInvalid={errors.password_confirmation}
              isValid={!has(errors, 'password_confirmation') && formState.isValid}
              formGroupProps={{ as: Col, sm: 12, className: clsx('mt-4') }}
              feedbackMessage={
                errors.password_confirmation
                  ? errors.password_confirmation.message
                  : t('resetPassword.setPassword.form.rewritePassword.success')
              }
            />
            <Col sm={12} xs={12} className="mt-2">
              <Button
                variant="coral"
                type="submit"
                disabled={!formState.isValid}
                block={isTabletOrMobile}
                loading={loading}
              >
                {t('resetPassword.setPassword.form.buttonNext')}
              </Button>
            </Col>
          </Form.Row>
        </Form>
      </div>
    </section>
  )
}

SetPassword.defaultProps = {
  title: t("resetPassword.setPassword.title"),
  description: t("resetPassword.setPassword.description"),
  onSubmit: console.info,
}

export default SetPassword
