import axios from "utils/axios";

import {
    INVESTMENT_SET_DATA,
    INVESTMENT_LOADING,
    INVESTMENT_RESET,
    INVESTMENT_PAGINATION,
    INVESTMENT_SET_DATE,
} from "../reducers/invesmenReducer";
import { SET_AUTH_DETECTOR_FALSE } from "../reducers/authDetectorReducer";
import getDate from "utils/dateConvert";
import {
    API_EXC_RATE,
    API_EXC_RATE_GS,
    API_FUND_FACT,
    API_MARKET_REVIEW,
    API_UNIT_PRICE,
    API_UNIT_PRICE_GS,
} from "utils/api.constant";
import { formatCurrency } from "utils/numberConvert";

// ----------
// start Dplk
// ----------
export const dplkDataPaginationActions =
    (page = 1) =>
    async (dispatch, getState) => {
        const {
            invesment: { start, end, take, order, performanceDate, pageCount },
        } = getState();

        const { lang } = getState().language;

        const config = {
            headers: {
                "x-accept-lang": lang,
            },
        };

        dispatch({
            type: INVESTMENT_LOADING,
        });

        dispatch({
            type: INVESTMENT_PAGINATION,
            payload: {
                page,
                take,
                order,
                pageCount,
                performanceDate,
            },
        });

        await axios
            .get(
                `${API_UNIT_PRICE}?page=${page}&take=${take}&order=${order}${start!=""?"&from="+start:""}${end!=""?"&to="+end:""}&search_by=${performanceDate}`,
                config
            )
            .then((res) => {
                if (res.data.statusCode === 200) {
                    const data = res.data.data.map((it, index) => {
                        return [
                            { content: index, name: "id" },
                            { content: it.inv_type_desc, name: "name" },
                            { content: it.currency_code, name: "currency" },
                            {
                                content: getDate(it.efctv_dt),
                                name: "date",
                            },
                            {
                                content: (
                                    <span className="text-success">
                                        {formatCurrency(it.price)}
                                    </span>
                                ),
                                name: "price",
                                attrs: { colSpan: 2, className: "text-right" },
                            },
                        ];
                    });

                    dispatch({
                        type: INVESTMENT_SET_DATA,
                        payload: data,
                    });

                    dispatch({
                        type: INVESTMENT_PAGINATION,
                        payload: {
                            page,
                            take,
                            order,
                            pageCount: res.data.meta.pageCount,
                            performanceDate,
                        },
                    });
                }
            })
            .catch((err) => {
                if (err.response.data.statusCode === 401) {
                    dispatch({
                        type: SET_AUTH_DETECTOR_FALSE,
                        payload: err.response.data.message,
                    });
                }
            });
    };

export const getDplkDataActions =
    (start, end) => async (dispatch, getState) => {
        const {
            invesment: { page, take, order, performanceDate },
        } = getState();
        const { lang } = getState().language;

        const config = {
            headers: {
                "x-accept-lang": lang,
            },
        };

        dispatch({
            type: INVESTMENT_RESET,
        });

        dispatch({
            type: INVESTMENT_LOADING,
        });

        await axios
            .get(
                `${API_UNIT_PRICE}?page=${page}&take=${take}&order=${order}${start!=""?"&from="+start:""}${end!=""?"&to="+end:""}&search_by=${performanceDate}`,
                config
            )
            .then((res) => {
                if (res.data.statusCode === 200) {
                    const data = res.data.data.map((it, index) => {
                        return [
                            { content: index, name: "id" },
                            { content: it.inv_type_desc, name: "name" },
                            { content: it.currency_code, name: "currency" },
                            {
                                content: getDate(it.efctv_dt),
                                name: "date",
                            },
                            {
                                content: (
                                    <span className="text-success">
                                        {formatCurrency(it.price)}
                                    </span>
                                ),
                                name: "price",
                                attrs: { colSpan: 2, className: "text-right" },
                            },
                        ];
                    });

                    dispatch({
                        type: INVESTMENT_SET_DATA,
                        payload: data,
                    });

                    dispatch({
                        type: INVESTMENT_PAGINATION,
                        payload: {
                            page,
                            take,
                            order,
                            pageCount: res.data.meta.pageCount,
                            performanceDate,
                        },
                    });

                    dispatch({
                        type: INVESTMENT_SET_DATE,
                        payload: {
                            start,
                            end,
                        },
                    });
                }
            })
            .catch((err) => {
                if (err.response.data.statusCode === 401) {
                    dispatch({
                        type: SET_AUTH_DETECTOR_FALSE,
                        payload: err.response.data.message,
                    });
                }else{
                    dispatch({
                        type: INVESTMENT_SET_DATA,
                        payload: [],
                    });

                    dispatch({
                        type: INVESTMENT_PAGINATION,
                        payload: {
                            page,
                            take,
                            order,
                            pageCount: 1,
                            performanceDate,
                        },
                    });
                }
            });
    };

export const getDplkDataByPerformanceAction =
    (id) => async (dispatch, getState) => {
        const {
            invesment: { page, take, order, start, end },
        } = getState();
        const { lang } = getState().language;

        const config = {
            headers: {
                "x-accept-lang": lang,
            },
        };

        dispatch({
            type: INVESTMENT_RESET,
        });

        dispatch({
            type: INVESTMENT_LOADING,
        });

        await axios
            .get(
                `${API_UNIT_PRICE}?page=${page}&take=${take}&order=${order}${start!=""?"&from="+start:""}${end!=""?"&to="+end:""}&search_by=${id}`,
                config
            )
            .then((res) => {
                if (res.data.statusCode === 200) {
                    const data = res.data.data.map((it, index) => {
                        return [
                            { content: index, name: "id" },
                            { content: it.inv_type_desc, name: "name" },
                            { content: it.currency_code, name: "currency" },
                            {
                                content: getDate(it.efctv_dt),
                                name: "date",
                            },
                            {
                                content: (
                                    <span className="text-success">
                                        {formatCurrency(it.price)}
                                    </span>
                                ),
                                name: "price",
                                attrs: { colSpan: 2, className: "text-right" },
                            },
                        ];
                    });

                    dispatch({
                        type: INVESTMENT_SET_DATA,
                        payload: data,
                    });

                    dispatch({
                        type: INVESTMENT_PAGINATION,
                        payload: {
                            page,
                            take,
                            order,
                            pageCount: res.data.meta.pageCount,
                            performanceDate: id,
                        },
                    });

                    dispatch({
                        type: INVESTMENT_SET_DATE,
                        payload: {
                            start,
                            end,
                        },
                    });
                }
            })
            .catch((err) => {
                if (err.response.data.statusCode === 401) {
                    dispatch({
                        type: SET_AUTH_DETECTOR_FALSE,
                        payload: err.response.data.message,
                    });
                }
            });
    };

// ----------
// end Dplk
// ----------

// ----------
// start unit group
// ----------

export const unitGroupSavingPaginationActions =
    (page = 1, take = 10, order = "ASC") =>
    async (dispatch, getState) => {
        const {
            invesment: { start, end },
        } = getState();
        const { lang } = getState().language;

        const config = {
            headers: {
                "x-accept-lang": lang,
            },
        };

        dispatch({
            type: INVESTMENT_LOADING,
        });

        await axios
            .get(
                `${API_UNIT_PRICE_GS}?page=${page}&take=${take}&order=${order}${start!=""?"&from="+start:""}${end!=""?"&to="+end:""}`,
                config
            )
            .then((res) => {
                if (res.data.statusCode === 200) {
                    const data = res.data.data.map((it, index) => {
                        return [
                            { content: index, name: "id" },
                            { content: it.name, name: "name" },
                            { content: it.curr_code, name: "currency" },
                            {
                                content: it.efctv_dt,
                                name: "date",
                            },
                            {
                                content: (
                                    <span className="text-success">
                                        {formatCurrency(it.price)}
                                    </span>
                                ),
                                name: "price",
                            },
                        ];
                    });

                    dispatch({
                        type: INVESTMENT_SET_DATA,
                        payload: data,
                    });

                    dispatch({
                        type: INVESTMENT_PAGINATION,
                        payload: {
                            page,
                            take,
                            order,
                            pageCount: res.data.meta.pageCount,
                        },
                    });
                }
            })
            .catch((err) => {
                if (err.response.data.statusCode === 401||err.response.data.statusCode === 400) {
                    dispatch({
                        type: SET_AUTH_DETECTOR_FALSE,
                        payload: err.response.data.message,
                    });
                }
            });
    };

export const getUnitGroupSavingActions =
    (start, end) => async (dispatch, getState) => {
        const {
            invesment: { page, take, order },
        } = getState();
        const { lang } = getState().language;

        const config = {
            headers: {
                "x-accept-lang": lang,
            },
        };

        dispatch({
            type: INVESTMENT_RESET,
        });

        dispatch({
            type: INVESTMENT_LOADING,
        });

        await axios
            .get(
                `${API_UNIT_PRICE_GS}?page=${page}&take=${take}&order=${order}${start!=""?"&from="+start:""}${end!=""?"&to="+end:""}`,
                config
            )
            .then((res) => {
                if (res.data.statusCode === 200) {
                    const data = res.data.data.map((it, index) => {
                        return [
                            { content: index, name: "id" },
                            { content: it.name, name: "name" },
                            { content: it.curr_code, name: "currency" },
                            {
                                content: it.efctv_dt,
                                name: "date",
                            },
                            {
                                content: (
                                    <span className="text-success">
                                        {formatCurrency(it.price)}
                                    </span>
                                ),
                                name: "price",
                            },
                        ];
                    });

                    dispatch({
                        type: INVESTMENT_SET_DATA,
                        payload: {
                            headers: [
                                { title: "Fund Name", name: "name" },
                                { title: "Currency", name: "currency" },
                                { title: "As of Date", name: "date" },
                                { title: "Price", name: "price" },
                            ],
                            data: data,
                            pageCount: res.data.meta.pageCount,
                        },
                    });

                    dispatch({
                        type: INVESTMENT_PAGINATION,
                        payload: {
                            page,
                            take,
                            order,
                        },
                    });

                    dispatch({
                        type: INVESTMENT_SET_DATE,
                        payload: {
                            start,
                            end,
                        },
                    });
                }
            })
            .catch((err) => {
                if (err.response.data.statusCode === 401) {
                    dispatch({
                        type: SET_AUTH_DETECTOR_FALSE,
                        payload: err.response.data.message,
                    });
                }
            });
    };

// ----------
// end unit group
// ----------

// ----------
// start nilai tukar dplk
// ----------
export const exchangeRateDplkPaginationActions =
    (page = 1) =>
    async (dispatch, getState) => {
        const {
            invesment: { start, end, take, order, pageCount },
        } = getState();
        const { lang } = getState().language;

        const config = {
            headers: {
                "x-accept-lang": lang,
            },
        };

        dispatch({
            type: INVESTMENT_LOADING,
        });

        dispatch({
            type: INVESTMENT_PAGINATION,
            payload: {
                page,
                take,
                order,
                pageCount,
            },
        });

        await axios
            .get(
                `${API_EXC_RATE}?page=${page}&take=${take}&order=${order}${start!=""?'&from='+start:""}${end!=""?"&to="+end:""}`,
                config
            )
            .then((res) => {
                if (res.data.statusCode === 200) {
                    const data = res.data.data.map((it, index) => {
                        return [
                            { content: index, name: "id" },
                            { content: getDate(it.efctv_dt), name: "date" },
                            {
                                content: formatCurrency(it.buy_rt),
                                name: "bought",
                            },
                            {
                                content: formatCurrency(it.sell_rt),
                                name: "sell",
                            },
                        ];
                    });

                    dispatch({
                        type: INVESTMENT_SET_DATA,
                        payload: data,
                    });

                    dispatch({
                        type: INVESTMENT_PAGINATION,
                        payload: {
                            page,
                            take,
                            order,
                            pageCount: res.data.meta.pageCount,
                        },
                    });
                }
            })
            .catch((err) => {
                if (err.response.data.statusCode === 401) {
                    dispatch({
                        type: SET_AUTH_DETECTOR_FALSE,
                        payload: err.response.data.message,
                    });
                }
            });
    };

export const getExchangeRateDplkActions =
    (start, end) => async (dispatch, getState) => {
        const {
            invesment: { page, take, order },
        } = getState();
        const { lang } = getState().language;

        const config = {
            headers: {
                "x-accept-lang": lang,
            },
        };

        dispatch({
            type: INVESTMENT_RESET,
        });

        dispatch({
            type: INVESTMENT_LOADING,
        });

        await axios
            .get(
                `${API_EXC_RATE}?page=${page}&take=${take}&order=${order}${start!=""?"&from="+start:""}${end!=""?"&to="+end:""}`,
                config
            )
            .then((res) => {
                if (res.data.statusCode === 200) {
                    const data = res.data.data.map((it, index) => {
                        return [
                            { content: index, name: "id" },
                            { content: getDate(it.efctv_dt), name: "date" },
                            {
                                content: formatCurrency(it.buy_rt),
                                name: "bought",
                            },
                            {
                                content: formatCurrency(it.sell_rt),
                                name: "sell",
                            },
                        ];
                    });

                    dispatch({
                        type: INVESTMENT_SET_DATA,
                        payload: data,
                    });

                    dispatch({
                        type: INVESTMENT_PAGINATION,
                        payload: {
                            page,
                            take,
                            order,
                            pageCount: res.data.meta.pageCount,
                        },
                    });

                    dispatch({
                        type: INVESTMENT_SET_DATE,
                        payload: {
                            start,
                            end,
                        },
                    });
                }
            })
            .catch((err) => {
                if (err.response.data.statusCode === 401) {
                    dispatch({
                        type: SET_AUTH_DETECTOR_FALSE,
                        payload: err.response.data.message,
                    });
                }else{
                    dispatch({
                        type: INVESTMENT_SET_DATA,
                        payload: [],
                    });

                    dispatch({
                        type: INVESTMENT_PAGINATION,
                        payload: {
                            page,
                            take,
                            order,
                            pageCount: 1,
                        },
                    });
                }
            });
    };

// ----------
// end nilai tukar dplk
// ----------

// ----------
// start nilai tukar group savings
// ----------

export const exchangeRateGsPaginationActions =
    (page = 1, take = 10, order = "ASC") =>
    async (dispatch, getState) => {
        const {
            invesment: { start, end },
        } = getState();
        const { lang } = getState().language;

        const config = {
            headers: {
                "x-accept-lang": lang,
            },
        };

        dispatch({
            type: INVESTMENT_LOADING,
        });

        await axios
            .get(
                `${API_EXC_RATE_GS}?page=${page}&take=${take}&order=${order}${start!=""?"&from="+start:""}${end!=""?"&to="+end:""}`,
                config
            )
            .then((res) => {
                if (res.data.statusCode === 200) {
                    const data = res.data.data.map((it, index) => {
                        return [
                            { content: index, name: "id" },
                            { content: it.efctv_dt, name: "date" },
                            {
                                content: formatCurrency(it.buy_rt),
                                name: "bought",
                            },
                            {
                                content: formatCurrency(it.sell_rt),
                                name: "sell",
                            },
                        ];
                    });

                    dispatch({
                        type: INVESTMENT_SET_DATA,
                        payload: {
                            headers: [
                                { title: "Tanggal", name: "date" },
                                { title: "Beli", name: "bought" },
                                { title: "Jual", name: "sell" },
                            ],
                            data: data,
                            pageCount: res.data.meta.pageCount,
                        },
                    });

                    dispatch({
                        type: INVESTMENT_PAGINATION,
                        payload: {
                            page,
                            take,
                            order,
                        },
                    });
                } else {
                }
            })
            .catch((err) => {
                if (err.response.data.statusCode === 401) {
                    dispatch({
                        type: SET_AUTH_DETECTOR_FALSE,
                        payload: err.response.data.message,
                    });
                }
            });
    };

export const getExchangeRateGSavingActions =
    (start, end) => async (dispatch, getState) => {
        const {
            invesment: { page, take, order },
        } = getState();
        const { lang } = getState().language;

        const config = {
            headers: {
                "x-accept-lang": lang,
            },
        };

        dispatch({
            type: INVESTMENT_RESET,
        });

        dispatch({
            type: INVESTMENT_LOADING,
        });

        await axios
            .get(
                `${API_EXC_RATE_GS}?page=${page}&take=${take}&order=${order}${start!=""?"&from="+start:""}${end!=""?"&to="+end:""}`,
                config
            )
            .then((res) => {
                if (res.data.statusCode === 200) {
                    const data = res.data.data.map((it, index) => {
                        return [
                            { content: index, name: "id" },
                            { content: it.efctv_dt, name: "date" },
                            {
                                content: formatCurrency(it.buy_rt),
                                name: "bought",
                            },
                            {
                                content: formatCurrency(it.sell_rt),
                                name: "sell",
                            },
                        ];
                    });

                    dispatch({
                        type: INVESTMENT_SET_DATA,
                        payload: {
                            headers: [
                                { title: "Tanggal", name: "date" },
                                { title: "Beli", name: "bought" },
                                { title: "Jual", name: "sell" },
                            ],
                            data: data,
                            pageCount: res.data.meta.pageCount,
                        },
                    });

                    dispatch({
                        type: INVESTMENT_PAGINATION,
                        payload: {
                            page,
                            take,
                            order,
                        },
                    });

                    dispatch({
                        type: INVESTMENT_SET_DATE,
                        payload: {
                            start,
                            end,
                        },
                    });
                }
            })
            .catch((err) => {
                if (err.response.data.statusCode === 401) {
                    dispatch({
                        type: SET_AUTH_DETECTOR_FALSE,
                        payload: err.response.data.message,
                    });
                }
            });
    };

// ----------
// end nilai tukar group savings
// ----------

// ----------
// start Fund Fact Sheet DPLK
// ----------

export const getFundFactSheetAction = () => (dispatch, getState) => {
    const { lang } = getState().language;

    const config = {
        headers: {
            "x-accept-lang": lang,
        },
    };

    return new Promise(async (resolve) => {
        await axios
            .get(API_FUND_FACT, config)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    resolve({
                        success: true,
                        data: res.data,
                    });
                }
            })
            .catch((err) => {
                if (err.response.data.statusCode === 401) {
                    dispatch({
                        type: SET_AUTH_DETECTOR_FALSE,
                        payload: err.response.data.message,
                    });
                }
                resolve({
                    success: false,
                    message: err.response.message,
                });
            });
    });
};

// ----------
// end Fund Fact Sheet DPLK
// ----------

// ----------
// start Monthly Market Review
// ----------

export const getMonthlyMarketAction = () => (_, getState) => {
    const { lang } = getState().language;

    const config = {
        headers: {
            "x-accept-lang": lang,
        },
    };

    return new Promise(async (resolve) => {
        await axios
            .get(API_MARKET_REVIEW, config)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    resolve({
                        success: true,
                        data: res.data.data,
                    });
                }
            })
            .catch((err) => {
                resolve({
                    success: false,
                    message: err.response.message,
                });
            });
    });
};

// ----------
// end Monthly Market Review
// ----------

export const resetData = () => (dispatch) => {
    dispatch({
        type: INVESTMENT_RESET,
    });
};
