// @flow
import * as React from 'react'
import ReactDOM from 'react-dom'
import clsx from 'clsx'
import { has } from 'lodash'
import { Button, Col, Container, Nav, Row } from 'react-bootstrap'
import { CSSTransition } from 'react-transition-group'
import { matchPath, NavLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { settingLanguageActions } from 'store/actions/languageSettingActions'

// Styles
import styles from './style.module.scss'

// eslint-disable-next-line no-empty-pattern
const Navigation = ({
  mode,
  position,
  navItems,
  homeLink,
  onChildNavToggleState,
  childNavSelector,
  showed,
  onClose,
  signOutHandle
}) => {

  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()
  const langId = useSelector(state => state.language.lang)
  const [childNavs, setChildNavs] = React.useState(null)
  const [lang, setLang] = React.useState('id')

  const handleCloseChild = () => setChildNavs(null)
  const navOnClick = to => {
    const hasChildsNavs = navItems?.filter(
      item =>
        !!matchPath(to, { path: item.to, exact: true }) &&
        has(item, 'submenu') &&
        !!item.submenu?.length
    )
    if (!hasChildsNavs?.length) onClose(!showed)
  }
  
  const settingLanguage = React.useCallback((val) => {
    dispatch(settingLanguageActions(val))
    i18n.changeLanguage(val)
  },[])

  React.useEffect(() => {
    onChildNavToggleState(!!childNavs)
  }, [childNavs])

  React.useLayoutEffect(() => {
    const hasChildsNavs = navItems?.filter(
      item =>
        !!matchPath(location.pathname, { path: item.to }) &&
        has(item, 'submenu') &&
        !!item.submenu?.length
    )

    if (!!hasChildsNavs?.length) setChildNavs(hasChildsNavs[0].submenu)
  }, [location, navItems])

  React.useEffect(() => {
    i18n.changeLanguage(langId)
  },[])

  return (
    <>
      <CSSTransition in={showed} timeout={300} classNames="transition-slide" unmountOnExit>
        <nav className={clsx('navigation', styles.navigation)} style={{ position }}>
          <Nav
            onSelect={selectedKey => alert(`selected ${selectedKey}`)}
            as="ul"
            className={clsx(styles.navigation__nav)}>
            {navItems?.map(({ label, icon, to }, k) => (
              <Nav.Item as="li" key={k}>
                <Nav.Link
                  to={to}
                  as={NavLink}
                  className={clsx(styles.navigation__nav__link)}
                  activeClassName={styles['has-active']}
                  onClick={e => navOnClick(to)}>
                  {!!icon && <i className={clsx(`mi-${icon}`)} />}
                  <span className={clsx(styles.navigation__nav__item__label)}>{label}</span>
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>

          {!!document.querySelector(childNavSelector) &&
            ReactDOM.createPortal(
              <CSSTransition
                in={!!childNavs?.length}
                timeout={300}
                classNames="transition-slide"
                unmountOnExit>
                <div className={clsx(styles.navigation__childNav)}>
                  <Nav as="ul">
                    <Nav.Item as="li">
                      <Nav.Link
                        className={clsx([
                          styles.navigation__childNav__link,
                          styles.navigation__childNav__back,
                        ])}
                        onClick={handleCloseChild}>
                        <i className={clsx('mi-chevron-outlined-left lg')} />
                        <span>{t('genLang.back')}</span>
                      </Nav.Link>
                    </Nav.Item>
                    {childNavs?.map(({ label, to,link }, k) => (
                      <Nav.Item as="li" key={k}>
                        {!link?<Nav.Link
                          exact
                          to={to}
                          as={NavLink}
                          className={clsx(styles.navigation__childNav__link)}
                          activeClassName={styles['has-active']}
                          onClick={e => to}>
                          <span>{label}</span>
                        </Nav.Link>:<a className={clsx(styles.sidebar__childNav__link) + ' nav-link'} target='_blank' href={link} rel='noopener noreferrer'>{label}</a>
                        }
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
              </CSSTransition>,
              document.querySelector(childNavSelector)
            )}

          <Container fluid className={clsx(styles.navigation__footer)}>
            <Row noGutters className={clsx(styles.navigation__footer__lang)}>
              <Col xs="auto">
                <Button
                  onClick={() => settingLanguage('en')}
                  variant="outline-light"
                  className={clsx(styles.navigation__footer__lang__selector, langId === 'en' && styles.btnLang__active)}>
                  English
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  onClick={() => settingLanguage('id')}
                  variant="outline-light"
                  className={clsx([
                    styles.navigation__footer__lang__selector,
                    styles['has-active'],
                    langId === 'id' && styles.btnLang__active
                  ])}>
                  Indonesia
                </Button>
              </Col>
            </Row>
            <Row>
              <Button onClick={signOutHandle} className={clsx(styles.navigation__footer__signout)} variant="outline-danger">
                {t("dashboard.btn.logOut")}
              </Button>{' '}
            </Row>
          </Container>
        </nav>
      </CSSTransition>
    </>
  )
}

Navigation.defaultProps = ({
  mode: 'employee',
  position: 'absolute',
  onChildNavToggleState: state => {},
  childNavSelector: '#__manulifeApp',
  showed: false,
  onClose: () => {},
})

export default Navigation
