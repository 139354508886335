import * as React from "react";
import { useHistory,useLocation } from "react-router-dom";

import useAuth from "hooks/useAuth";

const AuthGuardSign = (props) => {
    const history = useHistory();
    const location=useLocation()
    const { employerIsAuthenticated, employeeIsAuthenticated, stillLogin } = useAuth();

    React.useEffect(() => {
        stillLogin();
    }, []);
    if(location.pathname.search("auth") && location.pathname.search("signin")==-1 ){
        return <>{props.children}</>;
    }else{
        if (employeeIsAuthenticated && !employerIsAuthenticated) {
            return <>{history.push("/employee")}</>;
        } else if (employerIsAuthenticated && !employeeIsAuthenticated) {
            return <>{history.push("/employer")}</>;
        } else {
            return <>{props.children}</>;
        }
    }
};

export default AuthGuardSign;
