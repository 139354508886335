
export const en = {
    notification: {
        iconNotif: {
            newNotif: 'New Notification',
            read: 'Mark Already read',
            buttonAllNotif: 'Show All'
        },
        allNotif: {
            title: {
                text1: 'Notification',
                text2: 'Center'
            }
        },
        detailNotif: {
            backButton: 'back'
        }
    }
}

export const id = {
    notification: {
        iconNotif: {
            newNotif: 'Notifikasi Baru',
            read: 'Tandai Sudah dibaca',
            buttonAllNotif: 'Tampilkan Semua'
        },
        allNotif: {
            title: {
                text1: 'Pusat',
                text2: 'Notifikasi'
            }
        },
        detailNotif: {
            backButton: 'Kembali'
        }
    }
}