// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_label__PWi0X {
  line-height: inherit;
  display: flex;
  align-items: center;
}
.style_label__PWi0X .style_dot__26EJs {
  width: 0.75em;
  height: 0.75em;
  background: currentColor;
  display: inline-block;
  border-radius: 0.75em;
  margin-right: 0.375em;
}`, "",{"version":3,"sources":["webpack://./src/components/StatusText/style.module.scss"],"names":[],"mappings":"AAEA;EACE,oBAAA;EACA,aAAA;EACA,mBAAA;AADF;AAGE;EAEE,aADO;EAEP,cAFO;EAGP,wBAAA;EACA,qBAAA;EACA,qBALO;EAMP,qBAAA;AAFJ","sourcesContent":["@import 'scss/for-module';\n\n.label {\n  line-height: inherit;\n  display: flex;\n  align-items: center;\n\n  .dot {\n    $size: 0.75em;\n    width: $size;\n    height: $size;\n    background: currentColor;\n    display: inline-block;\n    border-radius: $size;\n    margin-right: $size / 2;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `style_label__PWi0X`,
	"dot": `style_dot__26EJs`
};
export default ___CSS_LOADER_EXPORT___;
