import { combineReducers } from "@reduxjs/toolkit";

import LanguageSettingReducer from "store/reducers/languageSettingReducer";
import MenuSidebarReducer from "./menuSidebarReducer";
import FaqReducer from "./faqReducer";
import DescriptionPageReducer from "./descriptionPageReducer";
import InvesmentReducer from "./invesmenReducer";

import NewsReducer from "./newsReducer";
import NotificationsReducer from "./notificationsReducer";

import EmployeeBallanceReducer from "./employee/ballanceReducer";
import EmployeeClaimReducer from "./employee/claimReducer";
import EmployeeProfilReducer from "./employee/profilReducer";
import EmployeeReportsReducer from "./employee/reportsReducer";
import EmployeeResetPasswordReducer from "./employee/resetPasswordReducer";
import EmployeeChangePasswordReducer from "./employee/changePasswordReducer";
import EmployeeInvestDirectReducer from "./employee/investDirectReducer";
import SignInReducer from "./signInReducer";
import AuthDetectorReducer from "./authDetectorReducer";
import EmployerResetPasswordReducer from "./employer/resetPasswordReducer";
import EmployerAttendeeReducer from "./employer/attendeeReducer";
import EmployerClaimReducer from "./employer/claimReducer";
import EmployerIuranReducer from "./employer/iuranReducer";
import EmployerBallanceReducer from "./employer/ballanceReducer";
import EmployerReportsReducer from "./employer/reportsReducer";
import EmployerChangePasswordReducer from "./employer/changePasswordReducer";
import EmployerProfilReducer from "./employer/profilReducer";
import sosmedReducer from "./sosmedReducer";
import appSetupReducer from "./appSetupReducer";
import sessionReducer from "./sessionReducer";

const RootReducer = combineReducers({
    session: sessionReducer,
    language: LanguageSettingReducer,
    menuSidebar: MenuSidebarReducer,
    faq: FaqReducer,
    descriptionPage: DescriptionPageReducer,
    invesment: InvesmentReducer,
    sosmedReducer: sosmedReducer,
    appSetup: appSetupReducer,

    signin: SignInReducer,
    news: NewsReducer,
    notifications: NotificationsReducer,
    authDetector: AuthDetectorReducer,

    employeeProfil: EmployeeProfilReducer,
    employeeBallance: EmployeeBallanceReducer,
    employeeClaim: EmployeeClaimReducer,
    employeeReports: EmployeeReportsReducer,
    employeeResetPassword: EmployeeResetPasswordReducer,
    employeeChangePassword: EmployeeChangePasswordReducer,
    employeeInvestDirect: EmployeeInvestDirectReducer,

    employerResetPassword: EmployerResetPasswordReducer,
    employerAttendee: EmployerAttendeeReducer,
    employerClaim: EmployerClaimReducer,
    employerBallance: EmployerBallanceReducer,
    employerReports: EmployerReportsReducer,
    employerChangePassword: EmployerChangePasswordReducer,
    employerProfil: EmployerProfilReducer,
    employerIuran: EmployerIuranReducer,
});

export default RootReducer;
