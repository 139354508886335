// @flow
/* eslint-disable react/no-multi-comp */
import clsx from 'clsx'
import * as React from 'react'
import SafeAnchor from 'react-bootstrap/SafeAnchor'

const defaultProps = {
  active: false,
  disabled: false,
  activeLabel: '(current)',
}

const PageItem = ((React.forwardRef(
  ({ active, disabled, className, style, activeLabel, children, ...props }, ref) => {
    const Component = active || disabled ? 'span' : SafeAnchor

    return (
      <li ref={ref} style={style} className={clsx(className, 'page-item', { active, disabled })}>
        <Component {...props} className={clsx('page-link')} disabled={disabled}>
          {children}
          {active && activeLabel && <span className="sr-only">{activeLabel}</span>}
        </Component>
      </li>
    )
  },
)))

PageItem.displayName = 'PageItem'
PageItem.defaultProps = defaultProps

export default PageItem

function createButton(name, defaultValue, label = name) {
  function Button({ children, ...props }) {
    return (
      <PageItem {...props} className={clsx(props.className, `page-item__${name.toLowerCase()}`)}>
        <span aria-hidden="true">{children || defaultValue}</span>
        <span className="sr-only">{label}</span>
      </PageItem>
    )
  }

  Button.displayName = name

  return Button
}

export const First = createButton(
  'First',
  <i className="mi-double-chevron-left"></i>,
  'First',
)
export const Prev= createButton(
  'Prev',
  <i className="mi-chevron-left"></i>,
  'Previous',
)
export const Ellipsis = createButton('Ellipsis', '…', 'More')
export const Next = createButton(
  'Next',
  <i className="mi-chevron-right"></i>,
  'Next',
)
export const Last = createButton(
  'Last',
  <i className="mi-double-chevron-right"></i>,
  'Last',
)
