// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__gK\\+r6 {
  flex: 1 1 auto;
}

.style_btnDownload__CsxF5 {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
}`, "",{"version":3,"sources":["webpack://./src/views/Guides/FormDownload/style.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,WAAA;EACA,UAAA;EACA,SAAA;EACA,YAAA;EACA,uBAAA;AACF","sourcesContent":[".wrapper {\n  flex: 1 auto;\n}\n\n.btnDownload{\n  width: 100%;\n  padding: 0;\n  margin: 0;\n  border: none;\n  background: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__gK+r6`,
	"btnDownload": `style_btnDownload__CsxF5`
};
export default ___CSS_LOADER_EXPORT___;
