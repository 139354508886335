
export const en = {
    resetPassword: {
         title: 'Reset Password',
         sReset: 'Congratulation! Successfully changed password',
         form: {
             email: {
                 label: 'Email/Phone',
                 label_gn: 'Group Number',
                 toolTip: 'Enter your Email Address (xxxx@domain.co.id or xxxx@domain.com) OR Phone Number (62XXXXXXXXX)',
                 toolTip_gn: 'Enter the Group Number printed on the Group Welcome Letter'
             },
             oldPas:'Old Password',
             newPas:'New Password',
             btnNext: 'Next'
         },
         haveAccount: {
             text: "Do not have an EB<i>Click</i> account yet?",
             link: ' Sign Up Now',
             labelSignIn:'Sign In Now'
         },
         verification: {
             title: 'Reset Password',
             text1: 'Please enter the OTP for validation:',
             text2: 'We just sent a 6-digit One Time Password (OTP) to',
             list1: 'OTP will expire in',
             list2: 'Did not receive OTP?',
             list3: 'Want to use different account?',
             btnResendOtp: 'Resend OTP',
             btnChangeData: 'Back to Homepage'
         },
         resendOtp: {
             title: 'Resend OTP',
             description: 'Select one of the methods below to get an OTP'
         },
         setPassword: {
            changePassword:{
                text1:"Change",
                text2:"Password"
            },
             alertSignIn: 'Please SignIn',
             title: 'Reset Password',
             description: 'Please create your new EB<i>Click</i> account password.',
             form: {
                label:'Please complete the following fields to reset your EBClick account password',
                 password: {
                     label: 'New Password',
                     form: {
                         text1: 'Minimum 8 characters',
                         text2: 'Use both UPERCASE and lowercase letters (example: Aa)',
                         text3: 'Use number (example: 1234)',
                         text4: 'Use symbol (example: !@#$)',
                         text5: `New Password' and 'Confirm New Password' must be match.`
                     }
                 },
                 rewritePassword: {
                     label: 'Confirm New Password',
                     error: `'New Password' and 'Confirm New Password' must be match`,
                     success:'Password meets all criteria'
                 },
                 buttonNext: 'Reset',
                 buttonCancel: 'Cancel'
             }
         }
    },
    otpPhone:"Phone"
 }

 export const id = {
     resetPassword: {
         title: 'Atur Ulang Kata Sandi',
         sReset: 'Selamat! Berhasil mengubah kata sandi',
         form: {
             email: {
                 label: 'Email/Ponsel',
                 label_gn: 'Nomor Group',
                 toolTip: 'Masukkan Alamat Email Anda (xxxx@domain.co.id atau xxxx@domain.com) ATAU Nomor Ponsel (62XXXXXXXXX)',
                 toolTip_gn: 'Masukkan Nomor Group yang tercantum di Welcome Letter Group'
             },
             oldPas:'Kata Sandi lama',
             newPas:'Kata Sandi Baru',
             btnNext: 'Selanjutnya'
         },
         haveAccount: {
             text: 'Belum punya akun EB<i>Click</i>? ',
             link: 'Daftar Sekarang',
             labelSignIn:'Masuk Sekarang'
         },
         verification: {
             title: 'Atur ulang kata sandi',
             text1: 'Silakan masukkan OTP untuk validasi:',
             text2: 'Kami telah mengirimkan 6-digit One Time Password (OTP) ke',
             list1: 'OTP akan kedaluwarsa dalam',
             list2: 'Belum menerima OTP?',
             list3: 'Ingin menggunakan akun lain?',
             btnResendOtp: 'Kirim Ulang OTP',
             btnChangeData: 'Kembali ke Halaman Utama'
         },
         resendOtp: {
             title: 'Kirim Ulang OTP',
             description: 'Pilih salah satu metode di bawah ini untuk mendapatkan OTP'
         },
         setPassword: {
            changePassword:{
                text1:"Ubah",
                text2:"Kata Sandi"
            },
             alertSignIn: 'Silakkan Masuk',
             title: 'Atur Ulang Kata Sandi',
             description: 'Silakan membuat kata sandi baru untuk akun EB<i>Click</i> Anda.',
             form: {
                label:'Harap lengkapi isian berikut untuk mengganti kata sandi akun EB<i>Click</i> Anda',
                 password: {
                     label: 'Kata Sandi Baru',
                     form: {
                         text1: 'Minimal 8 karakter',
                         text2: 'Gunakan huruf KAPITAL dan kecil (contoh: Aa)',
                         text3: 'Gunakan angka (contoh: 1234)',
                         text4: 'Gunakan simbol (contoh: !@#$)',
                         text5: `'Kata Sandi Baru' dan 'Ulangi Kata Sandi Baru' harus sama`
                     }
                 },
                 rewritePassword: {
                     label: 'Ulangi Kata Sandi Baru',
                     error: `'Kata Sandi Baru' dan 'Ulangi Kata Sandi Baru' harus sama`,
                     success:'Kata Sandi memenuhi semua kriteria'
                 },
                 buttonNext: 'Atur Ulang',
                 buttonCancel: 'Batal'
             }
         }
    },
    otpPhone:"Ponsel"
 }
