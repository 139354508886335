// @flow
import * as React from "react";
import clsx from "clsx";
import { has, isEmpty } from "lodash";
import { InputGroup } from "..";
import { Dropdown, Form } from "react-bootstrap";

const SelectToggler = React.forwardRef(
    (
        {
            "aria-expanded": ariaExpanded,
            "aria-haspopup": ariaHasPopup,
            children,
            formControlProps,
            hasValidation,
            label: Label,
            name,
            hasGrow,
            onClick,
            placeholder,
            underlined,
            value,
            ...rest
        },
        ref
    ) => {
        return (
            <InputGroup
                {...rest}
                className={clsx(has(rest, "className") ? rest.className : "")}
                hasValidation={hasValidation}
            >
                <Form.Control
                    {...formControlProps}
                    readOnly
                    ref={ref}
                    as="input"
                    name={name}
                    onClick={onClick}
                    value={value || ""}
                    placeholder={placeholder}
                    aria-expanded={ariaExpanded}
                    aria-haspopup={ariaHasPopup}
                    className={clsx(
                        !isEmpty(value) || hasGrow ? "has-grow" : "",
                        !!underlined && "underlined",
                        !!underlined &&
                            (typeof underlined === "string"
                                ? `underlined--${underlined}`
                                : `underlined--solid`),
                        formControlProps?.className
                    )}
                />
                {!!Label &&
                    (typeof Label === "string" ? (
                        <Form.Label htmlFor={name}>{Label}</Form.Label>
                    ) : (
                        Label
                    ))}
                {hasValidation && !isEmpty(value) && (
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                )}
                {hasValidation && (
                    <Form.Control.Feedback type="invalid">
                        Looks not good!
                    </Form.Control.Feedback>
                )}
                <span className="caret"></span>
            </InputGroup>
        );
    }
);

export const SelectMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
        return (
            <div
                ref={ref}
                style={style}
                className={clsx(className, "select-menu")}
                aria-labelledby={labeledBy}
            >
                <ul className="select-menu__list">
                    {React.Children.toArray(children)}
                </ul>
            </div>
        );
    }
);

export const Select = React.forwardRef(
    (
        {
            append: Append,
            className,
            formGroupProps,
            hasGrow,
            hasValidation,
            inputGroupProps,
            items,
            label,
            menuProps,
            name,
            onChange,
            placeholder,
            underlined,
            value: valueProp,
            error,
            errorMsg,
            ...restProps
        },
        ref
    ) => {
        const [value, setValue] = React.useState(valueProp);

        React.useEffect(() => {
            typeof valueProp !== "undefined" &&
                valueProp !== value &&
                setValue(valueProp);
        }, [valueProp, value]);

        const handleOnChange = (eventKey, e) => {
            e.preventDefault();
            setValue(eventKey);
            typeof onChange === "function" && onChange(eventKey);
        };

        return (
            <Form.Group
                {...formGroupProps}
                className={clsx(
                    hasValidation && "has-validation",
                    has(formGroupProps, "className") && formGroupProps.className
                )}
            >
                <Dropdown onSelect={handleOnChange}>
                    <Dropdown.Toggle
                        {...inputGroupProps}
                        as={SelectToggler}
                        label={label}
                        hasGrow={hasGrow}
                        underlined={underlined}
                        placeholder={placeholder}
                        hasValidation={hasValidation}
                        value={
                            items.find((i) => i.key.toString() === value)?.value
                        }
                    />

                    {!!items.length && (
                        <Dropdown.Menu {...menuProps} as={SelectMenu}>
                            {items.map(({ key, value: val }) => (
                                <Dropdown.Item
                                    key={key}
                                    active={value === key.toString()}
                                    eventKey={key.toString()}
                                >
                                    {val}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    )}
                </Dropdown>
                {error && (
                    <Form.Text className="text-muted">
                        <span style={{ color: "#ec6453" }}>{errorMsg}</span>
                    </Form.Text>
                )}
            </Form.Group>
        );
    }
);

export default Select;
