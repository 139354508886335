import { API_MENU } from "utils/api.constant";
import axios from "utils/axios";

import { GET_MENU_SIDEBAR, LOADING } from "../reducers/menuSidebarReducer";

// export const getMenuActions = () => async () => {
//     return new Promise(async (resolve, reject) => {
//         await axios
//             .get(API_MENU)
//             .then((res) => {
//                 if (res) {
//                     resolve(res.data.data);
//                 }
//             })
//             .catch((err) => {
//                 reject(err);
//             });
//     });
// };

export const getMenuActions = () => async (dispatch) => {
    dispatch({
        type: LOADING,
    });

    await axios
        .get(API_MENU)
        .then((res) => {
            if (res) {
                const transformDataM = res.data.data.map((it) => {
                    if (it.submenu.length > 0) {
                        return {
                            icon: it.icon,
                            label: it.label,
                            desc: it.desc,
                            to: it.to,
                            submenu: it.submenu.map((iti) => {
                                if (iti.label === "Pertanyaan (FAQ)"||iti.label=="Question (FAQ)") {
                                    return {
                                        ...iti,
                                        to: "",
                                        link: iti.to,
                                    };
                                } else {
                                    return {
                                        ...iti,
                                    };
                                }
                            }),
                        };
                    } else {
                        return {
                            icon: it.icon,
                            label: it.label,
                            desc: it.desc,
                            to: it.to,
                            submenu: [],
                        };
                    }
                });

                dispatch({
                    type: GET_MENU_SIDEBAR,
                    payload: transformDataM,
                });
            }
        })
        .catch((err) => {
            dispatch({
                type: LOADING_FALSE,
            });
        });
};
