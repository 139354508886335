/* eslint-disable no-empty-pattern */
// @flow
import clsx from 'clsx'
import * as React from 'react'
import {
  Billboard,
  Accordion,
  Form,
  Button
} from 'components'
import {
  Col,
  Container,
  Row,
  Card,
  Form as FormBootstrap
} from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { resetAction, postReportDplkAction, postReportGsAction } from 'store/actions/employer/iuranActions'
import { getFaqDataActions }from 'store/actions/faqActions'

// Styles
// import styles from './style.module.scss'

const Report = ({}) => {

  const dispatch = useDispatch()
  const { register, handleSubmit, setValue } = useForm()
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })
  const { t } = useTranslation()
  const [ loading, setLoading ] = React.useState(false)
  const [dataValue, setDateValue] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState('SINGKAT')
  const descPageText = useSelector(state => state.descriptionPage)
  const [radioTransac, setRadioTransac] = React.useState('PDF')
  const lang = useSelector(state => state.language.lang)
  const [ filterDate, setFilterDate ] = React.useState({
    from: { day: 19, month: 1, year: 2022 },
    to: { day: 20, month: 4, year: 2022 },
  })
  const [ faq, setFaq ] = React.useState(null)

  const getFaq = React.useCallback(async(group) => {
    await dispatch(getFaqDataActions(group))
      .then(
        res => {
          setFaq(res.data)
        }
      )
  },[setFaq])

  const getTransactionRadioHandler = React.useCallback((e) => {
    setValue('doc_type', e.target.value)
    setRadioTransac(e.target.value)
  },[setRadioTransac, setValue])

  const docsBaseHandler = React.useCallback((e) => {
    setValue('ringkasan_type', e)
    setSearchBy(e)
  },[setSearchBy])

  const responsiveRowRender = content =>
    !isTabletOrMobile ? (
      <Row className={clsx('mb-5')}>
        <Col sm={12}>{content}</Col>
      </Row>
    ) : (
      <div className={clsx('normalize-container-fluid', 'mb-5')}>{content}</div>
    )

  const changeDateHandler = React.useCallback((e) => {
    setFilterDate(e)
    setValue('from',`${e.from.year}-${e.from.month >= 10 ? e.from.month: '0'+e.from.month}-${e.from.day >= 10 ? e.from.day: '0'+e.from.day}`)
    setValue('to',`${e.to.year}-${e.to.month >= 10 ? e.to.month: '0'+e.to.month}-${e.to.day >= 10 ? e.to.day: '0'+e.to.day}`)
  },[setFilterDate])

  const onSubmit = React.useCallback(async(data) => {
    setLoading(true)
    switch(data.doc_type){
      case 'PDF':
        await dispatch(postReportGsAction(data))
          .then(
            res => {
              setLoading(false)
              if(res.success){
                toast.success(res.message, {
                  position: "top-right",
                  autoClose: true,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                })
              }else{
                toast.error(res.message, {
                  position: "top-right",
                  autoClose: true,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                })
              }
            }
          )
        break
      case 'XLS':
        await dispatch(postReportDplkAction(data))
          .then(
            res => {
              setLoading(false)
              if(res.success){
                toast.success(res.message, {
                  position: "top-right",
                  autoClose: true,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                })
              }else{
                toast.error(res.message, {
                  position: "top-right",
                  autoClose: true,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                })
              }
            }
          )
        break
    }
  },[setLoading])

  React.useEffect(() => {
    register('ringkasan_type', { required: true })
    register('from')
    register('to')
    register('doc_type')

    getFaq('laporan_tagihan')

    setValue('ringkasan_type', 'SINGKAT')
    setValue('doc_type', radioTransac)
    setValue('from',`${filterDate.from.year}-${filterDate.from.month >= 10 ? filterDate.from.month: '0'+filterDate.from.month}-${filterDate.from.day >= 10 ? filterDate.from.day: '0'+filterDate.from.day}`)
    setValue('to',`${filterDate.to.year}-${filterDate.to.month >= 10 ? filterDate.to.month: '0'+filterDate.to.month}-${filterDate.to.day >= 10 ? filterDate.to.day: '0'+filterDate.to.day}`)

    return() => {
      dispatch(resetAction())
    }
  },[lang])

  return (
    <section>
      <Container fluid>
        <h1 className="font-weight-light display-2 mb-4">
          {t('employerIuranReport.pageTitle.text1')} <span className="font-serif">{t('employerIuranReport.pageTitle.text2')}</span>
        </h1>
        <Row className={clsx('mb-4')}>
          <Col md={8}>
            <p className="pr-md-5 mr-md-5 font-weight-light">
            { descPageText.text }
            </p>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md={12} className={clsx('my-3 my-md-0')}>
            <FormBootstrap onSubmit={handleSubmit(onSubmit)}>
              <Card body style={{ height: '100%' }}>
                <Row style={{ height: '100%' }} className={clsx('py-3')}>
                  <Col
                    md={{ span: 6, offset: 1 }}
                    className="d-flex flex-column align-items-start justify-content-between"
                  >
                    <Form.Select
                      value={searchBy}
                      onChange={docsBaseHandler}
                      label={t('employerIuranReport.filter.searchBy')}
                      formGroupProps={{ className: clsx('w-100', 'mb-4 pr-4 pr-md-5') }}
                      menuProps={{ className: clsx('w-100') }}
                      items={[
                        { key: 'SINGKAT', value: t('employerIuranReport.filter.searchByItem.short') }
                      ]}
                      underlined
                    />
                    <Form.DateRangePicker2
                      onChange={changeDateHandler}
                      setDateValue={setDateValue}
                      dataValue={dataValue}
                      label={{
                        from: {
                          label: t('employerIuranReport.filter.from'),
                          placeholder: t('employerIuranReport.filter.choiceDate')
                        },
                        to: {
                          label: t('employerIuranReport.filter.to'),
                          placeholder: t('employerIuranReport.filter.choiceDate')
                        }
                      }}
                      maxMonthFrom={12}
                    />
                  </Col>
                  <Col md={{ span: 4 }}>
                      <span className="font-weight-light">{t('employerIuranReport.filter.type')}</span>
                      <fieldset className={clsx('d-flex d-md-block')}>
                        <Form.Radio
                          name="doc_type"
                          value="PDF"
                          checked={true}
                          label="PDF"
                          className={clsx('ml-4 ml-md-0')}
                          formGroupProps={{ className: clsx('mt-2', 'mt-md-3') }}
                          onChange={getTransactionRadioHandler}
                        />
                      </fieldset>
                  </Col>
                  <Col md={{ span: 5, offset: 1 }} className={clsx('d-flex align-items-end')}>
                    <Button loading={loading} type="submit" variant="coral" block>
                      {t('employerIuranReport.filter.btnProcess')}
                    </Button>
                  </Col>
                </Row>
              </Card>
            </FormBootstrap>
          </Col>
        </Row>
        {faq && faq.length > 0 && (
          <Row>
            <Col md={12}>
              <h1 className="font-weight-light">{t('faq.title')}</h1>
            </Col>
          </Row>
        )}
        {faq && responsiveRowRender(
          <Accordion itemsPerRow={faq ? faq : []} items={faq ? faq : []} />
        )}
        {/* <div className={clsx('my-4', 'pt-2 pb-3')}>
          <Billboard.Contact />
        </div> */}
      </Container>
    </section>
  )
}

export default Report
