// @flow
import clsx from 'clsx'
import * as React from 'react'
import { Card, Col, ListGroup, Row } from 'react-bootstrap'
import { CheckableRow } from '../'

import styles from './style.module.scss'
import parentStyles from '../style.module.scss'
import { CheckableTable } from '..'

const DataTableMobile = props => {
  const {
    data,
    headers,
    checkable,
    emptyData,
    dataActions,
    renderActions,
  } = props

  const renderData = (item, index, props = {}, addOn) => (
    <Card key={index} as={ListGroup.Item} className={clsx(styles.card, props.className)}>
      <Card.Body className={clsx('px-0 py-2')}>
        <Row noGutters>
          <Col
            {...dataActions.attrs}
            xs={12}
            width={dataActions.attrs?.width || 100}
            className={clsx(
              'd-flex',
              'mt-0 mb-3',
              'align-items-center',
              !!dataActions && !!dataActions.menu.length
                ? 'justify-content-between'
                : 'justify-content-end',
              parentStyles.table__cell__action,
              dataActions.attrs?.className,
            )}>
            {!!addOn && addOn()}
            {!!dataActions &&
              !!dataActions.menu.length &&
              typeof renderActions === 'function' &&
              renderActions(item, dataActions)}
          </Col>
          {headers
            .reduce<typeof item>((newData, head) => {
              const itemData = item.find(c => c.name === head.name) || {
                name: head.name,
                content: null,
                attrs: {},
              }

              return [
                ...newData,
                {
                  ...head,
                  content: <b>{head.title}</b>,
                },
                itemData,
              ]
            }, [])
            .map((dataItem, dataIndex) => (
              <Col
                {...dataItem.attrs?.mobile}
                key={dataItem.name.concat((index + dataIndex).toString())}
                xs={6}
                className={clsx('my-3')}>
                {dataItem.content}
              </Col>
            ))}
        </Row>
      </Card.Body>
    </Card>
  )

  return (
    <Card className={clsx(styles.card)}>
      <ListGroup variant="flush">
        {!!data.length ? (
          checkable ? (
            <CheckableTable>
              {({ assignSetter }) =>
                data.map((item, index) => (
                  <CheckableRow key={index}>
                    {({ checked, setChecked, component }) =>
                      renderData(
                        item,
                        index,
                        {
                          className: clsx(checked && styles.card__checked),
                        },
                        () => {
                          const Controller = component(setter =>
                            assignSetter(setter, index.toString()),
                          )

                          return <Controller />
                        },
                      )
                    }
                  </CheckableRow>
                ))
              }
            </CheckableTable>
          ) : (
            data.map((item, index) => renderData(item, index))
          )
        ) : (
          <Card as={ListGroup.Item} className={clsx(styles.card)}>
            {emptyData}
          </Card>
        )}
      </ListGroup>
    </Card>
  )
}

export default DataTableMobile
