export const GET_EMPYR_RESET_DATA = 'GET_EMPYR_RESET_DATA'
export const LOADING_EMPYR_RESET_PASSWORD = 'LOADING_EMPYR_RESET_PASSWORD'
export const LOADING_EMPYR_RESET_PASSWORD_FALSE = 'LOADING_EMPYR_RESET_PASSWORD_FALSE'

const initialState = {
    data: null,
    loading: false
}

const ResetPasswordReducer = (state = initialState, action) => {
    switch(action.type){
        case GET_EMPYR_RESET_DATA: {
            return{
                data: action.payload,
                loading: false
            }
        }
        case LOADING_EMPYR_RESET_PASSWORD: {
            return {
                ...state,
                loading: true
            }
        }
        case LOADING_EMPYR_RESET_PASSWORD_FALSE: {
            return {
                ...state,
                loading: false
            }
        }
        default:{
            return state
        }
    }
}

export default ResetPasswordReducer