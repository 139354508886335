// @flow
import * as React from 'react'
import { Redirect } from 'react-router'
import Authentication from 'layouts/Authentication'
import sideBg from 'assets/images/REGISTER_EBCLICK.jpg'

import SignIn from './SignIn'
import ResetPassword from './ResetPassword'
import Register from './Register'
import AuthGuardSign from 'auth/AuthGuardSign'

export const pathname = '/auth'
export const route = {
  component: props => (
    <AuthGuardSign>
      <Authentication {...props} mode="employer" bgImageSide={sideBg} />
    </AuthGuardSign>
  ),
  asLayout: true,
  routes: {
    '/signin': {
      component: SignIn,
    },
    '/reset-password': {
      routes: {
        '/set-password': {
          routes: {
            '/:token': {
              component: ResetPassword.Password,
            }
          }
        },
        '/verification': {
          routes: {
            '/:type/:secret': {
                component: ResetPassword.OTP,
            },
            '/': {
              component: () => <Redirect to={`/signin`} />
            }
          }
        },
        '/resending-otp': {
          routes: {
            '/:secret': {
              component: ResetPassword.Verification,
            },
            '/': {
              component: () => <Redirect to={`/signin`} />
            },
          }
        },
        '/': {
          component: ResetPassword.Main,
        },
      },
    },
    '/register': {
      routes: {
        '/set-password/:token': {
          component: Register.Password,
        },
        '/verification': {
          routes: {
            '/:type/:token': {
              component: Register.OTP,
            },
            '/': {
              component: ({ layoutPath }) => <Redirect to={`${layoutPath}/signin`} />
            },
          },
        },
        '/resend-otp/:token': {
          component: Register.Verification,
        },
        '/': {
          component: Register.Main,
        },
      },
    },
    '/': {
      component: ({ layoutPath }) => <Redirect to={`${layoutPath}/signin`} />,
    },
  },
}

const employerAuth = {
  pathname,
  route
}

export default employerAuth
