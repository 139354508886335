// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-date-styles .rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: #00a758;
    box-shadow: 0 0 3px #00a758;
}
.custom-date-styles .rmdp-day:not(.rmdp-day-hidden) span:hover {
    background-color: white !important;
    color: gray;
    cursor: pointer;
    border: solid 2px #00a758;
}
.custom-date-styles .rmdp-day.rmdp-today span {
    background-color: #36c783;
}
.custom-date-styles .rmdp-range {
    background-color: #00a758;
    box-shadow: 0 0 3px #00a758;
}
.custom-date-styles .rmdp-week-day {
    color: #00a758;
}
.custom-date-styles .rmdp-arrow {
    border: solid #00a758;
    border-width: 0 2px 2px 0;
}
.custom-date-styles .rmdp-arrow-container:hover {
    background-color: #00a758;
    box-shadow: 0 0 3px #00a758;
    border: solid white;
}
.custom-date-styles .rmdp-day  {
   padding: 1.5rem 1.5rem;
}
.custom-date-styles .rmdp-calendar{
    padding: 1rem;
}
.custom-date-styles.rmdp-shadow{
    box-shadow: none;
    border: none;
}
.rmdp-container {
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Form/MonthPicker/calender.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,2BAA2B;AAC/B;AACA;IACI,kCAAkC;IAClC,WAAW;IACX,eAAe;IACf,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;IACzB,2BAA2B;AAC/B;AACA;IACI,cAAc;AAClB;AACA;IACI,qBAAqB;IACrB,yBAAyB;AAC7B;AACA;IACI,yBAAyB;IACzB,2BAA2B;IAC3B,mBAAmB;AACvB;AACA;GACG,sBAAsB;AACzB;AACA;IACI,aAAa;AACjB;AACA;IACI,gBAAgB;IAChB,YAAY;AAChB;AACA;IACI,WAAW;AACf","sourcesContent":[".custom-date-styles .rmdp-day.rmdp-selected span:not(.highlight) {\n    background-color: #00a758;\n    box-shadow: 0 0 3px #00a758;\n}\n.custom-date-styles .rmdp-day:not(.rmdp-day-hidden) span:hover {\n    background-color: white !important;\n    color: gray;\n    cursor: pointer;\n    border: solid 2px #00a758;\n}\n.custom-date-styles .rmdp-day.rmdp-today span {\n    background-color: #36c783;\n}\n.custom-date-styles .rmdp-range {\n    background-color: #00a758;\n    box-shadow: 0 0 3px #00a758;\n}\n.custom-date-styles .rmdp-week-day {\n    color: #00a758;\n}\n.custom-date-styles .rmdp-arrow {\n    border: solid #00a758;\n    border-width: 0 2px 2px 0;\n}\n.custom-date-styles .rmdp-arrow-container:hover {\n    background-color: #00a758;\n    box-shadow: 0 0 3px #00a758;\n    border: solid white;\n}\n.custom-date-styles .rmdp-day  {\n   padding: 1.5rem 1.5rem;\n}\n.custom-date-styles .rmdp-calendar{\n    padding: 1rem;\n}\n.custom-date-styles.rmdp-shadow{\n    box-shadow: none;\n    border: none;\n}\n.rmdp-container {\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
