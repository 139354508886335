import React from 'react'
import { Card } from 'react-bootstrap'
import { SpinnerCircular } from 'spinners-react'
import clsx from 'clsx'
import styles from './style.module.scss'

const BoxWrapper = (props) => {

    const { children, loading } = props

    return (
        <div className="position-relative mb-4">
            <Card body xs={12}>{children}</Card>
            {loading && (
                <div xs={12}
                    className={clsx(
                        "w-100 position-absolute z-10 d-flex justify-content-center align-items-center ",
                        styles.loading
                    )}
                >
                    <SpinnerCircular thickness={180} size={30} />
                </div>
            )}
        </div>
    )
}

export default React.memo(BoxWrapper)
