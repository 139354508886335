// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_card__b8-4e {
  background-color: transparent !important;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  overflow-x: hidden;
}
.style_card__checked__NpeRG {
  background-color: #caeed9 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/DataTable/Mobile/style.module.scss"],"names":[],"mappings":"AAEA;EACE,wCAAA;EACA,+BAAA;EACA,gCAAA;EACA,kBAAA;AADF;AAGE;EACE,oCAAA;AADJ","sourcesContent":["@import 'scss/for-module';\n\n.card {\n  background-color: transparent !important;\n  border-left-width: 0 !important;\n  border-right-width: 0 !important;\n  overflow-x: hidden;\n\n  &__checked {\n    background-color: #caeed9 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `style_card__b8-4e`,
	"card__checked": `style_card__checked__NpeRG`
};
export default ___CSS_LOADER_EXPORT___;
