// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__xTYau {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.style_contentWrapper__KdQDz {
  padding-bottom: 6rem;
}

.style_subheading__l\\+HZP {
  max-width: 32rem;
  font-weight: 300;
}

.style_otpInput__0iaz9 {
  display: inline-block;
  max-width: 7.25rem;
  margin: 0 !important;
}
.style_otpInput__0iaz9 input {
  font-size: 0.9em;
  letter-spacing: 0.05em;
  padding: 0 !important;
  text-align: center;
  height: auto;
}

.style_loading__5FuVv {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.548);
  z-index: 100;
}

ul li .style_disabledLink__uhr47 {
  pointer-events: none;
  color: silver;
}

ul li .style_activeLink__VFkZG {
  pointer-events: auto;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/views/OTP/style.module.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,uBAAA;AADF;;AAIA;EACE,oBAAA;AADF;;AAIA;EACE,gBAAA;EACA,gBAAA;AADF;;AAIA;EACE,qBAAA;EACA,kBAAA;EACA,oBAAA;AADF;AAGE;EACE,gBAAA;EACA,sBAAA;EACA,qBAAA;EACA,kBAAA;EACA,YAAA;AADJ;;AAKA;EACE,kBAAA;EACA,MAAA;EACA,SAAA;EACA,4CAAA;EACA,YAAA;AAFF;;AAKA;EACE,oBAAA;EACA,aAAA;AAFF;;AAIA;EACE,oBAAA;EACA,YAAA;AADF","sourcesContent":["@import 'scss/for-module';\n\n.container {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n}\n\n.contentWrapper{\n  padding-bottom: 6rem;\n}\n\n.subheading {\n  max-width: 32rem;\n  font-weight: 300;\n}\n\n.otpInput {\n  display: inline-block;\n  max-width: 7.25rem;\n  margin: 0 !important;\n\n  input {\n    font-size: 0.9em;\n    letter-spacing: 0.05em;\n    padding: 0 !important;\n    text-align: center;\n    height: auto;\n  }\n}\n\n.loading{\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  background-color: rgba(255, 255, 255, 0.548);\n  z-index: 100;\n}\n\nul li .disabledLink{\n  pointer-events: none;\n  color: silver;\n}\nul li .activeLink{\n  pointer-events: auto;\n  color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__xTYau`,
	"contentWrapper": `style_contentWrapper__KdQDz`,
	"subheading": `style_subheading__l+HZP`,
	"otpInput": `style_otpInput__0iaz9`,
	"loading": `style_loading__5FuVv`,
	"disabledLink": `style_disabledLink__uhr47`,
	"activeLink": `style_activeLink__VFkZG`
};
export default ___CSS_LOADER_EXPORT___;
