// @flow
// eslint-disable-next-line no-unused-vars
import * as React from 'react'
import Guest from 'layouts/Guest'
import EBClickGuide from './EBClickGuide'

export const pathname = '/guides'
export const route = {
  component: Guest,
  asLayout: true,
  routes: {
    '/': {
      component: EBClickGuide,
    },
  },
}