// @flow
import * as React from 'react'
import { isEmpty, isFunction, keys } from 'lodash'
import { Switch, Route,useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
 
import { initialLanguageSettingActions } from 'store/actions/languageSettingActions'
import { API_MAINTENANCE } from "utils/api.constant";
import axios from "utils/axios";

// Routes
import routesList from './routes'
 
const RouteWithSubroutes = ({ path, routes, layoutPath, ...rest }) => {
  return (
    <Route
      path={path}
      render={props => (
        <rest.component
          {...props}
          routes={routes}
          rootPath={path}
          layoutPath={layoutPath}
          routesBlock={RoutesBlock}
          parentPath={path
            .split('/')
            .slice(0, path.split('/').length - 1)
            .join('/')}
        />
      )}
    />
  )
}
 
const RoutesBlock = ({ routes, rootPath, routesBlock, layoutPath, renderRoute }) => (
  <Switch>
    {keys((routes)).map((key) => {
      const route = routes[key]
 
      const routesBlockComponent = (routesBlock)
      if (typeof route.component === 'undefined') {
        route.component = routesBlockComponent
      }
      const path = `${rootPath}${key}`.replace(/(\/\/)/gi, '/')
 
      return !!renderRoute && isFunction(renderRoute) ? (
        renderRoute(RouteWithSubroutes, route, path)
      ) : (
        <RouteWithSubroutes
          key={key}
          path={path}
          layoutPath={!!route.asLayout ? path : !isEmpty(layoutPath) ? layoutPath : ''}
          {...route}
        />
      )
    })}
  </Switch>
)
 
const RouterView = ({ routes, ...rest }) => {
 
  const dispatch = useDispatch()
  // const [maintenance,SetMaintenance]=React.useState(false)
  const history=useHistory()
  
  const getMaintenance = ()=>{
    axios.get(API_MAINTENANCE)
    .then(response => {
       
        if (response.data.data === true) {
          // SetMaintenance(true)
            localStorage.setItem('maintenanceMode', true);
            history.push('/maintenance');
        } 
        else {
            localStorage.setItem('maintenanceMode', false);
        }
       
    })
    .catch(error => {
        console.error('Error fetching maintenance mode:', error);
    });

}
  
  React.useEffect(() => {
    getMaintenance()
    dispatch(initialLanguageSettingActions())
  }, [])
 
  return <RoutesBlock routes={routes} rootPath="" routesBlock={RoutesBlock} renderRoute={null} />
}
 
RouterView.defaultProps = {
  routes: routesList,
}
 
export default RouterView