
export const en = {
    employerClaim: {
        claimSubmission: {
            pageTitle: 'Incomplete',
            pageTitle2: 'Claim Submission',
            valSearchDoc: {
                all: 'All Participants',
                no: 'Per Participant'
            }
        },
        claimNotif: {
            pageTitle: 'Pension',
            pageTitle2: 'Notification',
            valSearchDoc: {
                oneMonth: 'Monthly Notifications',
                threeMonth: '3 Monthly Notification'
            },
            date: {
                label: 'Reporting Date'
            }
        },
        filter: {
            searchDoc: 'Search Documents By',
            participantNumber: {
                label: 'Enter Participant Number*',
                error: '*Participant Number must be filled',
                placeholder: 'example: 1234567',
                toolTip: 'Enter Participant Number*'
            },
            docType: 'Select Document Type*',
            docHistory: {
                label: 'Document History',
                toolTip: 'Document History'
            },
            btnProcess: 'Process',
            from: 'From',
            to: 'To',
            chooseDate: 'Choose Date',
            chooseMonth: 'Choose Month'
        },
        emptyDocument:'There is no documentary history for you yet. Please check again later',
        claimReport: {
            pageTitle: 'Claim',
            pageTitle2:'Payment Report',
            valSearchDoc: {
                brief: 'Claim Summary',
                detail: 'Detail Claim'
            },
            tab: {
                status_claim: {
                    label: 'Claim Status Information',
                    headerLabel: {
                        name: "Transaction Type",
                        noClaim: "No. Certificate",
                        status: "Status",
                        dateFilling: "Date of Filling"
                    },
                    emptyData: "There are no reports for you yet. Please check again later."
                },
            }
        }
    }
}

export const id = {
    employerClaim: {
        claimSubmission: {
            pageTitle: 'Pengajuan',
            pageTitle2:' Klaim Tidak Lengkap',
            valSearchDoc: {
                all: 'Semua Peserta',
                no: 'Per Peserta'
            }
        },
        claimNotif: {
            pageTitle: 'Pemberitahuan',
            pageTitle2:'Masa Pensiun',
            valSearchDoc: {
                oneMonth: 'Pemberitahuan Bulanan',
                threeMonth: 'Pemberitahuan 3 Bulanan'
            },
            date: {
                label: 'Tanggal Laporan'
            }
        },
        filter: {
            searchDoc: 'Cari Dokumen Berdasarkan',
            participantNumber: {
                label: 'Masukkan Nomor Peserta*',
                error: '*Nomor Peserta harus di isi',
                placeholder: 'contoh: 1234567',
                toolTip: 'Masukkan Nomor Peserta*'
            },
            docType: 'Pilih Tipe Dokumen*',
            docHistory: {
                label: 'Riwayat Dokumen',
                toolTip: 'Riwayat Dokumen'
            },
            btnProcess: 'Proses',
            from: 'Dari Tanggal:',
            to: 'Sampai Tanggal:',
            chooseDate: 'Pilih Tanggal',
            chooseMonth: 'Pilih Bulan'
        },
        emptyDocument:'Belum ada riwayat dokumen untuk Anda. Silahkan periksa lagi nanti.',
        claimReport: {
            pageTitle: 'Laporan',
            pageTitle2: 'Pembayaran Klaim',
            valSearchDoc: {
                brief: 'Ringkasan Klaim',
                detail: 'Detail Klaim'
            },
            tab: {
                status_claim: {
                    label: 'Informasi Status Claim',
                    headerLabel: {
                        name: "Jenis Transaksi",
                        noClaim: "No. Sertifikat",
                        status: "Status",
                        dateFilling: "Tanggal Pengajuan"
                    },
                    emptyData: "Belum ada laporan untuk Anda. Silahkan periksa lagi nanti."
                },
            }
        }
    }
}
