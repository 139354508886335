const getDate = (date) => {
    if (!date) {
        return "-";
    }

    const d = new Date(date);
    const day = d.getDate();
    const month = d.getMonth();
    const year = d.getFullYear();
    return `${day < 10 ? `0` + day : day}/${
        month + 1 < 10 ? `0` + (month + 1) : month + 1
    }/${year}`;
};

export default getDate;
