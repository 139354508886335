
export const en = {
    dashboard: {
        btn: {
            logOut: 'Log Out'
        }
    }
}

export const id = {
    dashboard: {
       btn: {
           logOut: 'Keluar'
       }
    }
}