export const SET_AUTH_DETECTOR_FALSE = "SET_AUTH_DETECTOR_FALSE";
export const SET_AUTH_DETECTOR_TRUE = "SET_AUTH_DETECTOR_TRUE";

const initialState = {
    auth: true,
    message: null,
};

const AuthDetectorReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTH_DETECTOR_FALSE: {
            return {
                auth: false,
                message: action.payload,
            };
        }
        case SET_AUTH_DETECTOR_TRUE: {
            return {
                auth: true,
                message: null,
            };
        }
        default: {
            return state;
        }
    }
};

export default AuthDetectorReducer;
