// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__0RcAq {
  flex: 1 1 auto;
}`, "",{"version":3,"sources":["webpack://./src/views/Guides/About/style.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF","sourcesContent":[".wrapper {\n  flex: 1 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__0RcAq`
};
export default ___CSS_LOADER_EXPORT___;
