// @flow
import React from 'react'
import Dashboard from 'layouts/Dashboard'
import { Redirect } from 'react-router'

import { Loadable } from 'components'

import employerAuth from './Auth'
import guide from './Guide'
import profile from './Profile'
import investment from './Investment'
import Attendee from './Attendee'
import Transactions from './Contribution/Transactions'
import Report from './Contribution/Report'
import notification from './Notification'
import AuthGuardEmployer from '../../auth/AuthGuardEmployer'

const Home = Loadable(React.lazy(() => import('./Home')))
const Ballance = Loadable(React.lazy(() => import('./Ballance')))
const BallanceSummary = Loadable(React.lazy(() => import('./Ballance/BallanceSummary')))
const Claim = Loadable(React.lazy(() => import('./Claim')))
const ClaimSubmissionInformation = Loadable(React.lazy(() => import('./Claim/ClaimSubmissionInformation')))

export const pathname = '/employer'
export const route = {
  routes: {
    [employerAuth.pathname]: employerAuth.route,
    '/': {
      component: props => (
        <AuthGuardEmployer>
          <Dashboard {...props} mode="employer" notification />
        </AuthGuardEmployer>
      ),
      asLayout: true,
      routes: {
        '/home': {
          component: Home,
        },
        '/attendee': {
          component: Attendee,
        },
        '/claim': {
          routes: {
            '/claim-submission-information': {
              component: ClaimSubmissionInformation
            },
            '/:slug': {
              component: Claim,
            },
            '/': {
              component: () => <Redirect to={`${pathname}/claim/incomplete-submission`} />,
            },
          },
        },
        '/contribution': {
          routes: {
            '/transactions': {
              component: Transactions,
            },
            '/report': {
              component: Report,
            },
            '/': {
              component: () => <Redirect to={`${pathname}/contribution/transactions`} />,
            },
          },
        },
        '/ballance': {
          routes: {
            '/ballance-summary': {
              component: BallanceSummary,
            },
            '/:slug': {
              component: Ballance,
            },
            '/': {
              component: () => <Redirect to={`${pathname}/ballance/company-account`} />,
            },
          },
        },
        [investment.pathname]: investment.route,
        [profile.pathname]: profile.route,
        [guide.pathname]: guide.route,
        [notification.pathname]: notification.route,
        '/': {
          component: _ => <Redirect to={`${pathname}/home`} />,
        },
      },
    },
  },
}