// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__QsdXB {
  width: 309px;
  height: 189px;
  overflow: hidden;
  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
}
.style_wrapper__QsdXB.style_bordered__ECh4P {
  border: 1.5px dashed #c6c7d0;
}`, "",{"version":3,"sources":["webpack://./src/components/ECard/style.module.scss"],"names":[],"mappings":"AAAA;EAEE,YAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;EACA,4CANe;AAMjB;AAEE;EACE,4BAAA;AAAJ","sourcesContent":[".wrapper {\n  $ecard-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);\n  width: 309px;\n  height: 189px;\n  overflow: hidden;\n  background: #ffffff;\n  border-radius: 1rem;\n  box-shadow: $ecard-shadow;\n\n  &.bordered {\n    border: 1.5px dashed #c6c7d0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__QsdXB`,
	"bordered": `style_bordered__ECh4P`
};
export default ___CSS_LOADER_EXPORT___;
