// @flow
import * as React from 'react'
import { Redirect } from 'react-router'
import { Loadable } from 'components'

const Me =  Loadable(React.lazy(() => import('./Me')))
const ECard =  Loadable(React.lazy(() => import('./ECard')))
const ChangePassword =  Loadable(React.lazy(() => import('./ChangePassword')))

export const pathname = '/profile'
export const route = {
  routes: {
    '/me': {
      component: Me,
    },
    '/e-card': {
      component: ECard,
    },
    '/change-password': {
      component: ChangePassword,
    },
    '/': {
      component: ({ layoutPath }) => <Redirect to={`${layoutPath}profile/me`} />,
    },
  },
}

const profile = {
  pathname,
  route
}

export default profile
