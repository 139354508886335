// @flow

import React from "react";
import clsx from "clsx";
import { Header, Footer, Form } from "components";
import Container from "react-bootstrap/Container";
import { Link, useLocation } from "react-router-dom";
import MediaQuery from "react-responsive";
import { Navbar, Nav, NavDropdown, Button, ListGroup, Row, Col } from "react-bootstrap";
import { settingLanguageActions } from 'store/actions/languageSettingActions'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// Styles
import styles from "./style.module.scss";

const NavigationMobile = React.memo((props) => {

  const { pathname } = useLocation();
  const [ language, setLanguage ] = React.useState('id');
  const dispatch = useDispatch()
  const langId = useSelector(state => state.language.lang);
  // const [accordianOpen, setAccordianOpen] = React.useState(false);
  const { i18n, t } = useTranslation();

  // const accordianHandler = React.useCallback(() => {
  //   setAccordianOpen((prevState) => !prevState);
  // }, [setAccordianOpen]);

  const linkPage = [
    {
      name: t('authentication.outService'),
      link: 'https://www.manulife.co.id/id/produk/korporasi/pensiun.html',
      type: 'url'
    },
    {
      name: t('authentication.guide'),
      link: '/dplk/guides',
      type: 'page'
    },
  ]

  const setLang = (lang) => {
    dispatch(settingLanguageActions(lang))
    i18n.changeLanguage(lang);
  }

  React.useEffect(() => {
    setLanguage(langId);
    i18n.changeLanguage(langId);
  },[langId])

  return (
    <div className={clsx(styles.navigationMobile, props.show && styles.show)}>
      <style jsx="true">{`
        .list-group-item.active {
          background-color: #e9e9eb;
          border-color: #e9e9eb;
          color: #282b3e;
          border-left: solid 4px #00a758;
        }
      `}</style>
      <div
        className={clsx("d-flex flex-column justify-content-between")}
      >
        <ListGroup defaultActiveKey={`#${pathname}`}>
          {/* <ListGroup.Item action href="#/calculator">
            Calculator pensiun
          </ListGroup.Item> */}
          {/* <ListGroup.Item>
            <Accordion>
              <Accordion.Toggle
                onClick={accordianHandler}
                className={clsx(styles.buttonAccordian)}
                eventKey="0"
              >
                <div
                  className={clsx(
                    "d-flex justify-content-between align-items-center"
                  )}
                >
                  <span>Layanan Kami</span>
                  <i
                    className={clsx(
                      accordianOpen && styles.rotateIcon,
                      "mi-chevron-left"
                    )}
                  ></i>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <div className={clsx(styles.accordian__item_wrapper, "mt-2")}>
                  <ul className={clsx("px-3")}>
                    <li className={clsx("py-2")}>
                      <Link to="/">Tentang DPLK Manulife</Link>
                    </li>
                    <li className={clsx("py-2")}>
                      <Link to="/">Jenis Akun DPLK Manulife</Link>
                    </li>
                  </ul>
                </div>
              </Accordion.Collapse>
            </Accordion>
          </ListGroup.Item> */}
          {linkPage.map((it, index) => {
            return(
              <ListGroup.Item key={index} action href={it.link} target="__blank">
                {it.name}
              </ListGroup.Item>
            )
          })}
          <ListGroup.Item action href="/dplk/signin/employer">
            {t('authentication.sign.signIn')}
          </ListGroup.Item>
          <ListGroup.Item action href="/dplk/employer/auth/register">
            {t('authentication.sign.signUp')}
          </ListGroup.Item>
        </ListGroup>
        <div className={clsx("pb-4 px-4 mt-2")}>
          <Row noGutters className={clsx(styles.navigation__footer__lang)}>
            <Col xs="auto" style={{margin:"0 auto"}}>
              <Button
                variant="outline-light"
                onClick={() => setLang('en')}
                className={clsx([
                  styles.navigation__footer__lang__selector,
                  language === 'en' && styles["has-active"],"mb-2"
                ])}
              >
                English
              </Button>
            </Col>
            <Col xs="auto" style={{margin:"0 auto"}}>
              <Button
                variant="outline-light"
                onClick={() => setLang('id')}
                className={clsx([
                  styles.navigation__footer__lang__selector,
                  language === 'id' && styles["has-active"],"mb-2"
                ])}
              >
                Indonesia
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
});

const Guest = ({ children, ...rest }) => {
  const child = !!rest.routesBlock ? <rest.routesBlock {...rest} /> : children;

  const { pathname } = useLocation()
  const { i18n, t } = useTranslation()
  const dispatch = useDispatch()
  const langId = useSelector(state => state.language.lang)
  const [hasShowedNavigation, setShowNavigation] = React.useState(false)

  const linkPage = [
    {
      name: t('authentication.outService'),
      link: 'https://www.manulife.co.id/id/produk/korporasi/pensiun.html',
      type: 'url'
    },
    {
      name: t('authentication.guide'),
      link: '/dplk/guides',
      type: 'page'
    },
  ]

  const settingLanguage = React.useCallback((val) => {
    dispatch(settingLanguageActions(val))
    i18n.changeLanguage(val)
    // window.location.reload()
  },[])

  React.useEffect(() => {
    i18n.changeLanguage(langId)
  },[langId])

  return (
    <Container as="main" className={clsx("px-0", styles.wrapper)} fluid>
      <style>{`
        .dropdown-toggle.nav-link{
          font-size: 1rem;
          color: #282b3e !important;
          padding: 0.5rem 1.4rem !important;
          font-weight: 400;
        }
       
      `}</style>
      <div className={clsx(styles.content)}>
        <Header.Mobile
          fluid
          onNavToggleClick={setShowNavigation}
          navShowed={hasShowedNavigation}
          className={clsx(styles.header)}
        />
        <NavigationMobile show={hasShowedNavigation} />
        <MediaQuery minWidth={992}>
          <Header
            logoIconColor="#00A758"
            logoIcon={true}
            className={clsx(styles.header)}
            appendToNav={
              <>
                <Navbar expand="lg">
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                      {/* <Nav.Link className={clsx(styles.navItem)} href="#/calculator">Calculator pensiun</Nav.Link> */}
                      {/* <NavDropdown title="Layanan Kami" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">Tentang DPLK Manulife</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.2">Jenis Akun DPLK Manulife</NavDropdown.Item>
                      </NavDropdown> */}
                      {linkPage.map((it, index) => {
                        if(it.type === 'url'){
                          return(
                            <Nav.Link
                              key={index}
                              className={clsx(styles.navItem)}
                              href="#"
                              onClick={() => {
                                window.open(
                                  it.link
                                );
                              }}
                            >
                              {it.name}
                            </Nav.Link>
                          )
                        }else{
                          return(
                            <Nav.Link
                              key={index}
                              className={clsx(styles.navItem)}
                              href={it.link}
                            >
                              {it.name}
                            </Nav.Link>
                          )
                        }
                      })}
                      <Form.Select
                        name="date"
                        value={langId}
                        underlined={false}
                        onChange={settingLanguage}
                        formGroupProps={{ className: "m-0" }}
                        inputGroupProps={{
                          className: clsx(styles.langDropdown),
                          formControlProps: { className: clsx("text-center") },
                        }}
                        items={[
                          { key: "id", value: "ID" },
                          { key: "en", value: "EN" },
                        ]}
                        formControlProps={{
                          className: "bg-red",
                        }}
                      />
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </>
            }
            fluid
          />
        </MediaQuery>
        <div className={styles.content__inner}>{child}</div>
        <Footer className={clsx("px-5 py-3")} layoutType="dashboard" />
      </div>
    </Container>
  );
};

export default Guest;
