// @flow
import * as React from 'react'
import { Redirect } from 'react-router'

import { Loadable } from 'components'
const Prices =  Loadable(React.lazy(() => import('./Prices')))
const Direction =  Loadable(React.lazy(() => import('./Direction')))
const Conversion =  Loadable(React.lazy(() => import('./Conversion')))
const MonthlyMarketReview =  Loadable(React.lazy(() => import('./MonthlyMarketReview')))
const FundFactSheet =  Loadable(React.lazy(() => import('./FundFactSheet')))
const Upr =  Loadable(React.lazy(() => import('./UnitPrice')))

export const pathname = '/investment'
export const route = {
  routes: {
    '/direction': {
      component: Direction,
    },
    '/prices': {
      component: Prices,
    },
    '/conversion': {
      component: Conversion,
    },
    '/fund-fact-sheet': {
      component: FundFactSheet,
    },
    '/monthly-market-review': {
      component: MonthlyMarketReview,
    },
    '/unit-price': {
      component: Upr,
    },
    '/': {
      component: ({ layoutPath }) => <Redirect to={`${layoutPath}investment/direction`} />,
    },
  },
}

const investment = {
  pathname,
  route
}

export default investment
