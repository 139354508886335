// @flow
import clsx from 'clsx'
import { Form } from 'components'
import { isEmpty } from 'lodash-es'
import * as React from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'

import styles from './style.module.scss'


const SearchBar = ({ showed, onClose }) => {
  const [search, setSearch] = React.useState('')
  const [hasFocus, setFocus] = React.useState(false)

  return (
    <div
      className={clsx(
        styles.wrapper,
        'shadow-sm',
        showed && styles.open,
        (hasFocus || !isEmpty(search)) && styles.expand,
      )}>
      <Button
        variant="icon"
        onClick={() => onClose(!showed)}
        className={clsx('mi-cross xl', styles.close)}
      />
      <Container className={clsx(styles.wrapper__container)}>
        <CSSTransition
          classNames="transition-fade"
          in={hasFocus || !isEmpty(search)}
          timeout={200}
          unmountOnExit>
          <h1 className="display-3 font-weight-light">Pencarian</h1>
        </CSSTransition>
        <Form.Input
          hasGrow
          size="lg"
          type="text"
          name="search"
          value={search}
          underlined={hasFocus || !isEmpty(search)}
          placeholder={`Cari di ${window.location.origin}`}
          formGroupProps={{ className: clsx(styles.searchInput) }}
          onChange={({ currentTarget: { value } }) => setSearch(value)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          prepend={<i className={clsx('mi-search lg', 'px-1')}></i>}
        />
        <CSSTransition
          classNames="transition-fade"
          in={!isEmpty(search)}
          timeout={200}
          unmountOnExit>
          <Row className={clsx('mt-5')}>
            <Col sm={12}>
              <h2 className="h5 font-weight-light">Tautan yang disarankan:</h2>
            </Col>
            <Col sm={12}>
              <Card as={Link} to="/" className={clsx('text-decoration-none')} body>
                <span>Apa Itu Asuransi Penyakit Kritis ?</span>
                <i className="mi-arrow-down float-right -r-125 lg"></i>
              </Card>
            </Col>
            <Col sm={12}>
              <Card as={Link} to="/" className={clsx('text-decoration-none')} body>
                <span>Siapa yang Harus Punya Asuransi Penyakit Kritis?</span>
                <i className="mi-arrow-down float-right -r-125 lg"></i>
              </Card>
            </Col>
          </Row>
        </CSSTransition>
      </Container>
    </div>
  )
}

export default SearchBar
