
export const en = {
    employerProfilCompanyInfo: {
        label: {
            text1: 'Company Info',
            text2: 'Company Address Info',
            text3: 'Company Phone and Email Info'
        },
        addOnLabel: {
            label: 'Company'
        } 
    },
    employerProfilGroupInfo: {
        label: 'Group Information'
    },
    employerProfilsubmission: {
        text1: 'Data Change ',
        text2: 'Submission'
    },
    employerProfilContact: {
        table: {
            accName: 'Account Name',
            accNumb: 'Account Number',
            bankName: 'Bank Name',
            bankBranch: 'Bank Branch'
        },
        label: 'Contact Person & Bank Account',
        emptyContact:'There is no contact for you yet. Please check again later.'
    },
    employerProfilemployerProfilPassword:{
        goodPassword:'Password meets all criteria'
    },
    formProfil:{
        nextBtn:"Done",
        cancelBtn:"Cancel"
    }
}

export const id = {
    employerProfilCompanyInfo: {
        label: {
            text1: 'Info Perusahaan',
            text2: 'Info Alamat Perusahaan',
            text3: 'Info Telepon dan Email Perusahaan'
        },
        addOnLabel: {
            label: 'Perusahaan'
        } 
    },
    employerProfilGroupInfo: {
        label: 'Informasi Grup'
    },
    employerProfilsubmission: {
        text1: 'Pengajuan',
        text2: 'Perubahan Data'
    },
    employerProfilContact: {
        table: {
            accName: 'Nama Akun',
            accNumb: 'Nomor Akun',
            bankName: 'Nama Bank',
            bankBranch: 'Cabang Bank'
        },
        label: 'Korespondensi & Rekening Bank',
        emptyContact:'Belum ada contact baru untuk Anda. Silahkan periksa lagi nanti.'
    },
    employerProfilemployerProfilPassword:{
        goodPassword:'Kata sandi memenuhi semua kriteria'
    },
    formProfil:{
        nextBtn:"Selesai",
        cancelBtn:"Batal"
    }
}
