
export const en = {
    dateNotif: {
        sixMonth: 'The time span allowed is only 6 months',
        oneYear: 'The time span allowed is only 1 years'
    }
}

export const id = {
    dateNotif: {
        sixMonth: 'Rentang Waktu yang di perbolehkan hanya 6 bulan',
        oneYear: 'Rentang Waktu yang di perbolehkan hanya 1 tahun'
    }
}