import axios from "utils/axios";
import { API_FAQ } from "utils/api.constant";

export const getFaqDataActions = (group) => async () => {
    return new Promise(async (resolve, reject) => {
        await axios
            .get(`${API_FAQ}/${group}/list`)
            .then((res) => {
                if (res) {
                    resolve(res.data);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
};
