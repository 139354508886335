// @flow
import * as React from "react";
import clsx from "clsx";
import { has, isEmpty } from "lodash";
import { InputGroup } from "..";
import { Form } from "react-bootstrap";
import NumberFormat from "react-number-format";

export const Input = React.forwardRef(
    (
        {
            append,
            appendCursorHelp,
            className,
            feedbackMessage,
            formGroupProps,
            hasGrow,
            hasValidation,
            inputGroupProps,
            isAllowed,
            label: Label,
            name,
            onChange,
            password,
            percentage,
            prepend,
            type,
            underlined,
            value: valueProp,
            defaultValue,
            autoComplete,
            ...restProps
        },
        ref
    ) => {
        const [hasVisible, setHasVisible] = React.useState(false);
        const [value, setValue] = React.useState(
            !valueProp && percentage ? 0 : valueProp
        );

        React.useEffect(() => {
            typeof valueProp !== "undefined" &&
                valueProp !== value &&
                setValue(valueProp);
        }, [valueProp, value]);

        const handleOnChange = (e) => {
            e.preventDefault();
            setValue(e.currentTarget.value);
            typeof onChange === "function" && onChange(e);
        };

        const inputType = password ? (hasVisible ? "text" : "password") : type;

        const renderInput = (props) =>
            percentage ? (
                <>
                    <NumberFormat
                        {...props}
                        value={value}
                        customInput={Form.Control}
                        decimalScale={0}
                        onChange={handleOnChange}
                        fixedDecimalScale
                        isNumericString
                        getInputRef={ref}
                        defaultValue={defaultValue}
                        isAllowed={
                            typeof isAllowed === "function"
                                ? isAllowed
                                : (val) => {
                                      const { value } = val;
                                      if (value <= 100) return val;
                                  }
                        }
                    />
                </>
            ) : (
                <Form.Control
                    {...props}
                    autoComplete={autoComplete ? "on" : "off"}
                    defaultValue={value}
                    onChange={handleOnChange}
                    type={inputType}
                />
            );

        return (
            <Form.Group
                {...formGroupProps}
                className={clsx(
                    percentage && "has-percentage",
                    hasValidation && "has-validation",
                    has(formGroupProps, "className") &&
                        formGroupProps?.className
                )}
            >
                <InputGroup {...inputGroupProps} hasValidation={hasValidation}>
                    {!!prepend && (
                        <InputGroup.Prepend>
                            <InputGroup.Text className={clsx("icon")}>
                                {prepend}
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                    )}
                    {renderInput({
                        ...restProps,
                        ref,
                        name,
                        defaultValue,
                        className: clsx(
                            !isEmpty(value) || hasGrow ? "has-grow" : "",
                            !!underlined && "underlined",
                            !!underlined &&
                                (typeof underlined === "string"
                                    ? `underlined--${underlined}`
                                    : `underlined--solid`),
                            className
                        ),
                    })}
                    {!!Label &&
                        (typeof Label === "string" ? (
                            <Form.Label htmlFor={name}>{Label}</Form.Label>
                        ) : (
                            Label
                        ))}
                    {percentage ? (
                        <InputGroup.Append>
                            <InputGroup.Text className="icon">
                                %
                            </InputGroup.Text>
                        </InputGroup.Append>
                    ) : password ? (
                        <InputGroup.Append>
                            <InputGroup.Text
                                className="icon"
                                onClick={() => setHasVisible(!hasVisible)}
                            >
                                <i
                                    className={clsx(
                                        hasVisible
                                            ? "mi-eye-close"
                                            : "mi-eye-open",
                                        "px-1"
                                    )}
                                ></i>
                            </InputGroup.Text>
                        </InputGroup.Append>
                    ) : (
                        !!append && (
                            <InputGroup.Append>
                                <InputGroup.Text
                                    className={clsx(
                                        "icon",
                                        appendCursorHelp && "icon--as-help"
                                    )}
                                >
                                    {append}
                                </InputGroup.Text>
                            </InputGroup.Append>
                        )
                    )}
                    {hasValidation && !isEmpty(value) && !!feedbackMessage && (
                        <Form.Control.Feedback type="valid">
                            {feedbackMessage}
                        </Form.Control.Feedback>
                    )}
                    {hasValidation && !!feedbackMessage && (
                        <Form.Control.Feedback type="invalid">
                            {feedbackMessage}
                        </Form.Control.Feedback>
                    )}
                </InputGroup>
            </Form.Group>
        );
    }
);

Input.defaultProps = {
    type: "text",
    password: false,
    underlined: "solid",
    appendCursorHelp: false,
};

export default React.memo(Input);
