
export const en = {
    signIn: {
        label: 'Welcome to',
        form: {
            email: {
                label: 'Email/Phone',
                toolTip: 'Enter your Email Address (xxxx@domain.co.id or xxxx@domain.com) OR Phone Number (62XXXXXXXXX)'
            },
            password: 'Password',
            no_group: {
                label: 'Group Number',
                toolTip: 'Enter the Group Number printed on the Group Welcome Letter'
            },
            forgotPassword: 'Forgot Password',
            btnNext: 'Continue',
            btnSignIn: 'Sign In'
        },
        tab: {
            employee: 'Employee',
            employer: 'Employer (PIC)'
        },
        haveAccount: {
            text: "Do not have an EB<i>Click</i> account yet?",
            link: 'Sign Up Now'
        },
        otp: {
            label:'Sign In',
            msg1: 'Please enter the OTP for validation:',
            msg2: {
                text1: 'We just sent',
                text2: 'a 6-digit',
                text3: 'One Time Password (OTP) to'
            },
            msg3: {
                text1: 'OTP will expire in',
                text2: 'Did not receive OTP?',
                text3: 'Want to use different account?',
                text4: 'Resend OTP',
                text5: 'Back to Homepage'
            }
        },
        resendingOtp: {
            title:'Resend OTP',
            msg1: 'Select one of the methods below to get an OTP',
            hp:'phone'
        },
        remindMe: 'Remember Me'
    }
}

export const id = {
    signIn: {
        label: 'Selamat Datang di ',
        form: {
            email: {
                label: 'Email/Ponsel',
                toolTip: 'Masukkan Alamat Email Anda (xxxx@domain.co.id atau xxxx@domain.com) ATAU Nomor Ponsel (62XXXXXXXXX)'
            },
            password: 'Kata Sandi',
            no_group: {
                label: 'Nomor Group',
                toolTip: 'Masukkan Nomor Group yang tercantum di Welcome Letter Group'
            },
            forgotPassword: 'Lupa Kata Sandi',
            btnNext: 'Lanjutkan',
            btnSignIn: 'Masuk'
        },
        tab: {
            employee: 'Karyawan',
            employer: 'Pemberi Kerja (PIC)'
        },
        haveAccount: {
            text: 'Belum punya akun EB<i>Click</i>?',
            link: 'Daftar Sekarang'
        },
        otp: {
            label:'Masuk',
            msg1: 'Silakan masukkan OTP untuk validasi:',
            msg2: {
                text1: 'Kami telah mengirimkan',
                text2: '6-digit',
                text3: 'One Time Password (OTP) ke'
            },
            msg3: {
                text1: 'OTP akan kedaluwarsa dalam',
                text2: 'Belum menerima OTP?',
                text3: 'Ingin menggunakan akun lain?',
                text4: 'Kirim Ulang OTP',
                text5: 'Kembali ke Halaman Utama'
            }
        },
        resendingOtp: {
            title:'Kirim Ulang OTP',
            msg1: 'Pilih salah satu metode dibawah ini untuk mendapatkan OTP',
            hp:'ponsel'
        },
        remindMe: 'Ingat Saya'
    }
}
