// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Calculator_wrapper__DhCdT {
  margin-top: 9rem;
}

body > iframe[style*="2147483647"] {
  display: none;
}

.Calculator_col2__4fBLi {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Calculator_col2__item__83wVO {
  width: 50%;
  padding: 0 1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/Calculator/index.module.scss"],"names":[],"mappings":"AACA;EACI,gBAAA;AAAJ;;AAEA;EACI,aAAA;AACJ;;AACA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AAEJ;AAEI;EACI,UAAA;EACA,eAAA;AAAR","sourcesContent":["\n.wrapper{\n    margin-top: 9rem;\n}\nbody > iframe[style*='2147483647']{\n    display: none;\n  }\n.col2 {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    // margin-left: -1rem;\n    // margin-right: -1rem;\n\n    &__item{\n        width: 50%;\n        padding: 0 1rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Calculator_wrapper__DhCdT`,
	"col2": `Calculator_col2__4fBLi`,
	"col2__item": `Calculator_col2__item__83wVO`
};
export default ___CSS_LOADER_EXPORT___;
