// @flow
import * as React from 'react'
import { Redirect } from 'react-router'
import { Loadable } from 'components'

const About =  Loadable(React.lazy(() => import('./About')))
const Features =  Loadable(React.lazy(() => import('./Features')))
const Download =  Loadable(React.lazy(() => import('./Download')))

const pathname = '/guide'
const route = {
  routes: {
    '/about': {
      component: About,
    },
    '/form': {
      component: Download,
    },
    '/features': {
      component: Features,
    },
    '/': {
      component: ({ layoutPath }) => <Redirect to={`${layoutPath}guide/about`} />,
    },
  },
}

const guide = {
  pathname,
  route
}

export default guide
