import { keys } from 'lodash-es'
import { useState } from 'react'

export const useInvestFields = () => {
  const [value, setValue] = useState({})

  const set = (data) => {
    if(data){
      const counted = keys(data).reduce((val, key) => val + data[key], 0)
      if (counted <= 100) {
        setValue(data)
      }
    }
  }

  const determineRiskType = () => {
    const riskValue = parseInt(((15 / 80) * value.FIX).toFixed())

    if (riskValue <= 7)
      return {
        type: 'konservatif',
        value: riskValue,
      }
    else if (riskValue <= 12)
      return {
        type: 'moderat',
        value: riskValue,
      }
    else
      return {
        type: 'agresif',
        value: riskValue,
      }
  }

  return [
    value,
    set,
    determineRiskType,
  ]
}

export default useInvestFields
