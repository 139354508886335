// @flow
import * as React from "react";
import clsx from "clsx";
import { Tabs, Alert } from "components";
import { Link, useParams } from "react-router-dom";
import { Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import styles from "./style.module.scss";
import Employee from "./Employee";
import Employer from "./Employer";

export const SignIn = ({ layoutPath, history, registerable }) => {
    const { role } = useParams();
    const { t } = useTranslation();
    const [msg, setMsg] = React.useState(null);
    const [tabKey, setTabKey] = React.useState("employee");
    const employee=true
    const tabHandler = (e) => {
        switch (e) {
            case "employee":
                history.push("/signin/employee");
                break;
            case "employer":
                history.push("/signin/employer");
                break;
            default:
                history.push("/signin/employer");
                break;
        }
    };
    const go=(url)=>{
        history.push(url)
    }
    React.useEffect(() => {
        switch (role) {
            case "employee":
                setTabKey(role);
                break;
            case "employer":
                setTabKey(role);
                break;
            default:
                history.push("/signin/employer");
                break;
        }
    }, [role]);

    return (
        <section className={clsx("container", styles.container)}>
            <div className={clsx("w-100")}>
                <h1 className="display-1">{t("signIn.label")} EB<i>Click</i> DPLK</h1>
                {msg && (
                    <Alert initialShow={true} closable className="mb-4 mr-lg-5">
                        <p className="mb-0">{msg}</p>
                    </Alert>
                )}

                <div className="mt-5">
                    <Tabs
                        id="claim"
                        onSelect={tabHandler}
                        activeKey={tabKey}
                        className={clsx(styles.tabs)}
                        responsiveMode="dropdown"
                    >
                        {/* employee tab */}
                        {employee&&(<Tab
                            eventKey="employee"
                            title={t("signIn.tab.employee")}
                        >
                            <Employee setMsg={setMsg} layoutPath={layoutPath} />
                            {registerable && (
                                <p className={styles.text__register}>
                                    <span dangerouslySetInnerHTML={{ __html: t("signIn.haveAccount.text") }}></span>
                                    {" "}
                                    <button style={{paddingLeft:0}} onClick={()=>go(`/employee/auth/register`)}
                                        className={clsx(
                                            "btn btn-link"
                                        )}
                                    >
                                        <strong>
                                            {t("signIn.haveAccount.link")}
                                        </strong>
                                    </button>
                                </p>
                            )}
                        </Tab>)
                        }

                        {/* employer tab */}
                        <Tab
                            eventKey="employer"
                            title={t("signIn.tab.employer")}
                        >
                            <Employer setMsg={setMsg} layoutPath={layoutPath} />
                            {registerable && (
                                <p className={styles.text__register}>
                                    <span dangerouslySetInnerHTML={{ __html: t("signIn.haveAccount.text") }}></span> {" "}
                                    <button style={{paddingLeft:0}} onClick={()=>go(`/employer/auth/register`)}
                                        className={clsx(
                                            "btn btn-link"
                                        )}
                                    >
                                        <b>
                                            {t("signIn.haveAccount.link")}
                                        </b>
                                    </button>
                                </p>
                            )}
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </section>
        
    );
    
};

SignIn.defaultProps = {
    registerable: true,
    labelId: "Nomor Peserta",
};

export default SignIn;
