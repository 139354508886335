// @flow
import * as React from 'react'
import clsx from 'clsx'
import * as yup from 'yup'
import { has, keys } from 'lodash'
import { Form as FormComponent, Alert } from 'components'
import { useForm } from 'react-hook-form'
import { Button, Col, Container, Form } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup'
import { CSSTransition } from 'react-transition-group'
import { useMediaQuery } from 'react-responsive'
import { t } from 'i18next'
import styles from './style.module.scss'

export const ChangePassword = ({
  layoutPath,
  parentPath,
  history,
  title,
  description,
  onSubmit,
  wrapperProps,
}) => {
    const { t } = useTranslation()
    const schema = yup.object().shape({
        old_password: yup
          .string()
          .min(8, t('resetPassword.setPassword.form.password.text1'))
          .matches(/^(?=.*[a-z])(?=.*[A-Z]).{1,}$/, {
            name: 'lettercases',
            message: t('resetPassword.setPassword.form.password.text2'),
          })
          .matches(/^(?=.*[0-9]).{1,}$/, {
            name: 'numeric',
            message: t('resetPassword.setPassword.form.password.text3'),
          })
          .matches(/^(?=.*[~!@#$%^&*()]).{1,}$/, {
            name: 'symbols',
            message: t('resetPassword.setPassword.form.password.text4'),
          }),
        password: yup
          .string()
          .min(8, t('resetPassword.setPassword.form.password.text1'))
          .matches(/^(?=.*[a-z])(?=.*[A-Z]).{1,}$/, {
            name: 'lettercases',
            message: t('resetPassword.setPassword.form.password.text2'),
          })
          .matches(/^(?=.*[0-9]).{1,}$/, {
            name: 'numeric',
            message: t('resetPassword.setPassword.form.password.text3'),
          })
          .matches(/^(?=.*[~!@#$%^&*()]).{1,}$/, {
            name: 'symbols',
            message: t('resetPassword.setPassword.form.password.text4'),
          }),
        password_confirmation: yup
          .string()
          .oneOf([yup.ref('password'), null], t('resetPassword.setPassword.form.rewritePassword.label'),)
      })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const [visiblePasswordCriteria, setVisiblePasswordCriteria] = React.useState(false)
  const { register, handleSubmit, errors, formState, watch } = useForm({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
  })

  React.useEffect(() => {
    if (!visiblePasswordCriteria && errors.password) setVisiblePasswordCriteria(true)
  }, [errors.password, visiblePasswordCriteria])

  return (
    <Container {...wrapperProps} as="section" fluid>
      <h1 className="font-weight-light display-2 mb-4 mt-5">{title}</h1>
      <p className="font-weight-light h4">{description}</p>
      <Form noValidate className={clsx(styles.form)} onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Col md={12}>
            <Alert initialShow={true} closable className="mb-4 mr-lg-5">
              <p className="mb-0">
                hello
              </p>
            </Alert>
          </Col>
          <FormComponent.Input
            hasGrow
            password
            hasValidation
            ref={register({ required: true })}
            name="old_password"
            label={t('resetPassword.setPassword.form.oldPas')}
            formGroupProps={{ as: Col, sm: 12 }}
            isInvalid={errors.old_password}
            isValid={!has(errors, 'old_password') && formState.isValid}
            feedbackMessage={errors.old_password && errors.old_password.message}
          />

          <FormComponent.Input
            hasGrow
            password
            hasValidation
            ref={register({ required: true })}
            name="password"
            label={t('resetPassword.setPassword.form.newPas')}
            isInvalid={errors.password}
            isValid={!has(errors, 'password_confirmation') && !!watch('password')}
            formGroupProps={{ as: Col, sm: 12, className: clsx(visiblePasswordCriteria && 'mb-2') }}
          />

          <CSSTransition
            unmountOnExit
            timeout={500}
            in={visiblePasswordCriteria}
            classNames="transition-fade">
            <Col sm={12}>
              <ul className={clsx('cross-list', 'lg-icon')}>
                {keys(schema.fields.password.exclusiveTests).map(test => (
                  <li
                    key={test}
                    className={clsx(
                      'font-weight-light',
                      !errors.password && 'checked',
                      errors.password && !keys(errors.password.types).includes(test)
                        ? 'checked'
                        : ''
                    )}>
                    {
                      schema.fields.password.tests.find(t => t.OPTIONS.name === test).OPTIONS
                        .message
                    }
                  </li>
                ))}
              </ul>
            </Col>
          </CSSTransition>

          <FormComponent.Input
            hasGrow
            password
            hasValidation
            ref={register({ required: true })}
            name="password_confirmation"
            label={t('resetPassword.setPassword.form.rewritePassword.label')}
            isInvalid={errors.password_confirmation}
            isValid={!has(errors, 'password_confirmation') && formState.isValid}
            formGroupProps={{ as: Col, sm: 12, className: clsx('mt-4') }}
            feedbackMessage={
              errors.password_confirmation
                ? errors.password_confirmation.message
                :t('resetPassword.setPassword.form.rewritePassword.success')
            }
          />
          <Col sm={12} xs={12} className="mt-2">
            <Button
              variant="coral"
              type="submit"
              disabled={!formState.isValid}
              block={isTabletOrMobile}>
              {t('resetPassword.setPassword.form.buttonNext')}
            </Button>
          </Col>
        </Form.Row>
      </Form>
    </Container>
  )
}

ChangePassword.defaultProps = {
  title: (
    <>
      {t('resetPassword.setPassword.form.setPassword.ChangePassword.text1')} <span className="font-serif">{t('resetPassword.setPassword.form.ChangePassword.text2')}</span>
    </>
  ),
  description: '',
  onSubmit: console.info,
  wrapperProps: {},
}

export default ChangePassword
