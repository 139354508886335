export const GET_EMPYE_INVEST_DIRECT = 'GET_EMPYE_INVEST_DIRECT'
export const LOADING_EMPYE_INVEST_DIRECT = 'LOADING_EMPYE_INVEST_DIRECT'
export const LOADING_EMPYE_INVEST_DIRECT_FALSE = 'LOADING_EMPYE_INVEST_DIRECT_FALSE'
export const RESET_EMPYE_INVEST_DIRECT = 'RESET_EMPYE_INVEST_DIRECT'

const initialState = {
    data: null,
    loading: false,
}

const InvestDirectReducer = (state = initialState, action) => {
    switch(action.type){
        case GET_EMPYE_INVEST_DIRECT: {
            return{
                ...state,
                data: action.payload,
                loading: false
            }
        }
        case LOADING_EMPYE_INVEST_DIRECT: {
            return{
                ...state,
                loading: true
            }
        }
        case LOADING_EMPYE_INVEST_DIRECT_FALSE: {
            return{
                ...state,
                loading: false
            }
        }
        default:{
            return state
        }
    }
}

export default InvestDirectReducer