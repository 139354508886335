
export const en = {
    employerAttende: {
        pageTitle: {
            text1: 'Participant',
            text2: 'Information'
        },
        tab: {
            list_participant: 'List of Participants',
            participant_trans: 'Participant Transfer List',
            paticipant_card: 'Participant Card',
            filter: {
                from: 'From',
                to: 'To',
                docType: 'Select Document Type*',
                docHistory: {
                   listParticipant: {
                        label: 'Document History',
                        toolTip: 'Document History List of Participant'
                   },
                   participantTranfer: {
                    label: 'Document History',
                    toolTip: 'Document History Participant Transfer List'
                   }
                },
                participant_status: 'Participant Status',
                btnProcess: 'Process',
                chooseDate: 'Choose Date',
                participantNumber: {
                    label: 'Enter Participant Number',
                    placeholder: 'example: 1234567',
                    toolTip: 'Enter Participant Number',
                    error: '*Participant Number Not Registered'
                },
                chooseParticipant: {
                    text: 'Choose Participant',
                    list: {
                        all_p: 'All Participants',
                        per_p: 'Per Participant'
                    }
                }
            },
            emptyDocument:'There is no documentary history for you yet. Please check again later',
            participantRequired:'Participant status must be selected',
            radioRequired:'Must be chosen',
            alert: "The mutation period that can be selected is maximum 6 month."
        },
        readyDownload:'Ready download'
    }
}

export const id = {
    employerAttende: {
        pageTitle: {
            text1: 'Peserta',
            text2: 'Informasi'
        },
        tab: {
            list_participant: 'Daftar Peserta',
            participant_trans: 'Daftar Perpindahan Peserta',
            paticipant_card: 'Kartu Peserta',
            filter: {
                from: 'Dari Tanggal:',
                to: 'Sampai Tanggal',
                docType: 'Pilih Tipe Dokumen*',
                docHistory: {
                    listParticipant: {
                        label: 'Riwayat Dokumen',
                        toolTip: 'Riwayat Dokumen Daftar Peserta'
                    },
                    participantTranfer: {
                        label: 'Riwayat Dokumen',
                        toolTip: 'Riwayat Dokumen Daftar Perpindahan Peserta'
                    }
                },
                participant_status: 'Status Peserta',
                btnProcess: 'Proses',
                chooseDate: 'Pilih Tanggal',
                participantNumber: {
                    label: 'Masukkan Nomor Peserta',
                    placeholder: 'contoh: 1234567',
                    toolTip: 'Masukkan Nomor Peserta',
                    error: '*Nomor Peserta Tidak Terdaftar'
                },
                chooseParticipant: {
                    text: 'Pilih Peserta',
                    list: {
                        all_p: 'Semua Peserta',
                        per_p: 'Per Peserta'
                    }
                }
            },
            emptyDocument:'Belum ada riwayat dokumen untuk Anda. Silahkan periksa lagi nanti.',
            participantRequired:'Status peserta harus di pilih',
            radioRequired:'Harus dipilih',
            alert: "Periode mutasi yang dapat dipilih maksimal 6 bulan."
        },
        readyDownload:'Siap Diunduh'
    }
}
