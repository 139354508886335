// @flow
import * as React from "react";
import { Redirect } from "react-router";
import { Authentication } from "layouts";

import Register from "./Register";
import ResetPassword from "./ResetPassword";
import AuthGuardSign from "auth/AuthGuardSign";

export const pathname = "/auth";
export const route = {
    component: (props) => (
        <AuthGuardSign>
            <Authentication {...props} />
        </AuthGuardSign>
    ),
    asLayout: true,
    routes: {
        "/register": {
            routes: {
                "/set-password/:token": {
                    component: Register.Password,
                },
                "/verification": {
                    routes: {
                        "/:type/:token": {
                            component: Register.OTP,
                        },
                        "/": {
                            component: <Redirect to={`/signin`} />,
                        },
                    },
                },
                "/resend-otp/:token": {
                    component: Register.Verification,
                },
                "/": {
                    component: Register.Main,
                },
            },
        },
        "/reset-password": {
            routes: {
                "/set-password": {
                    routes: {
                        "/:token": {
                            component: ResetPassword.Password,
                        },
                        "/": {
                            component: () => <Redirect to={`/signin`} />,
                        },
                    },
                },
                "/verification": {
                    routes: {
                        "/:type/:secret": {
                            component: ResetPassword.OTP,
                        },
                        "/": {
                            component: () => <Redirect to={`/signin`} />,
                        },
                    },
                },
                "/resending-otp": {
                    routes: {
                        "/:secret": {
                            component: ResetPassword.Verification,
                        },
                        "/": {
                            component: () => <Redirect to={`/signin`} />,
                        },
                    },
                },
                "/": {
                    component: ResetPassword.Main,
                },
            },
        },
        "/": {
            component: () => <Redirect to={`/signin`} />,
        },
    },
};

const employeeAuth = {
    pathname,
    route,
};

export default employeeAuth;
