// @flow

import * as React from 'react'
import PropTypes from 'prop-types'

import requiredForA11y from 'prop-types-extra/lib/isRequiredForA11y'
import { useUncontrolled } from 'uncontrollable'

import Nav from 'react-bootstrap/Nav'
import NavLink from 'react-bootstrap/NavLink'
import NavItem from 'react-bootstrap/NavItem'
import TabContainer from 'react-bootstrap/TabContainer'
import TabContent from 'react-bootstrap/TabContent'
import TabPane from 'react-bootstrap/TabPane'

import { forEach, map } from 'react-bootstrap/ElementChildren'
import { useMediaQuery } from 'react-responsive'
import Select from 'components/Form/Select'
import clsx from 'clsx'

const propTypes = {
  activeKey: PropTypes.any,
  defaultActiveKey: PropTypes.any,
  variant: PropTypes.string,
  transition: (PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.elementType])),
  id: (requiredForA11y(PropTypes.string)),
  onSelect: PropTypes.func,
  mountOnEnter: PropTypes.bool,
  unmountOnExit: PropTypes.bool,
}

const defaultProps = {
  variant: 'tabs',
  mountOnEnter: false,
  unmountOnExit: false,
  responsiveMode: 'default',
}

function getDefaultActiveKey(children) {
  let defaultActiveKey
  forEach(children, child => {
    if (defaultActiveKey == null) {
      defaultActiveKey = child.props.eventKey
    }
  })

  return defaultActiveKey
}

function renderTab(child) {
  const { title, eventKey, disabled, tabClassName, id } = child.props
  if (title == null) {
    return null
  }

  return (
    <NavItem as={NavLink} eventKey={eventKey} disabled={disabled} id={id} className={tabClassName}>
      {title}
    </NavItem>
  )
}

const Tabs = (props) => {
  const {
    id,
    onSelect,
    transition,
    mountOnEnter,
    unmountOnExit,
    children,
    activeKey = getDefaultActiveKey(children),
    responsiveMode,
    ...controlledProps
  } = useUncontrolled(props, {
    activeKey: 'onSelect',
  })

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })

  return (
    <TabContainer
      id={id}
      activeKey={activeKey}
      onSelect={onSelect}
      transition={transition}
      mountOnEnter={mountOnEnter}
      unmountOnExit={unmountOnExit}>
      <Nav {...controlledProps} role="tablist" as="nav">
        {isTabletOrMobile && responsiveMode === 'dropdown' ? (
          <Select
            value={activeKey}
            formGroupProps={{
              className: clsx('my-0', 'bg-white'),
            }}
            menuProps={{ className: clsx('w-100') }}
            items={map(children, child => {
              const { title, eventKey } = child.props

              return {
                key: eventKey,
                value: title,
              }
            })}
          />
        ) : (
          map(children, renderTab)
        )}
      </Nav>

      <TabContent>
        {map(children, child => {
          const childProps = { ...child.props }

          delete childProps.title
          delete childProps.disabled
          delete childProps.tabClassName

          return <TabPane {...childProps} />
        })}
      </TabContent>
    </TabContainer>
  )
}

Tabs.propTypes = propTypes
Tabs.defaultProps = defaultProps
Tabs.displayName = 'Tabs'

export default Tabs
