// @flow

import React from 'react'
import { applyNumbro } from './numbro'
import { applyMoment } from './moment'

export const usePlugins = () => {
  const [applied, setApplied] = React.useState(false)

  React.useEffect(() => {
    if (!applied) {
      applyNumbro()
      applyMoment()
      setApplied(true)
    }

    return () => {
      applied && setApplied(false)
    }
  }, [applied])

  return applied
}
