export const GET_SETUP = "GET_SETUP";
export const LOADING = "LOADING";
export const LOADING_FALSE = "LOADING_FALSE";

const initialState = {
    data: null,
    loading: false,
};

const appSetupReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SETUP: {
            return {
                data: action.payload,
                loading: false,
            };
        }
        case LOADING: {
            return {
                ...state,
                loading: true,
            };
        }
        case LOADING_FALSE: {
            return {
                ...state,
                loading: false,
            };
        }
        default: {
            return state;
        }
    }
};

export default appSetupReducer;
