// @flow

import * as React from 'react'

// Styles
// import styles from './style.module.scss'

// Sub-Components
import Mobile from './Mobile'


// eslint-disable-next-line no-empty-pattern
const Navigation = ({}) => {
  return <nav></nav>
}

Navigation.Mobile = Mobile

Navigation.defaultProps = ({
  //
})

export default Navigation
