import axios from "utils/axios";
import { API_FITUR } from "utils/api.constant";

export const getFeaturesDataActions = () => async () => {
    return new Promise(async (resolve, reject) => {
        await axios
            .get(API_FITUR)
            .then((res) => {
                if (res) {
                    resolve(res.data);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
};
