
// @flow
import * as React from 'react'
import clsx from 'clsx'
import { Spinner } from 'react-bootstrap'
import { Form, Alert, AuthWrapper } from 'components'
import { Link, useParams } from 'react-router-dom'
import * as yup from 'yup'
import Countdown from 'react-countdown'
import { useDispatch } from 'react-redux'

import styles from './style.module.scss'
import { t } from 'i18next'

export const OTP = ({
  layoutPath,
  parentPath,
  history,
  title,
  nextPath,
  data,
  postHandler,
  navigate,
  loading,
  resendOtpPath
}) => {

  const dispatch = useDispatch()
  const { type, token } = useParams()
  const [ msg, setMsg ] = React.useState(null)
  const [ disabled,setDisabled]=React.useState(styles.disabledLink)
  const [ otp, setOtp ] = React.useState({})
  const handleEnter=()=>{
    let otp=document.getElementById('otp_code')
    setOtp(prevState => ({
      ...prevState,
      [otp.getAttribute('name')]: otp.value
    }))
   
  }
  const inputHanlder = (e) => {
    setOtp(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const onSubmit = async(e) => {
    e.preventDefault()
    setMsg(null)
    await dispatch(postHandler(otp.otp_code ,token))
      .then(res => {
        if(res.success){
          navigate(res.data.token)
        }else{
          setMsg(res.message)
        }
      })
  }

  const renderer = ({ minutes, seconds }) => {
    setDisabled(minutes==0&&seconds==0?styles.activeLink:styles.disabledLink)
    return <span className={clsx('ml-1')}>{minutes < 10 ? `0` + minutes : minutes}:{seconds < 10 ? `0`+ seconds : seconds}</span>;
  }

  return (
    <section className={clsx('container', 'py-4', styles.container)}>
      <div className={clsx(styles.contentWrapper, 'w-100 position-relative')}>
        <AuthWrapper
          loading={loading}
        >
          {
            data && data.data && (
              <>
                <h1 className="display-1">{title}</h1>
                <form className="mb-auto" onSubmit={onSubmit}>
                  <div className={clsx('my-5')}>
                    { msg && (
                      <Alert initialShow={true} closable className="mb-4 mr-lg-5">
                        <p className="mb-0">
                          { msg }
                        </p>
                      </Alert>
                    ) }
                    <h2 className={clsx(styles.subheading, 'text-darkNavy')}>
                     {t("signIn.otp.msg1")+' '}
                      <Form.Input
                        disabled={loading}
                        type="number"
                        name="otp_code"
                        underlined="dashed"
                        placeholder="123456"
                        formGroupProps={{ className: clsx(styles.otpInput) }}
                        onChange={inputHanlder}
                        onKeyDown={e =>
                          ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) &&
                          e.currentTarget.value.length >= 6 &&
                          e.preventDefault()
                        }
                      />
                       <button className='btn btn-success mtn-sm ml-2' onClick={()=>handleEnter()}>OK</button>
                    </h2>
                  </div>
                  <h3 className={clsx('h5', 'font-weight-light', 'my-4')}>
                    {t("signIn.otp.msg2.text1")} {type === 'sms' ? 'SMS' : type} {t("signIn.otp.msg2.text2")}  <br />
                    {t("signIn.otp.msg2.text3")}  <strong>{data.data.userAccount}</strong>
                  </h3>
                  <ul className={clsx('union-list', 'font-weight-light')}>
                    <li>
                    {t("signIn.otp.msg3.text1")}
                        <strong>
                          <Countdown date={Date.now() + (data.data.expired - new Date().getTime())}  intervalDelay={0} renderer={renderer} />
                        </strong>
                    </li>
                    <li>
                    {t("signIn.otp.msg2.text2")}  {' '}
                      <Link to={resendOtpPath} className={clsx('decoration-underlined',disabled)}>
                        <strong>{t("signIn.otp.msg2.text4")} </strong>
                      </Link>
                    </li>
                    <li>
                    {t("signIn.otp.msg2.text3")} {' '}
                      <Link to={`${layoutPath}/register`} className={clsx('decoration-underlined')}>
                        <strong>{t("signIn.resendingOtp.hp")} </strong>
                      </Link>
                    </li>
                  </ul>
                </form>
              </>
            )

          }
        </AuthWrapper>
      </div>
    </section>
  )
}

OTP.defaultProps = {
  title: 'Title',
  nextPath: '/set-password',
}

export default OTP
