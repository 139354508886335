export const en = {
    monthName: {
        jan: 'January',
        feb: 'February',
        mar: 'March',
        apr: 'April',
        mei: 'May',
        jun: 'June',
        jul: 'July',
        aug: 'August',
        sep: 'September',
        okt: 'October',
        nov: 'November',
        des: 'December'
    },
    monthNameAbbv: {
        jan: 'Jan',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Apr',
        mei: 'May',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Aug',
        sep: 'Sep',
        okt: 'Oct',
        nov: 'Nov',
        des: 'Dec'
    }
}

export const id = {
    monthName: {
        jan: 'Januari',
        feb: 'Februari',
        mar: 'Maret',
        apr: 'April',
        mei: 'Mei',
        jun: 'Juni',
        jul: 'Juli',
        aug: 'Agustus',
        sep: 'September',
        okt: 'Oktober',
        nov: 'November',
        des: 'Desember'
    },
    monthNameAbbv: {
        jan: 'Jan',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Apr',
        mei: 'Mei',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Agu',
        sep: 'Sep',
        okt: 'Okt',
        nov: 'Nov',
        des: 'Des'
    }
}