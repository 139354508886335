import axios from "utils/axios";

import {
    GET_NOTIFICATION,
    LOADING_NOTIFICATION,
} from "../reducers/notificationsReducer";
import { SET_AUTH_DETECTOR_FALSE } from "../reducers/authDetectorReducer";
import { API_EMPR_NOTIF, API_EMP_NOTIF } from "utils/api.constant";

export const getNotificationsEmployeeAction =
    (cer_nmbr) => (dispatch, getState) => {
        const { lang } = getState().language;

        const config = {
            headers: {
                "x-accept-lang": lang,
            },
        };

        dispatch({
            type: LOADING_NOTIFICATION,
        });

        return new Promise(async(resolve, reject) => {
            await axios
                .get(`${API_EMP_NOTIF}/${cer_nmbr}?page=1&take=5`, config)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        dispatch({
                            type: GET_NOTIFICATION,
                            payload: res.data.data
                        })
                        resolve(res.data)
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.data.statusCode === 401) {
                            dispatch({
                                type: SET_AUTH_DETECTOR_FALSE,
                                payload: err.response.data.message,
                            });
                        }
                    } else {
                        console.log("Internal server error");
                    }
                    reject(err)
                });
        })

    };

export const getNotificationEmployeeDetailAction = (data) => (_, getState) => {
    const { lang } = getState().language;

    const config = {
        headers: {
            "x-accept-lang": lang,
        },
    };

    return new Promise(async (resolve) => {
        await axios
            .get(
                `${API_EMP_NOTIF}/${data.cer_nmbr}/${data.crm_nmbr}/${data.sub_nmbr}`,
                config
            )
            .then((res) => {
                if (res.data.statusCode === 200) {
                    resolve({
                        success: true,
                        data: res.data.data,
                    });
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.data.statusCode === 401) {
                        dispatch({
                            type: SET_AUTH_DETECTOR_FALSE,
                            payload: err.response.data.message,
                        });
                    } else {
                        resolve({
                            success: false,
                            message: err.response.data.message,
                        });
                    }
                } else {
                    console.log("Internal server error");
                }
            });
    });
};

export const getNotificationsEmployeeAllAction =
    (cer_nmbr) => async (_, getState) => {
        const { lang } = getState().language;

        const config = {
            headers: {
                "x-accept-lang": lang,
            },
        };

        return new Promise(async (resolve) => {
            await axios
                .get(`${API_EMP_NOTIF}/${cer_nmbr}?page=1&take=10`, config)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        resolve({
                            success: true,
                            data: res.data.data,
                        });
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.data.statusCode === 401) {
                            dispatch({
                                type: SET_AUTH_DETECTOR_FALSE,
                                payload: err.response.data.message,
                            });
                        } else {
                            resolve({
                                success: false,
                                message: err.response.data.message,
                            });
                        }
                    } else {
                        console.log("Internal server error");
                    }
                });
        });
    };

export const getNotificationsEmployerAction =
    () => (dispatch, getState) => {
        const { lang } = getState().language;

        const config = {
            headers: {
                "x-accept-lang": lang,
            },
        };

        dispatch({
            type: LOADING_NOTIFICATION,
        });

        return new Promise(async(resolve, reject) => {

            await axios
                .get(`${API_EMPR_NOTIF}?page=1&take=5`, config)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        dispatch({
                            type: GET_NOTIFICATION,
                            payload: res.data.data
                        })
                        resolve(res.data)
                    }
                })
                .catch((err) => {
                    if (err.response.data.statusCode === 401) {
                        dispatch({
                            type: SET_AUTH_DETECTOR_FALSE,
                            payload: err.response.data.message,
                        });
                    }
                    reject(err)
                });

        })

    };

export const getNotificationEmployerDetailAction = (data) => (_, getState) => {
    const { lang } = getState().language;

    const config = {
        headers: {
            "x-accept-lang": lang,
        },
    };

    return new Promise(async (resolve) => {
        await axios
            .get(`${API_EMPR_NOTIF}/${data.crm_nmbr}/${data.sub_nmbr}`, config)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    resolve({
                        success: true,
                        data: res.data.data,
                    });
                }
            })
            .catch((err) => {
                if (err.response.data.statusCode === 401) {
                    dispatch({
                        type: SET_AUTH_DETECTOR_FALSE,
                        payload: err.response.data.message,
                    });
                } else {
                    resolve({
                        success: false,
                        message: err.response.data.message,
                    });
                }
            });
    });
};

export const getNotificationsEmployerAllAction = () => async (_, getState) => {
    const { lang } = getState().language;

    const config = {
        headers: {
            "x-accept-lang": lang,
        },
    };

    return new Promise(async (resolve) => {
        await axios
            .get(`${API_EMPR_NOTIF}?page=1&take=10`, config)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    resolve({
                        success: true,
                        data: res.data.data,
                    });
                }
            })
            .catch((err) => {
                if (err.response.data.statusCode === 401) {
                    dispatch({
                        type: SET_AUTH_DETECTOR_FALSE,
                        payload: err.response.data.message,
                    });
                } else {
                    resolve({
                        success: false,
                        message: err.response.data.message,
                    });
                }
            });
    });
};
