// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_initialLoading__Uby9S {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10000;
  background: white;
}`, "",{"version":3,"sources":["webpack://./src/components/InitalPageLoading/style.module.scss"],"names":[],"mappings":"AACA;EACI,MAAA;EACA,SAAA;EACA,QAAA;EACA,OAAA;EACA,cAAA;EACA,iBAAA;AAAJ","sourcesContent":["\n.initialLoading{\n    top: 0;\n    bottom: 0;\n    right: 0;\n    left: 0;\n    z-index: 10000;\n    background: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"initialLoading": `style_initialLoading__Uby9S`
};
export default ___CSS_LOADER_EXPORT___;
