import { SET_AUTH_DETECTOR_FALSE, SET_AUTH_DETECTOR_TRUE } from '../reducers/authDetectorReducer'


export const authDetectorTrueAction = () => (dispatch) => {
    dispatch({
        type: SET_AUTH_DETECTOR_TRUE
    })
}

export const authDetectorFalseAction = () => (dispatch) => {
    dispatch({
        type: SET_AUTH_DETECTOR_FALSE
    })
}