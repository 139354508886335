// @flow
import React from 'react'
import clsx from 'clsx'
import { ReactComponent as LogoIcon } from 'assets/images/svgs/logo/icon.svg'
import { ReactComponent as LogoBrand } from 'assets/images/svgs/logo/brand.svg'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'

// Styles
import styles from './style.module.scss'

const layoutTypes = ['common', 'dashboard']
const layoutTypesObj = layoutTypes.reduce(
  (agg, next) => ({ ...(agg), [(next)]: true }),
  {}
)

const Header = ({
  layoutType,
  langSelector,
  appendToNav,
  logoIcon,
  fluid,
  headerRef,
  hideBrand,
  mode,
  navShowed,
  onNavToggleClick,
  onClickNotification,
  ...rest
}) => {
  const isTypeDashboard = layoutType === 'dashboard'

  const toggleNav = React.useCallback(status => onNavToggleClick(status),[onNavToggleClick])

  return (
    <header
      {...rest}
      ref={headerRef}
      className={clsx(styles.header, isTypeDashboard ? styles.dark : styles.light, rest.className)}>
      {logoIcon && (
        <Link
          className={clsx(
            styles.header__icon,
            isTypeDashboard ? styles.dark__icon : styles.light__icon
          )}
          to={`/${mode}`}>
          <LogoIcon fill={isTypeDashboard ? '#FFF' : '#00a758'} />
        </Link>
      )}
      <div className={clsx(styles.header__brand)}>
        <LogoBrand fill={isTypeDashboard ? '#FFF' : '#34384b'} />
      </div>
      <Button
        variant={isTypeDashboard ? 'dark' : 'light'}
        className={clsx(`mi-${navShowed ? 'cross' : 'hamburger'} lg`, styles.header__toggle)}
        onClick={() => toggleNav(!navShowed)}></Button>
      {isTypeDashboard && (
        <Button
          variant="dark"
          onClick={onClickNotification}
          className={clsx(['mi-notification lg', styles.header__notification])}
        />
      )}
    </header>
  )
}

export default Header
