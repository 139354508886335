import React from 'react'
import clsx from 'clsx'
import { Modal, Button } from 'react-bootstrap'
import styles from './style.module.scss'
import { ReactComponent as LogoIcon } from "assets/images/svgs/logo/icon.svg";
import { ReactComponent as LogoBrand } from "assets/images/svgs/logo/brand.svg";
import { useTranslation } from "react-i18next";

const ModalDialog = React.memo(({textItalic, showBirthdayModalPopup, handleClose}) => {
  const { t } = useTranslation();
  // const [show, invokeModal] = React.useState(false)
  // const initModal = () => {
  //   return invokeModal(!false)
  // }
  // const handleCloseBtn = () => invokeModal(false);
  return (
    <>
      {/* <Button variant="success" onClick={initModal}>
        Card Ultah
      </Button> */}
      {/* {
            props.position==undefined&&(
              <> <span className="font-serif">{props.textItalic},</span> { props.text }</>
            )
          } */}
      <Modal className={clsx(styles.modalPopUp)} show={showBirthdayModalPopup} backdrop="static">
        <Modal.Header className={clsx(styles.modalHeader)}  closeButton onClick={handleClose} >
          {/* <Modal.Title>React Modal Popover Example</Modal.Title> */}
          <LogoIcon
            fill="#FFF"
            width={24.266666666667}
            height={26}
            />
            <LogoBrand
              fill="#FFF"
              width={115}
              height={26}
            />
        </Modal.Header>
        <Modal.Body className={clsx(styles.modalHeader)} >
          {/* text body modal birthday card*/}
          <hr/><span>{t("birthdayCard.birthHeader1")}</span>
          <span className="font-serif"> {textItalic} </span><span>{t("birthdayCard.birthHeader2")}</span>
          {/* {
            props.position==undefined&&(
              <> <span className="font-serif">{props.textItalic},</span> { props.text }</>
            )
          } */}
          <br/><br/><span>{t("birthdayCard.birthBody")}</span>
          <br/><hr/><span>{t("birthdayCard.birthFooter1")}</span>
          <br /> <span>{t("birthdayCard.birthFooter2")}</span>
        </Modal.Body>
        <Modal.Footer className={clsx(styles.modalHeader)} >
          {/* <p>BANNER_MANULIFE</p> */}
        </Modal.Footer>
      </Modal>
    </>
  )
})
export default ModalDialog