// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_loading__kiPQA {
  z-index: 10;
  top: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.669);
  min-height: 123px;
}`, "",{"version":3,"sources":["webpack://./src/components/BoxWrapper/style.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,MAAA;EACA,YAAA;EACA,4CAAA;EACA,iBAAA;AACJ","sourcesContent":[".loading {\n    z-index: 10;\n    top: 0;\n    height: 100%;\n    background-color: rgba(255, 255, 255, 0.669);\n    min-height: 123px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": `style_loading__kiPQA`
};
export default ___CSS_LOADER_EXPORT___;
