// @flow
import * as React from 'react'
import ReactDOM from 'react-dom'
import clsx from 'clsx'
import { has } from 'lodash'
import { Nav } from 'react-bootstrap'
import { CSSTransition } from 'react-transition-group'
import { Link, matchPath, NavLink, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { ReactComponent as LogoIcon } from 'assets/images/svgs/logo/icon.svg'
import { ReactComponent as LogoBrand } from 'assets/images/svgs/logo/brand.svg'
import { SET_DESC } from 'store/reducers/descriptionPageReducer'

// Styles
import styles from './style.module.scss'


const matchPathHandler = (pathname, path) => {
  const match = matchPath(pathname, { path: path })
  return match ? match.isExact : false;
}

const matchPathMenuHandler = (pathname, path) => {
  return path ? pathname.includes(path) : false
}


// eslint-disable-next-line no-empty-pattern
const Sidebar = ({
  mode,
  position,
  navItems,
  homeLink,
  onChildNavToggleState,
  childNavSelector,
}) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [childNavs, setChildNavs] = React.useState(null)

  const setDescriptionPageHandler = () => {
    const samePath = navItems && navItems.filter(
      item => matchPathHandler(location.pathname, item.to)
    )
    if(samePath && samePath.length > 0){
      dispatch({
        type: SET_DESC,
        payload: samePath[0].desc
      })
    }
  }

  const setChildDescriptionPageHandler = (nav) => {
    const hasNavSamePath = nav[0].submenu.filter(item => {
      if(item.to){
        return matchPathHandler(location.pathname, item.to)
      }else{
        return false
      }
    })
    if(hasNavSamePath.length > 0){
      dispatch({
        type: SET_DESC,
        payload: hasNavSamePath[0].desc
      })
    }
  }

  React.useEffect(() => {
    onChildNavToggleState(!!childNavs)
  }, [childNavs])

  React.useLayoutEffect(() => {

    let hasChildsNavs = []

    for(let i = 0; navItems && navItems.length > i; i++){
      if(has(navItems[i], 'submenu')){
        const filterNav = navItems && navItems[i].submenu.filter(it => matchPathHandler(location.pathname, it.to))
        if(filterNav.length > 0){
          hasChildsNavs.push(navItems[i])
        }
      }
    }

    if(hasChildsNavs.length > 0){
      setChildDescriptionPageHandler(hasChildsNavs)
    }else{
      setDescriptionPageHandler()
    }

    if (hasChildsNavs.length > 0) setChildNavs(hasChildsNavs[0].submenu)
    else setChildNavs(null)

  }, [location, navItems])

  return (
    <nav className={clsx('sidebar', styles.sidebar)} style={{ position }}>
      <NavLink
        to={homeLink || '/'}
        className={clsx(styles.sidebar__brand)}
        activeClassName={styles.sidebar__brand__active}>
        <LogoIcon fill="#FFF"/>
      </NavLink>
      <Nav as="ul" className={clsx(styles.sidebar__nav)}>
        {navItems && navItems.map((item, k) => {
          const { label, icon, to, submenu } = item
          if(label !== 'Dashboard'){
            return (
              <Nav.Item as="li" key={k}>
                <Nav.Link
                  to={to}
                  as={NavLink}
                  className={clsx(styles.sidebar__nav__link, matchPathMenuHandler(location.pathname, to) && styles['has-active'])}
                  activeClassName={styles['has-active']}
                >
                  {!!icon && <i className={clsx(`${icon}`)} />}
                  <span className={clsx(styles.sidebar__nav__item__label)}>{label}</span>
                </Nav.Link>
              </Nav.Item>
            )
          }else{
            
            return (
              <Nav.Item as="li" key={k}>
                <Nav.Link
                  to={to}
                  as={NavLink}
                  className={clsx(styles.sidebar__nav__link, matchPathMenuHandler(location.pathname, to) && styles['has-active'])}
                  activeClassName={styles['has-active']}
                >
                  <span>
                      <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" fill="#fff" width="20" height="20">
                        <title>General_89_T89</title>
                        <path d="M30.248,3,6.536,26.659V57H53.464V26.609ZM24.96,52V37.248H35.037V52Zm23.508,0H40.033V32.252H19.964V52H11.532V28.722L30.251,10.079,48.468,28.694Z"/>
                      </svg>
                  </span>
                  <span className={clsx(styles.sidebar__nav__item__label)}>{label}</span>
                </Nav.Link>
              </Nav.Item>
            )
          }
        })}
      </Nav>
      {!!document.querySelector(childNavSelector) &&
        ReactDOM.createPortal(
          <CSSTransition
            in={!!childNavs?.length}
            timeout={300}
            classNames="transition-slide"
            unmountOnExit>
            <div className={clsx(styles.sidebar__childNav)}>
              <Link
                className={clsx(styles.sidebar__childNav__link, styles.sidebar__childNav__brand)}
                to="/">
                <LogoBrand fill="currentColor" />
              </Link>
              <Nav as="ul">
                {childNavs && childNavs.map(({ label, to, link }, k) => {
                  if(label !== 'Dashboard'){
                    return(
                      <Nav.Item as="li" key={k}>
                        {
                          !link && (
                            <Nav.Link
                              exact
                              to={to}
                              as={NavLink}
                              className={clsx(styles.sidebar__childNav__link)}
                              activeClassName={styles['has-active']}
                            >
                                <span>{label}</span>
                            </Nav.Link>
                          )
                        }
                        {
                          link && (
                            <a className={clsx(styles.sidebar__childNav__link) + ' nav-link'} target='_blank' href={link} rel='noopener noreferrer'>{label}</a>
                          )
                        }
                      </Nav.Item>
                    )
                  }
                })}
              </Nav>
            </div>
          </CSSTransition>,
          document.querySelector(childNavSelector),
        )}
    </nav>
  )
}

export default Sidebar
