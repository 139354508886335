// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_loading__YgMrg {
  position: absoute;
  background-color: rgba(255, 255, 255, 0.52);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 50vh;
}`, "",{"version":3,"sources":["webpack://./src/components/Loadable/styles.module.scss"],"names":[],"mappings":"AACA;EACI,iBAAA;EACA,2CAAA;EACA,WAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,gBAAA;AAAJ","sourcesContent":["\n.loading{\n    position: absoute;\n    background-color: rgba(255, 255, 255, 0.52);\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    min-height: 50vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": `styles_loading__YgMrg`
};
export default ___CSS_LOADER_EXPORT___;
