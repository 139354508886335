import React from "react";
import { useDispatch } from "react-redux";

import NotificationComponent from "views/Notification";
import { getNotificationsEmployeeAllAction } from "store/actions/notificationsActions";
import useAuth from "hooks/useAuth";
import { cerNmbrLSVar } from "contexts/AuthContext";

const Notification = (props) => {
    const dispatch = useDispatch();
    const { getValueLocalStorage } = useAuth();
    const [data, setData] = React.useState(null);
    const [loading, setLoading] = React.useState(null);

    const getDataAll = async () => {
        setLoading(true);
        const cer_nmbr = getValueLocalStorage(cerNmbrLSVar);
        await dispatch(getNotificationsEmployeeAllAction(cer_nmbr)).then(
            (res) => {
                setLoading(false);
                if (res.success) {
                    setData(res.data);
                }
            }
        );
    };

    React.useEffect(() => {
        getDataAll();
    }, []);

    return (
        <NotificationComponent
            {...props}
            loading={loading}
            data={data}
            mode="employee"
        />
    );
};

export default Notification;
