/**
 * GENERAL
 */
export const API_SETUP = "/api/v1/app-setup/public/app";
export const API_SOSMED = "/api/v1/sosmed";
export const API_AUTH_SESSION = "/api/v1/employee/auth/session";
export const API_USER_INFO = "/api/v1/:role/auth/user-info";

export const API_MENU = "/api/v1/menu/public/list";

export const API_FILE = "/api/v1/file?filepath";
export const API_NEWS = "/api/v1/news/public/lists";
export const API_FAQ = "/api/v1/faq";
export const API_FAQ_GROUP = "/api/v1/faq/:group/list";
export const API_FITUR = "/api/v1/fitur/public/lists";
export const API_FORM = "/api/v1/formulir/public/list";

export const API_UNIT_PRICE = "/api/v1/unit-price";
export const API_UNIT_PRICE_GS = "/api/v1/unit-prc-gs";

export const API_EXC_RATE = "/api/v1/exchange-rate";
export const API_EXC_RATE_GS = "/api/v1/exchg-rate-gs";

export const API_FUND_FACT = "/api/v1/fund-fact/public/list-2";

export const API_MARKET_REVIEW = "/api/v1/market-review/public/last";

export const API_LOGOUT = "/api/v1/cms-auth/logout";

/**
 * EMPLOYEE
 */
// auth
export const API_EMP_LOGIN = "/api/v1/employee/auth/login";
export const API_EMP_NOTIF = "/api/v1/employee/notif";
export const API_EMP_AUTH_VERIFY = "/api/v1/employee/auth/verify-otp-login";
export const API_EMP_AUTH_OTP = "/api/v1/employee/auth/otp";
export const API_EMP_AUTH_OTP_SEND = "/api/v1/employee/auth/send-otp-login";
export const API_EMP_CHANGE_PASS = "/api/v1/employee/auth/change-password";
export const API_EMP_LOG_OUT = "/api/v1/employee/auth/logout";
// ballance
export const API_EMP_BALLANCE = "/api/v1/employee/saldo/:cer_number";
export const API_EMP_BALLANCE_TRANS =
    "/api/v1/employee/saldo/:cer_number/trans";
export const API_EMP_BALLANCE_INVEST =
    "/api/v1/employee/saldo/:cer_number/invest";
export const API_EMP_BALLANCE_PERIOD_1N =
    "/api/v1/employee/saldo/:cer_number/:year/periode_ln";
export const API_EMP_BALLANCE_PERIOD_ALL =
    "/api/v1/employee/saldo/:cer_number/:year/:period/periode";
export const API_EMP_BALLANCE_PERIOD_YEAR =
    "/api/v1/employee/saldo/:cer_number/year";
export const API_EMP_PAS = "/api/v1/employee/saldo/:cer_number/pas";
// claim
export const API_EMP_CLAIM = "/api/v1/employee/claim/:cer_number";
// inv-direct
export const API_EMP_DIRECT_OPT = "/api/v1/employee/inv-direct/options";
export const API_EMP_DIRECT_RISK = "/api/v1/employee/risk-profile/:cer_number";
export const API_EMP_DIRECT_CREATE = "/api/v1/employee/inv-direct/create";
export const API_EMP_DIRECT_STATUS =
    "/api/v1/employee/inv-direct/status/:cer_number";
export const API_EMP_DIRECT_OTP = "/api/v1/employee/inv-direct/otp/:secret";
export const API_EMP_DIRECT_REOTP = "/api/v1/employee/inv-direct/resend-otp";
export const API_EMP_DIRECT_OTP_VERIFY =
    "/api/v1/employee/inv-direct/verify-otp";
// account
export const API_EMP_ACCOUNT = "/api/v1/employee/account/:cer_number";
export const API_EMP_ACCOUNT_ECARD =
    "/api/v1/employee/account/:cer_number/ecard-empe";
export const API_EMP_ACCOUNT_AVATAR = "/api/v1/employee/auth/avatar";
// register
export const API_EMP_REG = "/api/v1/employee/auth/register";
export const API_EMP_REG_OTP = "/api/v1/employee/auth/otp/:data";
export const API_EMP_REG_OTP_SEND = "/api/v1/employee/auth/send-otp-register";
export const API_EMP_REG_OTP_VERIFY =
    "/api/v1/employee/auth/verify-otp-register";
export const API_EMP_REG_CREATE_PASS = "/api/v1/employee/auth/create-password";
// report
export const API_EMP_REPORT = "/api/v1/employee/reports/:cert_number";
export const API_EMP_REPORT_DEL = "/api/v1/employee/reports/:id";
export const API_EMP_REPORT_DWN = "/api/v1/employee/reports/download/:id";
// forgot pass
export const API_EMP_FORGOT_PASS = "/api/v1/employee/auth/forgot-password";
export const API_EMP_FORGOT_PASS_VERIFY_OTP =
    "/api/v1/employee/auth/verify-otp-fpassword";
export const API_EMP_FORGOT_CHANGE_PASS =
    "/api/v1/employee/auth/change-fpassword";
export const API_EMP_FORGOT_SEND_OTP =
    "/api/v1/employee/auth/send-otp-fpassword";

/**
 * EMPLOYER
 */
export const API_EMPR_LOGIN = "/api/v1/employer/auth/login";
export const API_EMPR_NOTIF = "/api/v1/employer/notif";
export const API_EMPR_AUTH_VERIFY = "/api/v1/employer/auth/verify-otp-login";
export const API_EMPR_AUTH_OTP = "/api/v1/employer/auth/otp";
export const API_EMPR_AUTH_OTP_SEND = "/api/v1/employer/auth/send-otp-login";
export const API_EMPR_CHANGE_PASS = "/api/v1/employer/auth/change-password";
export const API_EMPR_LOG_OUT = "/api/v1/employer/auth/logout";
// peserta
export const API_EMPR_DAFTAR_PESERTA =
    "/api/v1/employer/peserta/daftar-peserta";
export const API_EMPR_PESERTA_STATUS = "/api/v1/employer/peserta/status";
export const API_EMPR_PERPINDAHAN_PESERTA =
    "/api/v1/employer/peserta/daftar-perpindahan-peserta";
export const API_EMPR_PESERTA_ECARD = "/api/v1/employer/peserta/ecard";
export const API_EMPR_IMAGE_ECARD = "/api/v1/employer/peserta/rdm-ecard";
// report
export const API_EMPR_REPORT = "/api/v1/employer/reports";
// ballance
export const API_EMPR_BALLANCE_COMP =
    "/api/v1/employer/ballance/company-account";
export const API_EMPR_BALLANCE_EMP = "/api/v1/employer/ballance";
export const API_EMPR_BALLANCE_ATTD =
    "/api/v1/employer/ballance/attendee-account";
export const API_EMPR_BALLANCE_YEAR = "/api/v1/employer/ballance/year";
export const API_EMPR_BALLANCE_PERIOD1 =
    "/api/v1/employer/ballance/:year/periode_ln";
export const API_EMPR_BALLANCE_PAS = "/api/v1/employer/ballance/pas";
export const API_EMPR_BALLANCE_PERIOD_YEAR =
    "/api/v1/employer/ballance/:year/:period/periode";
// claim
export const API_EMPR_CLAIM_IS = "/api/v1/employer/claim/incomplate-submission";
export const API_EMPR_CLAIM_PR = "/api/v1/employer/claim/payment-report";
export const API_EMPR_CLAIM_PN = "/api/v1/employer/claim/pension-notice";
export const API_EMPR_CLAIM_IP = "/api/v1/employer/claim/claim-in-process";
export const API_EMPR_CLAIM_SUBMISSION =
    "/api/v1/employer/claim/claim-submission?page=:page&take=:take";
// iuran
export const API_EMPR_CONTRIB_TRANS =
    "/api/v1/employer/contribution/transactions-info";
export const API_EMPR_CONTRIB_BILL_DPLK =
    "/api/v1/employer/contribution/billing-report";
// account
export const API_EMPR_ACCOUNT_COM_INFO =
    "/api/v1/employer/account/company-info";
export const API_EMPR_ACCOUNT_GRP_INFO = "/api/v1/employer/account/group-info";
export const API_EMPR_ACCOUNT_CNT_INFO =
    "/api/v1/employer/account/contact-info";
// register
export const API_EMPR_REG = "/api/v1/employer/auth/register";
export const API_EMPR_REG_OTP = "/api/v1/employer/auth/otp/:secret";
export const API_EMPR_REG_OTP_SEND = "/api/v1/employer/auth/send-otp-register";
export const API_EMPR_REG_OTP_VERIFY =
    "/api/v1/employer/auth/verify-otp-register";
export const API_EMPR_REG_CREATE_PASS = "/api/v1/employer/auth/create-password";
// forgot pass
export const API_EMPR_FORGOT_PASS = "/api/v1/employer/auth/forgot-password";
export const API_EMPR_FORGOT_PASS_VERIFY_OTP =
    "/api/v1/employer/auth/verify-otp-fpassword";
export const API_EMPR_FORGOT_CHANGE_PASS =
    "/api/v1/employer/auth/change-fpassword";
export const API_EMPR_FORGOT_SEND_OTP =
    "/api/v1/employer/auth/send-otp-fpassword";
export const API_EMPR_RISK_PROFILE = "/api/v1/employer/risk-profile";

export const API_INVESMENT_UNIT_PRICE =
    "/api/v1/unit-price?page=:page&take=:take&search_by=:search_by";
export const API_INVESMENT_EXCHANGE_RATE =
    "/api/v1/exchange-rate?page=:page&take=:take&order=:order";

export const API_FAQ_ARAHAN_INVESTASI = "/api/v1/faq/arahan_investasi/list";
export const API_FUND_FACT_LINK = "/api/v1/link";
export const API_PROFILE_ID = "/api/v1/profil/:id";
export const API_PROFILE_ECARD = "/api/v1/profil/get-ecard/:id";
export const API_LIST_FORMULIR = "/api/v1/formulir/public/list";
export const API_FAQ_INVEST_LIST = "/api/v1/faq/invest/list";

//MAINTENANCE PAGE
export const API_MAINTENANCE = "/api/v1/maintenance/status-maintenance";
export const API_MAINTENANCE_PASSKEY = "/api/v1/maintenance/passkey";