import { useMemo } from 'react'
import { toast } from 'react-toastify'

import axios from 'utils/axios'
import { Store } from 'store'
import { SET_AUTH_DETECTOR_FALSE } from 'store/reducers/authDetectorReducer'

const WithAxios = (props) => {

    useMemo(() => {
        axios.interceptors.response.use(
            response => response,
            async (error) => {
                const statusCode =  error.response == undefined ? 500 : error.response.status

                if(statusCode === 401){
                    Store.dispatch({
                        type: SET_AUTH_DETECTOR_FALSE,
                        payload: error.response.data.message
                    })
                    setTimeout(() => {
                        Store.dispatch({
                            type: SET_AUTH_DETECTOR_FALSE,
                            payload: null
                        })
                    },3000)
                } else if(statusCode === 400) {
                    throw error
                } else {
                    const msg = error.response == undefined ? 'Network failed to connect' : error.response.data.message
                    toast.error(msg, {
                        position: "top-right",
                        autoClose: true,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        )
    },[])

    return props.children
}

export default WithAxios
