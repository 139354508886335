// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_legendWrapper__\\+pFPa {
  padding-left: 0;
  list-style: none;
  max-width: 200px;
  font-size: 10px;
  position: relative;
}

.style_legendBoxColor__ik3Xj {
  width: 16px;
  height: 16px;
  border-radius: 2px;
}

.style_legendText__Sj5gx {
  flex: 1 1;
  margin-left: 10px;
  color: rgb(52, 52, 52);
  line-height: 1.3;
}`, "",{"version":3,"sources":["webpack://./src/components/Chart/Pie/style.module.scss"],"names":[],"mappings":"AACA;EACI,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;AAAJ;;AAIA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;AADJ;;AAIA;EACI,SAAA;EACA,iBAAA;EACA,sBAAA;EACA,gBAAA;AADJ","sourcesContent":["\n.legendWrapper{\n    padding-left: 0;\n    list-style: none;\n    max-width: 200px;\n    font-size: 10px;\n    position: relative;\n    // left: 45px;\n}\n\n.legendBoxColor{\n    width: 16px;\n    height: 16px;\n    border-radius: 2px;\n}\n\n.legendText{\n    flex: 1;\n    margin-left: 10px;\n    color: rgb(52, 52, 52);\n    line-height: 1.3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"legendWrapper": `style_legendWrapper__+pFPa`,
	"legendBoxColor": `style_legendBoxColor__ik3Xj`,
	"legendText": `style_legendText__Sj5gx`
};
export default ___CSS_LOADER_EXPORT___;
