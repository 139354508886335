// @flow
import * as React from 'react'
import { Dashboard } from 'layouts'
import { Redirect } from 'react-router'
import { Loadable } from 'components'

import employeeAuth from './Auth'
import guide from './Guide'
import profile from './Profile'
import investment from './Investment'
import notification from './Notification'

const Home = Loadable(React.lazy(() => import('./Home')))
const Ballance = Loadable(React.lazy(() => import('./Ballance')))
const Claim = Loadable(React.lazy(() => import('./Claim')))
const AuthGuardEmployee = Loadable(React.lazy(() => import('../../auth/AuthGuardEmployee')))

export const pathname = '/employee'
export const route = {
  routes: {
    [employeeAuth.pathname]: employeeAuth.route,
    '/': {
      component: props => (
        <AuthGuardEmployee>
          <Dashboard {...props} mode="employee" notification/>
        </AuthGuardEmployee>
      ),
      asLayout: true,
      routes: {
        '/home': {
          component: Home,
        },
        '/ballance': {
          component: Ballance,
        },
        '/claim': {
          component: Claim,
        },
        [investment.pathname]: investment.route,
        [profile.pathname]: profile.route,
        [guide.pathname]: guide.route,
        [notification.pathname]: notification.route,
        '/': {
          component: _ => <Redirect to={`${pathname}/home`} />,
        },
      },
    },
  },
}
