// @flow
import Prices from './Prices'
import Direction from './Direction'
import Conversion from './Conversion'

const Investment = {
  Prices: Prices,
  Direction: Direction,
  Conversion: Conversion
}

export default Investment
