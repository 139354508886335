
export const SET_EMPYE_SALDO = 'SET_EMPYE_SALDO'
export const LOADING_EMPYE_SALDO = 'LOADING_EMPYE_SALDO'
export const LOADING_EMPYE_TRANSAKSI = 'LOADING_EMPYE_TRANSAKSI'

const initialState = {
    rincianInvestasi: null,
    rincianTransaksi: null,
    saldo: null,
    saldoLoading: false,
    transaksiLoading: false
}

const BallanceReducer = (state = initialState, action) => {
    switch(action.type){
        case LOADING_EMPYE_TRANSAKSI: {
            return{
                ...state,
                transaksiLoading: true
            }
        }
        case SET_EMPYE_SALDO: {
            return{
                ...state,
                saldo: action.payload,
                saldoLoading: false
            }
        }
        case LOADING_EMPYE_SALDO: {
            return{
                ...state,
                saldoLoading: true
            }
        }
        default:{
            return state
        }
    }
}

export default BallanceReducer