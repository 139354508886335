// @flow
import clsx from 'clsx'
import * as React from 'react'
import { Row, Accordion, Button, Col, Card } from 'react-bootstrap'

const RowedAccordian = ({
  cols,
  items,
  isDownload,
  buttonIcon,
  itemsPerRow: rows,
  ...restProps
}) => {
  const [keyState, setKeyState] = React.useState(null)
  const clonedItems = [...items]
  const itemsPerRow = !!rows ? rows : parseInt((clonedItems.length / (!!cols ? cols : 2)).toFixed())

  const renderItem = ({ content, title, name }) => (
    <Col sm={12} key={name} className="accordion-item">
      <Accordion.Toggle
        as={Button}
        variant="accordion"
        eventKey={name}
        className={clsx(buttonIcon, keyState === name && 'collapsed')}>
        {title}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={name}>
        <div className="accordion-item__content">
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </Accordion.Collapse>
    </Col>
  )

  const monthName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const downloadSheet = (val) => {
    window.open(val, '_blank');
  }

  const renderDownload = ({ month, _list }) => (
    <Col sm={12} key={month} className="accordion-item">
      <Accordion.Toggle
        as={Button}
        variant="accordion"
        eventKey={month}
        className={clsx(buttonIcon, keyState === month && 'collapsed')}>
        {monthName[month - 1]}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={month}>
        <div className="accordion-item__content">
          {_list && _list.map((item, i) => {
            return (
              <Card onClick={() => downloadSheet(item.path)} key={i} className="my-2 border-t-1 cursor-pointer">
                <Card.Body className={clsx("px-2 py-2")}>
                  <span className="font-weight-bold">
                    <i className={clsx("mi-download lg", "vertical-align-text-bottom")} ></i>{" "}
                    {item.name}
                  </span>
                </Card.Body>
              </Card>
            )
          })}
        </div>
      </Accordion.Collapse>
    </Col>
  )
  return (
    <Accordion
      {...restProps}
      as={Row}
      activeKey={keyState}
      onSelect={setKeyState}
      className={clsx('align-items-start', 'mt-4', restProps?.className)}>
      {!isDownload ? itemsPerRow < clonedItems.length
        ? new Array(Math.ceil(clonedItems.length / itemsPerRow)).fill().map((_, key) => (
          <Col as={Row} key={key} noGutters md={!!cols ? 12 / cols : 2}>
            {clonedItems.splice(0, itemsPerRow).map(renderItem)}
          </Col>
        ))
        : clonedItems.map(renderItem)
        : clonedItems.map(renderDownload)}
    </Accordion>
  )
}

RowedAccordian.defaultProps = {
  buttonIcon: 'chevron',
  items: [],
  isDownload: false
}

export default RowedAccordian
