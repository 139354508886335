// @flow
import * as React from 'react'
import clsx from 'clsx'
import { Col, Container, Row } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// Styles
import styles from './style.module.scss'
import { Accordion } from 'components'
import { getFaqDataActions } from '../../../store/actions/faqActions'

export const About = ({ ...restProps }) => {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const descPageText = useSelector(state => state.descriptionPage)
  const lang = useSelector(state => state.language.lang)

  const [data, setData] = React.useState(null)

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })

  const responsiveRowRender = content =>
    !isTabletOrMobile ? (
      <Row className={clsx('mb-5')}>
        <Col sm={12}>{content}</Col>
      </Row>
    ) : (
      <div className={clsx('normalize-container-fluid', 'mb-5 pb-5')}>{content}</div>
    )

  const getData = React.useCallback(async() => {
    await dispatch(getFaqDataActions('tentang')).then(
      res => {
        const dtTransform = res.data.map((it) => {
          return{
            title: it.title,
            content: it.content,
            name: it.name
          }
        })
        setData(dtTransform)
      }
    )
    .catch(
      err => {
        console.log(err)
      }
    )
  },[setData])

  React.useEffect(() => {
    getData()
  },[lang])

  return (
    <Container as="section" className={clsx(styles.wrapper)} fluid>
      <h1 className="font-weight-light display-2 mb-4 mt-5">
        {t('guides.about.text1')} <span className="font-serif" dangerouslySetInnerHTML={{ __html: t("guides.about.text2") }}></span>
      </h1>
      <Row className={clsx('mt-3')}>
        <Col md={7}>
          <p className="pr-md-5 mr-md-5 font-weight-light">
          {/* { descPageText.text } */}
          </p>
        </Col>
      </Row>
      {/* <Row>
        <Col sm={12}>
          <h3 className="font-weight-light mt-5">{t("guides.about.panduan")}</h3>
        </Col>
      </Row> */}
      {<p>Manulife Indonesia dengan bangga mempersembahkan EBClick, layanan daring program Dana Pensiun dan Group Savings untuk Karyawan dan Pemberi Kerja.
                Fasilitas ini dapat memudahkan Anda untuk mengakses informasi kepesertaan Anda di mana saja dan kapan saja.
                Informasi yang dapat diperoleh melalui EBClick antara lain: Saldo, Klaim, Iuran, Investasi, e-card, dan informasi lainnya.
            </p>}
      {/* {
        data && (
          <>
            {responsiveRowRender(
              <Accordion cols={2} itemsPerRow={(data.length / 2).toFixed()} items={data} />,
            )}
          </>
        )
      } */}
    </Container>

    // backup
    // <Container as="section" className={clsx(styles.wrapper)} fluid>
    //   <h1 className="font-weight-light display-2 mb-4 mt-5">
    //     {t('guides.about.text1')} <span className="font-serif" dangerouslySetInnerHTML={{ __html: t("guides.about.text2") }}></span>
    //   </h1>
    //   <Row className={clsx('mt-3')}>
    //     <Col md={7}>
    //       <p className="pr-md-5 mr-md-5 font-weight-light">
    //       { descPageText.text }
    //       </p>
    //     </Col>
    //   </Row>
    //   <Row>
    //     <Col sm={12}>
    //       <h3 className="font-weight-light mt-5">{t("guides.about.panduan")}</h3>
    //     </Col>
    //   </Row>
    //   {
    //     data && (
    //       <>
    //         {responsiveRowRender(
    //           <Accordion cols={2} itemsPerRow={(data.length / 2).toFixed()} items={data} />,
    //         )}
    //       </>
    //     )
    //   }
    // </Container>
  )
}

export default About
