

export const en = {
    employerChangePassword: {
        pageTitle: {
            text1: 'Change',
            text2: 'Password'
        },
        desc: 'Please complete the following fields to change your EB<i>Click</i> account password',
        form: {
            password: 'Old Password',
            newPassword: 'New Password',
            repeatPassword: 'Repeat New Password',
            validation: {
                text1: 'Minimum 8 characters',
                text2: 'Use capital & lowercase letters (example: Aa)',
                text3: 'Use numbers (example: 1234)',
                text4: 'Use symbols (example: !@#$)',
                text5: 'Repeat Password must be same as Password.'
            }
        }
    }
}

export const id = {
    employerChangePassword: {
        pageTitle: {
            text1: 'Ubah',
            text2: 'Kata Sandi'
        },
        desc: 'Harap lengkapi isian berikut untuk mengganti kata sandi akun EB<i>Click</i> Anda',
        form: {
            password: 'Kata Sandi Lama',
            newPassword: 'Kata Sandi Baru',
            repeatPassword: 'Ulangi Kata Sandi Baru',
            validation: {
                text1: 'Minimal 8 karakter',
                text2: 'Gunakan huruf kapital & kecil (contoh: Aa)',
                text3: 'Gunakan angka (contoh: 1234)',
                text4: 'Gunakan simbol (contoh: !@#$)',
                text5: 'Ulangi Kata Sandi harus sama dengan Kata Sandi.'
            }
        }
    }
}
