import React from 'react'
import clsx from 'clsx'
import { SpinnerCircular } from 'spinners-react'

import styles from './style.module.scss'

const InitialPageLoading = () => {
  return (
    <div className={clsx('position-fixed d-flex justify-content-center align-items-center', styles.initialLoading)}>
      <SpinnerCircular thickness={180} size={50}/>
    </div>
  )
}

export default InitialPageLoading