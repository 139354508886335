import React from 'react'
import { useDispatch } from 'react-redux'

import NotificationComponent from 'views/Notification'
import { getNotificationsEmployerAllAction } from 'store/actions/notificationsActions' 

const Notification = (props) => {
  const dispatch = useDispatch()
  const [ data, setData ] = React.useState(null)
  const [ loading, setLoading ] = React.useState(null)

  const getDataAll = async() => {
    setLoading(true)
    await dispatch(getNotificationsEmployerAllAction())
      .then(
        res => {
          setLoading(false)
          if(res.success){
            setData(res.data)
          }
        }
      )
  }

  React.useEffect(() => {
    getDataAll()
  },[])

  return <NotificationComponent {...props} loading={loading} data={data} mode="employer" />
};

export default Notification;
