import React, { useState, useCallback, memo, useEffect, useRef } from "react";
import { DateObject, Calendar } from "react-multi-date-picker";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import "./calender.css";
import styles from "./index.module.scss";

const CustomInput = memo(({ type, setShow, value, label}) => {
    const lang = useSelector((state) => state.language.lang);
    const input=useRef()
    const [date, setDate] = useState({
        from: "",
        to: "",
    });

    const onInputFocus = useCallback(
        (_) => {
            setShow(true);
        },
        [setShow]
    );

    useEffect(() => {
        
        moment.locale(lang);
        value.length > 1 ?value.map((it, index) => {
                if (index === 0) {
                    return setDate({
                        from: moment(`${it.year}/${it.month}/${it.day}`).format(
                            "MMMM DD, YYYY"
                        ),
                    });
                } else if (index === 1) {
                    return setDate((prevState) => ({
                        ...prevState,
                        to: moment(`${it.year}/${it.month}/${it.day}`).format(
                            "MMMM DD, YYYY"
                        ),
                    }));
                }
            }):setDate({
                from: "",
                to: "",
            })
    }, [value, lang]);
   
    return (
        <div
            className={`${
                type === "horizontal"
                    ? "d-flex w-100 " + styles.fromWrapper
                    : "row"
            }`}
        >
            <div
                className={`${type === "horizontal" ? "px-1" : "mb-4 col"}`}
            >
                <div
                    className={`${
                        type === "horizontal"
                            ? "form-group mb-0"
                            : "mb-0 pr-4 pr-md-5 form-group"
                    }`}
                >
                    <div class="input-group">
                        <input
                            type="text"
                            readOnly
                            ref={input}
                            class="has-grow underlined underlined--solid form-control"
                            value={date?.from}
                            onFocus={onInputFocus}
                            placeholder={label?.from.placeholder}
                        />
                        <label class="form-label">{label?.from.label}</label>
                        <div onClick={() => setShow(true)} class="input-group-append">
                            <span class="icon input-group-text">
                                <i class="mi-calendar"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`${type === "horizontal" ? "px-1" : "mb-4 col-12"}`}
            >
                <div
                    className={`${
                        type === "horizontal"
                            ? "form-group mb-0"
                            : "mb-0 pr-4 pr-md-5 form-group"
                    }`}
                >
                    <div class="input-group">
                        <input
                            type="text"
                            readOnly
                            class="has-grow underlined underlined--solid form-control"
                            value={date?.to}
                            onFocus={onInputFocus}
                            placeholder={label?.to.placeholder}
                        />
                        <label class="form-label">{label?.to.label}</label>
                        <div  onClick={() => setShow(true)} class="input-group-append">
                            <span class="icon input-group-text">
                                <i class="mi-calendar"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

const DateRangePicker2 = (props) => {
    const calenderWrapperRef = useRef(null);
    const { type, onChange, maxMonthFrom, calenderPostion, label,dataValue,setDateValue} = props;
   
    const [show, setShow] = useState(false);
    const [maxDateValue, setMaxDateValue] = useState(null);
    const { t } = useTranslation();
    const refInput=useRef(null)
    const weekDays = [
        t("dayNameAbbv.su"),
        t("dayNameAbbv.mo"),
        t("dayNameAbbv.tu"),
        t("dayNameAbbv.we"),
        t("dayNameAbbv.th"),
        t("dayNameAbbv.fr"),
        t("dayNameAbbv.sa"),
    ];
    const months = [
        t("monthName.jan"),
        t("monthName.feb"),
        t("monthName.mar"),
        t("monthName.apr"),
        t("monthName.mei"),
        t("monthName.jun"),
        t("monthName.jul"),
        t("monthName.aug"),
        t("monthName.sep"),
        t("monthName.okt"),
        t("monthName.nov"),
        t("monthName.des"),
    ];

    const closeCalender = () => {
        setShow(false);
    };
   
    useEffect(() => {
        if (dataValue.length === 1) {
            const maxDate = moment(
                new Date(
                    `${dataValue[0].year}/${dataValue[0].month}/${dataValue[0].day}`
                )
            ).add(maxMonthFrom ? parseInt(maxMonthFrom) : 6, "month");
            const graterThan = moment().diff(maxDate.format("YYYY-MM-DD"));
            setMaxDateValue({
                day:
                    graterThan > 0
                        ? parseInt(maxDate.format("D"))
                        : parseInt(moment().format("D")),
                month:
                    graterThan > 0
                        ? parseInt(maxDate.format("M"))
                        : parseInt(moment().format("M")),
                year:
                    graterThan > 0
                        ? parseInt(maxDate.format("YYYY"))
                        : parseInt(moment().format("YYYY")),
            });
        }

        if (dataValue.length === 2) {
            onChange &&
                onChange({
                    from: {
                        day: dataValue[0].day,
                        month: dataValue[0].month,
                        year: dataValue[0].year,
                    },
                    to: {
                        day: dataValue[1].day,
                        month: dataValue[1].month,
                        year: dataValue[1].year,
                    },
                });
            setShow(false);
        }
    }, [dataValue]);

    useEffect(() => {
        if (show) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }
    }, [show]);
    
    return (
        <div
            className={`${
                type === "horizontal" ? "w-100 h-100 position-relative" : ""
            }`}
        >
            <CustomInput
                value={dataValue}
                setShow={setShow}
                type={type}
                label={label}
            />
            <div className={`${show ? "d-block" : "d-none"} position-relative`}>
                <div
                    className={`d-flex align-items-start justify-content-center ${styles.calenderWrapper}`}
                >
                    <div
                        onClick={closeCalender}
                        className={`${styles.calenderWrapper__bd}`}
                    ></div>
                    <div
                        ref={calenderWrapperRef}
                        className={`d-flex align-items-start justify-content-center py-4 position-relative ${styles.calenderWrapper__inner}`}
                    >
                        <div>
                            <Calendar
                                range
                               
                                value={dataValue.map((it) => {
                                    return new DateObject({ ...it });
                                })}
                                maxDate={
                                    new DateObject({
                                        day: new Date().getDate(),
                                        month: new Date().getMonth() + 1,
                                        year: new Date().getFullYear(),
                                    })
                                }
                                onChange={(e) => {
                                    setDateValue(
                                        e.map((it) => {
                                            return {
                                                day: it.day,
                                                month: it.month.number,
                                                year: it.year,
                                            };
                                        })
                                    );
                                }}
                                className="custom-date-styles"
                                weekDays={weekDays}
                                months={months}
                            />
                        </div>
                        <div>
                            <Calendar
                                ref={refInput}
                                range
                                value={dataValue.map((it) => {
                                    return new DateObject({ ...it });
                                })}
                                maxDate={
                                    maxDateValue
                                        ? new DateObject(maxDateValue)
                                        : new DateObject({
                                              day: new Date().getDate(),
                                              month: new Date().getMonth() + 1,
                                              year: new Date().getFullYear(),
                                          })
                                }
                                onChange={(e) => {
                                    setDateValue(
                                        e.map((it) => {
                                            return {
                                                day: it.day,
                                                month: it.month.number,
                                                year: it.year,
                                            };
                                        })
                                    );
                                }}
                                className="custom-date-styles"
                                weekDays={weekDays}
                                months={months}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(DateRangePicker2);
