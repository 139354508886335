export const GET_EMPYR_REPORT_DOCS = 'GET_EMPYR_REPORT_DOCS'
export const LOADING_EMPYR_REPORT_DOCS = 'LOADING_EMPYR_REPORT_DOCS'
export const LOADING_EMPYR_REPORT_DOCS_FALSE = 'LOADING_EMPYR_REPORT_DOCS_FALSE'
export const PAGINATION_EMPYR_REPORT_DOCS = 'PAGINATION_EMPYR_REPORT_DOCS'
export const FILTER_EMPYR_REPORT_DOCS = 'FILTER_EMPYR_REPORT_DOCS'
export const RESET_EMPYR_REPORT_DOCS = 'RESET_EMPYR_REPORT_DOCS'
export const RESET_FILTER_EMPYR_REPORT_DOCS = 'RESET_FILTER_EMPYR_REPORT_DOCS'

const initialState = {
    data: null,
    loading: false,
    page: 1,
    take: 5,
    order: 'ASC',
    filter: {
        sortBy: '',
        menu_id: '',
        status: '',
        search: '',
        type_id: '',
    },
    pageCount: 0
}

const ReportsReducer = (state = initialState, action) => {
    switch(action.type){
        case GET_EMPYR_REPORT_DOCS: {
            return{
                ...state,
                data: action.payload.data,
                pageCount: action.payload.pageCount,
                loading: false
            }
        }
        case LOADING_EMPYR_REPORT_DOCS: {
            return {
                ...state,
                loading: true,
                data: null
            }
        }
        case LOADING_EMPYR_REPORT_DOCS_FALSE: {
            return {
                ...state,
                loading: false
            }
        }
        case PAGINATION_EMPYR_REPORT_DOCS: {
            return {
                ...state,
                page: action.payload.page,
                pageCount: action.payload.pageCount
            }
        }
        case FILTER_EMPYR_REPORT_DOCS: {
            return {
                ...state,
                filter: {
                    ...action.payload
                }
            }
        }
        case RESET_EMPYR_REPORT_DOCS: {
            return{
                data: null,
                loading: false,
                page: 1,
                take: 5,
                order: 'ASC',
                filter: {
                    order_by: '',
                    menu_id: '',
                    status: '',
                    search: '',
                    type_id: '',
                },
                pageCount: 0
            }
        }
        case RESET_FILTER_EMPYR_REPORT_DOCS: {
            return{
                ...state,
                filter: {
                    order_by: '',
                    menu_id: '',
                    status: '',
                    search: '',
                    type_id: '',
                }
            }
        }
        default:{
            return state
        }
    }
}

export default ReportsReducer
