// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DateRangePicker2_formStyleWrapper__x1RUB {
  padding-bottom: 1px;
}

.DateRangePicker2_calenderWrapper__\\+eSbS {
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 10%;
  z-index: 100000;
}
@media only screen and (max-width: 776px) {
  .DateRangePicker2_calenderWrapper__\\+eSbS {
    padding-top: 0;
    overflow-y: auto;
    height: 100vh;
    background-color: rgba(1, 1, 1, 0.44);
  }
}
.DateRangePicker2_calenderWrapper__inner__6JfrT {
  background-color: white;
  box-shadow: 0px 3px 29px -12px rgba(0, 0, 0, 0.44);
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.125);
  max-width: 760px;
}
@media only screen and (max-width: 776px) {
  .DateRangePicker2_calenderWrapper__inner__6JfrT {
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
.DateRangePicker2_calenderWrapper__bd__TVnFL {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(1, 1, 1, 0.44);
}
@media only screen and (max-width: 776px) {
  .DateRangePicker2_calenderWrapper__bd__TVnFL {
    display: none;
  }
}
.DateRangePicker2_calenderWrapper__\\+eSbS.DateRangePicker2_top__right__8RebM {
  transform: translateX(35%) translateY(-100%);
}

.DateRangePicker2_fromWrapper__X3ZbS > div {
  width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/Form/DateRangePicker2/index.module.scss"],"names":[],"mappings":"AACA;EACI,mBAAA;AAAJ;;AAGA;EACI,eAAA;EACA,WAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EACA,gBAAA;EACA,eAAA;AAAJ;AAEI;EAVJ;IAWQ,cAAA;IACA,gBAAA;IACA,aAAA;IACA,qCAAA;EACN;AACF;AACI;EACI,uBAAA;EAGA,kDAAA;EACA,kBAAA;EACA,gBAAA;EACA,sCAAA;EACA,gBAAA;AACR;AACQ;EAVJ;IAWQ,sBAAA;IACA,gBAAA;IACA,mBAAA;EAEV;AACF;AACI;EACI,kBAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EACA,qCAAA;AACR;AACQ;EARJ;IASQ,aAAA;EAEV;AACF;AACI;EACI,4CAAA;AACR;;AAII;EACI,UAAA;AADR","sourcesContent":["\n.formStyleWrapper{\n    padding-bottom: 1px;\n}\n\n.calenderWrapper{\n    position: fixed;\n    width: 100%;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n    padding-top: 10%;\n    z-index: 100000;\n\n    @media only screen and (max-width: 776px){\n        padding-top: 0;\n        overflow-y: auto;\n        height: 100vh;\n        background-color: rgba(1, 1, 1, 0.44);\n    }\n\n    &__inner{\n        background-color: white;\n        -webkit-box-shadow: 0px 3px 29px -12px rgba(0,0,0,0.44);\n        -moz-box-shadow: 0px 3px 29px -12px rgba(0,0,0,0.44);\n        box-shadow: 0px 3px 29px -12px rgba(0,0,0,0.44);\n        border-radius: 6px;\n        overflow: hidden;\n        border: 1px solid rgba(0, 0, 0, 0.125);\n        max-width: 760px;\n\n        @media only screen and (max-width: 776px){\n            flex-direction: column;\n            margin-top: 2rem;\n            margin-bottom: 2rem;\n        }\n    }\n\n    &__bd{\n        position: absolute;\n        top: 0;\n        right: 0;\n        bottom: 0;\n        left: 0;\n        background-color: rgba(1, 1, 1, 0.44);\n\n        @media only screen and (max-width: 776px){\n            display: none;\n        }\n    }\n\n    &.top__right{\n        transform: translateX(35%) translateY(-100%);\n    }\n}\n\n.fromWrapper{\n    > div{\n        width: 50%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formStyleWrapper": `DateRangePicker2_formStyleWrapper__x1RUB`,
	"calenderWrapper": `DateRangePicker2_calenderWrapper__+eSbS`,
	"calenderWrapper__inner": `DateRangePicker2_calenderWrapper__inner__6JfrT`,
	"calenderWrapper__bd": `DateRangePicker2_calenderWrapper__bd__TVnFL`,
	"top__right": `DateRangePicker2_top__right__8RebM`,
	"fromWrapper": `DateRangePicker2_fromWrapper__X3ZbS`
};
export default ___CSS_LOADER_EXPORT___;
