import { SET_LANGUAGE } from "../reducers/languageSettingReducer";

export const settingLanguageActions = (lang) => async (dispatch) => {
    if (!lang) {
        return;
    }
    localStorage.setItem("lang", lang);
    dispatch({
        type: SET_LANGUAGE,
        payload: lang,
    });
    setTimeout(() => {
        window.location.reload();
    }, 300);
};

export const initialLanguageSettingActions = () => async (dispatch) => {
    const avaiableLang = localStorage.getItem("lang");
    if (avaiableLang) {
        dispatch({
            type: SET_LANGUAGE,
            payload: avaiableLang,
        });
    } else {
        localStorage.setItem("lang", "id");
        dispatch({
            type: SET_LANGUAGE,
            payload: "id",
        });
    }
};
