import React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import NotificationDetailComponent from 'views/Notification/Detail'
import { getNotificationEmployerDetailAction } from 'store/actions/notificationsActions'

const NotificationDetail = (props) => {
  const [ data, setData ] = React.useState(null)
  const [ loading, setLoading ] = React.useState(null)
  const dispatch = useDispatch()
  const { crmNmbr, subNmbr } = useParams()

  const getDataDetail = async() => {

    const data = {
      crm_nmbr: crmNmbr,
      sub_nmbr: subNmbr
    }
    setLoading(true)
    await dispatch(getNotificationEmployerDetailAction(data))
      .then(
        res => {
          setLoading(false)
          if(res.success){
            setData(res.data)
            dispatch({
                type: 'RELOAD_NOTIF',
                payload:true
              })
          }
        }
      )
  }

  React.useEffect(() => {
    getDataDetail()
  },[])

  return <NotificationDetailComponent {...props} loading={loading}  data={data} mode="employee"/>
};

export default NotificationDetail;
