/* eslint-disable no-empty-pattern */
// @flow
import clsx from "clsx";
import * as React from "react";
import { Button, Col, Container, Row, Tab, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { DataTable, Form, Tabs, TopContent, Pagination } from "components";
import {
  getExchangeRateDplkActions,
  exchangeRateDplkPaginationActions,
  getExchangeRateGSavingActions,
  exchangeRateGsPaginationActions,
} from "store/actions/invesmentActions";

// Styles
import styles from "./style.module.scss";

export const Conversion = ({}) => {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const dispatch = useDispatch();

  const descPageText = useSelector((state) => state.descriptionPage);
  const data = useSelector((state) => state.invesment);
  const { t } = useTranslation();
  const [keyTab, setKeyTab] = React.useState("");

  const dplkConversion = {
    headers: [
      { title: "Tanggal", name: "date" },
      { title: "Beli", name: "bought" },
      { title: "Jual", name: "sell" },
    ],
    data: [
      [
        { content: 1, name: "id" },
        {
          content: "20/11/2020",
          name: "date",
        },
        {
          content: "13,721.00",
          name: "bought",
        },
        {
          content: "14,539.00",
          name: "sell",
        },
      ],
      [
        { content: 2, name: "id" },
        {
          content: "19/11/2020",
          name: "date",
        },
        {
          content: "13,712.00",
          name: "bought",
        },
        {
          content: "14,519.00",
          name: "sell",
        },
      ],
    ],
  };

  const groupSavingsConversion = {
    headers: [
      { title: "Tanggal", name: "date" },
      { title: "Beli", name: "bought" },
      { title: "Jual", name: "sell" },
    ],
    data: [
      [
        { content: 1, name: "id" },
        {
          content: "19/11/2020",
          name: "date",
        },
        {
          content: "13,712.00",
          name: "bought",
        },
        {
          content: "14,519.00",
          name: "sell",
        },
      ],
      [
        { content: 2, name: "id" },
        {
          content: "20/11/2020",
          name: "date",
        },
        {
          content: "13,721.00",
          name: "bought",
        },
        {
          content: "14,539.00",
          name: "sell",
        },
      ],
    ],
  };

  const [filterDate, setFilterDate] = React.useState({
    from: {
      day,
      month: month - 1,
      year,
    },
    to: {
      day,
      month,
      year,
    },
  });

  const tabHandler = React.useCallback(
    (key = "dplk") => {
      setKeyTab(key);
      const yearFrom = filterDate.from.year;
      const monthFrom =
        filterDate.from.month <= 10
          ? "0" + filterDate.from.month
          : filterDate.from.month;
      const dayFrom =
        filterDate.from.day <= 10
          ? "0" + filterDate.from.day
          : filterDate.from.day;

      const yearTo = filterDate.to.year;
      const monthTo =
        filterDate.to.month <= 10
          ? "0" + filterDate.to.month
          : filterDate.to.month;
      const dayTo =
        filterDate.to.day <= 10 ? "0" + filterDate.to.day : filterDate.to.day;

      switch (key) {
        case "dplk":
          dispatch(
            getExchangeRateDplkActions(
              `${yearFrom}-${monthFrom}-${dayFrom}`,
              `${yearTo}-${monthTo}-${dayTo}`
            )
          );
          break;
        case "groupSavings":
          // dispatch(getExchangeRateGSavingActions('2022-02-06','2022-08-09'))
          break;
      }
    },
    [dispatch, setKeyTab]
  );

  const paginationHandler = React.useCallback(
    (e) => {
      switch (keyTab) {
        case "dplk":
          dispatch(exchangeRateDplkPaginationActions(e));
          break;
        case "groupSavings":
          // dispatch(exchangeRateGsPaginationActions(e))
          break;
      }
    },
    [dispatch, keyTab]
  );

  React.useEffect(() => {
    tabHandler();
  }, []);

  return (
    <Container as="section" className={clsx(styles.wrapper, "mb-5")} fluid>
      <TopContent text="Nilai Tukar" textItalic="USD-IDR" />
      <Row className={clsx("mt-3 mb-5")}>
        <Col md={7}>
          <p className="pr-5 mr-5 font-weight-light">{descPageText.text}</p>
        </Col>
      </Row>
      <Tabs
        onSelect={tabHandler}
        id="conversion"
        className={clsx(styles.tabs)}
        responsiveMode="dropdown"
      >
        <Tab eventKey="dplk" title="Nilai Tukar DPLK">
          <Row className="py-4 justify-content-end">
            <Col md={6} className={clsx("py-2")}>
              <p className="w-100">
                A volutpat massa id interdum facilisis dui amet lectus. Tellus
                nisl neque, et et quam non nibh nulla. Mattis a sed gravida
                tristique.
              </p>
            </Col>
            <Col md={6} className={clsx("py-2")}>
              <Row className={clsx("align-items-start")}>
                <Col md={8}>
                  <Form.DateRangePicker
                    hasGrow
                    value={filterDate}
                    onChange={setFilterDate}
                    fromInputProps={{
                      label: "Dari",
                      placeholder: "Pilih Tanggal",
                    }}
                    toInputProps={{
                      label: "Hingga",
                      placeholder: "Pilih Tanggal",
                    }}
                  />
                  <div className="d-flex py-2">
                    <span className="text-sm mt-2 d-block">
                      Maksimal periode 1 (satu) tahun
                    </span>
                    <Button
                      variant="icon"
                      className={clsx("ml-auto")}
                      onClick={() =>
                        setFilterDate({
                          from: {
                            day,
                            month: month - 1,
                            year,
                          },
                          to: {
                            day,
                            month,
                            year,
                          },
                        })
                      }
                    >
                      <i className={clsx("mi mi-reload")}></i> Ulangi
                    </Button>
                  </div>
                </Col>
                <Col className={clsx("d-flex")}>
                  <Button variant="coral" block>
                    Unduh
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md={12} className={clsx("mt-4")}>
              {data.loading && (
                <div
                  className={clsx(
                    "w-100 d-flex justify-content-center align-items-center py-5"
                  )}
                >
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              )}
              {!data.loading && data.data && (
                <DataTable
                  {...data.data}
                  responsiveMode="table"
                  className={clsx("w-100")}
                />
              )}
            </Col>
            <Col
              xs={12}
              md={6}
              xl={4}
              className={clsx("d-flex justify-content-end")}
            >
              <Pagination
                threeDots
                totalPages={data.data ? data.data?.pageCount : 1}
                currentPage={data.page}
                onClick={paginationHandler}
                showMax={4}
                prevNext
                activeBgColor="#fff"
                activeBorderColor="gray"
                className={clsx("justify-content-end")}
              />
            </Col>
          </Row>
        </Tab>
        {/* <Tab eventKey="groupSavings" title="Nilai Tukar Group Saving">
          <Row className="py-4 justify-content-end">
            <Col md={6} className={clsx('py-2')}>
              <p className="w-100">
                A volutpat massa id interdum facilisis dui amet lectus. Tellus nisl neque, et et
                quam non nibh nulla. Mattis a sed gravida tristique.
              </p>
            </Col>
            <Col md={6} className={clsx('py-2')}>
              <Row className={clsx('align-items-start')}>
                <Col md={8}>
                  <Form.DateRangePicker
                    hasGrow
                    value={filterDate}
                    onChange={setFilterDate}
                    fromInputProps={{ label: 'Dari', placeholder: 'Pilih Tanggal' }}
                    toInputProps={{ label: 'Hingga', placeholder: 'Pilih Tanggal' }}
                  />
                  <div className="d-flex py-2">
                    <span className="text-sm mt-2 d-block">Maksimal periode 1 (satu) tahun</span>
                    <Button
                      variant="icon"
                      className={clsx('ml-auto')}
                      onClick={() =>
                        setFilterDate({
                          from: null,
                          to: null,
                        })
                      }>
                      <i className={clsx('mi mi-reload')}></i> Ulangi
                    </Button>
                  </div>
                </Col>
                <Col className={clsx('d-flex')}>
                  <Button variant="coral" block>
                    Unduh
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md={12} className={clsx('mt-4')}>
              { data.loading && (
                 <div className={clsx('w-100 d-flex justify-content-center align-items-center py-5')}>
                  <Spinner animation="border" role="status">
                  </Spinner>
                </div>
              ) }
              { !data.loading && data.data && (
                <DataTable {...data.data} responsiveMode="table" className={clsx('w-100')} />
              )}
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Pagination
                threeDots
                totalPages={data.data ? data.data?.pageCount : 1}
                currentPage={data.page}
                onClick={paginationHandler}
                showMax={4}
                prevNext
                activeBgColor="#fff"
                activeBorderColor="gray"
                className={clsx('justify-content-end')}
              />
            </Col>
          </Row>
        </Tab> */}
      </Tabs>
    </Container>
  );
};

export default Conversion;
