import clsx from 'clsx'
import * as React from 'react'
import { Image } from 'react-bootstrap'
import ContentLoader from 'react-content-loader'

// Styles
import styles from './style.module.scss'

const ECard = ({ imgSrc, ...props }) => {
  return (
    <div className={clsx(styles.wrapper, !imgSrc && styles.bordered)}>
      {!!imgSrc ? (
        <Image src={imgSrc} fluid />
      ) : (
        <ContentLoader
          speed={2}
          width={195}
          height="100%"
          viewBox="0 0 195 154"
          backgroundColor="#EDEDED"
          foregroundColor="#E2E2E2"
          {...props}>
          <path d="M 25.717 2.923 V 16 l 2.942 -2.923 V 0 l -2.942 2.923 z M 19.885 16 l 2.942 -2.923 V 0 l -2.942 2.923 V 16 z M 14 16 l 2.942 -2.923 V 0 L 14 2.923 V 16 z" />
          <path d="M 0.5 35.5 v -11 h 190 l 4.5 11 H 0.5 z M 15 47 h 42 v 6 H 15 z M 15 70 h 42 v 6 H 15 z M 15 93 h 42 v 6 H 15 z M 15 116 h 42 v 6 H 15 z M 15 139 h 42 v 6 H 15 z M 15 56 h 55 v 6 H 15 z M 15 79 h 55 v 6 H 15 z M 15 102 h 55 v 6 H 15 z M 15 125 h 55 v 6 H 15 z M 15 148 h 55 v 6 H 15 z" />
          <path d="M 99.039 13.178 c -1.189 0 -2.17 -0.981 -2.17 -2.325 V 10.8 h 5.788 c 0.103 -3.462 -1.5 -4.91 -3.722 -4.91 c -2.377 0 -3.979 1.913 -3.979 4.445 c 0 2.636 1.499 4.444 3.928 4.444 c 1.498 0 2.842 -0.826 3.514 -1.808 l -1.189 -1.086 c -0.362 0.724 -1.292 1.292 -2.17 1.292 z m -0.207 -5.737 c 1.137 0 1.809 0.982 1.809 2.016 h -3.773 c 0.052 -0.982 0.879 -2.016 1.964 -2.016 z" />
          <path d="M 94.646 7.699 H 92.89 v 6.925 h -1.86 V 7.7 h -1.137 v -1.55 h 1.137 V 4.392 c 0 -1.396 0.878 -2.636 2.635 -2.636 c 0.827 0 1.499 0.207 1.912 0.413 l -0.568 1.447 c -0.258 -0.155 -0.672 -0.31 -1.034 -0.31 c -0.568 0 -1.085 0.414 -1.085 1.189 v 1.654 h 2.222 l -0.465 1.55 z" />
          <path d="M 88.961 3.513 c 0 -0.672 -0.568 -1.189 -1.188 -1.189 c -0.672 0 -1.189 0.569 -1.189 1.189 c 0 0.62 0.517 1.188 1.189 1.188 c 0.62 0 1.188 -0.516 1.188 -1.188 z m -2.118 11.06 h 1.912 V 6.149 h -1.912 v 8.424 z" />
          <path d="M 84.724 14.573 h -1.86 V 3.462 l 1.86 -1.86 v 12.971 z M 78.987 6.15 h 1.86 v 8.423 h -1.911 v -0.93 c -0.517 0.465 -1.344 1.085 -2.43 1.085 c -1.084 0 -2.583 -0.568 -2.583 -2.687 V 6.149 h 1.86 v 5.323 c 0 0.982 0.414 1.654 1.292 1.654 c 0.724 0 1.396 -0.465 1.912 -1.033 V 6.149 z M 67.308 14.574 h -1.86 V 6.15 h 1.86 v 0.93 c 0.671 -0.62 1.653 -1.137 2.739 -1.137 c 0.982 0 2.377 0.569 2.377 2.636 v 5.995 h -1.86 v -5.53 c 0 -0.827 -0.362 -1.55 -1.19 -1.55 c -0.723 0 -1.601 0.62 -2.066 0.982 v 6.098 z" />
          <path d="M 61.88 13.644 V 14.677 h 1.861 V 8.682 c 0 -1.705 -1.137 -2.687 -3.049 -2.687 c -1.498 0 -2.635 0.62 -3.307 1.447 l 1.033 1.24 c 0.362 -0.516 1.24 -1.188 2.17 -1.188 c 0.828 0 1.345 0.413 1.345 0.982 c 0 0.465 -0.259 0.827 -0.93 0.878 l -0.673 0.052 c -1.808 0.155 -3.617 0.878 -3.617 2.842 c 0 1.602 1.188 2.533 2.687 2.533 c 1.292 0 2.016 -0.62 2.48 -1.137 z m -1.291 -2.947 l 1.292 -0.155 v 1.602 c -0.465 0.569 -1.189 1.137 -2.068 1.137 c -0.723 0 -1.292 -0.465 -1.292 -1.137 c 0 -0.93 0.982 -1.292 2.068 -1.447 z" />
          <path d="M 46.067 14.573 H 44 V 2.842 h 2.17 l 3.515 5.788 l 3.41 -5.788 h 2.171 v 11.731 H 53.2 V 6.304 h -0.052 l -3.566 5.892 l -3.514 -5.892 v 8.27 z" />
        </ContentLoader>
      )}
    </div>
  )
}

ECard.defaultProps = {
  imgSrc: null,
}

export default ECard
