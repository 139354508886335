import axios from "utils/axios";
import { has } from "lodash";

import {
    LOADING_EMPYR_IURAN,
    LOADING_EMPYR_IURAN_FALSE,
    RESET_EMPYR_IURAN,
} from "../../reducers/employer/iuranReducer";
import { formatCurrency } from "utils/numberConvert";
import { SET_AUTH_DETECTOR_FALSE } from "../../reducers/authDetectorReducer";
import getDate from "utils/dateConvert";
import {
    API_EMPR_CONTRIB_BILL_DPLK,
    API_EMPR_CONTRIB_TRANS,
} from "utils/api.constant";
import {} from 'utils/numberConvert'

export const getTransactionTabelAction = (data) => (dispatch, getState) => {
    const { lang } = getState().language;
    const { search_by, trans_type, from, to } = data;

    const config = {
        headers: {
            "x-accept-lang": lang,
        },
    };

    dispatch({
        type: LOADING_EMPYR_IURAN,
    });

    return new Promise(async (resolve) => {
        await axios
            .get(
                `${API_EMPR_CONTRIB_TRANS}?search_by=${search_by}&from=${from}&to=${to}&trans_type=${trans_type}`,
                config
            )
            .then((res) => {
                dispatch({
                    type: LOADING_EMPYR_IURAN_FALSE,
                });

                if (res.data.statusCode === 200) {
                    const noTitleFilter = res.data.data.filter(
                        (it) => !has(it, "title")
                    );
                    const titleFilter = res.data.data.filter((it) =>
                        has(it, "title")
                    );
                    let accordanceTable;
                    const type = trans_type.concat(`-${search_by}`);
                    switch (type) {
                        case "PEMBAYARAN-IURAN_ATAU_PREMI":
                            accordanceTable = noTitleFilter.map((it) => {
                                return [
                                    {
                                        content: getDate(it.paid_dt),
                                        name: "paid",
                                    },
                                    {
                                        content: it.desc,
                                        name: "transaction_description",
                                    },
                                    { content: formatCurrency(it.cntrb_amt), name: "credit" },
                                ];
                            });
                            break;
                        case "PEMBAYARAN-BIAYA":
                            accordanceTable = noTitleFilter.map((it) => {
                                return [
                                    {
                                        content: getDate(it.paid_dt),
                                        name: "paid",
                                    },
                                    {
                                        content: it.desc,
                                        name: "transaction_description",
                                    },
                                    { content: formatCurrency(it.cntrb_amt), name: "credit" },
                                ];
                            });
                            break;
                        case "TERTUNGGAK-IURAN_ATAU_PREMI":
                            accordanceTable = noTitleFilter.map((it) => {
                                return [
                                    {
                                        content: getDate(it.efctv_dt),
                                        name: "efctv_dt",
                                    },
                                    {
                                        content: getDate(it.due_date),
                                        name: "due_date",
                                    },
                                    {
                                        content: it.cntibsi ? it.cntibsi : "-",
                                        name: "cntibsi",
                                    },
                                    { content: it.top_up, name: "top_up" },
                                    {
                                        content: formatCurrency(it.cntrb_amt),
                                        name: "cntrb_amt",
                                    },
                                ];
                            });
                            break;
                        case "TERTUNGGAK-BIAYA":
                            accordanceTable = noTitleFilter.map((it) => {
                                return [
                                    {
                                        content: getDate(it.due_date),
                                        name: "due_date",
                                    },
                                    {
                                        content: getDate(it.efctv_dt),
                                        name: "efctv_dt",
                                    },
                                    {
                                        content: it.fee ? formatCurrency(it.fee) : "-",
                                        name: "fee",
                                    },
                                ];
                            });
                            break;
                    }

                    resolve({
                        success: true,
                        data: {
                            title: titleFilter[0].title,
                            data: accordanceTable,
                        },
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: LOADING_EMPYR_IURAN_FALSE,
                });
                // if (err.response.data.statusCode === 401) {
                //     dispatch({
                //         type: SET_AUTH_DETECTOR_FALSE,
                //         payload: err.response.data.message,
                //     });
                // }
            });
    });
};

export const postReportDplkAction = (data) => (dispatch, getState) => {
    const { lang } = getState().language;

    const config = {
        headers: {
            "x-accept-lang": lang,
        },
    };

    return new Promise(async (resolve) => {
        await axios
            .post(API_EMPR_CONTRIB_BILL_DPLK, data, config)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    resolve({
                        success: true,
                        message: res.data.message,
                    });
                } else {
                    resolve({
                        success: false,
                        message: res.data.message,
                    });
                }
            })
            .catch((err) => {
                if (err.response.data.statusCode === 401) {
                    dispatch({
                        type: SET_AUTH_DETECTOR_FALSE,
                        payload: err.response.data.message,
                    });
                }
                resolve({
                    success: false,
                    message: err.response.data.message,
                });
            });
    });
};

export const postReportGsAction = (data) => (dispatch, getState) => {
    const { lang } = getState().language;

    const config = {
        headers: {
            "x-accept-lang": lang,
        },
    };

    return new Promise(async (resolve) => {
        await axios
            .post(API_EMPR_CONTRIB_BILL_DPLK, data, config)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    resolve({
                        success: true,
                        message: res.data.message,
                    });
                } else {
                    resolve({
                        success: false,
                        message: res.data.message,
                    });
                }
            })
            .catch((err) => {
                if (err.response.data.statusCode === 401) {
                    dispatch({
                        type: SET_AUTH_DETECTOR_FALSE,
                        payload: err.response.data.message,
                    });
                }
                resolve({
                    success: false,
                    message: err.response.data.message,
                });
            });
    });
};

export const resetAction = () => (dispatch) => {
    dispatch({
        type: RESET_EMPYR_IURAN,
    });
};
