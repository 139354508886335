export const GET_EMPYR_CHANGE_PASSWORD = 'GET_EMPYR_CHANGE_PASSWORD'
export const LOADING_EMPYR_CHANGE_PASSWORD = 'LOADING_EMPYR_CHANGE_PASSWORD'

const initialState = {
    data: null,
    loading: false
}

const ChangePasswordReducer = (state = initialState, action) => {
    switch(action.type){
        case GET_EMPYR_CHANGE_PASSWORD: {
            return{
                data: action.payload,
                loading: false
            }
        }
        case LOADING_EMPYR_CHANGE_PASSWORD: {
            return{
                ...state,
                loading: true
            }
        }
        default:{
            return state
        }
    }
}

export default ChangePasswordReducer