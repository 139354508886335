export const GET_SIGNIN = "GET_SIGNIN";
export const LOADING = "LOADING";
export const LOADING_FALSE = "LOADING_FALSE";

const initialState = {
    data: null,
    loading: false,
};

const SignInReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SIGNIN: {
            return {
                data: action.payload,
                loading: false,
            };
        }
        case LOADING: {
            return {
                ...state,
                loading: true,
            };
        }
        case LOADING_FALSE: {
            return {
                ...state,
                loading: false,
            };
        }
        default: {
            return state;
        }
    }
};

export default SignInReducer;
