
export const en = {
    register: {
         title: 'Create EB<i>Click</i> Account',
         form: {
             btnNext: 'Next'
         },
         haveAccount: {
             text: "Do not have an EB<i>Click</i> account yet?",
             link: 'Sign up now'
         },
         verification: {
             title: 'Enter OTP',
             text1: 'Please enter the verification code (OTP) you received',
             text2: {
                 t1: 'We have sent an',
                 t2: 'containing a 6-digit verification code (OTP) to'
             },
             list1: 'OTP code will expire in',
             list2: 'Not receiving OTP code?',
             list3: 'Send to another number?',
             btnResendOtp: 'Resending',
             btnChangeData: 'Change Data'
         },
         resendOtp: {
             title: 'Resend OTP',
             description: 'Choose one of the methods below to get a verification code'
         },
         setPassword: {
            title: 'Register',
            description: 'Please create a password for your EB<i>Click</i> account.',
            form: {
                password: {
                    label: 'Password',
                    form: {
                        text1: 'Minimum 8 characters',
                        text2: 'Use capital & lowercase letters (example: Aa)',
                        text3: 'Use numbers (example: 1234)',
                        text4: 'Use symbols (example: !@#$)',
                        text5: 'Repeat Password must be same as Password.'
                    }
                },
                rewritePassword: {
                    label: 'Repeat Password',
                    text1: 'Password meets all criteria'
                },
                buttonNext: 'Done',
            },
            alertSingin: 'Sign In Now'
        },
        form: {
            email: {
                label: 'Email',
                tooltip: 'Enter your Email Address registered in DPLK Manulife Indonesia'
            },
            phone: {
                label: 'Phone ( Optional )',
                tooltip: 'Enter your Phone Number registered in DPLK Manulife Indonesia'
            },
            noGroup: {
                label: 'Group Number',
                tooltip: 'Enter the Group Number printed on the Group Welcome Letter'
            },
            noCompany: {
                label: 'Company Code',
                tooltip: 'Enter the Company Code printed on the Group Welcome Letter'
            },
            btnCancel: 'Cancel',
            btnNext: 'Next',
            haveAccount: 'Already have an EB<i>Click</i> account?',
            btnEnter: 'Sign In'
        },
        employeeRegister: {
            form: {
                participantNumb: {
                    label: "Participant Number",
                    toolTip: 'Enter the Participant Number printed on the e-card or contact Customer Service Manulife Indonesia'
                },
                dateOfBirth: {
                    label: 'Date of Birth',
                    date: {
                        label: 'Date'
                    },
                    month: {
                        label: 'Month'
                    },
                    year: {
                        label: 'Year'
                    }
                }
            },
            btnNext: 'Next',
            btnCancel: 'Cancel',
            bottomCon: {
                haveAccount: 'Already have an EB<i>Click</i> account?',
                enter: 'Sign In'
            }
        }
    }
 }

 export const id = {
     register: {
         title: 'Buat Akun EB<i>Click</i>',
         form: {
             btnNext: 'Selanjutnya'
         },
         haveAccount: {
             text: 'Belum punya akun EB<i>Click</i>? ',
             link: 'Daftar Sekarang'
         },
         verification: {
             title: 'Masukan OTP',
             text1: 'Silahkan masukkan kode verifikasi (OTP) yang Anda terima',
             text2: {
                 t1: 'Kami telah mengirimkan',
                 t2: 'berisi 6 digit kode verifikasi (OTP) ke'
             },
             list1: 'Kode OTP akan kadaluarsa dalam',
             list2: 'Tidak menerima kode OTP?',
             list3: 'Kirim ke nomor lain?',
             btnResendOtp: 'Kirim Ulang',
             btnChangeData: 'Ubah Data'
         },
         resendOtp: {
             title: 'Kirim ulang OTP',
             description: 'Pilih salah satu metode dibawah ini untuk mendapatkan kode verifikasi'
         },
         setPassword: {
            title: 'Daftar',
            description: 'Silahkan membuat kata sandi untuk akun EB<i>Click</i> Anda.',
            form: {
                 password: {
                     label: 'Kata Sandi',
                     form: {
                         text1: 'Minimal 8 karakter',
                         text2: 'Gunakan huruf kapital & kecil (contoh: Aa)',
                         text3: 'Gunakan angka (contoh: 1234)',
                         text4: 'Gunakan simbol (contoh: !@#$)',
                         text5: 'Ulangi Kata Sandi harus sama dengan Kata Sandi.'
                     }
                 },
                 rewritePassword: {
                     label: 'Ulangi Kata Sandi',
                     text1: 'Kata sandi memenuhi semua kriteria'
                 },
                 buttonNext: 'Selesai'
            },
            alertSingin: 'Masuk Sekarang'
        },
        form: {
            email: {
                label: 'Email',
                tooltip: 'Masukkan Alamat Email Anda yang terdaftar di DPLK Manulife Indonesia'
            },
            phone: {
                label: 'Ponsel ( Opsional )',
                tooltip: 'Masukkan Nomor Ponsel Anda yang terdaftar di DPLK Manulife Indonesia'
            },
            noGroup: {
                label: 'Nomor Group',
                tooltip: 'Masukkan Nomor Group yang tercantum di Welcome Letter Group'
            },
            noCompany: {
                label: 'Kode Perusahaan',
                tooltip: 'Masukkan Kode Perusahaan yang tercantum di Welcome Letter Group'
            },
            btnCancel: 'Batal',
            btnNext: 'Selanjutnya',
            haveAccount: 'Sudah punya akun EB<i>Click</i>?',
            btnEnter: 'Masuk'
        },
        employeeRegister: {
            form: {
                participantNumb: {
                    label: "Nomor Peserta",
                    toolTip: 'Masukkan Nomor Peserta yang tercantum di e-card atau hubungi Customer Service Manulife Indonesia'
                },
                dateOfBirth: {
                    label: 'Tanggal Lahir',
                    date: {
                        label: 'Tanggal'
                    },
                    month: {
                        label: 'Bulan'
                    },
                    year: {
                        label: 'Tahun'
                    }
                }
            },
            btnNext: 'Lanjut',
            btnCancel: 'Batal',
            bottomCon: {
                haveAccount: 'Sudah punya akun EB<i>Click</i>?',
                enter: 'Masuk'
            }
        }
    }
 }
