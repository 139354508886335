export const SET_LANGUAGE = 'SET_LANGUAGE'

const initialState = {
    lang: 'id'
}

const LanguageSettingReducer = (state = initialState, action) => {
    switch(action.type){
        case SET_LANGUAGE: {
            return{
                lang: action.payload
            }
        }
        default:{
            return state
        }
    }
}

export default LanguageSettingReducer