export const GET_EMPYE_REPORT_DOCS = 'GET_EMPYE_REPORT_DOCS'
export const LOADING_EMPYE_REPORT_DOCS = 'LOADING_EMPYE_REPORT_DOCS'
export const LOADING_EMPYE_REPORT_DOCS_FALSE = 'LOADING_EMPYE_REPORT_DOCS_FALSE'
export const PAGINATION_EMPYE_REPORT_DOCS = 'PAGINATION_EMPYE_REPORT_DOCS'
export const RESET_EMPYE_REPORT_DOCS = 'RESET_EMPYE_REPORT_DOCS'

const initialState = {
    data: null,
    loading: false,
    page: 1,
    take: 5,
    order: 'ASC',
    pageCount: 0
}

const ReportsReducer = (state = initialState, action) => {
    switch(action.type){
        case GET_EMPYE_REPORT_DOCS: {
            return{
                ...state,
                data: action.payload,
                loading: false
            }
        }
        case LOADING_EMPYE_REPORT_DOCS: {
            return {
                ...state,
                loading: true,
                data: null
            }
        }
        case LOADING_EMPYE_REPORT_DOCS_FALSE: {
            return {
                ...state,
                loading: false
            }
        }
        case PAGINATION_EMPYE_REPORT_DOCS: {
            return {
                ...state,
                page: action.payload.page,
                pageCount: action.payload.pageCount
            }
        }
        case RESET_EMPYE_REPORT_DOCS: {
            return{
                data: null,
                loading: false,
                page: 1,
                take: 5,
                order: 'ASC',
                pageCount: 0
            }
        }
        default:{
            return state
        }
    }
}

export default ReportsReducer
