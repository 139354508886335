import * as React from "react";
import clsx from "clsx";
import { Form, Button } from "components";
import { Link, withRouter } from "react-router-dom";
import {
    Col,
    OverlayTrigger,
    Popover,
    Form as FormBootstrap,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useAuth from "hooks/useAuth";
import styles from "./style.module.scss";

const Employee = (props) => {
    const { t } = useTranslation();

    const { loginEmployee, loading } = useAuth();
    const [nextBtn, setNextBtn] = React.useState(false);
    const [inputData, setInputData] = React.useState({});
    const [comChange, setComChange] = React.useState(0);
    const [loadingSignIn, setLoadingSignIn] = React.useState(false);
    const [remindMe, setRemindMe] = React.useState(false);

    const inputHandler = React.useCallback(
        (e) => {
            if (e.target.name === "email" && e.target.value.length > 0) {
                setNextBtn(true);
            } else if (
                e.target.name === "password" &&
                e.target.value.length > 0
            ) {
                setNextBtn(true);
            } else {
                setNextBtn(false);
            }
            setInputData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }));
        },
        [setInputData, setNextBtn]
    );

    const onSubmit = React.useCallback(
        async (e) => {
            e.preventDefault();
            props.setMsg(null);
            if (comChange === 1) {
                setLoadingSignIn(true);
                await loginEmployee(inputData.email, inputData.password)
                    .then((res) => {
                        if (res.success && !res.data.otp) {
                            props.history.push("/employee")
                        } else if (res.success && res.data.otp) {
                            props.history.push(`${props.layoutPath}/employee/verification/email/${res.data.secret}`)
                        } else {
                            props.setMsg(res.data);
                        }
                    })
                    .finally(() => {
                        setLoadingSignIn(false);
                    });
            }
        },
        [inputData, nextBtn, props.setMsg]
    );

    const employeeNextStep = () => {
        if (inputData.email) {
            setComChange(1);
            setNextBtn(false);
        }
    };

    return (
        <FormBootstrap className={clsx(styles.form)} onSubmit={onSubmit}>
            <FormBootstrap.Row className="align-items-center">
                <Form.Input
                    hasGrow
                    type="text"
                    hasValidation
                    appendCursorHelp
                    name="email"
                    label={t("signIn.form.email.label")}
                    isInvalid={""}
                    onChange={inputHandler}
                    autoComplete={remindMe}
                    formGroupProps={{ as: Col, sm: 12 }}
                    append={
                        <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="top"
                            overlay={
                                <Popover>
                                    <Popover.Content>
                                        {t("signIn.form.email.toolTip")}
                                    </Popover.Content>
                                </Popover>
                            }
                        >
                            <i className="mi-info px-1"></i>
                        </OverlayTrigger>
                    }
                />
                {comChange === 1 && (
                    <Form.Input
                        hasGrow
                        password
                        autoComplete={remindMe}
                        name="password"
                        label={t("signIn.form.password")}
                        onChange={inputHandler}
                        formGroupProps={{ as: Col, sm: 12 }}
                    />
                )}
                {comChange === 1 && (
                    <div
                        className={clsx(
                            "mb-3 w-100 d-flex justify-content-between align-items-center"
                        )}
                    >
                        <Col xs={6} className="text-right"></Col>
                        <Col xs={6} className="text-right">
                            <Link
                                to={`/employee/auth/reset-password`}
                                className={clsx("decoration-underlined")}
                            >
                                <strong>
                                    {t("signIn.form.forgotPassword")}
                                </strong>
                            </Link>
                        </Col>
                    </div>
                )}
                <Col xs={12} className="mt-3">
                    {comChange === 0 ? (
                        <div
                            onClick={employeeNextStep}
                            className={clsx(
                                styles.btnNext,
                                !nextBtn && styles.btnNext__disabled
                            )}
                        >
                            {t("signIn.form.btnNext")}
                        </div>
                    ) : (
                        <Button
                            loading={loadingSignIn ? loadingSignIn.toString() : undefined}
                            as="button"
                            variant="coral"
                            type="submit"
                            disabled={!nextBtn}
                        >
                            {t("signIn.form.btnSignIn")}
                        </Button>
                    )}
                </Col>
            </FormBootstrap.Row>
        </FormBootstrap>
    );
};

export default withRouter(Employee);
