// @flow
/* eslint-disable no-use-before-define */
import * as React from 'react'
import { Redirect } from 'react-router'
import { Employee, Employer, Guides, Calculator, SignIn, EBClickMaintenance } from 'pages'

const routes = {
  [Guides.pathname]: Guides.route,
  [Employee.pathname]: Employee.route,
  [Employer.pathname]: Employer.route,
  [Calculator.pathname]: Calculator.route,
  [SignIn.pathname]: SignIn.route,
  [EBClickMaintenance.pathname]: EBClickMaintenance.route,
  '/': {
    component: () => <Redirect to={'/signin/employee'} />,
  },
}

export default routes
