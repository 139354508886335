// @flow
import * as React from 'react'
import clsx from 'clsx'

import styles from './style.module.scss'

const StatusText = ({
  append,
  dotSize,
  label,
  prepend,
  type,
}) => {
  return (
    <div className={clsx('d-flex', 'justify-content-between', 'align-items-center')}>
      {prepend}
      <span className={clsx(styles.label, !!dotSize && `text-${dotSize}`)}>
        <i className={clsx(styles.dot, `text-${type}`)}></i>
        {label}
      </span>
      {append}
    </div>
  )
}

export default StatusText
