// @flow

import * as React from "react";
import clsx from "clsx";
import { has, isEmpty } from "lodash";
import { Link, useHistory } from "react-router-dom";
import { Button, Container, Dropdown, Spinner } from "react-bootstrap";
import { Header, Sidebar, SearchBar, Footer, Navigation } from "components";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { store } from "react-notifications-component";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getMenuActions } from "store/actions/menuSidebarActions";
import {
    getNotificationsEmployeeAction,
    getNotificationsEmployerAction,
} from "store/actions/notificationsActions";
import useAuth from "hooks/useAuth";
import autoLogout from "./autoLogout";
import getDate2 from "utils/dateConverter2";

// Styles
import styles from "./style.module.scss";

// Routes
import { matchPath } from "react-router-dom/cjs/react-router-dom.min";
import { cerNmbrLSVar, userLSVar } from "contexts/AuthContext";
import { toast } from "react-toastify";

const notificationLink = {
    employee: {
        link: "/employee/notification/all",
    },
    employer: {
        link: "/employer/notification/all",
    },
};

const LoadingNotification = () => {
    return (
        <div
            style={{ minHeight: 100 }}
            className="w-100 h-100 d-flex justify-content-center align-items-center"
        >
            <Spinner animation="border" />
        </div>
    );
};


class Pages extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = null;
    }

    render() {
        const RouteBlock = this.props.routesBlock;

        return !!RouteBlock ? (
            <SwitchTransition>
                <CSSTransition
                    key={this.props.location.pathname}
                    timeout={300}
                    classNames="transition-slide"
                    unmountOnExit
                >
                    <div className={clsx(styles.content)}>
                        <RouteBlock
                            {...this.props}
                            renderRoute={(RouteComponent, route, path) => {
                                const TransitionedComponent = (routeProps) => (
                                    <route.component {...routeProps} />
                                );

                                return (
                                    <RouteComponent
                                        {...route}
                                        key={path}
                                        path={path}
                                        component={TransitionedComponent}
                                        layoutPath={
                                            !!route.asLayout
                                                ? path
                                                : !!this.props.layoutPath &&
                                                  !isEmpty(
                                                      this.props.layoutPath
                                                  )
                                                ? this.props.layoutPath
                                                : ""
                                        }
                                    />
                                );
                            }}
                        />
                        <Footer
                            className={clsx(styles.footer)}
                            layoutType="dashboard"
                        />
                    </div>
                </CSSTransition>
            </SwitchTransition>
        ) : (
            !!this.props.children && this.props.children
        );
    }
}

let refreshLoginTimeOut;

export const Dashboard = ({ mode, notification, ...rest }) => {
    const { timerAutoLogout } = autoLogout();
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });
    const dispatch = useDispatch();
    const history = useHistory();
    const { logoutEmployee, logoutEmployer, getValueLocalStorage, roleName } =
        useAuth();
    const authDetector = useSelector((state) => state.authDetector);
    const notifData = useSelector((state) => state.notifications);
    const [readCount,setReadCount]=React.useState(0)
    const { t } = useTranslation();
    const notifHandleButton = React.useRef(null);
    const [ notificationData, setNotificationData ] = React.useState([])
    const [dropdownIsOpen,setDropdownIsOpen] = React.useState(false)
    const langId = useSelector((state) => state.language.lang);

    const headerRef = React.useRef(null);
    const [currLocation, setCurrLocation] = React.useState(
        rest.location.pathname
    );
    const [hasShowedSearchBar, setShowSearchBar] = React.useState(false);
    const [hasShowedNavigation, setShowNavigation] = React.useState(false);
    const [hasSidebarChildNavOpen, setHasSidebarChildNavOpen] =
        React.useState(false);
    // navigation
    const dataMenu = useSelector((state) => state.menuSidebar);

    const handleOnScroll = ({ target: { scrollingElement } }) => {
        const height = headerRef.current?.clientHeight;
        const headerClassList = headerRef.current?.classList;

        if (!!height && !isTabletOrMobile) {
            const toggle =
                scrollingElement.scrollTop >= height ? "add" : "remove";

            headerClassList[toggle]("opacity-90");
            headerClassList[toggle]("bg-white");
            headerClassList[toggle]("shadow-sm");
        }
    };

    const NotificationItem = React.memo((props) => {
        return (
            <Link to={props.to} onClick={()=>{ dropDownToggle()}}>
                <div
                    className={clsx(
                        styles.notifWrapper,
                        props.read ? "" : styles.unread
                    )}
                >
                    <div className={clsx("mb-1")}>
                        <span className={clsx(styles.notifTopText)}>
                            {props.date}
                        </span>
                    </div>
                    <div className={clsx(styles.notifBody)}>
                        <h3 className={clsx(styles.notifTitle)}>
                            <strong>{props.title}</strong>
                        </h3>
                        <p className={clsx(styles.notifText, "mb-0")} dangerouslySetInnerHTML={{ __html:props.textBody.length>65?props.textBody.substring(0,65)+'....':props.textBody}}>
                        
                        </p>
                    </div>
                </div>
            </Link>
        );
    });
    const notifHandler = () => {
        history.push(notificationLink[mode].link);
    };

    const logoutHandler = async () => {
        const roleUser = getValueLocalStorage(userLSVar);
        if (roleUser && authDetector.auth) {
            switch (roleUser.roleName) {
                case "EMPLOYEE":
                    await logoutEmployee().then((res) => {
                        if (res) {
                            history.push("/signin");
                        }
                    });
                    break;
                case "EMPLOYER":
                    await logoutEmployer().then((res) => {
                        if (res) {
                            history.push("/signin");
                        }
                    });
                    break;
            }
        } else {
            history.push("/singin");
        }
    };
    const dropDownToggle=()=>{
        setDropdownIsOpen(!dropdownIsOpen)
      }
    const notifButtonHandler = async() => {
        if(notifData.reload){
                    const userActive = getValueLocalStorage(userLSVar);
                    switch (userActive.roleName) {
                        case "EMPLOYEE":
                            const cer_nmbr = getValueLocalStorage(cerNmbrLSVar);
                            try{
                                const data = await dispatch(getNotificationsEmployeeAction(cer_nmbr))
                                setReadCount(data.meta.nrCount)
                                setNotificationData(data.data)
                            }catch(err){
                                if(err.response){
                                    toast.error(err.response.data.message)
                                }
                            }
                            break;
                        case "EMPLOYER":
                            try{
                                const data = await dispatch(getNotificationsEmployerAction())
                                setReadCount(data.meta.nrCount)
                                setNotificationData(data.data)
                            }catch(err){
                                if(err.response){
                                    toast.error(err.response.data.message)
                                }
                            }
                            break;
                    }
        }


    };

    React.useLayoutEffect(() => {
        window.addEventListener("scroll", handleOnScroll);

        return () => {
            window.removeEventListener("scroll", handleOnScroll);
        };
    }, []);

    React.useEffect(() => {
        const pathname = rest.location.pathname;
        if (dataMenu.data) {
            if (currLocation !== pathname) {
                setCurrLocation(pathname);
                const hasChildsNavs = dataMenu.data.filter(
                    (item) =>
                        !!matchPath(pathname, { path: item.to }) &&
                        has(item, "submenu") &&
                        !!item.submenu.length
                );
                if (!hasChildsNavs.length && hasShowedNavigation)
                    setShowNavigation(false);
            }
        }
    }, [rest.location, currLocation, dataMenu]);

    React.useEffect(() => {
        dispatch({
            type: 'RELOAD_NOTIF',
            payload:true
        })
        dispatch(getMenuActions());
    }, [langId]);

      React.useEffect(() => {
        timerAutoLogout()
      },[])
      React.useEffect(() => {
        notifButtonHandler()
      },[notifData.reload])

    React.useEffect(() => {
        if (!authDetector.auth) {
            refreshLoginTimeOut = setTimeout(() => {
                history.go(0);
                clearTimeout(refreshLoginTimeOut);
            }, 2000);
        } else {
            clearTimeout(refreshLoginTimeOut);
        }
    }, [authDetector.auth]);

    const WrapperDropdownMenu = React.forwardRef(
        ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
            return (
                <div className={`${styles.wrapperBoxNotif} position-absolute`}>
                    <div className="position-relative">
                        <div
                            ref={ref}
                            style={style}
                            className={`${className} ${styles.wrapperBoxNotiInner}`}
                            aria-labelledby={labeledBy}
                        >
                            {children}
                        </div>
                    </div>
                </div>
            );
        }
    );

    return (
        <Container
            as="main"
            id="main-dashboard"
            className={clsx(
                "px-0",
                styles.wrapper,
                hasSidebarChildNavOpen && styles.showSidebarChild
            )}
            fluid
        >
            <style jsx="true">{`
                .notification-dropdown .dropdown-toggle {
                    background-color: transparent !important;
                    color: #282b3e !important;
                    padding: 0;
                    margin-right: 1rem;
                    border-color: transparent !important;
                }
                .notification-dropdown .dropdown-toggle::after {
                    display: none;
                }
                .notification-dropdown .dropdown-toggle i {
                    font-size: 1.35rem !important;
                }
                .notification-dropdown .dropdown-toggle:hover {
                    border-color: transparent !important;
                    color: #282b3e !important;
                }
                .notification-dropdown .dropdown-toggle:focus {
                    border-color: transparent !important;
                    color: #282b3e !important;
                    box-shadow: none !important;
                }
                .notification-dropdown .dropdown-menu {
                    inset: 0px auto auto 0px !important;
                    width: 380px;
                    z-index: 1050 !important;
                }
            `}</style>

            {/* mobile/tablet */}
            <MediaQuery maxWidth={992}>
                <Header.Mobile
                    fluid
                    layoutType="dashboard"
                    mode={mode}
                    logoIcon={true}
                    headerRef={headerRef}
                    className={clsx(styles.header)}
                    hideBrand={hasSidebarChildNavOpen}
                    langSelector={false}
                    onClickNotification={notifHandler}
                    appendToNav={
                        <>
                            {notification && (
                                <Button
                                    variant="icon"
                                    className="mr-3 mi-notification lg"
                                    onClick={() => {
                                        store.addNotification({
                                            content: (notifProps) => (
                                                <div
                                                    {...notifProps}
                                                    className="notification__item notification__item--success"
                                                >
                                                    <div className="notification__icon">
                                                        <i className="mi-badge-ok"></i>
                                                    </div>
                                                    <div className="notification__content">
                                                        <div
                                                            className="notification__close"
                                                            onClick={() =>
                                                                store.removeNotification(
                                                                    notifProps.id
                                                                )
                                                            }
                                                        ></div>
                                                        <div className="notification__title">
                                                            <strong>
                                                                Dokumen Siap
                                                                Diunduh
                                                            </strong>{" "}
                                                            (Info
                                                            Peserta_Alexander-12345_020221)
                                                        </div>
                                                        <div className="notification__message">
                                                            Sit egestas
                                                            elementum dignissim
                                                            eros, bibendum
                                                            adipiscing non
                                                            sapien viverra. Enim
                                                            elit nulla in
                                                            rhoncus.
                                                        </div>
                                                    </div>
                                                </div>
                                            ),
                                            insert: "bottom",
                                            container: "bottom-full",
                                            dismiss: {
                                                duration: 100000,
                                                click: false,
                                                touch: false,
                                            },
                                        });
                                    }}
                                />
                            )}
                        </>
                    }
                    onNavToggleClick={setShowNavigation}
                    navShowed={hasShowedNavigation}
                />
                {dataMenu.data && (
                    <Navigation.Mobile
                        position="fixed"
                        homeLink={`/${mode}/home`}
                        navItems={dataMenu.data}
                        onChildNavToggleState={setHasSidebarChildNavOpen}
                        childNavSelector="#main-dashboard"
                        onClose={setShowNavigation}
                        showed={hasShowedNavigation}
                        signOutHandle={logoutHandler}
                    />
                )}
            </MediaQuery>

            {/* desktop/laptop */}
            <MediaQuery minWidth={992}>
                <SearchBar
                    onClose={setShowSearchBar}
                    showed={hasShowedSearchBar}
                />
                <Header
                    mode={mode}
                    logoIcon={false}
                    headerRef={headerRef}
                    className={clsx(styles.header)}
                    hideBrand={hasSidebarChildNavOpen}
                    langSelector
                    appendToNav={
                        <>
                            {/* <Button
                variant="icon"
                className="mx-3 mi-search lg"
                onClick={() => setShowSearchBar(!hasShowedSearchBar)}
              /> */}
                            {notification && (
                                <Dropdown className="notification-dropdown"onToggle={dropDownToggle}
                                show={dropdownIsOpen}>
                                 <Dropdown.Toggle ref={notifHandleButton} onClick={dropDownToggle}>
                                        <i className="mi-notification"></i>
                                        <span className='badge-notif' style={{
                                            position: 'relative',
                                            left: '-8px',
                                            fontSize: '10px',
                                            textAlign: 'center',
                                            color: 'white',
                                            minWidth: '5px',
                                            padding: '0px 5px 0px 5px',
                                            borderRadius: '10px',
                                            background: 'red'
                                            }}>
                                            {readCount}
                                        </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu as={WrapperDropdownMenu}>
                                        <div
                                            className={clsx(
                                                "d-flex justify-content-between align-items-center mb-1",
                                                styles.notifHead
                                            )}
                                        >
                                            <div className={clsx("m-0")}>
                                                <strong>
                                                    {t(
                                                        "notification.iconNotif.newNotif"
                                                    )}
                                                </strong>
                                            </div>
                                            <div
                                                className={clsx(
                                                    styles.notifMarkRead
                                                )}
                                            >
                                                {t(
                                                    "notification.iconNotif.read"
                                                )}{" "}
                                                <span>
                                                    (
                                                    {notifData.data
                                                        ? notifData.data.filter(
                                                              (it) =>
                                                                  it.read === 0
                                                          ).length
                                                        : 0}
                                                    )
                                                </span>
                                            </div>
                                        </div>
                                        <div className={styles.notifBoxWrapper}>
                                            {notifData.data &&
                                                !notifData.loading &&
                                                notificationData.map(
                                                    (it, index) => {
                                                        return (
                                                            <NotificationItem
                                                                key={index}
                                                                number={index}
                                                                read={
                                                                    it.read !==
                                                                    0
                                                                }
                                                                date={getDate2(
                                                                    it.created_at
                                                                )}
                                                                title={
                                                                    it.subject
                                                                }
                                                                textBody={
                                                                    it.msg
                                                                }
                                                                to={
                                                                    roleName ===
                                                                    "EMPLOYEE"
                                                                        ? `/${mode}/notification/detail/${it.cer_nmbr}/${it.crm_nmbr}/${it.sub_nmbr}`
                                                                        : `/${mode}/notification/detail/${it.crm_nmbr}/${it.sub_nmbr}`
                                                                }
                                                            />
                                                        );
                                                    }
                                                )}
                                        </div>
                                        {notifData.loading && (
                                            <LoadingNotification />
                                        )}
                                        <div className={clsx("p-2")}>
                                            <button
                                                onClick={notifHandler}
                                                className={clsx(
                                                    styles.buttonAllNotif
                                                )}
                                            >
                                                {t(
                                                    "notification.iconNotif.buttonAllNotif"
                                                )}{" "}
                                                <i
                                                    className={clsx(
                                                        "mi-chevron-right ml-2"
                                                    )}
                                                ></i>
                                            </button>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                            <Button
                                onClick={logoutHandler}
                                variant="outline-coral"
                                size="sm"
                            >
                                <strong>{t("dashboard.btn.logOut")}</strong>
                            </Button>
                        </>
                    }
                    fluid
                />
                {dataMenu.data && (
                    <Sidebar
                        position="fixed"
                        homeLink={`/${mode}/home`}
                        navItems={dataMenu.data}
                        onChildNavToggleState={setHasSidebarChildNavOpen}
                        childNavSelector="#main-dashboard"
                    />
                )}
            </MediaQuery>
            <Pages {...rest} />
        </Container>
    );
};

export default Dashboard;
