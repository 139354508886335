// @flow
import * as React from 'react'
import { Redirect } from 'react-router'
import { Loadable } from 'components'
import { API_EMP_ACCOUNT } from "utils/api.constant";

const Prices =  Loadable(React.lazy(() => import('./Prices')))
const Direction =  Loadable(React.lazy(() => import('./Direction')))
const Conversion =  Loadable(React.lazy(() => import('./Conversion')))
const ChangeDirection =  Loadable(React.lazy(() => import('./ChangeDirection')))
const FundFactSheet =  Loadable(React.lazy(() => import('./FundFactSheet')))
const MonthlyMarketReview =  Loadable(React.lazy(() => import('./MonthlyMarketReview')))
const Upr = Loadable(React.lazy(() => import('./UnitPrice')))
const NoneChangeDirection = Loadable(React.lazy(() => import('./NoneChangeDirection')))
const DynamicChangeDirection = Loadable(React.lazy(() => import('../../../views/Investment/DynamicDirection')))

const pathname = '/investment'
const route = {
  routes: {
    '/direction': {
      component: Direction,
    },
    '/prices': {
      component: Prices,
    },
    '/conversion': {
      component: Conversion,
    },
    '/change-direction': {
      component: DynamicChangeDirection,
    },
    '/fund-fact-sheet': {
      component: FundFactSheet,
    },
    '/monthly-market-review': {
      component: MonthlyMarketReview,
    },
    '/unit-price': {
      component: Upr,
    },
    '/': {
      component: ({ layoutPath }) => <Redirect to={`${layoutPath}investment/direction`} />,
    },
  },
}


const invesment = {
  pathname,
  route
}

export default invesment
