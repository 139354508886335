export const SET_DESC = 'SET_DESC'
export const RESET_DESC = 'RESET_DESC'

const initialState = {
    text: '',
}

const DescriptionPageReducer = (state = initialState, action) => {
    switch(action.type){
        case SET_DESC: {
            return{
                text: action.payload,
            }
        }
        default:{
            return state
        }
    }
}

export default DescriptionPageReducer