// @flow
import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { ReactComponent as LogoIcon } from "assets/images/svgs/logo/icon.svg";
import { ReactComponent as LogoBrand } from "assets/images/svgs/logo/brand.svg";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

// Styles
import styles from "./style.module.scss";
import { getSosmedActions } from "store/actions/sosmedActions";
import { getAppSetupActions, resetData } from "store/actions/appSetupActions";

const Footer = ({ layoutType, className }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const dataSosmed = useSelector((state) => state.sosmedReducer);
    const dataAppSetup = useSelector((state) => state.appSetup);
    const lang = useSelector((state) => state.language.lang);
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 767.98px)" });
    const isTypeDashboard = layoutType === "dashboard";

    const menuItems = [
        {
            href: "https://www.manulife.co.id/id/syarat-penggunaan.html",
            label: t("footer.termOfUse"),
            layouts: ["common", "dashboard"],
        },
        {
            href: "https://www.manulife.co.id/id/kebijakan-privasi.html",
            label: t("footer.privacy"),
            layouts: ["common", "dashboard"],
        },
        {
            href: "https://www.manulife.co.id/id/hubungi-kami.html",
            label: t("footer.contactUs"),
            layouts: ["common", "dashboard"],
        },
    ];

    React.useEffect(() => {
        dispatch(getSosmedActions());
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(getAppSetupActions());
    }, [lang]);

    const renderMenus = () => (
        <Nav
            as="ul"
            className={clsx(styles.footer__nav, styles.footer__nav__links)}
        >
            {menuItems
                .filter((menu) => menu.layouts.includes(layoutType))
                .map(({ href, label }, key) => (
                    <Nav.Item
                        as="li"
                        key={key}
                        className={clsx("nav-item", styles.footer__nav__item)}
                    >
                        <Nav.Link
                            as={Link}
                            to="#"
                            onClick={() => window.open(href)}
                            className={clsx(
                                "nav-link",
                                styles.footer__nav__link
                            )}
                        >
                            {label}
                        </Nav.Link>
                    </Nav.Item>
                ))}
        </Nav>
    );

    const renderSocials = () => (
        <Nav as="ul" className={clsx(styles.footer__nav)}>
            {dataSosmed.data.map(({ href, label, icon }, key) => (
                <Nav.Item
                    as="li"
                    key={key}
                    className={clsx(
                        "nav-item",
                        styles.footer__nav__item,
                        styles.footer__nav__item__icon
                    )}
                >
                    <Nav.Link
                        href="#"
                        onClick={() => window.open(href)}
                        aria-label={label}
                        className={clsx("nav-link", styles.footer__nav__link)}
                    >
                        <i className={clsx(`mi-${icon}`)}></i>
                    </Nav.Link>
                </Nav.Item>
            ))}
        </Nav>
    );

    return (
        <footer
            className={clsx(
                styles.footer,
                (isTypeDashboard || isTabletOrMobile) && styles["footer--dark"],
                className
            )}
            data-type={layoutType}
        >
            {isTypeDashboard || isTabletOrMobile ? (
                <>
                    <div className={styles.footer__top}>
                        <Container fluid>
                            <Row className={clsx("align-items-center")}>
                                <Col md={6}>{renderMenus()}</Col>
                                <Col>{renderSocials()}</Col>
                            </Row>
                        </Container>
                    </div>
                    <div className={styles.footer__bottom}>
                        <Container fluid>
                            <Row>
                                <Col sm={12} md={6}>
                                    <Link
                                        className={clsx(styles.footer__brand)}
                                        to="/"
                                    >
                                        <div
                                            className={clsx(
                                                styles.footer__brand__icon
                                            )}
                                        >
                                            <LogoIcon
                                                fill="#00A758"
                                                width={24.266666666667}
                                                height={26}
                                            />
                                        </div>
                                        <LogoBrand
                                            fill="#FFF"
                                            width={89.375}
                                            height={26}
                                        />
                                    </Link>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className={styles.footer__copy}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    dataAppSetup.data
                                                        ?.copyright || "",
                                            }}
                                        ></div>
                                        <p className="mt-3">{t('footer.copyright')}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </>
            ) : (
                <Container>{renderMenus()}</Container>
            )}
        </footer>
    );
};

export default Footer;
