export const GET_EMPYE_CLAIM = 'GET_EMPYE_CLAIM'
export const LOADING_EMPYE_CLAIM = 'LOADING_EMPYE_CLAIM'
export const PAGINATION_EMPYE_CLAIM = 'PAGINATION_EMPYE_CLAIM'
export const RESET_EMPYE_CLAIM = 'RESET_EMPYE_CLAIM'
export const GET_EMPYE_CLAIM_HISTORY = 'GET_EMPYE_CLAIM_HISTORY'
export const PAGINATION_EMPYE_CLAIM_HISTORY = 'PAGINATION_EMPYE_CLAIM_HISTORY'
const initialState = {
    data: null,
    detail: null,
    loading: false,
    page: 1,
    take: 10,
    pageCount: 1,
    order: 'ASC',
    dataHistory:null,
    pageHistory:1,
    pageCountHistory:1
}

const ClaimReducer = (state = initialState, action) => {
    switch(action.type){
        case GET_EMPYE_CLAIM: {
            return{
                ...state,
                data: action.payload,
                loading: false
            }
        }
        case GET_EMPYE_CLAIM_HISTORY: {
            return{
                ...state,
                dataHistory: action.payload,
                loading: false
            }
        }
        case LOADING_EMPYE_CLAIM: {
            return{
                ...state,
                loading: true
            }
        }
        case PAGINATION_EMPYE_CLAIM: {
            return{
                ...state,
                page: action.payload.page,
                pageCount: action.payload.pageCount
            }
        }
        case PAGINATION_EMPYE_CLAIM_HISTORY: {
            return{
                ...state,
                pageHistory: action.payload.page,
                pageCountHistory: action.payload.pageCount
            }
        }
        case RESET_EMPYE_CLAIM: {
            return{
                data: null,
                detail: null,
                loading: false,
                page: 1,
                take: 5,
                pageCount: 1,
                order: 'ASC',
                dataHistory:null,
                pageCountHistory:1,
                pageHistory:1
            }
        }
        default:{
            return state
        }
    }
}

export default ClaimReducer
