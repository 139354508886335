// @flow
import NumbroInstance from 'numbro'

NumbroInstance.registerLanguage({
  languageTag: 'id-ID',
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'ribu',
    million: 'jt',
    billion: 'm',
    trillion: 't',
  },
  ordinal: number => {
    return number === 1 ? 'er' : 'ème'
  },
  currency: {
    symbol: 'Rp',
    position: 'prefix',
    code: 'IDR',
  },
  currencyFormat: {
    thousandSeparated: false,
    totalLength: 4,
    spaceSeparated: true,
    average: true,
  },
  formats: {
    fourDigits: {
      totalLength: 4,
      spaceSeparated: true,
      average: true,
    },
    fullWithTwoDecimals: {
      output: 'currency',
      mantissa: 2,
      spaceSeparated: true,
      thousandSeparated: true,
    },
    fullWithTwoDecimalsNoCurrency: {
      mantissa: 2,
      thousandSeparated: true,
    },
    fullWithNoDecimals: {
      output: 'currency',
      spaceSeparated: true,
      thousandSeparated: true,
      mantissa: 0,
    },
  },
})

export const applyNumbro = () => {
  window.numbro = NumbroInstance

  window.numbro.setLanguage('id-ID')

  window.numbro.setDefaults({
    thousandSeparated: true,
    mantissa: 0,
  })

  return window.numbro
}

export default NumbroInstance
