
export const en = {
    employeeNewsTable: {
        date: 'Date',
        information: 'Information',
        desc: 'Description'
    },
    employeeNewsTableNullLabel: 'Stay tuned for more updates from us.'
}

export const id = {
    employeeNewsTable: {
        date: 'Tanggal',
        information: 'Informasi',
        desc: 'Deskripsi'
    },
    employeeNewsTableNullLabel: 'Silakan tunggu berita terkini dari kami.'
}