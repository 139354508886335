// @flow
// eslint-disable-next-line no-unused-vars
import * as React from 'react'
import Guest from 'layouts/Guest'
import MaintenanceMode from './MaintenanceMode'

export const pathname = '/maintenance'
export const route = {
  // component: Guest,
  asLayout: true,
  routes: {
    '/': {
      component: MaintenanceMode,
    },
  },
}