// @flow
import * as React from 'react'
import PageItem, { Ellipsis, First, Last, Next, Prev } from './PageItem'
import { Pagination as PaginationBootstrap } from 'react-bootstrap';

const Pagination = ((React.forwardRef(
  ({ ...restProps }, ref) => {
    const [currentPage,setCurrentPage] = React.useState(1);

    const createPaginationItem = (i) => {
      return <PaginationBootstrap.Item
          key={i}
          active={i === currentPage}
          onClick={() => {restProps.onClick(i); setCurrentPage(i)}}
        >
          {i}
        </PaginationBootstrap.Item>
    };
    const ellipsisPagination=()=>{
      return restProps.totalPages>10?<PaginationBootstrap.Ellipsis />:<></>
    }
    const paginationItem=()=>{

      var current = currentPage,
        last = restProps.totalPages,
        delta = 2,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

        for (let i = 1; i <= last; i++) {
            if (i == 1 || i == last || i >= left && i < right) {
                range.push(i);
            }
        }

        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(createPaginationItem(l + 1));
                } else if (i - l !== 1) {
                    rangeWithDots.push(ellipsisPagination());
                }
            }
            rangeWithDots.push(createPaginationItem(i));
            l = i;
        }

      return rangeWithDots;

    }
    React.useEffect(() => {
      const pagination = document.querySelectorAll('.pagination')
      for(var i=0; i< pagination.length; i++){
        for(var j=0; j < pagination[i].querySelectorAll("a.page-link").length; j++){
          if( pagination[i].querySelectorAll("a")[j]){
            pagination[i].querySelectorAll("a")[j].removeAttribute("href")
            pagination[i].querySelectorAll("a")[j].classList.add('cursor-pointer')
          }
        }
      }

    },[restProps])

    React.useEffect(() => {
      const paginationElem = document.querySelector('.pagination')
      const paginationItem = paginationElem?.querySelector('.page-item.active')

      if(paginationItem){
        const getSiblingElem = paginationItem.nextSibling
        if(restProps.currentPage === restProps.totalPages){
          const getElemAfterSelect = paginationElem.querySelectorAll('.page-item.active ~ .page-item')
          getElemAfterSelect.forEach((item) => {
            item.classList.add('d-none')
          })
        }else{
          getSiblingElem.classList.remove('d-none')
        }
      }
    },[restProps.currentPage, restProps.totalPages])

    if (restProps.totalPages === 0) return null;

    return (
      <PaginationBootstrap className="paginationInfo">
          <PaginationBootstrap.Prev
            onClick={() => {restProps.onClick(currentPage-1);setCurrentPage(currentPage-1)}}
            disabled={currentPage === 1}
          />
          {paginationItem()}
          <PaginationBootstrap.Next
            onClick={() => {restProps.onClick(currentPage+1);setCurrentPage(currentPage+1)}}
            disabled={currentPage === restProps.totalPages}
          />
        </PaginationBootstrap>
    )
  }
)))

Pagination.First = First
Pagination.Prev = Prev
Pagination.Ellipsis = Ellipsis
Pagination.Item = PageItem
Pagination.Next = Next
Pagination.Last = Last

export default Pagination
