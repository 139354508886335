// @flow

import * as React from 'react'
import ReactDOM from 'react-dom'
import clsx from 'clsx'
import { Form } from 'components'
import { Button, Card, Col, Fade, Row } from 'react-bootstrap'
import { usePopper } from 'react-popper'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
// import { isArray, isEqual, isNull, isString } from 'lodash'

const FilterableData = ({
  data: dataProp,
  initialState,
  children,
  onChanges,
  state,
  setState,
  resetFilterHandler,
  ...restProps
}) => {

  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
  const [visibility, setVisibility] = React.useState(false)
  const [referenceElement, setReferenceElement] = React.useState(null)
  const [popperElement, setPopperElement] = React.useState(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  })

  const [countedFuncs, setCountedFuncs] = React.useState(0)

  const getColor = name => {
    let color = 'white'
    switch (name) {
      case 1:
        color = 'warning'
        break
      case 2:
        color = 'scarletGum'
        break
      case 3:
        color = 'eggBlue'
        break
      default:
        color = 'white'
        break
    }

    return color
  }

  const onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setVisibility(!visibility)
  }

  const handleSortByElemClick = (by) => (e) => {
    setState({ ...state, sortBy: state.sortBy !== by ? by : '' })
  }

  const handleTypeClick = (type) => (e) => {
    setState({ ...state, typeId: state.typeId !== type ? type : '' })
  }

  const handleDocTypesElemClick = (type) => (e) => {
    const documentTypes = [...state.documentTypes]

    if (documentTypes.includes(type))
      documentTypes.splice(
        documentTypes.findIndex(T => T === type),
        1
      )
    else documentTypes.push(type)

    setState({ ...state, documentTypes })
  }

  const handleStatusElemChange = (e) => {

    const name = e.currentTarget.name
    const statusses = [...state.statusses]

    if (statusses.includes(name))
      statusses.splice(
        statusses.findIndex(T => T === name),
        1
      )
    else statusses.push(name)

    setState({ ...state, statusses })
  }

  return (
    <>
      {children({ events: { onClick }, props: { ref: setReferenceElement }, countedFuncs })}
      {ReactDOM.createPortal(
        <Fade in={visibility} unmountOnExit>
          <div
            {...attributes.popper}
            ref={setPopperElement}
            style={{ ...styles.popper, width: isMobile ? '92vw' : '22rem', zIndex: 9999 }}>
            <Card className={clsx('shadow-lg')}>
              <Card.Body>
                <Row>
                <Col xs={12} className={clsx('mb-3')}>
                    <span className="text-sm">Sort By</span>
                    <div style={{ flexWrap: 'wrap' }} className="d-flex mt-2">
                      <Button
                        size="sm"
                        className={clsx('mr-1 mb-2')}
                        variant={`rounded-${state?.sortBy === 'name' ? 'primary' : 'white'}`}
                        onClick={handleSortByElemClick('name')}>
                        {t('employerHome.reportData.filter.name')}
                      </Button>
                      <Button
                        size="sm"
                        className={clsx('mx-1 mb-2')}
                        variant={`rounded-${state?.sortBy === 'date' ? 'primary' : 'white'}`}
                        onClick={handleSortByElemClick('date')}>
                          {t('employerHome.reportData.filter.date')}
                      </Button>
                    </div>
                  </Col>
                  <Col xs={12} className={clsx('mb-3')}>
                    <span className="text-sm">Type</span>
                    <div style={{ flexWrap: 'wrap' }} className="d-flex mt-2">
                      <Button
                        size="sm"
                        className={clsx('mr-1 mb-2')}
                        variant={`rounded-${state?.typeId === 1 ? 'primary' : 'white'}`}
                        onClick={handleTypeClick(1)}>
                        {t('employerHome.reportData.filter.participantDraft')}
                      </Button>
                      <Button
                        size="sm"
                        className={clsx('mx-1 mb-2')}
                        variant={`rounded-${state?.typeId === 2 ? 'primary' : 'white'}`}
                        onClick={handleTypeClick(2)}>
                        {t('employerHome.reportData.filter.participanTransDraft')}
                      </Button>
                      <Button
                        size="sm"
                        className={clsx('mr-1 mb-2')}
                        variant={`rounded-${state?.typeId === 3 ? 'primary' : 'white'}`}
                        onClick={handleTypeClick(3)}>
                        {t('employerHome.reportData.filter.participantCard')}
                      </Button>
                      <Button
                        size="sm"
                        className={clsx('mx-1 mb-2')}
                        variant={`rounded-${state?.typeId === 4 ? 'primary' : 'white'}`}
                        onClick={handleTypeClick(4)}>
                        {t('employerHome.reportData.filter.incompleteClaim')}
                      </Button>
                      <Button
                        size="sm"
                        className={clsx('mx-1 mb-2')}
                        variant={`rounded-${state?.typeId === 5 ? 'primary' : 'white'}`}
                        onClick={handleTypeClick(5)}>
                        {t('employerHome.reportData.filter.claimPaymentReport')}
                      </Button>
                      <Button
                        size="sm"
                        className={clsx('mx-1 mb-2')}
                        variant={`rounded-${state?.typeId === 6 ? 'primary' : 'white'}`}
                        onClick={handleTypeClick(6)}>
                        {t('employerHome.reportData.filter.retirementPeriod')}
                      </Button>
                      <Button
                        size="sm"
                        className={clsx('mx-1 mb-2')}
                        variant={`rounded-${state?.typeId === 7 ? 'primary' : 'white'}`}
                        onClick={handleTypeClick(7)}>
                        {t('employerHome.reportData.filter.billingInfo')}
                      </Button>
                      <Button
                        size="sm"
                        className={clsx('mx-1 mb-2')}
                        variant={`rounded-${state?.typeId === 8 ? 'primary' : 'white'}`}
                        onClick={handleTypeClick(8)}>
                        {t('employerHome.reportData.filter.billingReport')}
                      </Button>
                    </div>
                  </Col>
                  <Col xs={12} className={clsx('mb-3')}>
                    <span className="text-sm">Menu</span>
                    <div style={{ flexWrap: 'wrap' }} className="d-flex mt-2">
                      <Button
                        size="sm"
                        className={clsx('mr-1 mb-2')}
                        onClick={handleDocTypesElemClick(1)}
                        variant={`rounded-${
                          state?.documentTypes.includes(1) ? getColor(1) : 'white'
                        }`}>
                        {t('employerHome.reportData.filter.participant')}
                      </Button>
                      <Button
                        size="sm"
                        className={clsx('mx-1 mb-2')}
                        onClick={handleDocTypesElemClick(2)}
                        variant={`rounded-${
                          state?.documentTypes.includes(2)
                            ? getColor(2)
                            : 'white'
                        }`}>
                        {t('employerHome.reportData.filter.claim')}
                      </Button>
                      <Button
                        size="sm"
                        className={clsx('mx-1 mb-2')}
                        onClick={handleDocTypesElemClick(3)}
                        variant={`rounded-${
                          state?.documentTypes.includes(3) ? getColor(3) : 'white'
                        }`}>
                        {t('employerHome.reportData.filter.dues')}
                      </Button>
                      <Button
                        size="sm"
                        className={clsx('mx-1 mb-2')}
                        onClick={handleDocTypesElemClick(4)}
                        variant={`rounded-${
                          state?.documentTypes.includes(4) ? getColor(4) : 'white'
                        }`}>
                        {t('employerHome.reportData.filter.ballance')}
                      </Button>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <span className="text-sm">{t('employerHome.reportData.filter.labelByStatus')}</span>
                    <div className="mt-3">
                      <Form.Checkbox
                        name="1"
                        checked={state?.statusses.includes("1")}
                        label={t('employerHome.reportData.filter.process')}
                        variant="success"
                        formGroupProps={{ className: 'mb-4' }}
                        onChange={handleStatusElemChange}
                      />
                      <Form.Checkbox
                        name="3"
                        checked={state?.statusses.includes("3")}
                        label={t('employerHome.reportData.filter.done')}
                        variant="warning"
                        formGroupProps={{ className: 'mb-4' }}
                        onChange={handleStatusElemChange}
                      />
                      <Form.Checkbox
                        name="2"
                        checked={state?.statusses.includes("2")}
                        label={t('employerHome.reportData.filter.fail')}
                        variant="danger"
                        formGroupProps={{ className: 'mb-0' }}
                        onChange={handleStatusElemChange}
                      />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className={clsx('py-2')}>
                <Button
                  size="sm"
                  variant="white"
                  className={clsx('d-block ml-auto py-2 px-3')}
                  onClick={resetFilterHandler}>
                  Reset Filter
                </Button>
              </Card.Footer>
            </Card>
          </div>
        </Fade>,
        document.querySelector('#__manulifeApp')
      )}
    </>
  )
}

export default React.memo(FilterableData)
