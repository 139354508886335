import axios from "axios";
import { toast } from "react-toastify";

import { Store } from "store";
import { SET_AUTH_DETECTOR_FALSE } from "store/reducers/authDetectorReducer";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  withCredentials: true,
  timeout: 100000,
  timeoutErrorMessage: "Request timeout!",
  xsrfHeaderName: "X-CSRF-TOKEN",
  xsrfCookieName: "_xsrf",
  headers: {
    accept: "*/*",
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const { lang } = Store.getState().language;
    config.headers["x-accept-lang"] = lang;
    return config;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const statusCode =
      error.response == undefined ? 500 : error.response.status;

    if (statusCode === 401) {
      Store.dispatch({
        type: SET_AUTH_DETECTOR_FALSE,
        payload: error.response.data.message,
      });
      setTimeout(() => {
        Store.dispatch({
          type: SET_AUTH_DETECTOR_FALSE,
          payload: null,
        });
      }, 3000);
    } else if (statusCode === 400) {
      throw error;
    } else {
      const msg =
        error.response == undefined
          ? "Network failed to connect"
          : error.response.data.message;
      if (error.response) {
        toast.error(msg, {
          position: "top-right",
          autoClose: true,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }
);

export default axiosInstance;
