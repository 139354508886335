import { memo } from "react";
import { SpinnerCircular } from "spinners-react";
import { Button as BtnBootstrap } from "react-bootstrap";
import clsx from "clsx";

import styles from "./styles.module.scss";

const Button = (props) => {
    const Loading = () => {
        return (
            <div
                className={clsx(
                    styles.spinnerWrapper,
                    "d-flex align-items-center justify-content-center"
                )}
            >
                <SpinnerCircular animation="border" variant="light" size="sm" />
            </div>
        );
    };

    return (
        <BtnBootstrap
            className={clsx("position-relative", styles.button)}
            {...props}
        >
            {props.loading && <Loading />}
            <div className={clsx(`${props.loading ? styles.children : ""}`)}>
                {props.children}
            </div>
        </BtnBootstrap>
    );
};

export default memo(Button);
