// @flow
import BsForm from 'react-bootstrap/Form'
import Input from './Input'
import Radio from './Radio'
import Select from './Select'
import Checkbox from './Checkbox'
import DatePicker from './DatePicker'
import PeriodPicker from './PeriodPicker'
import DateRangePicker from './DateRangePicker'
import DateRangePicker2 from './DateRangePicker2'
import DatePicker2 from './DatePicker2'
import MonthPicker from './MonthPicker'




let Form = {}

Form.Input = Input
Form.Radio = Radio
Form.Select = Select
Form.Checkbox = Checkbox
Form.DatePicker = DatePicker
Form.PeriodPicker = PeriodPicker
Form.DateRangePicker = DateRangePicker
Form.DateRangePicker2 = DateRangePicker2
Form.DatePicker2 = DatePicker2
Form.MonthPicker = MonthPicker

export { default as InputGroup } from 'react-bootstrap/InputGroup'
export default Form
