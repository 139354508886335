import {
    GET_SETUP,
    LOADING,
    LOADING_FALSE,
} from "store/reducers/appSetupReducer";
import { API_SETUP } from "utils/api.constant";
import axios from "utils/axios";

export const getAppSetupActions = () => async (dispatch) => {
    dispatch({
        type: LOADING,
    });

    await axios
        .get(API_SETUP)
        .then((res) => {
            if (res) {
                dispatch({
                    type: GET_SETUP,
                    payload: res.data.data,
                });
            }
        })
        .catch((err) => {
            dispatch({
                type: LOADING_FALSE,
            });
        });
};
