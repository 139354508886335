import React, { createContext, useEffect, useReducer } from "react";
import axios from "utils/axios";
import { useDispatch } from "react-redux";
import {
    authDetectorTrueAction,
    authDetectorFalseAction,
} from "store/actions/authDetectorActions";
import {
    API_AUTH_SESSION,
    API_EMPR_LOGIN,
    API_EMP_LOGIN,
    API_USER_INFO,
    API_EMPR_LOG_OUT,
    API_EMP_LOG_OUT,
} from "utils/api.constant";
import cryptoJs from 'crypto-js'

export const userLSVar = "@manulife/user";
export const cerNmbrLSVar = "@manulife/actvcer";

const initialState = {
    employeeIsAuthenticated: true,
    employerIsAuthenticated: true,
    isAuthenticated: false,
    list_cert_number: null,
    cert_number: null,
    loading: false,
    roleName: null,
};

const getValueLocalStorage = (name) => {
    if(!name) return
    const data = localStorage.getItem(name)
    if(!data) return
    const toDecrypt = cryptoJs.AES.decrypt(data, process.env.REACT_APP_MANULIFE_LOCAL_SALT).toString(cryptoJs.enc.Utf8)
    return JSON.parse(toDecrypt);
};

const getUserInfo = (role) => {
    return new Promise(async (resolve, reject) => {
        await axios
            .get(API_USER_INFO.replace(":role", role))
            .then((res) => {
                resolve(res.data.data);
            })
            .catch((err) => {
                reject(err.response.data.message);
            });
    });
};

const setLocalStorage = (name, data) => {
    if (name && data) {
        const toEncrypt = cryptoJs.AES.encrypt( JSON.stringify(data), process.env.REACT_APP_MANULIFE_LOCAL_SALT)
        localStorage.setItem(name, toEncrypt);
    } else {
        localStorage.removeItem(name);
    }
};

const reducer = (state, action) => {
    switch (action.type) {
        case "LOGIN_EMPLOYEE": {
            return {
                ...state,
                employeeIsAuthenticated: true,
                employerIsAuthenticated: false,
                loading: false,
            };
        }
        case "LOGOUT_EMPLOYEE": {
            return {
                ...state,
                employeeIsAuthenticated: false,
                loading: false,
            };
        }
        case "LOGIN_EMPLOYER": {
            return {
                ...state,
                employerIsAuthenticated: true,
                employeeIsAuthenticated: false,
                loading: false,
            };
        }
        case "LOGOUT_EMPLOYER": {
            return {
                ...state,
                employerIsAuthenticated: false,
                loading: false,
            };
        }
        case "REGISTER": {
            return {
                ...state,
                isAuthenticated: true,
            };
        }
        case "SET_ROLENAME": {
            return {
                ...state,
                roleName: action.payload,
            };
        }
        case "SET_LIST_CERT_NUMBER": {
            return {
                ...state,
                list_cert_number: action.payload,
            };
        }
        case "SET_CERT_NUMBER": {
            return {
                ...state,
                cert_number: action.payload,
            };
        }
        case "LOADING": {
            return {
                ...state,
                loading: true,
            };
        }
        case "LOADING_FALSE": {
            return {
                ...state,
                loading: false,
            };
        }
        default: {
            return { ...state };
        }
    }
};

const AuthContext = createContext({
    ...initialState,
    loginEmployee: () => Promise.resolve(),
    logoutEmployee: () => Promise.resolve(),
    employeeStillLogin: () => Promise.resolve(),
    loginEmployer: () => Promise.resolve(),
    logoutEmployer: () => Promise.resolve(),
    employerStillLogin: () => Promise.resolve(),
    getValueLocalStorage,
    setCertNumberHandler: () => Promise.resolve(),
    loginOtp: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
    const dispatchRedux = useDispatch();
    const [state, dispatch] = useReducer(reducer, initialState);

    const userDetail = (data) => {
        return {
            compName: data.compName,
            userName: data.userName,
            roleName: data.roleName,
            account: data.account,
            otpSendType: data.otpSendType,
            multiAccount: data.multiAccount,
            email:data.email,
            phone:data.phone
        };
    };

    const loginOtp = (data) => {
        if (data.success) {
            switch (data.roleName) {
                case "employee":
                    setLocalStorage(cerNmbrLSVar,data.data.user.account)
                    setLocalStorage(userLSVar, userDetail(data.data.user));
                    dispatch({
                        type: "LOGIN_EMPLOYEE",
                    });
                    dispatchRedux(authDetectorTrueAction());
                    setCertNumberHandler();
                    break;
                case "employer":
                    setLocalStorage(cerNmbrLSVar,data.data.user.account)
                    setLocalStorage(userLSVar, userDetail(data.data.user));
                    dispatch({
                        type: "LOGIN_EMPLOYER",
                    });
                    dispatchRedux(authDetectorTrueAction());
                    setCertNumberHandler();
                    break;
            }
        } else {
            switch (data.roleName) {
                case "employee":
                    setLocalStorage(userLSVar, null);
                    dispatch({
                        type: "LOGOUT_EMPLOYEE",
                    });
                    break;
                case "employer":
                    setLocalStorage(userLSVar, null);
                    dispatch({
                        type: "LOGOUT_EMPLOYER",
                    });
                    break;
            }
        }
    };

    const loginEmployee = (username, password) => {
        return new Promise(async (resolve) => {
            dispatch({
                type: "LOADING",
            });
            await axios
                .post(API_EMP_LOGIN, {
                    username,
                    password,
                })
                .then((res) => {
                    if (
                        res.data.statusCode === 200 &&
                        !res.data.data.otp &&
                        res.data.data.user &&
                        res.data.data.user.roleName === "EMPLOYEE"
                    ) {
                        setLocalStorage(
                            userLSVar,
                            userDetail(res.data.data.user)
                        );
                        dispatch({
                            type: "LOGIN_EMPLOYEE",
                        });
                        dispatch({
                            type: "SET_ROLENAME",
                            payload: res.data.data.user.roleName,
                        });
                        setCertNumberHandler(res.data.data.user.account);
                        dispatchRedux(authDetectorTrueAction());
                        resolve({
                            success: true,
                            data: {
                                message: res.data.message,
                                otp: res.data.data.otp,
                            },
                        });
                    } else if (
                        res.data.statusCode === 200 &&
                        res.data.data.otp
                    ) {
                        resolve({
                            success: true,
                            data: {
                                message: res.data.message,
                                otp: res.data.data.otp,
                                secret: res.data.data.secret,
                            },
                        });
                    } else {
                        setLocalStorage(userLSVar, null);
                        dispatch({
                            type: "LOADING_FALSE",
                        });
                        resolve({
                            success: false,
                            data: res.data.message,
                        });
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.data.statusCode === 400) {
                            dispatch({
                                type: "LOADING_FALSE",
                            });
                            resolve({
                                success: false,
                                data: err.response.data.message,
                            });
                        } else {
                            dispatch({
                                type: "LOADING_FALSE",
                            });
                            resolve({
                                success: false,
                                data: err.response.data.message,
                            });
                        }
                    } else {
                        console.log("Internal server error");
                    }
                });
        });
    };

    const logoutEmployee = () => {
        return new Promise(async (resolve) => {
            dispatch({
                type: "LOADING",
            });
            await axios
                .post(API_EMP_LOG_OUT)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        setLocalStorage(userLSVar, null);
                        dispatch({
                            type: "LOGOUT_EMPLOYEE",
                        });
                        dispatch({
                            type: "SET_LIST_CERT_NUMBER",
                            payload: null,
                        });
                        dispatchRedux(authDetectorFalseAction());
                        setLocalStorage(cerNmbrLSVar, null);
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
                .catch((err) => {
                    if (err.response.data.statusCode === 400) {
                        setLocalStorage(userLSVar, null);
                        resolve(false);
                        dispatch({
                            type: "LOGOUT_EMPLOYEE",
                        });
                    }
                });
        });
    };

    const loginEmployer = (data) => {
        return new Promise(async (resolve) => {
            dispatch({
                type: "LOADING",
            });
            await axios
                .post(API_EMPR_LOGIN, {
                    username: data.username,
                    password: data.password,
                    group_nmbr: parseInt(data.group_nmbr),
                })
                .then((res) => {
                    if (
                        res.data.statusCode === 200 &&
                        !res.data.data.otp &&
                        res.data.data.user.roleName === "EMPLOYER"
                    ) {
                        setLocalStorage(
                            userLSVar,
                            userDetail(res.data.data.user)
                        );
                        dispatch({
                            type: "LOGIN_EMPLOYER",
                        });
                        dispatch({
                            type: "SET_ROLENAME",
                            payload: res.data.data.user.roleName,
                        });
                        dispatchRedux(authDetectorTrueAction());
                        // setCertNumberHandler()
                        resolve({
                            success: true,
                            data: {
                                message: res.data.message,
                                otp: res.data.data.otp,
                            },
                        });
                    } else if (
                        res.data.statusCode === 200 &&
                        res.data.data.otp
                    ) {
                        resolve({
                            success: true,
                            data: {
                                message: res.data.message,
                                otp: res.data.data.otp,
                                secret: res.data.data.secret,
                            },
                        });
                    } else {
                        setLocalStorage(userLSVar, null);
                        dispatch({
                            type: "LOGOUT_EMPLOYER",
                        });
                        resolve({
                            success: false,
                            data: res.data.message,
                        });
                    }
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING_FALSE",
                    });

                    if (err.response) {
                        resolve({
                            success: false,
                            data: err.response.data.message,
                        });
                    } else {
                        console.log("Internal server error");
                    }
                });
        });
    };

    const logoutEmployer = () => {
        return new Promise(async (resolve) => {
            dispatch({
                type: "LOADING",
            });
            await axios
                .post(API_EMPR_LOG_OUT)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        setLocalStorage(userLSVar, null);
                        dispatchRedux(authDetectorFalseAction());
                        dispatch({
                            type: "LOGOUT_EMPLOYER",
                        });
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
                .catch((err) => {});
        });
    };

    const stillLogin = async () => {
        dispatch({
            type: "LOADING",
        });
        const userRole = getValueLocalStorage(userLSVar);
        await axios.get(API_AUTH_SESSION).then(async (res) => {
            switch (res.data.data.signedAs) {
                case "EMPLOYEE":
                    if (!userRole) {
                        await getUserInfo("employee").then((resIn) => {
                            setLocalStorage(userLSVar, userDetail(resIn));
                            dispatch({
                                type: "LOGIN_EMPLOYEE",
                            });
                            dispatch({
                                type: "SET_ROLENAME",
                                payload: resIn.roleName,
                            });
                            setCertNumberHandler(resIn.account);
                            dispatchRedux(authDetectorTrueAction());
                        });
                    } else {
                        dispatch({
                            type: "LOGIN_EMPLOYEE",
                        });
                        dispatch({
                            type: "SET_ROLENAME",
                            payload: userRole.roleName,
                        });
                    }
                    break;
                case "EMPLOYER":
                    if (!userRole) {
                        await getUserInfo("employer").then((resIn) => {
                            if (!userRole) {
                                setLocalStorage(userLSVar, userDetail(resIn));
                                dispatch({
                                    type: "LOGIN_EMPLOYER",
                                });
                                dispatch({
                                    type: "SET_ROLENAME",
                                    payload: resIn.roleName,
                                });
                                dispatchRedux(authDetectorTrueAction());
                            }
                        });
                    } else {
                        dispatch({
                            type: "LOGIN_EMPLOYER",
                        });
                        dispatch({
                            type: "SET_ROLENAME",
                            payload: userRole.roleName,
                        });
                    }
                    break;
                default:
                    setLocalStorage(userLSVar, null);
                    setLocalStorage(cerNmbrLSVar, null);
                    dispatch({
                        type: "LOGOUT_EMPLOYEE",
                    });
                    dispatch({
                        type: "LOGOUT_EMPLOYER",
                    });
                    break;
            }
        });
    };

    const setCertNumberHandler = (value = null) => {
        if (!value) {
            const certFromLocStorage = getValueLocalStorage(userLSVar);
            if (certFromLocStorage) {
                const certNumb =
                    certFromLocStorage.multiAccount.length > 0 &&
                    certFromLocStorage.multiAccount.map((it) => {
                        return {
                            key: it.cer_nmbr,
                            value: it.cer_nmbr,
                        };
                    });
                dispatch({
                    type: "SET_LIST_CERT_NUMBER",
                    payload: certNumb,
                });
                const certAble = getValueLocalStorage(cerNmbrLSVar);
                if (certAble) {
                    dispatch({
                        type: "SET_CERT_NUMBER",
                        payload: certAble,
                    });
                } else {
                    if (certNumb) {
                        setLocalStorage(cerNmbrLSVar, certNumb[0].key);
                        dispatch({
                            type: "SET_CERT_NUMBER",
                            payload: certNumb[0].key,
                        });
                    }
                }
            }
        } else {
            setLocalStorage(cerNmbrLSVar, value);
            dispatch({
                type: "SET_CERT_NUMBER",
                payload: value,
            });
        }
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                loginEmployee,
                logoutEmployee,
                loginEmployer,
                logoutEmployer,
                getValueLocalStorage,
                setCertNumberHandler,
                loginOtp,
                stillLogin,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
