// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_modalPopUp__rkIzE {
  margin-top: 5%;
}

.style_modalHeader__f73dO {
  width: auto !important;
  background-color: #00a758 !important;
  color: white !important;
}

@media (max-width: 726px) {
  .style_modalPopUp__rkIzE {
    width: auto !important;
    margin-top: 17%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ModalPopup/style.module.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;AADF;;AAIA;EACE,sBAAA;EACA,oCAAA;EACA,uBAAA;AADF;;AAKA;EACE;IACE,sBAAA;IACA,eAAA;EAFF;AACF","sourcesContent":["@import 'scss/for-module';\n\n.modalPopUp {\n  margin-top: 5%;  \n}\n\n.modalHeader {\n  width: auto !important;\n  background-color: $primary !important;\n  color: white !important;\n  // z-index: 1000;\n}\n\n@media (max-width: 726px) {\n  .modalPopUp{\n    width: auto !important;\n    margin-top: 17%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalPopUp": `style_modalPopUp__rkIzE`,
	"modalHeader": `style_modalHeader__f73dO`
};
export default ___CSS_LOADER_EXPORT___;
