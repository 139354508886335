import { userLSVar } from "contexts/AuthContext";
import useAuth from "hooks/useAuth";
import { useHistory } from "react-router-dom";

let timerLogOutHandler;
const timeLogOout = 600000;

const autoLogout = () => {
    const { logoutEmployee, logoutEmployer, getValueLocalStorage } = useAuth();
    const history = useHistory();

    const logOutHandler = async () => {
        const roleUser = getValueLocalStorage(userLSVar);
        if (roleUser) {
            switch (roleUser.roleName) {
                case "EMPLOYEE":
                    await logoutEmployee().then((res) => {
                        if (res) {
                            history.go(0);
                        }
                    });
                    break;
                case "EMPLOYER":
                    await logoutEmployer().then((res) => {
                        if (res) {
                            history.go(0);
                        }
                    });
                    break;
            }
        }
    };

    const timerAutoLogout = async () => {
        clearTimeout(timerLogOutHandler);
        timerLogOutHandler = setTimeout(() => {
            logOutHandler();
        }, timeLogOout);
        const event = [
            "mousedown",
            "mousemove",
            "onscroll",
            "touchmove",
            "keypress",
        ];
        for (let i = 0; i < event.length; i++) {
            document.addEventListener(
                event[i],
                () => {
                    clearTimeout(timerLogOutHandler);
                    timerLogOutHandler = setTimeout(() => {
                        logOutHandler();
                    }, timeLogOout);
                },
                false
            );
        }
    };

    return {
        timerAutoLogout: timerAutoLogout,
    };
};

export default autoLogout;
