
export const en = {
    employeeClaimTable: {
        view:"View Details",
        name:'Name',
        transcName: 'Transaction Name',
        appTime:'Application Time',
        endTime:'End Time',
        estimate:'Estimation',
        estimateComplete: 'Submission Date',
        status: 'Status',
        desc: 'Description',
        emptyTransaction:'There is no claim transaction yet'
    },
    employeeClaimDoneTable: {
        transcName: 'Transaction Name',
        timeUp: 'Proccess Date',
        taxSlip: 'Tax Withholding Slip',
        emptyClaim:'There is no claim transaction yet'
    },
    employeeClaimLabelTable: {
        label: 'Claim List'
    },
    employeeClaimLabelDoneTable: {
        label: 'Claim History'
    },
    employeeClaimLabelDetail:{
        label:"Claim Details",
        labelBupot:"Proof of Withholding Tax"
    },
    employeeClaimTopContent: {
        titlePage: {
            text1: 'Claim',
            text2: 'Information'
        },
        answerDesc: {
            text1: 'Frequently Asked Questions Submitting a Claim',
            text2: 'Manulife DPLK claim and answer questions',
            buttonText: 'More'
        }
    },
    employeeClaimFaq:'Claim FAQ'
}

export const id = {
    employeeClaimTable: {
        view:"Lihat Rincian",
        name:'Nama',
        transcName: 'Nama Transaksi',
        estimateComplete: 'Estimasi Tanggal Selesai',
        estimate:'Estimasi',
        appTime:'Waktu Pengajuan',
        endTime:'Waktu Selesai',
        status: 'Status',
        desc: 'Keterangan',
        emptyTransaction:'Belum ada transaksi klaim'
    },
    employeeClaimDoneTable: {
        transcName: 'Nama Transaksi',
        timeUp: 'Tanggal Selesai',
        taxSlip: 'Bukti Potong Pajak',
        emptyClaim:'Belum ada transaksi klaim'
    },
    employeeClaimLabelTable: {
        label: 'Daftar Klaim'
    },
    employeeClaimLabelDoneTable: {
        label: 'Riwayat Klaim'
    },
    employeeClaimLabelDetail:{
        label:"Rincian Klaim",
        labelBupot:"Bukti Potong Pajak"
    },
    employeeClaimTopContent: {
        titlePage: {
            text1: 'Informasi',
            text2: 'Klaim'
        },
        answerDesc: {
            text1: 'Tanya Jawab Pengajuan Klaim',
            text2: 'Tanya jawab pengajuan klaim DPLK Manulife',
            buttonText: 'Selengkapnya'
        }
    },
    employeeClaimFaq:'FAQ Klaim'
}
