export const GET_EMPYR_IURAN = 'GET_EMPYR_IURAN'
export const SET_EMPYR_IURAN_FILTER = 'SET_EMPYR_IURAN_FILTER'
export const LOADING_EMPYR_IURAN = 'LOADING_EMPYR_IURAN'
export const LOADING_EMPYR_IURAN_FALSE = 'LOADING_EMPYR_IURAN_FALSE'
export const RESET_EMPYR_IURAN = 'RESET_EMPYR_IURAN'

const initialState = {
    data: null,
    loading: false
}

const IuranReducer = (state = initialState, action) => {
    switch(action.type){
        case GET_EMPYR_IURAN: {
            return{
                data: action.payload,
                loading: false
            }
        }
        case LOADING_EMPYR_IURAN: {
            return {
                ...state,
                loading: true
            }
        }
        case LOADING_EMPYR_IURAN_FALSE: {
            return {
                ...state,
                loading: false
            }
        }
        case RESET_EMPYR_IURAN: {
            return {
                data: null,
                loading: false
            }
        }
        default:{
            return state
        }
    }
}

export default IuranReducer