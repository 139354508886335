export const GET_SOSMED = "GET_SOSMED";
export const LOADING = "LOADING";

const initialState = {
    data: [],
    loading: false,
};

const sosmedReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SOSMED: {
            return {
                data: action.payload,
                loading: false,
            };
        }
        case LOADING: {
            return {
                ...state,
                loading: true,
            };
        }
        default: {
            return state;
        }
    }
};

export default sosmedReducer;
