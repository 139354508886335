
export const en = {
    employerIuranTransaction: {
        pageTitle: {
            text1: 'Transaction',
            text2: 'Information'
        },
        filter: {
            searchBy: 'Search Documents By',
            searchByItem: {
                duesPremius: 'Contribution/Premi',
                cost: 'Cost'
            },
            choiceDate: 'Choose Date',
            from: 'From',
            to: 'To',
            type: 'Select Transaction Type*',
            typeItem: {
                text1: 'Payment',
                text2: 'Outstanding'
            },
            btnProcess: 'Process'
        },
        table: {
            table1: {
                paymentDate: 'Payment Date',
                desc: 'Description',
                amount: 'Credit'
            },
            table2: {
                contrib: 'Regular/Premium Contribution',
                contribAmount: 'Total bill',
                dueDate: 'Billing Date',
                paidDate: 'Paid Date',
                topUp: 'Top Up',
                fee: 'Fee'
            }
        }
    },
    employerIuranReport: {
        pageTitle: {
            text1: 'Billing',
            text2: 'Report'
        },
        filter: {
            searchBy: 'Short Summary',
            searchByItem: {
                short: 'Billing Summary',
            },
            choiceDate: 'Choose Date',
            from: 'From',
            to: 'To',
            type: 'Select Transaction Type*',
            btnProcess: 'Process'
        },
        emptyTransaction:'There is no Transaction for you yet. Please check again later'
    },
    employerConversion:{
        emptySaldo:'There is no balance for you yet. Please check again later.',
        pageTitle:'Transaction Information'
    },
    employerPrices:{
        pageTitle:'Unit price',
        emptyUnit:'There is no units for you yet. Please check again later.',
        maxPeriode:'The mutation period that can be selected is maximum 1 year',
        unduh:'Show',
        repeat: 'Reset',
        desc:''
    },
    employerDirection:{
        pageTitle:{
            text1:'Investment Referral',
            text2:'Current Investment Direction:'
        },
        marketReview:'Latest Monthly Market Review',
        fundFact:'DPLK Fund Fact Sheet'
    },
    employerInvesConversion:{
        pageTitle:'Exchange Rate (US Dollar)'
    }
}

export const id = {
    employerIuranTransaction: {
        pageTitle: {
            text1: 'Informasi',
            text2: 'Transaksi'
        },
        filter: {
            searchBy: 'Cari Dokumen Berdasarkan',
            searchByItem: {
                duesPremius: 'Iuran/Premi',
                cost: 'Biaya'
            },
            choiceDate: 'Pilih Tanggal',
            from: 'Dari Tanggal:',
            to: 'Sampai Tanggal:',
            type: 'Pilih Tipe Transaksi*',
            typeItem: {
                text1: 'Pembayaran',
                text2: 'Tertunggak'
            },
            btnProcess: 'Proses'
        },
        table: {
            table1: {
                paymentDate: 'Tanggal Pembayaran',
                desc: 'Deskripsi',
                amount: 'Kredit'
            },
            table2: {
                contrib: 'Kontribusi Reguler/Premi',
                contribAmount: 'Total Tagihan',
                dueDate: 'Tanggal Tagihan',
                paidDate: 'Jatuh Tempo',
                topUp: 'Top Up Reguler',
                fee: 'Fee'
            }
        },
        emptyTransaction:'Belum ada Transaksi baru untuk Anda. Silahkan periksa lagi nanti'
    },
    employerIuranReport: {
        pageTitle: {
            text1: 'Laporan',
            text2: 'Tagihan'
        },
        filter: {
            searchBy: 'Ringkasan Singkat',
            searchByItem: {
                short: 'Ringkasan Tagihan',
            },
            choiceDate: 'Pilih Tanggal',
            from: 'Dari Tanggal:',
            to: 'Sampai Tanggal:',
            type: 'Pilih Tipe Transaksi*',
            btnProcess: 'Proses'
        }
    },
    employerConversion:{
        emptySaldo:'Belum ada saldo untuk Anda. Silahkan periksa lagi nanti.',
        pageTitle:'Informasi Transaksi'
    },
    employerPrices:{
        pageTitle:'Harga Satuan',
        emptyUnit:'Belum ada unit untuk Anda. Silahkan periksa lagi nanti.',
        maxPeriode:'Periode mutasi yang dapat dipilih maksimal 1 tahun',
        unduh:'Tampilkan',
        repeat: 'Ulangi',
        desc:''
    },
    employerDirection:{
        pageTitle:{
            text1:'Arahan Investasi',
            text2:'Arahan Investasi saat ini:'
        },
        marketReview:'Review Pasar Bulanan Terbaru',
        fundFact:'Lembar Fakta Dana DPLK'
    },
    employerInvesConversion:{
        pageTitle:'Nilai Tukar (US Dollar)'
    }
}
