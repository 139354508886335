import React from 'react'
import clsx from 'clsx'
import { Button, Col, Container, Image, Row, Form as FormBootstrap, OverlayTrigger, Popover } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import { Form } from 'components'
import styles from './index.module.scss'

const Calculator = () => {

    const { register, handleSubmit, errors } = useForm()
    const onSubmit = _data => history.push('/employee/home')

    return (
        <Container>
            <section className={clsx(styles.wrapper)}>
                <FormBootstrap onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col>
                            <div className={clsx('text-center mb-5')}>
                                <h4>DATA</h4>
                            </div>
                            <div className={clsx(styles.col2, 'w-100')}>
                                <div className={clsx(styles.col2__item)}>Tanggal Perhitungan</div>
                                <div className={clsx(styles.col2__item)}>
                                    <Form.DateRangePicker2
                                    label={{
                                        from: {
                                        label: "Dari Tanggal",
                                        placeholder: "Pilih Tanggal"
                                        },
                                        to: {
                                        label:  "Hingga",
                                        placeholder: "Pilih Tanggal"
                                        }
                                    }}
                                    maxMonthFrom={6}
                                    type="horizontal"
                                    />
                                </div>

                            </div>
                            <div className={clsx(styles.col2, 'w-100')}>
                            <div className={clsx(styles.col2__item)}>
                                    <Form.DatePicker
                                        hasGrow
                                        label="Tanggal Lahir"
                                        placeholder="Pilih Tanggal"
                                        formGroupProps={{ className: clsx('w-100') }}
                                    />
                                </div>
                            </div>
                            <div className={clsx(styles.col2, 'w-100')}>
                                <div className={clsx(styles.col2__item)}>
                                    <Form.Input
                                        hasGrow
                                        type="text"
                                        hasValidation
                                        appendCursorHelp
                                        name="dana"
                                        label="Penghasilan Dasar Pengsiun (Rupiah)"
                                        isInvalid={!!errors.attendee_id}
                                        ref={register({ required: true })}
                                        // formGroupProps={{ as: Col, sm: 12 }}
                                        append={
                                        <OverlayTrigger
                                            trigger={['hover', 'focus']}
                                            placement="top"
                                            overlay={
                                            <Popover>
                                                <Popover.Content>
                                                Sunt veniam excepteur amet tempor deserunt voluptate sint.
                                                </Popover.Content>
                                            </Popover>
                                            }>
                                            <i className="mi-info px-1"></i>
                                        </OverlayTrigger>
                                        }
                                    />
                                </div>
                                <div className={clsx(styles.col2__item)}>
                                    <Form.Input
                                        hasGrow
                                        type="text"
                                        hasValidation
                                        appendCursorHelp
                                        name="dana"
                                        label="Tambah Dana (Jika Perlu)"
                                        isInvalid={!!errors.attendee_id}
                                        ref={register({ required: true })}
                                        // formGroupProps={{ as: Col, sm: 12 }}
                                        append={
                                        <OverlayTrigger
                                            trigger={['hover', 'focus']}
                                            placement="top"
                                            overlay={
                                            <Popover>
                                                <Popover.Content>
                                                Sunt veniam excepteur amet tempor deserunt voluptate sint.
                                                </Popover.Content>
                                            </Popover>
                                            }>
                                            <i className="mi-info px-1"></i>
                                        </OverlayTrigger>
                                        }
                                    />
                                </div>
                            </div>
                            <Form.Input
                                hasGrow
                                type="text"
                                hasValidation
                                appendCursorHelp
                                name="dana"
                                label="Dana Pada Tanggal Perhitungan (Rupiah)"
                                isInvalid={!!errors.attendee_id}
                                ref={register({ required: true })}
                                formGroupProps={{ as: Col, sm: 12 }}
                                append={
                                <OverlayTrigger
                                    trigger={['hover', 'focus']}
                                    placement="top"
                                    overlay={
                                    <Popover>
                                        <Popover.Content>
                                        Sunt veniam excepteur amet tempor deserunt voluptate sint.
                                        </Popover.Content>
                                    </Popover>
                                    }>
                                    <i className="mi-info px-1"></i>
                                </OverlayTrigger>
                                }
                            />
                        </Col>
                        <Col>
                            <div className={clsx('text-center mb-5')}>
                                <h4>ASUMSI</h4>
                            </div>
                            <div className={clsx(styles.col2, 'w-100')}>
                                <div className={clsx(styles.col2__item, 'form-group')}>
                                    <Form.Select
                                        hasGrow
                                        label="Usia Pensiun Normal (Tahun)"
                                        name="upn"
                                        underlined={true}
                                        // onChange={setLang}
                                        formGroupProps={{ className: 'm-0' }}
                                        items={[
                                            { key: '40', value: '40' },
                                            { key: '30', value: '30' },
                                        ]}
                                    />
                                </div>
                                <div className={clsx(styles.col2__item, 'form-group')}>
                                    <Form.Select
                                        hasGrow
                                        label="Bulan Kenaikan Gaji"
                                        name="upn"
                                        underlined={true}
                                        // onChange={setLang}
                                        formGroupProps={{ className: 'm-0' }}
                                        items={[
                                            { key: 'januari', value: 'Januari' },
                                            { key: 'februari', value: 'Februari' },
                                        ]}
                                    />
                                </div>
                            </div>
                            <div className={clsx(styles.col2, 'w-100')}>
                                <div className={clsx(styles.col2__item)}>
                                    <Form.Input
                                        hasGrow
                                        type="text"
                                        hasValidation
                                        appendCursorHelp
                                        name="dana"
                                        label="Iuran Perusahaan"
                                        isInvalid={!!errors.attendee_id}
                                        ref={register({ required: true })}
                                        // formGroupProps={{ as: Col, sm: 12 }}
                                        append={
                                        <OverlayTrigger
                                            trigger={['hover', 'focus']}
                                            placement="top"
                                            overlay={
                                            <Popover>
                                                <Popover.Content>
                                                Sunt veniam excepteur amet tempor deserunt voluptate sint.
                                                </Popover.Content>
                                            </Popover>
                                            }>
                                            <i className="mi-info px-1"></i>
                                        </OverlayTrigger>
                                        }
                                    />
                                </div>
                                <div className={clsx(styles.col2__item)}>
                                    <Form.Input
                                        hasGrow
                                        type="text"
                                        hasValidation
                                        appendCursorHelp
                                        name="dana"
                                        label="Iuran Karyawan"
                                        isInvalid={!!errors.attendee_id}
                                        ref={register({ required: true })}
                                        // formGroupProps={{ as: Col, sm: 12 }}
                                        append={
                                        <OverlayTrigger
                                            trigger={['hover', 'focus']}
                                            placement="top"
                                            overlay={
                                            <Popover>
                                                <Popover.Content>
                                                Sunt veniam excepteur amet tempor deserunt voluptate sint.
                                                </Popover.Content>
                                            </Popover>
                                            }>
                                            <i className="mi-info px-1"></i>
                                        </OverlayTrigger>
                                        }
                                    />
                                </div>
                            </div>
                            <div className={clsx(styles.col2, 'w-100')}>
                                <div className={clsx(styles.col2__item)}>
                                    <Form.Input
                                        hasGrow
                                        type="text"
                                        hasValidation
                                        appendCursorHelp
                                        name="dana"
                                        label="Tingkat Kenaikan Gaji / Tahun (%)"
                                        isInvalid={!!errors.attendee_id}
                                        ref={register({ required: true })}
                                        // formGroupProps={{ as: Col, sm: 12 }}
                                        append={
                                        <OverlayTrigger
                                            trigger={['hover', 'focus']}
                                            placement="top"
                                            overlay={
                                            <Popover>
                                                <Popover.Content>
                                                Sunt veniam excepteur amet tempor deserunt voluptate sint.
                                                </Popover.Content>
                                            </Popover>
                                            }>
                                            <i className="mi-info px-1"></i>
                                        </OverlayTrigger>
                                        }
                                    />
                                </div>
                                <div className={clsx(styles.col2__item)}>
                                    <Form.Select
                                        hasGrow
                                        label="Tingkat Hasil Investas / Tahun (%)"
                                        name="upn"
                                        underlined={true}
                                        // onChange={setLang}
                                        formGroupProps={{ className: 'm-0' }}
                                        items={[
                                            { key: 'Moderate', value: 'moderate' },
                                        ]}
                                    />
                                </div>
                            </div>
                            <div className={clsx(styles.col2, 'w-100')}>
                                <div className={clsx(styles.col2__item)}>
                                    <Form.Input
                                        hasGrow
                                        type="text"
                                        hasValidation
                                        appendCursorHelp
                                        name="dana"
                                        label="Biaya Pengelolaan Investasi / Tahun (%)"
                                        isInvalid={!!errors.attendee_id}
                                        ref={register({ required: true })}
                                        // formGroupProps={{ as: Col, sm: 12 }}
                                        append={
                                        <OverlayTrigger
                                            trigger={['hover', 'focus']}
                                            placement="top"
                                            overlay={
                                            <Popover>
                                                <Popover.Content>
                                                Sunt veniam excepteur amet tempor deserunt voluptate sint.
                                                </Popover.Content>
                                            </Popover>
                                            }>
                                            <i className="mi-info px-1"></i>
                                        </OverlayTrigger>
                                        }
                                    />
                                </div>
                                <div className={clsx(styles.col2__item)}>
                                    <Form.Input
                                        hasGrow
                                        type="text"
                                        hasValidation
                                        appendCursorHelp
                                        name="dana"
                                        label="Biaya Pendaftaran (Rupiah)"
                                        isInvalid={!!errors.attendee_id}
                                        ref={register({ required: true })}
                                        // formGroupProps={{ as: Col, sm: 12 }}
                                        append={
                                        <OverlayTrigger
                                            trigger={['hover', 'focus']}
                                            placement="top"
                                            overlay={
                                            <Popover>
                                                <Popover.Content>
                                                Sunt veniam excepteur amet tempor deserunt voluptate sint.
                                                </Popover.Content>
                                            </Popover>
                                            }>
                                            <i className="mi-info px-1"></i>
                                        </OverlayTrigger>
                                        }
                                    />
                                </div>
                            </div>
                            <Form.Input
                                hasGrow
                                type="text"
                                hasValidation
                                appendCursorHelp
                                name="dana"
                                label="Biaya Administrasi Setoran (Rupiah)"
                                isInvalid={!!errors.attendee_id}
                                ref={register({ required: true })}
                                formGroupProps={{ as: Col, sm: 12 }}
                                append={
                                <OverlayTrigger
                                    trigger={['hover', 'focus']}
                                    placement="top"
                                    overlay={
                                    <Popover>
                                        <Popover.Content>
                                        Sunt veniam excepteur amet tempor deserunt voluptate sint.
                                        </Popover.Content>
                                    </Popover>
                                    }>
                                    <i className="mi-info px-1"></i>
                                </OverlayTrigger>
                                }
                            />
                        </Col>
                    </Row>
                </FormBootstrap>
            </section>
        </Container>
    )
}

export default Calculator
