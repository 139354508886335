import React, { Fragment, useEffect, useState } from "react";
import axios from "utils/axios";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
    Col,
    Container,
    Row,
    Accordion,
    Button,
    ListGroup,
} from "react-bootstrap";
import styles from "./style.module.scss";
import { API_FORM } from "utils/api.constant";
import { toast } from "react-toastify";

const FormDownload = () => {
    const descPageText = useSelector((state) => state.descriptionPage);
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });
    const [dataFormDownload, setDataFormDownload] = useState({
        data1: null,
        data2: null,
    });
    const [keyState, setKeyState] = useState(null);
    const [keyStateChildOne, setKeyStateChildOne] = useState(null);
    const lang = useSelector((state) => state.language.lang);

    useEffect(() => {
        const config = {
            headers: {
                "x-accept-lang": lang,
            },
        };
        axios
            .get(API_FORM, config)
            .then((response) => {
                const responseData = response.data;
                if (responseData.statusCode === 200) {
                    const withId = responseData.data.map((it, index) => {
                        return {
                            ...it,
                            id: index,
                        };
                    });
                    const divideNumber = Math.ceil(withId.length / 2);
                    const sliceData1 = withId.slice(0, divideNumber);
                    const sliceData2 = withId.slice(
                        divideNumber,
                        withId.length
                    );
                    setDataFormDownload({
                        data1: sliceData1,
                        data2: sliceData2,
                    });
                }
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });
    }, [lang]);

    const responsiveRowRender = (content) =>
        !isTabletOrMobile ? (
            <Row className={clsx("mb-5")}>
                <Col sm={12}>{content}</Col>
            </Row>
        ) : (
            <div className={clsx("normalize-container-fluid", "mb-5 pb-5")}>
                {content}
            </div>
        );

    const handleClickDownload = (url) => {
        window.open(url, "_blank");
    };

    return (
        <Container as="section" className={clsx(styles.wrapper)} fluid>
            <h1 className="font-weight-light display-2 mb-4 mt-5">
                Unduh <span className="font-serif">Formulir</span>
            </h1>
            <Row className={clsx("mt-3")}>
                <Col md={7}>
                    <p className="pr-md-5 mr-md-5 font-weight-light">
                        {descPageText.text}
                    </p>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <h3 className="font-weight-light mt-5">Daftar Formulir</h3>
                </Col>
            </Row>

            {responsiveRowRender(
                <Accordion
                    // {...restProps}
                    as={Row}
                    activeKey={keyState}
                    onSelect={setKeyState}
                    className={clsx("align-items-start", "mt-4")}
                >
                    <div className="col-md-6">
                        {dataFormDownload.data1 &&
                            dataFormDownload.data1.map(
                                (dataForm, indexForm) => (
                                    <Col
                                        as={Row}
                                        key={dataForm.id}
                                        noGutters
                                        md={12}
                                    >
                                        <Col sm={12} className="accordion-item">
                                            <button
                                                onClick={() =>
                                                    dataForm.link &&
                                                    handleClickDownload(
                                                        dataForm.link
                                                    )
                                                }
                                                className={`${styles.btnDownload}`}
                                            >
                                                <Accordion.Toggle
                                                    as={Button}
                                                    variant="accordion"
                                                    eventKey={dataForm.id + 1}
                                                    className={clsx(
                                                        "chevron",
                                                        keyState ===
                                                            dataForm.id + 1 &&
                                                            "collapsed"
                                                    )}
                                                >
                                                    {dataForm.title}
                                                </Accordion.Toggle>
                                            </button>

                                            <Accordion.Collapse
                                                eventKey={dataForm.id + 1}
                                            >
                                                <ListGroup variant="flush">
                                                    {dataForm.sub.map(
                                                        (sub, indexSubOne) => {
                                                            return (
                                                                <Fragment>
                                                                    {sub.link && (
                                                                        <ListGroup.Item
                                                                            onClick={() =>
                                                                                handleClickDownload(
                                                                                    sub.link
                                                                                )
                                                                            }
                                                                            style={{
                                                                                cursor: "pointer",
                                                                            }}
                                                                        >
                                                                            <i
                                                                                className={clsx(
                                                                                    "mi-download lg",
                                                                                    "vertical-align-text-bottom"
                                                                                )}
                                                                            ></i>{" "}
                                                                            {
                                                                                sub.title
                                                                            }
                                                                        </ListGroup.Item>
                                                                    )}

                                                                    {!sub.link && (
                                                                        <Accordion
                                                                            activeKey={
                                                                                keyStateChildOne
                                                                            }
                                                                            onSelect={
                                                                                setKeyStateChildOne
                                                                            }
                                                                        >
                                                                            <Col
                                                                                key={
                                                                                    indexSubOne
                                                                                }
                                                                                sm={
                                                                                    12
                                                                                }
                                                                                className="accordion-item pr-0"
                                                                            >
                                                                                <Accordion.Toggle
                                                                                    as={
                                                                                        Button
                                                                                    }
                                                                                    variant="accordion"
                                                                                    eventKey={
                                                                                        indexSubOne +
                                                                                        1
                                                                                    }
                                                                                    className={clsx(
                                                                                        "chevron",
                                                                                        keyStateChildOne ===
                                                                                            indexSubOne +
                                                                                                1 &&
                                                                                            "collapsed"
                                                                                    )}
                                                                                >
                                                                                    {
                                                                                        sub.title
                                                                                    }
                                                                                </Accordion.Toggle>

                                                                                {sub.sub.map(
                                                                                    (
                                                                                        subTwo,
                                                                                        indexSubTwo
                                                                                    ) => {
                                                                                        return (
                                                                                            <Accordion.Collapse
                                                                                                key={
                                                                                                    indexSubTwo
                                                                                                }
                                                                                                eventKey={
                                                                                                    indexSubOne +
                                                                                                    1
                                                                                                }
                                                                                            >
                                                                                                <ListGroup variant="flush">
                                                                                                    <ListGroup.Item
                                                                                                        onClick={() =>
                                                                                                            handleClickDownload(
                                                                                                                subTwo.link
                                                                                                            )
                                                                                                        }
                                                                                                        style={{
                                                                                                            cursor: "pointer",
                                                                                                        }}
                                                                                                    >
                                                                                                        <i
                                                                                                            className={clsx(
                                                                                                                "mi-download lg",
                                                                                                                "vertical-align-text-bottom"
                                                                                                            )}
                                                                                                        ></i>{" "}
                                                                                                        {
                                                                                                            subTwo.title
                                                                                                        }
                                                                                                    </ListGroup.Item>
                                                                                                </ListGroup>
                                                                                            </Accordion.Collapse>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </Col>
                                                                        </Accordion>
                                                                    )}
                                                                </Fragment>
                                                            );
                                                        }
                                                    )}
                                                </ListGroup>
                                            </Accordion.Collapse>
                                        </Col>
                                    </Col>
                                )
                            )}
                    </div>
                    <div className="col-md-6">
                        {dataFormDownload.data2 &&
                            dataFormDownload.data2.map(
                                (dataForm, indexForm) => (
                                    <Col
                                        as={Row}
                                        key={dataForm.id}
                                        noGutters
                                        md={12}
                                    >
                                        <Col sm={12} className="accordion-item">
                                            <button
                                                onClick={() =>
                                                    dataForm.link &&
                                                    handleClickDownload(
                                                        dataForm.link
                                                    )
                                                }
                                                className={`${styles.btnDownload}`}
                                            >
                                                <Accordion.Toggle
                                                    as={Button}
                                                    variant="accordion"
                                                    eventKey={dataForm.id + 1}
                                                    className={clsx(
                                                        "chevron",
                                                        keyState ===
                                                            dataForm.id + 1 &&
                                                            "collapsed"
                                                    )}
                                                >
                                                    {dataForm.title}
                                                </Accordion.Toggle>
                                            </button>

                                            <Accordion.Collapse
                                                eventKey={dataForm.id + 1}
                                            >
                                                <ListGroup variant="flush">
                                                    {dataForm.sub.map(
                                                        (sub, indexSubOne) => {
                                                            return (
                                                                <Fragment>
                                                                    {sub.link && (
                                                                        <ListGroup.Item
                                                                            onClick={() =>
                                                                                handleClickDownload(
                                                                                    sub.link
                                                                                )
                                                                            }
                                                                            style={{
                                                                                cursor: "pointer",
                                                                            }}
                                                                        >
                                                                            <i
                                                                                className={clsx(
                                                                                    "mi-download lg",
                                                                                    "vertical-align-text-bottom"
                                                                                )}
                                                                            ></i>{" "}
                                                                            {
                                                                                sub.title
                                                                            }
                                                                        </ListGroup.Item>
                                                                    )}

                                                                    {!sub.link && (
                                                                        <Accordion
                                                                            activeKey={
                                                                                keyStateChildOne
                                                                            }
                                                                            onSelect={
                                                                                setKeyStateChildOne
                                                                            }
                                                                        >
                                                                            <Col
                                                                                key={
                                                                                    indexSubOne
                                                                                }
                                                                                sm={
                                                                                    12
                                                                                }
                                                                                className="accordion-item pr-0"
                                                                            >
                                                                                <Accordion.Toggle
                                                                                    as={
                                                                                        Button
                                                                                    }
                                                                                    variant="accordion"
                                                                                    eventKey={
                                                                                        indexSubOne +
                                                                                        1
                                                                                    }
                                                                                    className={clsx(
                                                                                        "chevron",
                                                                                        keyStateChildOne ===
                                                                                            indexSubOne +
                                                                                                1 &&
                                                                                            "collapsed"
                                                                                    )}
                                                                                >
                                                                                    {
                                                                                        sub.title
                                                                                    }
                                                                                </Accordion.Toggle>

                                                                                {sub.sub.map(
                                                                                    (
                                                                                        subTwo,
                                                                                        indexSubTwo
                                                                                    ) => {
                                                                                        return (
                                                                                            <Accordion.Collapse
                                                                                                key={
                                                                                                    indexSubTwo
                                                                                                }
                                                                                                eventKey={
                                                                                                    indexSubOne +
                                                                                                    1
                                                                                                }
                                                                                            >
                                                                                                <ListGroup variant="flush">
                                                                                                    <ListGroup.Item
                                                                                                        onClick={() =>
                                                                                                            handleClickDownload(
                                                                                                                subTwo.link
                                                                                                            )
                                                                                                        }
                                                                                                        style={{
                                                                                                            cursor: "pointer",
                                                                                                        }}
                                                                                                    >
                                                                                                        <i
                                                                                                            className={clsx(
                                                                                                                "mi-download lg",
                                                                                                                "vertical-align-text-bottom"
                                                                                                            )}
                                                                                                        ></i>{" "}
                                                                                                        {
                                                                                                            subTwo.title
                                                                                                        }
                                                                                                    </ListGroup.Item>
                                                                                                </ListGroup>
                                                                                            </Accordion.Collapse>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </Col>
                                                                        </Accordion>
                                                                    )}
                                                                </Fragment>
                                                            );
                                                        }
                                                    )}
                                                </ListGroup>
                                            </Accordion.Collapse>
                                        </Col>
                                    </Col>
                                )
                            )}
                    </div>
                </Accordion>
            )}
        </Container>
    );
};

export default FormDownload;
