export const GET_EMPYE_PROFIL = 'GET_EMPYE_PROFIL'
export const GET_EMPYE_CARD = 'GET_EMPYE_CARD'
export const LOADING_EMPYE_PROFIL = 'LOADING_EMPYE_PROFIL'
export const HIDE_LOADING_EMPYE_PROFIL = 'HIDE_LOADING_EMPYE_PROFIL'
const initialState = {
    data: null,
    ecard: null,
    loading: false
}

const ProfilReducer = (state = initialState, action) => {
    switch(action.type){
        case GET_EMPYE_PROFIL: {
            return{
                ...state,
                data: action.payload,
                loading: false
            }
        }
        case GET_EMPYE_CARD: {
            return{
                ...state,
                ecard: action.payload,
                loading: false
            }
        }
        case LOADING_EMPYE_PROFIL: {
            return {
                ...state,
                loading: true
            }
        }
        case HIDE_LOADING_EMPYE_PROFIL: {
            return {
                ...state,
                loading: false
            }
        }
        default:{
            return state
        }
    }
}

export default ProfilReducer