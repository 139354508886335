
export const en = {
    employerBallance: {
        companyAccount: {
            pageTitle: {
                text1: 'Balance Information ',
                text2: 'Information'
            },
            box: {
                saldoAmount: {
                    label: 'Total Balance',
                    date: 'Balance by date',
                    toolTipsResult: 'The correct data refers to the data listed on our system',
                },
                accumulate: {
                    label: 'Accumulated Contribution/Premium ',
                    toolTipsAccumulate: 'Total contributions/premiums that employers and/or participants have paid in a certain period',
                },
                result: {
                    label: 'Earning',
                    toolTipsResult: 'The return earned on the investment of funds available in the balance within a certain period',
                },
                withdrawl: {
                    label: 'Accumulated Withdrawn Funds',
                    toolTipsWithdrawl: 'A portion of funds withdrawn from available balances in a certain period (Fund Withdrawal / Correction)',
                },
                cost: {
                    label: 'Fee',
                    toolTipsCost: 'Fees charged for administrative and investment services in a certain period',
                }
            },
            filter: {
                searchBy: 'Search Documents By',
                date: 'Up to Date',
                docType: 'Select Document Type*',
                chooseDate: 'Choose Date'
            }
        },
        companyParticipant: {
            pageTitle: {
                text1: 'Participant Balance',
                text2: 'Information'
            },
            filter: {
                participant: {
                    label: 'Enter Participant Number*',
                    placeholder: 'example: 1234567',
                    error: '*Participant Number Not Registered'
                },
                date: {
                    label: 'Up to Date'
                }
            }
        },
        btnProcess: 'Process',
        docHistory: {
            label: 'Document History',
            toolTip: 'Document History'
        },
        emptyDocument:'There is no documentary history for you yet. Please check again later',
        filter: {
            searchDoc: {
                total: 'Total',
                perType: 'Per Contribution Type',
                perInvest: 'Per Investment Type'
            }
        },
        modalReportMoney: {
            form: {
                year: {
                    label: 'Year',
                    placeholder: 'Select Year'
                },
                reportPeriod: {
                    label: 'Periode Laporan',
                    placeholder: 'Select Report Period'
                },
                period: {
                    label: 'Period',
                    placeholder: 'Select Period'
                }
            },
            title: "Financial Statements",
            desc: "A Financial Statement is a report that contains accumulated balances and transactions that occur within a 6-month period. You can request a Financial Statement up to 2 years back."
        },
        ballanceSummary: {
            title: {
                text1: "Summary of Account",
                text2: "Statements"
            },
            buttonProcess: 'Process'
        }
     }
}

export const id = {
    employerBallance: {
       companyAccount: {
           pageTitle: {
               text1: 'Informasi Saldo',
               text2: 'Perusahaan'
           },
           box: {
               saldoAmount: {
                   label: 'Saldo Total',
                   date: 'Saldo per tanggal',
                   toolTipsResult: 'Data yang benar adalah data yang tersedia pada sistem kami',
               },
               accumulate: {
                   label: 'Akumulasi Kontribusi/Premi',
                   toolTipsAccumulate: 'Total kontribusi/premi yang telah dibayar oleh pemberi kerja dan/atau peserta dalam satu periode tertentu',
               },
               result: {
                   label: 'Hasil Pengembangan (Net)',
                   toolTipsResult: 'Nominal yang ditampilkan adalah hasil pengembangan setelah dikurangi dengan biaya. Detail biaya dapat diakses melalui fitur Cari Dokumen yang tersedia pada kolom di bawah ini',
               },
               withdrawl: {
                   label: 'Akumulasi Penarikan Dana ',
                   toolTipsWithdrawl: 'Sebagian dana yang dicairkan dari saldo yang tersedia dalam satu periode tertentu (Penarikan Dana / Koreksi)',
               },
               cost: {
                   label: 'Biaya',
                   toolTipsCost: 'Biaya yang dikenakan atas layanan administrasi dan investasi dalam satu periode tertentu',
               }
           },
           filter: {
               searchBy: 'Cari Dokumen Berdasarkan',
               date: 'Sampai Dengan Tanggal',
               docType: 'Pilih Tipe Dokumen*',
               chooseDate: 'Pilih Tanggal'
           }
       },
       companyParticipant: {
            pageTitle: {
                text1: 'Informasi Saldo',
                text2: 'Peserta'
            },
            filter: {
                participant: {
                    label: 'Masukkan Nomor Peserta*',
                    placeholder: 'contoh: 1234567',
                    error: '*Nomor Peserta Tidak Terdaftar'
                },
                date: {
                    label: 'Sampai Dengan Tanggal'
                }
            }
       },
        btnProcess: 'Proses',
        docHistory: {
           label: 'Riwayat Dokumen',
           toolTip: 'Riwayat Dokumen'
        },
        emptyDocument:'Belum ada riwayat dokumen untuk Anda. Silahkan periksa lagi nanti.',
        filter: {
            searchDoc: {
                total: 'Total',
                perType: 'Per Jenis Kontribusi',
                perInvest: 'Per Jenis Investasi'
            }
        },
        modalReportMoney: {
            form: {
                year: {
                    label: 'Tahun',
                    placeholder: 'Pilih Tahun'
                },
                reportPeriod: {
                    label: 'Periode Laporan',
                    placeholder: 'Pilih Periode Laporan'
                },
                period: {
                    label: 'Periode',
                    placeholder: 'Pilih Periode'
                }
            },
            title: "Ringkasan Laporan Rekening",
            desc: "Laporan Ringkasan Keuangan adalah laporan yang berisi akumulasi saldo dan transaksi yang terjadi dalam periode per 6 bulan. Anda dapat meminta Laporan Ringkasan Keuangan hingga kurun waktu 2 tahun ke belakang. Jika tidak tersedia harap hubungi Customer Service Manulife Indonesia."
        },
        ballanceSummary: {
            title: {
                text1: "Ringkasan Laporan",
                text2: "Rekening"
            },
            buttonProcess: 'Proses'
        }
    }
}
