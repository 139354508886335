// @flow
import * as React from "react";
import { Button, Fade } from "react-bootstrap";
import { Calendar, Form } from "components";
import { has, isEmpty, isNull } from "lodash";
import { useLocaleUtils } from "lib/shared/hooks";
import { usePopper } from "react-popper";
import clsx from "clsx";

const PeriodPicker = ({
    calendarClassName,
    isRtl,
    locale,
    maxRange,
    value,
    hasGrow,
    ...restProps
}) => {
    const calendarElement = React.useRef(null);
    const { getToday } = useLocaleUtils(locale);
    const today = getToday();
    const initialState = {
        activeDate: {
            from: {
                ...today,
                day: 1,
                month: null,
            },
            to: {
                ...today,
                day: 1,
                month: null,
            },
        },
    };

    const [mainState, setMainState] = React.useState({ ...initialState });
    const [inputElement, setInputElement] = React.useState(null);
    const [referenceElement, setReferenceElement] = React.useState(null);
    const [popperElement, setPopperElement] = React.useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: [0, 8],
                },
            },
        ],
    });

    const [parsedValue, setParsedValue] = React.useState("");

    const [calendarVisibility, setCalendarVisiblity] = React.useState(false);

    const setMonth = (monthNumber, target = "from") =>
        !!monthNumber &&
        setMainState({
            ...mainState,
            activeDate: {
                ...mainState.activeDate,
                [target]: {
                    ...mainState.activeDate.from,
                    month: monthNumber,
                },
            },
        });

    const selectMonth = (newMonthNumber) => {
        if (
            !isNull(mainState.activeDate.from.month) &&
            !isNull(mainState.activeDate.to.month)
        )
            return setMainState({
                ...mainState,
                activeDate: {
                    ...mainState.activeDate,
                    from: {
                        ...mainState.activeDate.from,
                        month: newMonthNumber,
                    },
                    to: {
                        ...mainState.activeDate.to,
                        month: null,
                    },
                },
            });

        if (
            mainState.activeDate.from.month &&
            isEmpty(mainState.activeDate.to.month)
        ) {
            if (newMonthNumber === mainState.activeDate.from.month) return;
            return setMonth(newMonthNumber, "to");
        }

        if (isEmpty(mainState.activeDate.from.month))
            return setMonth(newMonthNumber);
    };

    const applibleMaxRange = maxRange - 1;
    const minDate = {
        ...today,
        day: 1,
        month: !isNull(mainState.activeDate.from.month)
            ? mainState.activeDate.from.month - applibleMaxRange
            : 1,
    };

    const maxDate = {
        ...today,
        day: 1,
        month: !isNull(mainState.activeDate.from.month)
            ? mainState.activeDate.from.month + applibleMaxRange
            : 12,
    };

    const handleCloseCalendar = (e) => {
        setCalendarVisiblity(false);
    };

    const isFilled =
        !isNull(mainState.activeDate.from.month) &&
        !isNull(mainState.activeDate.to.month);

    React.useLayoutEffect(() => {
        const eventHandler = (event) => {
            if (referenceElement && !referenceElement.contains(event.target)) {
                if (isNull(popperElement)) handleCloseCalendar(event);
                else if (popperElement && !popperElement.contains(event.target))
                    handleCloseCalendar(event);
            }
        };

        document.addEventListener("click", eventHandler);

        return () => {
            document.removeEventListener("click", eventHandler);
        };
    }, [inputElement, popperElement, referenceElement]);

    React.useEffect(() => {
        if (isFilled) {
            const from = window
                .moment({
                    ...mainState.activeDate.from,
                    month: mainState.activeDate.from.month - 1,
                })
                .format("MMMM");

            const to = window
                .moment({
                    ...mainState.activeDate.to,
                    month: mainState.activeDate.to.month - 1,
                })
                .format("MMMM");

            setParsedValue(`${from} - ${to}`);
            setCalendarVisiblity(false);
        } else setParsedValue("");
    }, [mainState, isFilled]);

    return (
        <>
            <Form.Input
                {...restProps}
                append={
                    <i
                        className="mi-chevron-down sm -for-btn"
                        onClick={() => !!inputElement && inputElement.focus()}
                    ></i>
                }
                hasGrow={hasGrow}
                placeholder={
                    has(restProps, "placeholder")
                        ? restProps.placeholder
                        : "Pick a Date"
                }
                formGroupProps={{
                    ...restProps.formGroupProps,
                    className: clsx("mb-0", restProps.className),
                    ref: setReferenceElement,
                }}
                readOnly
                value={parsedValue}
                ref={setInputElement}
                onFocus={() => setCalendarVisiblity(true)}
            />
            <Fade in={calendarVisibility} unmountOnExit>
                <div
                    ref={setPopperElement}
                    style={{ ...styles.popper, display: "flex", zIndex: 9 }}
                    {...attributes.popper}
                >
                    <div
                        role="grid"
                        ref={calendarElement}
                        className={clsx(
                            "calendar",
                            "shadow-lg",
                            "-month-only",
                            "-no-focus-outline",
                            isRtl ? "-rtl" : "-ltr",
                            calendarClassName
                        )}
                    >
                        <Calendar.MonthSelector
                            isOpen
                            activeDate={mainState.activeDate}
                            onMonthSelect={selectMonth}
                            minimumDate={minDate}
                            maximumDate={maxDate}
                            locale={locale}
                        />
                        <Fade in={isFilled} unmountOnExit>
                            <Button
                                variant="outline-primary"
                                size="sm"
                                className="reset-button"
                                onClick={() =>
                                    setMainState({ ...initialState })
                                }
                            >
                                Reset
                            </Button>
                        </Fade>
                    </div>
                </div>
            </Fade>
        </>
    );
};

PeriodPicker.defaultProps = {
    maxRange: 12,
    value: { from: null, to: null },
    locale: "en",
};

export default React.memo(PeriodPicker);
