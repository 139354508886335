// @flow
import clsx from 'clsx'
import * as React from 'react'
import BadgeBase from 'react-bootstrap/Badge'

const Badge = ({ size, disabled, ...restProps }) => {
  return (
    <BadgeBase
      {...restProps}
      className={clsx(restProps.className, !!size && `badge-${size}`, disabled && `badge-disabled`)}
    />
  )
}

export default Badge
