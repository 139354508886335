import { useState, useEffect } from "react";
import clsx from "clsx";
import {
    Row,
    Accordion as AccrodianBootstrap,
    Col,
    Button,
    ListGroup,
} from "react-bootstrap";

const AboutEBClick = ({ data: dt }) => {
    const [data, setData] = useState({
        data1: null,
        data2: null,
    });
    const [keyState, setKeyState] = useState(null);

    const dataListTransform = (dtls) => {
        const lengthData = Math.ceil(dtls.length / 2);
        if (lengthData > 1) {
            const data1 = dtls.slice(0, lengthData);
            const data2 = dtls.slice(lengthData, dtls.length);
            setData({
                data1,
                data2,
            });
        } else {
            const data1 = dtls.slice(0, lengthData);
            const data2 = null;
            setData({
                data1,
                data2,
            });
        }
    };

    useEffect(() => {
        dataListTransform(dt);
    }, [dt]);

    return (
        <>
            {/* New Update from Business */}
            {data.data1 && data.data1.map((dataForm) => (
                <>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: dataForm.content,
                                            }}>
                    </div>
                </>
            ))}
                       
            
            {/* Old AGIT */}
            {/* <AccrodianBootstrap
                // {...restProps}
                as={Row}
                activeKey={keyState}
                onSelect={setKeyState}
                className={clsx("align-items-start", "mt-4", )}
            >
                <Col as={Row} noGutters md={12}>
                    {data.data1 &&
                        data.data1.map((dataForm, indexForm) => (
                            <Col
                                key={indexForm + 100}
                                md={12}
                                className="accordion-item"
                            >
                                <AccrodianBootstrap.Toggle
                                    as={Button}
                                    variant="accordion"
                                    eventKey={dataForm.id + 1}
                                    className={clsx(
                                        "chevron",
                                        keyState === dataForm.id + 1 &&
                                            "collapsed"
                                    )}
                                >
                                    {dataForm.title}
                                </AccrodianBootstrap.Toggle>

                                <AccrodianBootstrap.Collapse
                                    eventKey={dataForm.id + 1}
                                >
                                    <ListGroup variant="flush">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: dataForm.content,
                                            }}
                                        ></div>
                                    </ListGroup>
                                </AccrodianBootstrap.Collapse>
                            </Col>
                        ))}
                </Col>
                <Col as={Row} noGutters md={12}>
                    {data.data2 &&
                        data.data2.map((dataForm, indexForm) => (
                            
                            <Col
                                key={indexForm + 500}
                                md={12}
                                className="accordion-item"
                            >
                                <AccrodianBootstrap.Toggle
                                    as={Button}
                                    variant="accordion"
                                    eventKey={dataForm.id + 1}
                                    className={clsx(
                                        "chevron",
                                        keyState === dataForm.id + 1 &&
                                            "collapsed"
                                    )}
                                >
                                    {dataForm.title}
                                </AccrodianBootstrap.Toggle>

                                <AccrodianBootstrap.Collapse
                                    eventKey={dataForm.id + 1}
                                >
                                    <ListGroup variant="flush">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: dataForm.content,
                                            }}
                                        ></div>
                                    </ListGroup>
                                </AccrodianBootstrap.Collapse>
                            </Col>
                        ))}
                </Col>
            </AccrodianBootstrap> */}
        </>
    );
};

export default AboutEBClick;
