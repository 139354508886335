// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_loading__v8rNO {
  z-index: 10;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.669);
  min-height: 300px;
}

.style_pagination__f4cFn {
  z-index: 20;
}

.style_wrapper__ODHG\\+ {
  min-height: 370px;
}`, "",{"version":3,"sources":["webpack://./src/components/AuthWrapper/style.module.scss"],"names":[],"mappings":"AACA;EACI,WAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,4CAAA;EACA,iBAAA;AAAJ;;AAGA;EACI,WAAA;AAAJ;;AAGA;EACI,iBAAA;AAAJ","sourcesContent":["\n.loading{\n    z-index: 10;\n    top: 0;\n    left: 0;\n    height: 100%;\n    background-color:  rgba(255, 255, 255, 0.669);\n    min-height: 300px;\n}\n\n.pagination{\n    z-index: 20;\n}\n\n.wrapper{\n    min-height: 370px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": `style_loading__v8rNO`,
	"pagination": `style_pagination__f4cFn`,
	"wrapper": `style_wrapper__ODHG+`
};
export default ___CSS_LOADER_EXPORT___;
