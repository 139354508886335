
export const en = {
    employeeBallance: {
        pageTitle: {
            text1: 'Balance',
            text2: 'Information'
        },
        buttonMore: 'More',
        card: {
            accountBallance: {
                label: 'Your Account Balance',
                descDate: 'Balance by date'
            },
            accumulation: {
                label: 'Accumulated Contribution',
                toolTip: 'Accumulated Contribution'
            },
            withDraw: {
                label: 'Withdrawn funds',
                toolTip: 'Withdrawn funds'
            },
            result: {
                label: 'Earning',
                toolTip: 'Earning'
            },
            cost: {
                label: 'Fee',
                toolTip: 'Fee'
            }
        },
        filter: {
            form: {
                from: 'From',
                to: 'To',
                max: 'The mutation period that can be selected is maximum 6 (six) months',
                choiceDate: 'Choose Date'
            },
            btnShow: 'Show'
        },
        tabs: {
            tansactionDetail: 'Transaction Detail',
            investDetail: 'Investment Detail'
        },
        table: {
            transaction: {
                date: 'Date',
                type: 'Due Type',
                desc: 'Transaction Type',
                debit: 'Debit',
                credit: 'Credit'
            },
            invesment: {
                type_nm: 'Investment Type',
                total: 'Total',
                tambahan: 'Top Up',
                pesrta: 'Participant Balance',
                pengalihan_dana: 'Transfer of Funds',
                pemberi_kerja: 'Employer Balance'
            }

        },
        transaction: {
            desc: 'This feature helps you to check all transaction details on your account within a certain period that you can choose.'
        },
        emptyInvestation:'There is no transaction yet',
        modalReportMoney: {
            form: {
                year: {
                    label: 'Year',
                    placeholder: 'Choose Year'
                },
                reportPeriod: {
                    label: 'Report Period (month)',
                    placeholder: 'Choose Report Period'
                },
                period: {
                    label: 'Period',
                    placeholder: 'Choose Period'
                }
            },
            buttonProcess: 'Process'
        }
    }
}

export const id = {
    employeeBallance: {
        pageTitle: {
            text1: 'Informasi',
            text2: 'Saldo'
        },
        buttonMore: 'Selengkapnya',
        card: {
            accountBallance: {
                label: 'Saldo Rekening Anda',
                descDate: 'Saldo per tanggal'
            },
            accumulation: {
                label: 'Akumulasi Kontribusi',
                toolTip: 'Akumulasi Kontribusi'
            },
            withDraw: {
                label: 'Penarikan Dana',
                toolTip: 'Penarikan Dana'
            },
            result: {
                label: 'Penghasilan',
                toolTip: 'Penghasilan'
            },
            cost: {
                label: 'Biaya',
                toolTip: 'Biaya'
            }
        },
        filter: {
            form: {
                from: 'Dari Tanggal',
                to: 'Hingga',
                max: 'Periode mutasi yang dapat dipilih maksimal 6 (enam) bulan',
                choiceDate: 'Pilih Tanggal'
            },
            btnShow: 'Tampilkan'
        },
        tabs: {
            tansactionDetail: 'Rincian Transaksi',
            investDetail: 'Komposisi Investasi'
        },
        table: {
            transaction: {
                date: 'Tanggal',
                type: 'Jenis Iuran',
                desc: 'Jenis Transaksi',
                debit: 'Debit',
                credit: 'Kredit',
            },
            invesment: {
                type_nm: 'Jenis Investasi',
                total: 'Total',
                tambahan: 'Top Up',
                pesrta: 'Saldo Peserta',
                pengalihan_dana: 'Pengalihan Dana',
                pemberi_kerja: 'Saldo Pemberi Kerja'
            }
        },
        transaction: {
            desc: 'Fitur ini membantu Anda untuk memeriksa seluruh rincian transaksi pada rekening Anda dalam periode tertentu yang dapat Anda pilih.'
        },
        emptyInvestation:'Belum ada transaksi',
        modalReportMoney: {
            form: {
                year: {
                    label: 'Tahun',
                    placeholder: 'Pilih Tahun'
                },
                reportPeriod: {
                    label: 'Periode Laporan (bulan)',
                    placeholder: 'Pilih Periode Laporan'
                },
                period: {
                    label: 'Periode',
                    placeholder: 'Pilih Periode'
                }
            },
            buttonProcess: 'Proses'
        }
    }
}
