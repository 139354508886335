export const GET_EMPYR_CLAIM = 'GET_EMPYR_CLAIM'
export const LOADING_EMPYR_CLAIM = 'LOADING_EMPYR_CLAIM'
export const LOADING_EMPYR_CLAIM_FALSE = 'LOADING_EMPYR_CLAIM_FALSE'

const initialState = {
    data: null,
    loading: false,
    page: 1,
    pageCount: 1
}

const ClaimReducer = (state = initialState, action) => {
    switch(action.type){
        case GET_EMPYR_CLAIM: {
            return{
                data: action.payload,
                loading: false
            }
        }
        case LOADING_EMPYR_CLAIM: {
            return {
                ...state,
                loading: true
            }
        }
        case LOADING_EMPYR_CLAIM_FALSE: {
            return {
                ...state,
                loading: false
            }
        }
        default:{
            return state
        }
    }
}

export default ClaimReducer