// @flow

import * as React from "react";
import { usePlugins } from "lib/plugins";
import { BrowserRouter } from 'react-router-dom'
import { ReactNotifications } from "react-notifications-component";
import RouterView from "router";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

import { Store } from "store";
import { AuthProvider } from "contexts/AuthContext";
import "./lib/translation/i18n";

import "react-toastify/dist/ReactToastify.css";

const App = () => {
    const pluginApplied = usePlugins();
    const PUBLIC_PATH = process.env.REACT_APP_BASEURL

    return (
        <Provider store={Store}>
            <ToastContainer />
            <AuthProvider>
                <BrowserRouter basename={PUBLIC_PATH}>
                    <ReactNotifications isMobile />
                    {pluginApplied && <RouterView />}
                </BrowserRouter>
            </AuthProvider>
        </Provider>
    );
};

export default App;
