import * as React from "react";
import ReactDOM from "react-dom";

// App
import App from "./App.jsx";

// Styles
import "./scss/main.scss";

ReactDOM.render(
    <App />,
    document.getElementById("__manulifeApp")
);

// Measurement Performant
// import reportWebVitals from './reportWebVitals'
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)
