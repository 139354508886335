export const GET_NOTIFICATION = 'GET_NOTIFICATION'
export const LOADING_NOTIFICATION = 'LOADING_NOTIFICATION'
export const PAGINATION_NOTIFICATION = 'PAGINATION_NOTIFICATION'
export const RELOAD_NOTIF = 'RELOAD_NOTIF'
const initialState = {
    data: null,
    loading: false
}

const NotificationsReducer = (state = initialState, action) => {
    switch(action.type){
        case GET_NOTIFICATION: {
            return{
                data: action.payload,
                loading: false
            }
        }
        case PAGINATION_NOTIFICATION: {
            return{
                data: action.payload,
                loading: false
            }
        }
        case LOADING_NOTIFICATION: {
            return{
                ...state,
                loading: true
            }
        }
        case RELOAD_NOTIF:{
            return{
                ...state,
                reload:action.payload
            }
        }
        default:{
            return state
        }
    }
}

export default NotificationsReducer
