/* eslint-disable no-empty-pattern */
// @flow
import clsx from 'clsx'
import * as React from 'react'
import {
  Billboard,
  Accordion,
  Form,
  DataTable,
  Button,
  TableWrapper
} from 'components'
import {
  Col,
  Container,
  Row,
  Card,
  Form as FormBootstrap
} from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ReactComponent as SVGSmile } from 'assets/images/svgs/smile.svg'

import { getTransactionTabelAction, resetAction } from 'store/actions/employer/iuranActions'
import { getFaqDataActions } from 'store/actions/faqActions'

// Styles
// import styles from './style.module.scss'

const Transactions = ({ }) => {

  const dispatch = useDispatch()
  const { register, handleSubmit, setValue } = useForm()
  const { t } = useTranslation()
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })
  const [dataValue, setDateValue] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState('IURAN_ATAU_PREMI')
  const [btnLoading, setBtnLoading] = React.useState(false)
  const [titleTable, setTitleTable] = React.useState('')
  const [radioTransac, setRadioTransac] = React.useState('PEMBAYARAN')
  const [filterDate, setFilterDate] = React.useState({
    from: { day: 19, month: 1, year: 2022 },
    to: { day: 20, month: 4, year: 2022 },
  })
  const [faq, setFaq] = React.useState(null)
  const descPageText = useSelector(state => state.descriptionPage)
  const loading = useSelector(state => state.employerIuran.loading)
  const lang = useSelector(state => state.language.lang)
  const [tableDataTransac, setTableDataTransac] = React.useState(null)

  const headerPembayaranTable = [
    { title: t('employerIuranTransaction.table.table1.paymentDate'), name: 'paid', attrs: { width: 100 } },
    { title: t('employerIuranTransaction.table.table1.desc'), name: 'transaction_description', attrs: { width: 400 } },
    { title: t('employerIuranTransaction.table.table1.amount'), name: 'credit', attrs: { width: 200 } }
  ]

  const headerTertunggakTable = [
    { title: t('employerIuranTransaction.table.table2.dueDate'), name: 'due_date', attrs: { width: 200 } },
    { title: t('employerIuranTransaction.table.table2.paidDate'), name: 'efctv_dt', attrs: { width: 200 } },
    { title: t('employerIuranTransaction.table.table2.contrib'), name: 'cntibsi', attrs: { width: 400 } },
    { title: t('employerIuranTransaction.table.table2.topUp'), name: 'top_up', attrs: { width: 230 } },
    { title: t('employerIuranTransaction.table.table2.contribAmount'), name: 'cntrb_amt', attrs: { width: 300 } },
  ]

  const headerTertunggakBiayaTable = [
    { title: t('employerIuranTransaction.table.table2.dueDate'), name: 'due_date', attrs: { width: 200 } },
    { title: t('employerIuranTransaction.table.table2.paidDate'), name: 'efctv_dt', attrs: { width: 200 } },
    { title: t('employerIuranTransaction.table.table2.fee'), name: 'fee', attrs: { width: 230 } },
  ]

  const [tableHeaderData, setTableHeaderData] = React.useState([])

  const getFaq = async (group) => {
    await dispatch(getFaqDataActions(group))
      .then(
        res => {
          setFaq(res.data)
        }
      )
  }

  const getTransactionRadioHandler = React.useCallback((e) => {
    setValue('trans_type', e.target.value)
    setRadioTransac(e.target.value)
  }, [setRadioTransac])

  const docsBaseHandler = (e) => {
    setValue('search_by', e)
    setSearchBy(e)
  }

  const responsiveRowRender = content =>
    !isTabletOrMobile ? (
      <Row className={clsx('mb-5')}>
        <Col sm={12}>{content}</Col>
      </Row>
    ) : (
      <div className={clsx('normalize-container-fluid', 'mb-5')}>{content}</div>
    )

  const changeDateHandler = React.useCallback((e) => {
    setFilterDate(e)
    setValue('from', `${e.from.year}-${e.from.month >= 10 ? e.from.month : '0' + e.from.month}-${e.from.day >= 10 ? e.from.day : '0' + e.from.day}`)
    setValue('to', `${e.to.year}-${e.to.month >= 10 ? e.to.month : '0' + e.to.month}-${e.to.day >= 10 ? e.to.day : '0' + e.to.day}`)
  }, [setFilterDate])

  const onSubmit = async(data) => {
    setBtnLoading(true)
    const type = data.trans_type.concat(`-${data.search_by}`)
    switch (type) {
      case 'PEMBAYARAN-IURAN_ATAU_PREMI':
        setTableHeaderData(headerPembayaranTable)
        await dispatch(getTransactionTabelAction({
          search_by: data.search_by,
          trans_type: data.trans_type,
          from: data.from,
          to: data.to
        }))
          .then(
            res => {
              setBtnLoading(false)
              if (res.success) {
                setTitleTable(res.data.title)
                setTableDataTransac(res.data.data)
              }
            }
          )
        break
      case 'PEMBAYARAN-BIAYA':
        setTableHeaderData(headerPembayaranTable)
        await dispatch(getTransactionTabelAction({
          search_by: data.search_by,
          trans_type: data.trans_type,
          from: data.from,
          to: data.to
        }))
          .then(
            res => {
              setBtnLoading(false)
              if (res.success) {
                setTitleTable(res.data.title)
                setTableDataTransac(res.data.data)
              }
            }
          )
        break
      case 'TERTUNGGAK-IURAN_ATAU_PREMI':
        setTableHeaderData(headerTertunggakTable)
        await dispatch(getTransactionTabelAction({
          search_by: data.search_by,
          trans_type: data.trans_type,
          from: data.from,
          to: data.to
        }))
          .then(
            res => {
              setBtnLoading(false)
              if (res.success) {
                setTitleTable(res.data.title)
                setTableDataTransac(res.data.data)
              }
            }
          )
        break
      case 'TERTUNGGAK-BIAYA':
        setTableHeaderData(headerTertunggakBiayaTable)
        await dispatch(getTransactionTabelAction({
          search_by: data.search_by,
          trans_type: data.trans_type,
          from: data.from,
          to: data.to
        }))
          .then(
            res => {
              setBtnLoading(false)
              if (res.success) {
                setTitleTable(res.data.title)
                setTableDataTransac(res.data.data)
              }
            }
          )
        break
    }
  }

  const getInitialTableData = async (data) => {
    await dispatch(getTransactionTabelAction(data))
      .then(
        res => {
          if (res.success) {
            setTitleTable(res.data.title)
            setTableDataTransac(res.data.data)
          }
        }
      )
  }

  React.useEffect(() => {
    register('search_by', { required: true })
    register('from')
    register('to')
    register('trans_type')

    getFaq('info_transaksi')

    setValue('search_by', 'IURAN_ATAU_PREMI')
    setValue('trans_type', radioTransac)
    setValue('from', `${filterDate.from.year}-${filterDate.from.month >= 10 ? filterDate.from.month : '0' + filterDate.from.month}-${filterDate.from.day >= 10 ? filterDate.from.day : '0' + filterDate.from.day}`)
    setValue('to', `${filterDate.to.year}-${filterDate.to.month >= 10 ? filterDate.to.month : '0' + filterDate.to.month}-${filterDate.to.day >= 10 ? filterDate.to.day : '0' + filterDate.to.day}`)

    setSearchBy('IURAN_ATAU_PREMI')
    setRadioTransac('PEMBAYARAN')

    getInitialTableData({
      search_by: 'IURAN_ATAU_PREMI',
      trans_type: 'PEMBAYARAN',
      from: `${filterDate.from.year}-${filterDate.from.month >= 10 ? filterDate.from.month : '0' + filterDate.from.month}-${filterDate.from.day >= 10 ? filterDate.from.day : '0' + filterDate.from.day}`,
      to: `${filterDate.to.year}-${filterDate.to.month >= 10 ? filterDate.to.month : '0' + filterDate.to.month}-${filterDate.to.day >= 10 ? filterDate.to.day : '0' + filterDate.to.day}`
    })

    setTableHeaderData(headerPembayaranTable)

    return () => {
      dispatch(resetAction())
    }

  }, [lang])

  return (
    <section>
      <Container fluid>
        <h1 className="font-weight-light display-2 mb-4">
          {t('employerIuranTransaction.pageTitle.text1')} <span className="font-serif">{t('employerIuranTransaction.pageTitle.text2')}</span>
        </h1>
        <Row className={clsx('mb-4')}>
          <Col md={8}>
            <p className="pr-md-5 mr-md-5 font-weight-light">
            {descPageText.text}
            </p>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md={12} className={clsx('my-3 my-md-0')}>
            <FormBootstrap onSubmit={handleSubmit(onSubmit)}>
              <Card body style={{ height: '100%' }}>
                <Row style={{ height: '100%' }} className={clsx('py-3')}>
                  <Col
                    md={{ span: 6, offset: 1 }}
                    className="d-flex flex-column align-items-start justify-content-between"
                  >
                    <Form.Select
                      value={searchBy}
                      onChange={docsBaseHandler}
                      label={t('employerIuranTransaction.filter.searchBy')}
                      formGroupProps={{ className: clsx('w-100', 'mb-4 pr-4 pr-md-5') }}
                      menuProps={{ className: clsx('w-100') }}
                      items={[
                        { key: 'IURAN_ATAU_PREMI', value: t('employerIuranTransaction.filter.searchByItem.duesPremius') },
                        { key: 'BIAYA', value: t('employerIuranTransaction.filter.searchByItem.cost') },
                      ]}
                      underlined
                    />
                    <Form.DateRangePicker2
                        onChange={changeDateHandler}
                        setDateValue={setDateValue}
                        dataValue={dataValue}
                        label={{
                          from: {
                            label: t('employerIuranTransaction.filter.from'),
                            placeholder: t('employerIuranTransaction.filter.choiceDate')
                          },
                          to: {
                            label: t('employerIuranTransaction.filter.to'),
                            placeholder: t('employerIuranTransaction.filter.choiceDate')
                          }
                        }}
                        maxMonthFrom={12}
                    />
                  </Col>
                  <Col md={{ span: 4 }}>
                    <span className="font-weight-light">{t('employerIuranTransaction.filter.type')}</span>
                    <fieldset className={clsx('d-flex d-md-block')}>
                      <Form.Radio
                        name="pembayaran"
                        value="PEMBAYARAN"
                        label={t('employerIuranTransaction.filter.typeItem.text1')}
                        formGroupProps={{ className: clsx('mt-2', 'mt-md-3') }}
                        checked={radioTransac === 'PEMBAYARAN'}
                        onChange={getTransactionRadioHandler}
                      />
                      <Form.Radio
                        name="pembayaran"
                        value="TERTUNGGAK"
                        label={t('employerIuranTransaction.filter.typeItem.text2')}
                        className={clsx('ml-4 ml-md-0')}
                        formGroupProps={{ className: clsx('mt-2', 'mt-md-3') }}
                        checked={radioTransac === 'TERTUNGGAK'}
                        onChange={getTransactionRadioHandler}
                      />
                    </fieldset>
                  </Col>
                  <Col md={{ span: 5, offset: 1 }} className={clsx('d-flex align-items-end')}>
                    <Button loading={btnLoading} type="submit" variant="coral" block>
                      {t('employerIuranTransaction.filter.btnProcess')}
                    </Button>
                  </Col>
                </Row>
              </Card>
            </FormBootstrap>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md={12} className={clsx('my-3 my-md-0')}>
            <h1 className={clsx('display-4', 'font-weight-light', 'mb-3 mt-5')}>{titleTable}</h1>
            <TableWrapper
              loading={loading}
            >
              <DataTable
                headers={tableHeaderData.length > 0 ? tableHeaderData : []}
                data={tableDataTransac && !loading ? tableDataTransac : []}
                emptyData={
                  !loading && (
                    <div className={clsx('d-flex', 'justify-content-center', 'align-items-center', 'py-3')}>
                      <SVGSmile />
                      <p className="mb-0 ml-3 font-weight-light">{t("employerIuranTransaction.emptyTransaction")}</p>
                    </div>
                  )
                }
              />
            </TableWrapper>
          </Col>

        </Row>
        {faq && faq.length > 0 && (
          <Row>
            <Col md={12}>
              <h1 className="font-weight-light">{t('faq.title')}</h1>
            </Col>
          </Row>
        )}
        {faq && responsiveRowRender(
          <Accordion itemsPerRow={faq ? faq : []} items={faq ? faq : []} />
        )}
        {/* <div className={clsx('my-4', 'pt-2 pb-3')}>
          <Billboard.Contact />
        </div> */}
      </Container>
    </section>
  )
}

export default Transactions
