
export const en = {
    employerNewsTable: {
        title: 'Recent News',
        date: 'Date',
        information: 'Information',
        desc: 'Description'
    }
}

export const id = {
    employerNewsTable: {
        title: 'Berita Terkini',
        date: 'Tanggal',
        information: 'Informasi',
        desc: 'Deskripsi'
    }
}