
export const en = {
    birthdayCard: {
        birthHeader1: 'Dear Customer',
        birthHeader2: '',
        birthBody: 'Happy Birthday! On this special day, we would like to express our gratitude for choosing Manulife Indonesia as your trusted partner in your financial journey. We wish you and your family continued prosperity and financial success in the years to come.',
        birthFooter1: 'Warm regards,',
        birthFooter2: 'Manulife Indonesia'
    }
}

export const id = {
    birthdayCard: {
        birthHeader1: 'Nasabah',
        birthHeader2: 'yang terhormat,',
        birthBody: 'Selamat Ulang Tahun! pada kesempatan berbahagia ini, kami ingin mengucapkan terima kasih telah mempercayakan perjalanan finansial Anda kepada Manulife Indonesia. Semoga tahun ini dan yang akan datang membawa kemakmuran dan kesuksesan finansial yang berkelanjutan bagi Anda dan keluarga.',
        birthFooter1: 'Salam hangat,',
        birthFooter2: 'Manulife Indonesia'
    }
}