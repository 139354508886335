// @flow
import MomentInstance from 'moment'
import 'moment/locale/id'

export const applyMoment = () => {
  window.moment = MomentInstance

  window.moment.locale('id')

  window.moment.defaultFormat = "MMM D, YYYY"

  return window.moment
}

export default MomentInstance
