import * as React from 'react'
import { Loadable } from 'components'

// @flow
const Main =  Loadable(React.lazy(() => import('./Main')))
const OTP =  Loadable(React.lazy(() => import('./OTP')))
const Password =  Loadable(React.lazy(() => import('./Password')))
const Verification =  Loadable(React.lazy(() => import('./Verification')))


const ResetPassword = {
    Main,
    OTP,
    Password,
    Verification
}

export default ResetPassword
