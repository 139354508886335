// @flow
import clsx from "clsx";
import * as React from "react";
import { Form } from "react-bootstrap";

export const Radio = React.forwardRef(
    (
        {
            name,
            id,
            onChange,
            className,
            label,
            formGroupProps,
            checked: checkedProp,
            ...restProps
        },
        ref
    ) => {
        const [checked, setChecked] = React.useState(checkedProp);

        const handleOnChange = (e) => {
            setChecked(e.currentTarget.checked);
            typeof onChange === "function" && onChange(e);
        };

        return (
            <Form.Group {...formGroupProps}>
                <Form.Check.Label htmlFor={id} className={clsx(className)}>
                    <Form.Check.Input
                        {...restProps}
                        id={id}
                        ref={ref}
                        name={name}
                        type="radio"
                        defaultChecked={checked}
                        onChange={handleOnChange}
                    />
                    <span className="indicator"></span>
                    <span className="indicator__back"></span>
                    {!!label && (
                        <span className={clsx("description")}>{label}</span>
                    )}
                </Form.Check.Label>
            </Form.Group>
        );
    }
);

export default Radio;
