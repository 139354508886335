import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { en as employeeNewsTableEn, id as employeeNewsTableId } from './pages/employee/news'
import { en as employeeReportDocTableEn, id as employeeReportDocTableId } from './pages/employee/reportsDoc'
import { en as employeeClaimTableEn, id as employeeClaimTableId } from './pages/employee/claim'
import { en as employeeInvestEn, id as employeeInvestId } from './pages/employee/invest'
import { en as employeeProfilEn, id as employeeProfilId } from './pages/employee/profil'
import { en as employeeBallanceEn, id as employeeBallanceId } from './pages/employee/ballance'
import { en as employeeHomeEn, id as employeeHomeId } from './pages/employee/home'
import { en as employeeChangePasswordEn, id as employeeChangePasswordId } from './pages/employee/changePassword'

import { en as signInEn, id as signInId } from './pages/signin'
import { en as resetPasswordEn, id as resetPasswordId } from './pages/reset-password'
import { en as dashboardEn, id as dashboardId } from './pages/dashboard'

import { en as employerNewsTableEn, id as employerNewsTableId } from './pages/employer/news'
import { en as employerHomeTableEn, id as employerHomeTableId } from './pages/employer/home'
import { en as employerIuranEn, id as employerIuranId } from './pages/employer/iuran'
import { en as employerChangePasswordEn, id as employerChangePasswordId } from './pages/employer/changePassword'
import { en as employerProfilEn, id as employerProfilId } from './pages/employer/profil'
import { en as employerAttendeEn, id as employerAttendeId } from './pages/employer/attende'
import { en as employerClaimEn, id as employerClaimId } from './pages/employer/claim'
import { en as employerBallanceEn, id as employerBallanceId } from './pages/employer/ballance'
import { en as employerInvestEn, id as employerInvestId } from './pages/employer/invest'
import { en as faqEn, id as faqId } from './pages/faq'
import { en as genLangEn, id as genLangId } from './pages/gen-lang'
import { en as notifEn, id as notifId } from './pages/notif'
import { en as dateNotifEn, id as dateNotifId } from './pages/dateNotif'
import { en as registerEn, id as registerId } from './pages/register'
import { en as guidesEn, id as guidesId } from './pages/guides'
import { en as authenticationEn, id as authenticationId } from './pages/authetication'
import { en as footerEn, id as footerId } from './pages/footer'
import { en as monthNameEn, id as monthNameId } from './pages/monthName'
import { en as dayNameEn, id as dayNameId } from './pages/dayName'
import { en as birthdayCardEN, id as birthdayCardID } from './pages/birthDayLang'


i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
            ...employeeNewsTableEn,
            ...employeeReportDocTableEn,
            ...employeeClaimTableEn,
            ...employeeProfilEn,
            ...employeeInvestEn,
            ...employeeBallanceEn,
            ...employeeHomeEn,
            ...employeeChangePasswordEn,
            ...signInEn,
            ...dashboardEn,
            ...resetPasswordEn,
            ...employerNewsTableEn,
            ...employerHomeTableEn,
            ...employerIuranEn,
            ...employerProfilEn,
            ...employerChangePasswordEn,
            ...employerAttendeEn,
            ...employerClaimEn,
            ...employerBallanceEn,
            ...faqEn,
            ...genLangEn,
            ...notifEn,
            ...dateNotifEn,
            ...registerEn,
            ...guidesEn,
            ...authenticationEn,
            ...footerEn,
            ...monthNameEn,
            ...employerInvestEn,
            ...dayNameEn,
            ...birthdayCardEN
        }
      },
      id: {
        translation: {
            ...employeeNewsTableId,
            ...employeeReportDocTableId,
            ...employeeClaimTableId,
            ...employeeProfilId,
            ...employeeInvestId,
            ...employeeBallanceId,
            ...employeeHomeId,
            ...employeeChangePasswordId,
            ...signInId,
            ...dashboardId,
            ...resetPasswordId,
            ...employerNewsTableId,
            ...employerHomeTableId,
            ...employerIuranId,
            ...employerProfilId,
            ...employerChangePasswordId,
            ...employerAttendeId,
            ...employerClaimId,
            ...employerBallanceId,
            ...faqId,
            ...genLangId,
            ...notifId,
            ...dateNotifId,
            ...registerId,
            ...guidesId,
            ...authenticationId,
            ...footerId,
            ...monthNameId,
            ...employerInvestId,
            ...dayNameId,
            ...birthdayCardID
        } 
      }
    },
    lng: "id",
    fallbackLng: "id",

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
