// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_loading__QxNBC {
  z-index: 10;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.669);
  min-height: 300px;
}

.style_pagination__0qoTu {
  z-index: 20;
}

.style_tableWrapper__PP72A {
  min-height: 370px;
  box-sizing: border-box;
  margin-right: 0;
  margin-left: 0;
  flex-direction: column;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/TableWrapper/style.module.scss"],"names":[],"mappings":"AACA;EACI,WAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,4CAAA;EACA,iBAAA;AAAJ;;AAGA;EACI,WAAA;AAAJ;;AAGA;EACI,iBAAA;EACA,sBAAA;EACA,eAAA;EACA,cAAA;EACA,sBAAA;EACA,8BAAA;AAAJ","sourcesContent":["\n.loading{\n    z-index: 10;\n    top: 0;\n    left: 0;\n    height: 100%;\n    background-color:  rgba(255, 255, 255, 0.669);\n    min-height: 300px;\n}\n\n.pagination{\n    z-index: 20;\n}\n\n.tableWrapper{\n    min-height: 370px;\n    box-sizing: border-box;\n    margin-right: 0;\n    margin-left: 0;\n    flex-direction: column;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": `style_loading__QxNBC`,
	"pagination": `style_pagination__0qoTu`,
	"tableWrapper": `style_tableWrapper__PP72A`
};
export default ___CSS_LOADER_EXPORT___;
