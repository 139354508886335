
export const en = {
    employerHome: {
        title: 'Download Documents',
        saying: 'Good Morning',
        btnNext: 'More',
        table: {
            name: 'Document Name',
            submission_time: 'Request Date',
            estimate_complete: 'Estimated Completion Date',
            status: 'Status',
            file_type: 'File Type'
        },
        groupNumber: 'Group Number',
        box: {
            ballance: 'Your Account Balance',
            claimProcess: 'Number of Claim Submissions'
        },
        inputFilter: {
            placeholder: 'Find documents here'
        },
        emptyNews:'Stay tuned for more updates from us.',
        emptyReport:'There is no report for you yet. Please check again later',
        reportData: {
            filter: {
                date: 'Date',
                name: 'Name',
                participantDraft: 'Participant Lists',
                participanTransDraft: 'List of transfer Participants',
                participantCard: 'Participant Card',
                incompleteClaim: 'Incomplete Claim',
                claimPaymentReport: 'Claim Payment Report',
                retirementPeriod: 'Retirement Period',
                billingInfo: 'Billing Info',
                billingReport: 'Billing Report',
                participant: 'Participant',
                claim: 'Claim',
                dues: 'Dues',
                ballance: 'Ballance',
                labelByStatus: 'By Status',
                process: 'Process',
                done: 'Done',
                fail: 'Failed'
            }
        }
    }
}

export const id = {
    employerHome: {
        title: 'Unduh Dokumen',
        saying: 'Selamat Pagi',
        btnNext: 'Selengkapnya',
        table: {
            name: 'Nama Dokumen',
            submission_time: 'Tanggal Pengajuan',
            estimate_complete: 'Estimasi Tanggal Selesai',
            status: 'Status',
            file_type: 'Format Dokumen'
        },
        groupNumber: 'No. Group',
        box: {
            ballance: 'Saldo Rekening Anda',
            claimProcess: 'Jumlah Pengajuan Klaim'
        },
        inputFilter: {
            placeholder: 'Cari dokumen di sini'
        },
        emptyNews:'Silakan tunggu berita terkini dari kami.',
        emptyReport:'Belum ada report baru untuk Anda. Silahkan periksa lagi nanti.',
        reportData: {
            filter: {
                date: 'Tanggal',
                name: 'Nama',
                participantDraft: 'Daftar Peserta',
                participanTransDraft: 'Daftar Perpindahan Peserta',
                participantCard: 'Kartu Peserta',
                incompleteClaim: 'Klaim Tidak Lengkap',
                claimPaymentReport: 'Laporan Pembayaran Klaim',
                retirementPeriod: 'Masa Pensiun',
                billingInfo: 'Info Tagihan',
                billingReport: 'Laporan Tagihan',
                participant: 'Peserta',
                claim: 'Klaim',
                dues: 'Iuran',
                ballance: 'Saldo',
                labelByStatus: 'Berdasarkan Status',
                process: 'Proses',
                done: 'Selesai',
                fail: 'Gagal'
            }
        }
    }
}
