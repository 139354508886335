import * as React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import styles from './style.module.scss'
import getDate2 from 'utils/dateConverter2'
import useAuth from 'hooks/useAuth'

const go=(to)=>{
    window.location.href=to
  }
const NotificationItem = React.memo((props) => {
    return(
      <Link onClick={()=>go(props.to)}>
        <div className={clsx('w-100',styles.notifWrapper, props.read ? '' : styles.unread)}>
          <div className={clsx('mb-1')}>
            <span className={clsx(styles.notifTopText)}>{props.date}</span>
          </div>
          <div className={clsx(styles.notifBody)}>
            <h3 className={clsx(styles.notifTitle)}>
              <strong>{props.title}</strong>
            </h3>
            <p className={clsx(styles.notifText, 'mb-0')}>
              {props.style&&props.style.italic?
              <i>{props.textBody}</i>
              :props.textBody
              }
            </p>
          </div>
        </div>
      </Link>
    )
})

const Loading = () => {
  return(
    <div style={{ minHeight: 300 }} className="w-100 d-flex justify-content-center align-items-center">
        <Spinner animation="border" />
    </div>
  )
}

const Notification = (props) => {

  const { t } = useTranslation()
  const { roleName } = useAuth()

  return (
    <div>
        <div className={clsx(styles.notifHeader)}></div>
        <div className={clsx(styles.notifContent)}>
            <div className={clsx(styles.notifContent__title)}>
              {t('notification.allNotif.title.text1')} <span>{t('notification.allNotif.title.text2')}</span>
            </div>
            <div className={clsx(styles.notifContent__box)}>
                {props.data && !props.loading && props.data.map((it, index) => {
                  return(
                    <NotificationItem
                        key={index}
                        to={
                          roleName === 'EMPLOYEE' ?
                          `/dplk/employee/notification/detail/${it.cer_nmbr}/${it.crm_nmbr}/${it.sub_nmbr}`:
                          `/dplk/employer/notification/detail/${it.crm_nmbr}/${it.sub_nmbr}`
                        }
                        read={it.read !== 0}
                        date={getDate2(it.created_at)}
                        title={it.subject}
                        textBody={it.desc}
                    />
                  )
                })}
            </div>
            {props.loading && (
              <Loading/>
            )}
        </div>
    </div>
  )
}

export default Notification;
