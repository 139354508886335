// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_bgimg__2HpoC {
  height: 100%;
  margin: 0;
  font-family: "Manulife JH Sans Regular";
}

/* If the screen size is 601px wide or more*/
@media screen and (min-width: 601px) {
  body {
    font-size: 25px;
  }
}
/* If the screen size is 600px wide or less */
@media screen and (max-width: 400px) {
  body {
    font-size: 11px;
  }
}
.style_topleft__mljTz {
  position: absolute;
  top: 3%;
  left: 16px;
}

.style_bottomleft__cDnJt {
  position: absolute;
  bottom: 0;
  left: 16px;
}

.style_middle__\\+Kik- {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.style_middle__\\+Kik- hr {
  margin: auto;
  width: 40%;
}
.style_middle__\\+Kik- img {
  margin-top: 18%;
  width: 167px;
  height: 30px;
}
.style_middle__\\+Kik- a {
  text-decoration: underline 1px;
  color: white;
  padding-bottom: 1px;
}
.style_middle__\\+Kik- .style_svg-icon__U4FuV {
  fill: rgb(2, 164, 18);
}
.style_middle__\\+Kik- .style_svg-icon-brand__yHbpz {
  fill: rgb(0, 0, 0);
}
.style_middle__\\+Kik- .style_title-maintenance__8Y0cw {
  color: #00A758;
}
.style_middle__\\+Kik- .style_desc-maintenance__bkqRy {
  font-weight: lighter;
}`, "",{"version":3,"sources":["webpack://./src/pages/Maintenance/style.module.scss"],"names":[],"mappings":"AAMA;EACE,YAAA;EACA,SAAA;EACA,uCAAA;AALF;;AAQA,4CAAA;AACE;EACA;IACE,eAAA;EALF;AACF;AAQA,6CAAA;AACA;EACE;IACE,eAAA;EANF;AACF;AASA;EACE,kBAAA;EACA,OAAA;EACA,UAAA;AAPF;;AAUA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;AAPF;;AAUA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,kBAAA;AAPF;AAQE;EACE,YAAA;EACA,UAAA;AANJ;AASE;EACI,eAAA;EACA,YAAA;EACA,YAAA;AAPN;AAUE;EACE,8BAAA;EACA,YAAA;EACA,mBAAA;AARJ;AAWE;EACE,qBAAA;AATJ;AAYE;EACE,kBAAA;AAVJ;AAaE;EACE,cAAA;AAXJ;AAcE;EACE,oBAAA;AAZJ","sourcesContent":["@import 'scss/for-module';\n\n// body, html, .div {\n  \n// }\n\n.bgimg {\n  height: 100%;\n  margin: 0;\n  font-family: \"Manulife JH Sans Regular\";\n}\n\n/* If the screen size is 601px wide or more*/\n  @media screen and (min-width: 601px) {\n  body {\n    font-size: 25px;\n  }\n}\n\n/* If the screen size is 600px wide or less */\n@media screen and (max-width: 400px) {\n  body {\n    font-size: 11px;\n  }\n}\n\n.topleft {\n  position: absolute;\n  top: 3%;\n  left: 16px;\n}\n\n.bottomleft {\n  position: absolute;\n  bottom: 0;\n  left: 16px;\n}\n\n.middle {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  text-align: center;\n  hr {\n    margin: auto;\n    width: 40%;\n  }\n  \n  img {\n      margin-top: 18%;\n      width: 167px;\n      height: 30px;\n  }\n  \n  a {\n    text-decoration: underline 1px;\n    color: white;\n    padding-bottom: 1px;\n  }\n  \n  .svg-icon {\n    fill: rgb(2, 164, 18);\n  }\n  \n  .svg-icon-brand {\n    fill: rgb(0, 0, 0);\n  }\n  \n  .title-maintenance {\n    color: #00A758;\n  }\n  \n  .desc-maintenance {\n    font-weight: lighter;\n  }\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bgimg": `style_bgimg__2HpoC`,
	"topleft": `style_topleft__mljTz`,
	"bottomleft": `style_bottomleft__cDnJt`,
	"middle": `style_middle__+Kik-`,
	"svg-icon": `style_svg-icon__U4FuV`,
	"svg-icon-brand": `style_svg-icon-brand__yHbpz`,
	"title-maintenance": `style_title-maintenance__8Y0cw`,
	"desc-maintenance": `style_desc-maintenance__bkqRy`
};
export default ___CSS_LOADER_EXPORT___;
