// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_spinnerWrapper__wgSqi {
  width: 100%;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
}

.styles_children__xrFFB {
  visibility: hidden;
}

.styles_button__cLVM3 {
  cursor: pointer;
}
.styles_button__cLVM3:disabled {
  cursor: no-drop !important;
}

button svg {
  height: 50%;
  color: rgb(255, 255, 255) !important;
}

button svg circle {
  stroke: rgba(255, 255, 255, 0.768627451) !important;
}

button .styles_justify-content-center__vBBbd {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Button/styles.module.scss"],"names":[],"mappings":"AAEA;EACI,WAAA;EACA,kBAAA;EACA,QAAA;EACA,OAAA;EACA,SAAA;EACA,MAAA;AADJ;;AAIA;EACI,kBAAA;AADJ;;AAIA;EACI,eAAA;AADJ;AAGI;EACI,0BAAA;AADR;;AAKA;EACI,WAAA;EACA,oCAAA;AAFJ;;AAIA;EACI,mDAAA;AADJ;;AAGA;EACI,WAAA;AAAJ","sourcesContent":["\n\n.spinnerWrapper{\n    width: 100%;\n    position: absolute;\n    right: 0;\n    left: 0;\n    bottom: 0;\n    top: 0;\n}\n\n.children{\n    visibility: hidden;\n}\n\n.button{\n    cursor: pointer;\n\n    &:disabled{\n        cursor: no-drop !important;\n    }\n}\n\nbutton svg{\n    height: 50%;\n    color: rgb(255, 255, 255) !important;\n}\nbutton svg circle{\n    stroke: #ffffffc4 !important;\n}\nbutton .justify-content-center{\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinnerWrapper": `styles_spinnerWrapper__wgSqi`,
	"children": `styles_children__xrFFB`,
	"button": `styles_button__cLVM3`,
	"justify-content-center": `styles_justify-content-center__vBBbd`
};
export default ___CSS_LOADER_EXPORT___;
