// @flow
import * as React from 'react'
import clsx from 'clsx'
import { Form as Form } from 'components'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Button, Col, OverlayTrigger, Popover, Form as FormBootstrap } from 'react-bootstrap'

import styles from './style.module.scss'

export const Register = ({ layoutPath, parentPath }) => {
  const { register, handleSubmit, errors } = useForm()
  const { t } = useTranslation()
  const onSubmit = data => console.log(data)

  return (
    <section className={clsx('container', 'py-4', styles.container)}>
      <h1 className="display-1" dangerouslySetInnerHTML={{ __html:t("register.title")}}></h1>
      <FormBootstrap noValidate className={clsx(styles.form)} onSubmit={handleSubmit(onSubmit)}>
        <FormBootstrap.Row>
          <Form.Input
            hasGrow
            type="text"
            hasValidation
            appendCursorHelp
            name="attendee_id"
            label={t("employeeRegister.form.participantNumb.label")}
            isInvalid={!!errors.attendee_id}
            ref={register({ required: true })}
            formGroupProps={{ as: Col, sm: 12 }}
            append={
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={
                  <Popover>
                    <Popover.Content>
                    {t("employeeRegister.form.participantNumb.label")}
                    </Popover.Content>
                  </Popover>
                }>
                <i className="mi-info px-1"></i>
              </OverlayTrigger>
            }
          />
          <Col sm={12}>
            <FormBootstrap.Label className="mb-0 label-grow">{t("employeeRegister.form.dateOfBirth.label")}</FormBootstrap.Label>
            <FormBootstrap.Row>
              <Form.Select
                name="date"
                placeholder={t("employeeRegister.form.dateOfBirth.date.label")}
                formGroupProps={{ as: Col, xs: 4, className: 'mt-0' }}
                menuProps={{ className: clsx('w-100') }}
                items={Array.from(Array(31).keys()).map(i => ({ key: i, value: i + 1 }))}
                underlined
              />
              <Form.Select
                name="month"
                placeholder="Bulan"
                formGroupProps={{ as: Col, xs: 4, className: 'mt-0' }}
                menuProps={{ className: clsx('w-100') }}
                items={[
                  'Januari',
                  'Februari',
                  'Maret',
                  'April',
                  'Mei',
                  'Juni',
                  'Juli',
                  'Agustus',
                  'September',
                  'Oktober',
                  'November',
                  'Desember',
                ].map(month => ({ key: month, value: month }))}
                underlined
              />
              <Form.Select
                name="year"
                placeholder="Tahun"
                formGroupProps={{ as: Col, xs: 4, className: 'mt-0' }}
                menuProps={{ className: clsx('w-100') }}
                items={Array.from(Array(22).keys()).map(i => ({
                  key: i,
                  value: `20${i.toString().length >= 2 ? i : `0${i}`}`,
                }))}
                underlined
              />
            </FormBootstrap.Row>
          </Col>
          <Col sm={12} className={clsx(['mt-2', styles.form__actions])}>
            <Button as={Link} to={`${layoutPath}/signin`} variant="outline-coral" className="mr-2">
            {t("employeeRegister.btnCancel")}
            </Button>
            <Button as={Link} to={`${parentPath}/verification`} variant="coral" type="submit">
              {t("employeeRegister.btnNext")}
            </Button>
          </Col>
        </FormBootstrap.Row>
      </FormBootstrap>
      <p className={styles.text__signin}>
      <span dangerouslySetInnerHTML={{ __html: t("employeeRegister.bottomCon.haveAccount")+' ' }}></span>
       {' '}
        <Link to={`${layoutPath}/signin`} className={clsx('decoration-underlined')}>
          <strong>{t("employeeRegister.bottomCon.enter")}</strong>
        </Link>
      </p>
    </section>
  )
}

export default Register
