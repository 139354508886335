// @flow
import * as React from 'react'
import clsx from 'clsx'
import { Card, Col, Row, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { Alert, AuthWrapper } from 'components'

import styles from './style.module.scss'

export const Verification = ({ title, onSubmit, message, loading, listType }) => {

  const { t } = useTranslation()

  return (
    <section className={clsx('container', 'py-4 position-relative', styles.container)}>
      <AuthWrapper
        loading={loading}
      >
        <div className={clsx('w-100', styles.contentWrapper)}>
          <h1 className="display-1 mb-5" dangerouslySetInnerHTML={{ __html: title }}></h1>
          {
            message && (
              <Alert initialShow={true} closable className="mb-4 mr-lg-5">
                <p className="mb-0">
                  { message }
                </p>
              </Alert>
            )
          }
          <h2 className={clsx(styles.subheading, 'text-darkNavy', 'mb-5')}>
            {t('resetPassword.resendOtp.description')}
          </h2>
          <Row className="mb-auto w-100">
            <Col sm={12} md={6} xl={5}>
              <Row>
                {listType && listType.typeOpt.map((it, index) => {
                  if(it === 'email'){
                    return(
                      <Col key={index} xs={6}>
                        <button onClick={() => onSubmit('email')} className={clsx('w-100 p-0', styles.buttonVerif)}>
                          <Card body className={clsx(styles.cardLink)}>
                            <i className="mi-envelope xxl d-block"></i>
                            <span className="d-block" style={{ textTransform: 'capitalize' }}>{it}</span>
                          </Card>
                        </button>
                      </Col>
                    )
                  }else{
                    return(
                      <Col xs={6}>
                        <button onClick={() => onSubmit('sms')} className={clsx('w-100 p-0', styles.buttonVerif)}>
                          <Card body className={clsx(styles.cardLink)}>
                            <i className="mi-phone xxl d-block"></i>
                            <span className="d-block" style={{ textTransform: 'capitalize' }}>{it=='sms'?t('otpPhone'):it}</span>
                          </Card>
                        </button>
                      </Col>
                    )
                  }
                })}
              </Row>
            </Col>
          </Row>
        </div>
      </AuthWrapper>
    </section>
  )
}

Verification.defaultProps = {
  title: 'Title',
}

export default Verification
