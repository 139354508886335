import { GET_SOSMED, LOADING } from "store/reducers/sosmedReducer";
import { API_SOSMED } from "utils/api.constant";
import axios from "utils/axios";

export const getSosmedActions = () => async (dispatch) => {
    dispatch({
        type: LOADING,
    });

    await axios
        .get(API_SOSMED)
        .then((res) => {
            if (res) {
                dispatch({
                    type: GET_SOSMED,
                    payload: res.data.data,
                });
            }
        })
        .catch((err) => {
            if (err.response.data.statusCode === 401) {
                dispatch({
                    type: SET_AUTH_DETECTOR_FALSE,
                    payload: err.response.data.message,
                });
            }
        });
};
