/* eslint-disable no-empty-pattern */
// @flow
import clsx from "clsx";
import * as React from "react";
import { Button, Col, Container, Row, Tab, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { DataTable, Form, Pagination, Tabs, TopContent } from "components";
import {
  getDplkDataActions,
  getUnitGroupSavingActions,
  dplkDataPaginationActions,
  unitGroupSavingPaginationActions,
} from "store/actions/invesmentActions";

// Styles
import styles from "./style.module.scss";

export const Prices = ({}) => {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const dispatch = useDispatch();

  const descPageText = useSelector((state) => state.descriptionPage);
  const data = useSelector((state) => state.invesment);

  const performanceMenuItems = [
    {
      name: "1yr",
      content: "Performance (1 Yr)",
    },
    {
      name: "6mth",
      content: "Performance (6 Mth)",
    },
    {
      name: "3mth",
      content: "Performance (3 Mth)",
    },
    {
      name: "1mth",
      content: "Performance (1 Mth)",
    },
  ];

  const [performanceName, setPerformanceName] = React.useState(
    performanceMenuItems[0].name
  );

  const [filterDate, setFilterDate] = React.useState({
    from: {
      day,
      month: month - 1,
      year,
    },
    to: {
      day,
      month,
      year,
    },
  });

  const [keyTab, setKeyTab] = React.useState("");

  const handlePerformanceMenuChange = (name) => setPerformanceName(name);

  const groupSavingsPrices = {
    headers: [
      { title: "Fund Name", name: "name" },
      { title: "Currency", name: "currency" },
      { title: "As of Date", name: "date" },
      { title: "Price", name: "price" },
      {
        title: performanceMenuItems.find(
          (item) => item.name === performanceName
        ).content,
        name: "performance",
        sortable: true,
        sortType: "number",
        attrs: {
          className: clsx(styles.performanceHead),
        },
        dataActions: {
          menu: performanceMenuItems,
          handler: {
            onFireEvent: handlePerformanceMenuChange,
          },
        },
      },
    ],
    data: [
      [
        { content: 1, name: "id" },
        { content: "Manulife Pend. Tetap Dollar", name: "name" },
        { content: "USD", name: "currency" },
        {
          content: "19/02/21",
          name: "date",
        },
        {
          content: <span className="text-success">1.629837</span>,
          name: "price",
        },
        {
          content: <span className="text-success">1.83%</span>,
          name: "performance",
        },
      ],
      [
        { content: 2, name: "id" },
        { content: "Manulife Pasar Uang", name: "name" },
        { content: "IDR", name: "currency" },
        {
          content: "19/02/21",
          name: "date",
        },
        {
          content: <span className="text-success">2,022.87</span>,
          name: "price",
        },
        {
          content: <span className="text-success">1.83%</span>,
          name: "performance",
        },
      ],
      [
        { content: 3, name: "id" },
        { content: "Manulife Dana Ekuitas", name: "name" },
        { content: "IDR", name: "currency" },
        {
          content: "19/02/21",
          name: "date",
        },
        {
          content: <span className="text-danger">9,198.24</span>,
          name: "price",
        },
        {
          content: <span className="text-danger">-0.32%</span>,
          name: "performance",
        },
      ],
      [
        { content: 4, name: "id" },
        { content: "Manulife Dana Ekuitas USD", name: "name" },
        { content: "USD", name: "currency" },
        {
          content: "19/02/21",
          name: "date",
        },
        {
          content: <span className="text-danger">1.028534</span>,
          name: "price",
        },
        {
          content: <span className="text-danger">-0.32%</span>,
          name: "performance",
        },
      ],
      [
        { content: 5, name: "id" },
        { content: "Manulife Pend. Tetap Korp", name: "name" },
        { content: "IDR", name: "currency" },
        {
          content: "19/02/21",
          name: "date",
        },
        {
          content: <span className="text-danger">3,038.45</span>,
          name: "price",
        },
        {
          content: <span className="text-danger">-0.32%</span>,
          name: "performance",
        },
      ],
      [
        { content: 6, name: "id" },
        { content: "Manulife Pend. Tetap Negara", name: "name" },
        { content: "IDR", name: "currency" },
        {
          content: "19/02/21",
          name: "date",
        },
        {
          content: <span className="text-danger">3,156.23</span>,
          name: "price",
        },
        {
          content: <span className="text-danger">-0.32%</span>,
          name: "performance",
        },
      ],
    ],
  };

  const tabHandler = React.useCallback(
    (key = "dplk") => {
      setKeyTab(key);

      const yearFrom = filterDate.from.year;
      const monthFrom =
        filterDate.from.month <= 10
          ? "0" + filterDate.from.month
          : filterDate.from.month;
      const dayFrom =
        filterDate.from.day <= 10
          ? "0" + filterDate.from.day
          : filterDate.from.day;

      const yearTo = filterDate.to.year;
      const monthTo =
        filterDate.to.month <= 10
          ? "0" + filterDate.to.month
          : filterDate.to.month;
      const dayTo =
        filterDate.to.day <= 10 ? "0" + filterDate.to.day : filterDate.to.day;

      switch (key) {
        case "dplk":
          dispatch(
            getDplkDataActions(
              `${yearFrom}-${monthFrom}-${dayFrom}`,
              `${yearTo}-${monthTo}-${dayTo}`
            )
          );
          break;
        case "groupSavings":
          // dispatch(getUnitGroupSavingActions('2002-01-22','2002-01-24'))
          // dispatch(getUnitGroupSavingActions(`${yearFrom}-${monthFrom}-${dayFrom}`,`${yearTo}-${monthTo}-${dayTo}`))
          break;
      }
    },
    [dispatch, setKeyTab, filterDate]
  );

  const paginationHandler = React.useCallback(
    (e) => {
      switch (keyTab) {
        case "dplk":
          dispatch(dplkDataPaginationActions(e));
          break;
        case "groupSavings":
          dispatch(unitGroupSavingPaginationActions(e));
          break;
      }
    },
    [dispatch, keyTab]
  );

  React.useEffect(() => {
    tabHandler();
  }, [filterDate, filterDate]);

  return (
    <Container as="section" className={clsx(styles.wrapper, "mb-5")} fluid>
      <TopContent text="Harga" textItalic="Unit" />
      <Row className={clsx("mt-3 mb-5")}>
        <Col md={7}>
          <p className="pr-5 mr-5 font-weight-light">{descPageText.text}</p>
        </Col>
      </Row>
      <Tabs
        onSelect={tabHandler}
        id="prices"
        className={clsx(styles.tabs)}
        responsiveMode="dropdown"
      >
        <Tab eventKey="dplk" title="Harga Unit DPLK">
          <Row className="py-4 justify-content-end">
            <Col md={6} className={clsx("py-2")}>
              <p className="w-100">
                A volutpat massa id interdum facilisis dui amet lectus. Tellus
                nisl neque, et et quam non nibh nulla. Mattis a sed gravida
                tristique.
              </p>
            </Col>
            <Col md={6} className={clsx("py-2")}>
              <Row className={clsx("align-items-start")}>
                <Col md={8}>
                  <Form.DateRangePicker
                    hasGrow
                    value={filterDate}
                    onChange={setFilterDate}
                    fromInputProps={{
                      label: "Dari Tanggal",
                      placeholder: "Pilih Tanggal",
                    }}
                    toInputProps={{
                      label: "Hingga",
                      placeholder: "Pilih Tanggal",
                    }}
                  />
                  <div className="d-flex py-2">
                    <span className="text-sm mt-2 d-block">
                      Maksimal periode 1 (satu) tahun
                    </span>
                    <Button
                      variant="icon"
                      className={clsx("ml-auto")}
                      onClick={() =>
                        setFilterDate({
                          from: {
                            day,
                            month: month - 1,
                            year,
                          },
                          to: {
                            day,
                            month,
                            year,
                          },
                        })
                      }
                    >
                      <i className={clsx("mi mi-reload")}></i> Ulangi
                    </Button>
                  </div>
                </Col>
                <Col className={clsx("d-flex")}>
                  <Button variant="coral" block>
                    Unduh
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md={12} className={clsx("mt-4")}>
              {data.loading && (
                <div
                  className={clsx(
                    "w-100 d-flex justify-content-center align-items-center py-5"
                  )}
                >
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              )}
              {!data.loading && data.data && (
                <DataTable {...data.data} responsiveMode="table" />
              )}
            </Col>
            <Col
              xs={12}
              md={6}
              xl={4}
              className={clsx("d-flex justify-content-end")}
            >
              <Pagination
                threeDots
                totalPages={data.data ? data.data?.pageCount : 1}
                currentPage={data.page}
                onClick={paginationHandler}
                showMax={4}
                prevNext
                activeBgColor="#fff"
                activeBorderColor="gray"
                className={clsx("justify-content-end")}
              />
            </Col>
          </Row>
        </Tab>
        {/* <Tab eventKey="groupSavings" title="Harga Unit Group Savings">
          <Row className="py-4 justify-content-end">
            <Col md={6} className={clsx('py-2')}>
              <p className="w-100">
                A volutpat massa id interdum facilisis dui amet lectus. Tellus nisl neque, et et
                quam non nibh nulla. Mattis a sed gravida tristique.
              </p>
            </Col>
            <Col md={6} className={clsx('py-2')}>
              <Row className={clsx('align-items-start')}>
                <Col md={8}>
                  <Form.DateRangePicker
                    hasGrow
                    value={filterDate}
                    onChange={setFilterDate}
                    fromInputProps={{ label: 'Dari', placeholder: 'Pilih Tanggal' }}
                    toInputProps={{ label: 'Hingga', placeholder: 'Pilih Tanggal' }}
                  />
                  <div className="d-flex py-2">
                    <span className="text-sm mt-2 d-block">Maksimal periode 1 (satu) tahun</span>
                    <Button
                      variant="icon"
                      className={clsx('ml-auto')}
                      onClick={() =>
                        setFilterDate({
                          from: null,
                          to: null,
                        })
                      }>
                      <i className={clsx('mi mi-reload')}></i> Ulangi
                    </Button>
                  </div>
                </Col>
                <Col className={clsx('d-flex')}>
                  <Button variant="coral" block>
                    Unduh
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md={12} className={clsx('mt-4')}>
              { data.loading && (
                 <div className={clsx('w-100 d-flex justify-content-center align-items-center py-5')}>
                  <Spinner animation="border" role="status">
                  </Spinner>
                </div>
              ) }
              { !data.loading && data.data && (
                <DataTable {...data.data} responsiveMode="table" />
              ) }
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Pagination
                threeDots
                totalPages={data.data ? data.data?.pageCount : 1}
                currentPage={data.page}
                onClick={paginationHandler}
                showMax={4}
                prevNext
                activeBgColor="#fff"
                activeBorderColor="gray"
                className={clsx('justify-content-end')}
              />
            </Col>
          </Row>
        </Tab> */}
      </Tabs>
    </Container>
  );
};

export default Prices;
