import { getDateAccordingToMonth } from './generalUtils'

const getSlideDate = ({ parent, isInitialActiveChild, activeDate, monthChangeDirection }) => {
  if (!parent) {
    return isInitialActiveChild ? activeDate : getDateAccordingToMonth(activeDate, 'NEXT')
  }
  const child = parent.children[isInitialActiveChild ? 0 : 1]
  const isActiveSlide =
    child.classList.contains('-shown') || child.classList.contains('-shown-animated') // check -shown-animated for Safari bug
  return isActiveSlide ? activeDate : getDateAccordingToMonth(activeDate, monthChangeDirection)
}

const animateContent = ({ parent, direction }) => {
  const wrapperChildren = Array.from(parent.children)
  const shownItem = wrapperChildren.find(child => child.classList.contains('-shown'))
  const hiddenItem = wrapperChildren.find(child => child !== shownItem)
  const baseClass = shownItem.classList[0]
  const isNextMonth = direction === 'NEXT'
  const getAnimationClass = value => (value ? '-hidden-next' : '-hidden-previous')
  hiddenItem.style.transition = 'none'
  shownItem.style.transition = ''
  shownItem.className = `${baseClass} ${getAnimationClass(!isNextMonth)}`
  hiddenItem.className = `${baseClass} ${getAnimationClass(isNextMonth)}`
  hiddenItem.classList.add('-shown-animated')
}

const handleSlideAnimationEnd = ({ target }) => {
  target.classList.remove('-hidden-next')
  target.classList.remove('-hidden-previous')
  target.classList.replace('-shown-animated', '-shown')
}

export { animateContent, getSlideDate, handleSlideAnimationEnd }
