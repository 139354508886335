// @flow
import * as React from 'react'
import { Redirect } from 'react-router'

import Notification from './Notification'
import NotificationDetail from './NotificationDetail'


const pathname = '/notification'
const route = {
  routes: {
    '/all': {
      component: Notification,
    },
    '/detail/:cerNmbr/:crmNmbr/:subNmbr': {
      component: NotificationDetail,
    },
    '/': {
      component: ({ layoutPath }) => <Redirect to={`${layoutPath}notification/all`} />,
    },
  },
}

const notification = {
    pathname,
    route
}

export default notification

