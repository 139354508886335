// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__l\\+Uux {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.style_subheading__PTofW {
  max-width: 32rem;
  font-weight: 300;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.style_form__cpnAc {
  width: 100%;
  max-width: 32rem;
  margin-top: 2rem;
  margin-bottom: auto;
}`, "",{"version":3,"sources":["webpack://./src/views/SetPassword/style.module.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,uBAAA;AADF;;AAIA;EACE,gBAAA;EACA,gBAAA;AADF;;AAGA;;EAEI,aAAA;AAAJ;;AAEA;EACE,WAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;AACF","sourcesContent":["@import 'scss/for-module';\n\n.container {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n}\n\n.subheading {\n  max-width: 32rem;\n  font-weight: 300;\n}\ninput::-ms-reveal,\n    input::-ms-clear {\n    display: none;\n}\n.form {\n  width: 100%;\n  max-width: 32rem;\n  margin-top: 2rem;\n  margin-bottom: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__l+Uux`,
	"subheading": `style_subheading__PTofW`,
	"form": `style_form__cpnAc`
};
export default ___CSS_LOADER_EXPORT___;
