// @flow
import * as React from "react";
import clsx from "clsx";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { getFeaturesDataActions } from "../../../store/actions/featuresActions";
import { Accordion } from "components";
import { useTranslation } from 'react-i18next';

// Styles
import styles from "./style.module.scss";

export const Features = ({ ...restProps }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const descPageText = useSelector((state) => state.descriptionPage);
  const [guides, setGuides] = React.useState([]);
  const lang = useSelector(state => state.language.lang)

  React.useEffect(async () => {
    await dispatch(getFeaturesDataActions())
      .then((res) => {
        setGuides(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [lang]);

  const guide = guides.map(({ icon, title, subtitle, ...rest }) => ({
    name: title.toLowerCase().split(" ").join("-"),
    title: (
      <>
        <i className={clsx(`${icon}`, "xl", "ml-2")}></i>
        <span className="d-block font-weight-light h4 mb-0">{title}</span>
        <span className="d-block font-weight-light text-md">{subtitle}</span>
        <i aria-hidden className="accordion-chevron -right"></i>
      </>
    ),
    content: rest.content || (
      <div className="-eq-padding-x font-weight-light">
        <p>
          Tart souffle cotton candy cotton candy biscuit gummi bears. Dragee
          tart brownie gummies gummies halvah.
        </p>
        <span className="h5">Cara Akses Halaman:</span>
        <ol className="pl-4 mt-3">
          <li>Apple pie sesame snaps.</li>
          <li>Tart souffle cotton candy.</li>
        </ol>
      </div>
    ),
  }));

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });

  const responsiveRowRender = (content) =>
    !isTabletOrMobile ? (
      <Row className={clsx("mt-4 mb-5")}>
        <Col sm={12}>{content}</Col>
      </Row>
    ) : (
      <div className={clsx("normalize-container-fluid", "mt-4 mb-5 pb-5")}>
        {content}
      </div>
    );

  return (
    <Container as="section" className={clsx(styles.wrapper)} fluid>
      <h1 className="font-weight-light display-2 mb-4 mt-5">
        {t('guides.features.text1')} <span className="font-serif" dangerouslySetInnerHTML={{ __html: t("guides.features.text2") }}></span>
      </h1>
      <Row className={clsx("mt-3")}>
        <Col md={7}>
          <p className="mb-4 pr-md-5 mr-md-5 font-weight-light">
          { descPageText.text }
          </p>
          {/* <Button variant="coral">Lihat Demo Panduan</Button> */}
        </Col>
      </Row>
      {responsiveRowRender(
        <Accordion
          cols={2}
          items={guide}
          itemsPerRow={((guide.length + 1) / 2).toFixed()}
          buttonIcon="element-inside"
          className={clsx("gap-x-lg")}
        />
      )}
    </Container>
  );
};

export default Features;
