// @flow
import * as React from "react";
import ReactDOM from "react-dom";
import { Calendar } from "components";
import { Form as FormComponent } from "components";
import { Col, Fade, Row, Form } from "react-bootstrap";
import { has, isEqual, isFunction, isNull } from "lodash-es";
import { useLocaleUtils } from "lib/shared/hooks";
import { usePopper } from "react-popper";
import clsx from "clsx";
import { useSelector } from "react-redux";

export const DateRangePicker = React.forwardRef(
    (
        {
            hasGrow,
            colProps,
            calendarProps,
            fromInputProps,
            selectorEndingYear,
            selectorStartingYear,
            toInputProps,
            value: valueProp,
            monthRangeDate,
            ...restProps
        },
        ref
    ) => {
        const lang = useSelector((state) => state.language.lang);
        const { getToday } = useLocaleUtils(calendarProps?.locale || "en");
        const startingYear = selectorStartingYear || 2000;
        const endingYear = selectorEndingYear || getToday().year;
        const [onSelectYear, setOnSelectYear] = React.useState(null);
        const [onSelectMonth, setOnSelectMonth] = React.useState(null);

        const [calendarState, setCalendarState] = React.useState({
            activeDate: {
                year: getToday().year,
                month: getToday().month,
                day: getToday().day,
            },
        });

        const [nextCalendarState, setNextCalendarState] = React.useState({
            ...calendarState,
            activeDate: {
                year: calendarState.activeDate.year,
                month: calendarState.activeDate.month + 1,
                day: 1,
            },
        });

        const [value, setValue] = React.useState(
            valueProp || {
                from: null,
                to: null,
            }
        );

        const [parsedValue, setParsedValue] = React.useState({
            from: null,
            to: null,
        });
        const [calendarVisibility, setCalendarVisiblity] =
            React.useState(false);

        const [inputElement, setInputElement] = React.useState(null);
        const [referenceElement, setReferenceElement] = React.useState(null);
        const [popperElement, setPopperElement] = React.useState(null);
        const { styles, attributes } = usePopper(
            referenceElement,
            popperElement,
            {
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, 8],
                        },
                    },
                ],
            }
        );

        const handleCloseCalendar = (e) => {
            setCalendarVisiblity(false);
        };

        const handleCalendarChange = (newValue) => {
            setValue(newValue);
            if (!!newValue.from && !!newValue.to) {
                handleCloseCalendar();
                !!restProps.onChange &&
                    isFunction(restProps.onChange) &&
                    restProps.onChange(newValue);
            }
        };

        const maxDate = React.useMemo(() => {
            const x = window.moment(
                `${onSelectYear ? onSelectYear : new Date().getFullYear()}/${
                    onSelectMonth ? onSelectMonth : new Date().getMonth() + 1
                }/${calendarState.activeDate.day}`
            );
            const y = window
                .moment(x)
                .add(monthRangeDate ? parseInt(monthRangeDate) : 6, "M");
            const t = window.moment(y).endOf("month");
            return {
                day: t.date(),
                month: t.month() + 1,
                year: t.year(),
            };
        }, [
            onSelectMonth,
            onSelectYear,
            calendarState.activeDate.day,
            monthRangeDate,
        ]);

        React.useLayoutEffect(() => {
            const eventHandler = (event) => {
                if (
                    referenceElement &&
                    !referenceElement.contains(event.target)
                ) {
                    if (isNull(popperElement)) handleCloseCalendar(event);
                    else if (
                        popperElement &&
                        !popperElement.contains(event.target)
                    )
                        handleCloseCalendar(event);
                }
            };

            document.addEventListener("click", eventHandler);

            return () => {
                document.removeEventListener("click", eventHandler);
            };
        }, [inputElement, popperElement, referenceElement]);

        React.useEffect(() => {
            window.moment.locale(lang);
            const toNativeDate = (date) =>
                new Date(date.year, date.month - 1, date.day);
            setParsedValue({
                from: !!value.from
                    ? window.moment(toNativeDate(value.from)).format()
                    : "",
                to: !!value.to
                    ? window.moment(toNativeDate(value.to)).format()
                    : "",
            });
        }, [nextCalendarState, value, lang]);

        React.useEffect(() => {
            if (!!calendarState.activeDate) {
                if (
                    calendarState.activeDate.year >
                    nextCalendarState.activeDate.year
                ) {
                    setNextCalendarState({
                        ...nextCalendarState,
                        activeDate: {
                            ...nextCalendarState.activeDate,
                            year: calendarState.activeDate.year,
                        },
                    });
                } else if (
                    calendarState.activeDate.month >
                        nextCalendarState.activeDate.month ||
                    calendarState.activeDate.month <
                        nextCalendarState.activeDate.month
                ) {
                    setNextCalendarState({
                        ...nextCalendarState,
                        activeDate: {
                            ...nextCalendarState.activeDate,
                            month: calendarState.activeDate.month + 1,
                            day: 1,
                        },
                    });
                }
            }
        }, [calendarState]);

        React.useEffect(() => {
            if (
                (has(valueProp, "to") && valueProp.to !== value.to) ||
                (has(valueProp, "from") && valueProp.from !== value.from)
            )
                setValue(valueProp);
        }, [valueProp]);

        return (
            <Row ref={setReferenceElement}>
                <Col {...colProps}>
                    <FormComponent.Input
                        {...fromInputProps}
                        append={
                            <i
                                className="mi-calendar"
                                onClick={() =>
                                    !!inputElement && inputElement.focus()
                                }
                            ></i>
                        }
                        hasGrow={hasGrow}
                        placeholder={
                            has(fromInputProps, "placeholder")
                                ? fromInputProps.placeholder
                                : "Pick a Date"
                        }
                        formGroupProps={{
                            ...fromInputProps.formGroupProps,
                            className: clsx(
                                "mb-0",
                                fromInputProps.formGroupProps?.className
                            ),
                        }}
                        readOnly
                        value={parsedValue.from || ""}
                        ref={setInputElement}
                        onFocus={() => setCalendarVisiblity(true)}
                    />
                </Col>
                <Col {...colProps}>
                    <FormComponent.Input
                        {...toInputProps}
                        append={
                            <i
                                className="mi-calendar"
                                onClick={() =>
                                    !!inputElement && inputElement.focus()
                                }
                            ></i>
                        }
                        hasGrow={hasGrow}
                        placeholder={
                            has(toInputProps, "placeholder")
                                ? toInputProps.placeholder
                                : "Pick a Date"
                        }
                        formGroupProps={{
                            ...toInputProps.formGroupProps,
                            className: clsx(
                                "mb-0",
                                toInputProps.formGroupProps?.className
                            ),
                        }}
                        readOnly
                        value={parsedValue.to || ""}
                        ref={setInputElement}
                        onFocus={() => setCalendarVisiblity(true)}
                    />
                </Col>
                {ReactDOM.createPortal(
                    <Fade in={calendarVisibility} unmountOnExit>
                        <div
                            ref={setPopperElement}
                            style={{
                                ...styles.popper,
                                display: "flex",
                                zIndex: 9999,
                            }}
                            {...attributes.popper}
                        >
                            <Calendar
                                {...calendarProps}
                                value={value}
                                onChange={handleCalendarChange}
                                calendarClassName={clsx(
                                    "shadow-none",
                                    "border"
                                )}
                                state={calendarState}
                                onSelectYear={setOnSelectYear}
                                onSelectMonth={setOnSelectMonth}
                                selectorStartingYear={startingYear}
                                selectorEndingYear={endingYear}
                                onStateChange={(state) =>
                                    !isEqual(state, calendarState) &&
                                    setCalendarState({
                                        ...calendarState,
                                        ...state,
                                        activeDate: {
                                            ...calendarState.activeDate,
                                            ...state.activeDate,
                                        },
                                    })
                                }
                            />
                            <Calendar
                                {...calendarProps}
                                value={value}
                                onChange={handleCalendarChange}
                                calendarClassName={clsx(
                                    "shadow-none",
                                    "border"
                                )}
                                state={nextCalendarState}
                                onStateChange={(state) =>
                                    !isEqual(state, nextCalendarState) &&
                                    setNextCalendarState({
                                        ...nextCalendarState,
                                        ...state,
                                        activeDate: {
                                            ...nextCalendarState.activeDate,
                                            ...state.activeDate,
                                        },
                                    })
                                }
                                selectorStartingYear={
                                    calendarState.activeDate.year
                                }
                                selectorEndingYear={getToday().year}
                                minimumDate={
                                    !!value.from
                                        ? {
                                              year: calendarState.activeDate
                                                  .year,
                                              month:
                                                  calendarState.activeDate
                                                      .month + 1,
                                              day: calendarState.activeDate.day,
                                          }
                                        : {}
                                }
                                maximumDate={{
                                    year: maxDate.year,
                                    month: maxDate.month,
                                    day: maxDate.day,
                                }}
                            />
                        </div>
                    </Fade>,
                    document.querySelector("#__manulifeApp")
                )}
            </Row>
        );
    }
);

export default React.memo(DateRangePicker);
