/* eslint-disable no-empty-pattern */
// @flow
import * as React from "react";
import clsx from "clsx";
import {
  Col,
  Container,
  Row,
  Card,
  OverlayTrigger,
  Popover,
  ListGroup,
  Form,
} from "react-bootstrap";
import useInvestFields from "lib/hooks/useInvestFields";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";

import { Accordion, Form as FormComponent, TopContent } from "components";
import { API_EMPR_RISK_PROFILE, API_FAQ_INVEST_LIST } from 'utils/api.constant'

// Styles
import styles from "./style.module.scss";
import useAuth from "hooks/useAuth";
import axios from "axios";

export const Profile = ({}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });
  const descPageText = useSelector((state) => state.descriptionPage);
  const auth = useAuth();
  const [dataFaqs, setDataFaqs] = React.useState([]);
  const [dataRiskProfile, setDataRiskProfile] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(API_EMPR_RISK_PROFILE)
      .then((response) => {
        const responseData = response.data;
        if (responseData.statusCode === 200) {
          setDataRiskProfile(responseData.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  React.useEffect(() => {
    axios
      .get(API_FAQ_INVEST_LIST)
      .then((response) => {
        const responseData = response.data;
        if (responseData.statusCode === 200) {
          setDataFaqs(responseData.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const faqs = [
    {
      title: "Apakah Fact Sheet?",
    },
    {
      title: "Informasi apa saja yang dimuat dalam Fact Sheet?",
    },
    {
      title: "Apa manfaat dari Fact Sheet?",
    },
    {
      title: "Bagaimana cara membaca Fund Fact Sheet yang benar?",
    },
    {
      title: "Apakah Monthly Market Review?",
    },
    {
      title: "Apa saja jenis profil risiko dan perbedaannya?",
    },
    {
      title: "Bagaimana proses perubahan arah investasi?",
    },
  ].map((faq) => ({
    ...faq,
    name: faq.title.toLowerCase().split(" ").join("-"),
    content: (
      <p>
        Eiusmod tempor cupidatat voluptate est quis. Et nostrud velit tempor
        culpa velit in ad ex ut aliqua. Cupidatat occaecat ipsum Lorem
        voluptate. Eu excepteur incididunt tempor incididunt enim. Consequat
        nisi sint sunt pariatur duis ex sit mollit nulla quis. In fugiat
        adipisicing magna incididunt.
      </p>
    ),
  }));

  const [values, setValues] = useInvestFields();

  const handleFieldChange = (type) => (e) => {
    const { value } = e.currentTarget;
    setValues({ ...values, [type]: parseFloat(!!value ? value : 0) });
  };

  const renderInfoPopover = (content) => (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement="top"
      overlay={
        <Popover>
          <Popover.Content>{content}</Popover.Content>
        </Popover>
      }
    >
      <i className="mi-info md ml-1 cursor-pointer vertical-align-text-bottom"></i>
    </OverlayTrigger>
  );

  const responsiveRowRender = (content1, content2) =>
    !isTabletOrMobile ? (
      <Row className={clsx("my-5")}>
        <Col md={5}>{content1}</Col>
        <Col md={{ span: 6, offset: 1 }}>{content2}</Col>
      </Row>
    ) : (
      <div className={clsx("normalize-container-fluid", "my-5")}>
        <div className="my-5">{content1}</div>
        <div className="container-fluid mt-5">{content2}</div>
      </div>
    );

  return (
    <Container as="section" className={clsx(styles.wrapper, "mb-5")} fluid>
      <TopContent text="Profil" textItalic="Risiko" />
      <Row className={clsx("mt-3")}>
        <Col md={7}>
          <p className="pr-md-5 mr-md-5 font-weight-light">
            {descPageText.text}
          </p>
        </Col>
        <Col md={12} className={clsx("my-4")}>
          <Card className={clsx("on-boarding__target")}>
            <Row noGutters>
              <Col sm={12} className={styles.form__wrapper}>
                <Card.Body>
                  <Form className={clsx("px-md-4 pt-md-3")}>
                    <h2 className="h6 mb-4">
                      <strong>Arahan Investasi saat ini:</strong>
                    </h2>
                    <Row as="fieldset">
                      {dataRiskProfile.map((d, i) => (
                        <Col
                          key={i}
                          xs={6}
                          md={4}
                          className={clsx(styles.input__wrapper)}
                        >
                          <span
                            className={clsx(
                              isTabletOrMobile ? "text-md d-block" : "text-sm"
                            )}
                          >
                            {d.inv_type_nm}
                            {renderInfoPopover(d.inv_type_desc)}
                          </span>
                          <FormComponent.Input
                            percentage
                            formGroupProps={{ className: "mt-0" }}
                            onChange={handleFieldChange("FIX")}
                            value={d.percentage}
                            isAllowed={(val) => val}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Form>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      {responsiveRowRender(
        <>
          <h3
            className={clsx(
              "font-weight-light",
              "mb-0",
              isTabletOrMobile && "container-fluid"
            )}
          >
            FAQ Terkait
          </h3>
          <Accordion itemsPerRow={dataFaqs.length} items={dataFaqs} />
        </>,
        <>
          <h3 className={clsx("font-weight-light mb-4")}>
            Monthly Market Review Terbaru
          </h3>
          <ListGroup className="mb-5">
            <Card as={ListGroup.Item}>
              <Card.Body className={clsx("px-0 py-2")}>
                <span className="font-weight-bold">
                  <i
                    className={clsx(
                      "mi-download lg",
                      "vertical-align-text-bottom"
                    )}
                  ></i>{" "}
                  Download Monthly Market Review - Nov 2020
                </span>
              </Card.Body>
            </Card>
          </ListGroup>

          <h3 className={clsx("font-weight-light mb-4")}>
            Fund Fact Sheet DPLK Desember 2020
          </h3>
          <FormComponent.Select
            label="Versi Bahasa"
            items={[
              { key: "id", value: "Indonesia" },
              { key: "en", value: "Bahasa Inggris" },
            ]}
            formGroupProps={{ className: clsx("w-50", "mb-2", "pt-3") }}
          />
          <ListGroup>
            <Card as={ListGroup.Item} className="my-2 border-t-1">
              <Card.Body className={clsx("px-0 py-2")}>
                <span className="font-weight-bold">
                  <i
                    className={clsx(
                      "mi-download lg",
                      "vertical-align-text-bottom"
                    )}
                  ></i>{" "}
                  GRO Dana Saham (GDS)
                </span>
              </Card.Body>
            </Card>
            <Card as={ListGroup.Item} className="my-2 border-t-1">
              <Card.Body className={clsx("px-0 py-2")}>
                <span className="font-weight-bold">
                  <i
                    className={clsx(
                      "mi-download lg",
                      "vertical-align-text-bottom"
                    )}
                  ></i>{" "}
                  GRO Dana Pendapatan Tetap (GDPT)
                </span>
              </Card.Body>
            </Card>
            <Card as={ListGroup.Item} className="my-2 border-t-1">
              <Card.Body className={clsx("px-0 py-2")}>
                <span className="font-weight-bold">
                  <i
                    className={clsx(
                      "mi-download lg",
                      "vertical-align-text-bottom"
                    )}
                  ></i>{" "}
                  GRO Dana Pasar Uang (GDPU)
                </span>
              </Card.Body>
            </Card>
            <Card as={ListGroup.Item} className="my-2 border-t-1">
              <Card.Body className={clsx("px-0 py-2")}>
                <span className="font-weight-bold">
                  <i
                    className={clsx(
                      "mi-download lg",
                      "vertical-align-text-bottom"
                    )}
                  ></i>{" "}
                  GRO Dana Syariah (GDS)
                </span>
              </Card.Body>
            </Card>
            <Card as={ListGroup.Item} className="my-2 border-t-1">
              <Card.Body className={clsx("px-0 py-2")}>
                <span className="font-weight-bold">
                  <i
                    className={clsx(
                      "mi-download lg",
                      "vertical-align-text-bottom"
                    )}
                  ></i>{" "}
                  GRP Dana US Dollar (GDUSD)
                </span>
              </Card.Body>
            </Card>
          </ListGroup>
        </>
      )}
    </Container>
  );
};

export default Profile;
