import * as React from 'react'
import clsx from 'clsx'
import { Form } from 'components'
import useAuth from 'hooks/useAuth'
import { t } from 'i18next'
// Styles
import styles from './style.module.scss'

const TopContent = React.memo((props) => {

  const { list_cert_number, cert_number, setCertNumberHandler } = useAuth()

  return (
    <div className="d-flex flex-md-row flex-column justify justify-content-md-between justify-content-sm-start align-items-start align-items-md-center">
       <div className='row mr-0' style={{width:'100%',marginLeft:'0px !important'}}>
            <div className='col-md-9'>
                <h1 className="font-weight-light display-2 mb-4 mt-5">

                    {
                        props.position!=undefined&&(
                           <> { props.text } <span className="font-serif">{props.textItalic}</span> </>
                        )
                    }
                    {
                        props.position==undefined&&(
                           <> <span className="font-serif">{props.textItalic}</span> { props.text }</>
                        )
                    }
                </h1>
            </div>
            <div className='col-md-3'>
                {
                cert_number && list_cert_number && (
                    <div style={{float:'right',position: 'relative',left: '33px'}}>
                        <div className="d-flex flex-column align-items-end justify-content-start">
                            <label className='mt-5 mb-0 pl-1'>{t('genLang.changeAccount')}</label>
                          
                            <Form.Select
                                // label="Pilih Akun"
                                value={cert_number && cert_number.toString()}
                                items={list_cert_number && list_cert_number}
                                onChange={e => setCertNumberHandler(e)}
                                formControlProps={{
                                    className : 'form-control mt-0 mb-0'
                                }}
                                inputGroupProps={{
                                    className: clsx(styles.inputgroup),
                                    formControlProps: { className: clsx('text-right') },
                                }}
                            />
                        </div>
                    </div>
                )
                }
            </div>
       </div>

    </div>
  )
})

export default TopContent
