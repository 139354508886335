
export const en = {
    employeeHome: {
        pageTitle: 'Good Morning ',
        box: {
            box1: {
                label: 'Your Account Balance'
            },
            box2: {
                label: 'Claim Information'
            }
        },
        reportDownload: {
            failAlert: "Documents that can be downloaded must have the status Ready to Download"
        }
    },
}

export const id = {
    employeeHome: {
        pageTitle: 'Selamat Pagi',
        box: {
            box1: {
                label: 'Saldo Rekening Anda'
            },
            box2: {
                label: 'Informasi Klaim'
            }
        },
        reportDownload: {
            failAlert: "Dokumen yang bisa di download harus berstatus Siap Diunduh"
        }
    },
}