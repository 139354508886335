// @flow
// eslint-disable-next-line no-unused-vars
import * as React from 'react'
import { Authentication } from 'layouts'
import { Loadable } from 'components'
const SignIn =  Loadable(React.lazy(() => import('./SignIn')))
const Verification =  Loadable(React.lazy(() => import('./Verification')))
const OTP =  Loadable(React.lazy(() => import('./OTP')))

import AuthGuardSign from '../../auth/AuthGuardSign'

export const pathname = '/signin'
export const route = {
  component: props => (
    <AuthGuardSign>
      <Authentication {...props}/>
    </AuthGuardSign>
  ),
  asLayout: true,
  routes: {
    '/:role': {
      routes: {
        '/verification': {
          routes: {
            '/:type/:secret': {
              component: OTP,
            },
            '/:secret': {
              component: Verification,
            },
          }
        },
        '/': {
          component: SignIn
        }
      }
    },
    '/': {
      component: SignIn,
    },
  },
}