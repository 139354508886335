export const GET_EMPYR_ATTENDEE = 'GET_EMPYR_ATTENDEE'
export const LOADING_EMPYR_ATTENDEE = 'LOADING_EMPYR_ATTENDEE'
export const LOADING_EMPYR_ATTENDEE_FALSE = 'LOADING_EMPYR_ATTENDEE_FALSE'
export const PAGINATION_EMPYR_ATTENDEE = 'PAGINATION_EMPYR_ATTENDEE'
export const RESET_EMPYR_ATTENDEE = 'RESET_EMPYR_ATTENDEE'

const initialState = {
    data: null,
    loading: false,
    page: 1,
    pageCount: 0
}

const AttendeeReducer = (state = initialState, action) => {
    switch(action.type){
        case GET_EMPYR_ATTENDEE: {
            return{
                data: action.payload,
                loading: false
            }
        }
        case LOADING_EMPYR_ATTENDEE: {
            return {
                ...state,
                loading: true
            }
        }
        case LOADING_EMPYR_ATTENDEE_FALSE: {
            return {
                ...state,
                loading: false
            }
        }
        case PAGINATION_EMPYR_ATTENDEE: {
            return {
                ...state,
                page: action.payload.page,
                pageCount: action.payload.pageCount
            }
        }
        case RESET_EMPYR_ATTENDEE: {
            return{
                data: null,
                loading: false
            }
        }
        default:{
            return state
        }
    }
}

export default AttendeeReducer