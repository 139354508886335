export const GET_NEWS = 'GET_NEWS'
export const LOADING_NEWS = 'LOADING_NEWS'
export const PAGINATION_NEWS = 'PAGINATION_NEWS'
export const RESET_NEWS = 'RESET_NEWS'

const initialState = {
    data: null,
    loading: false,
    page: 1,
    take: 5,
    pageCount: 1,
    order: 'ASC'
}

const NewsReducer = (state = initialState, action) => {
    switch(action.type){
        case GET_NEWS: {
            return{
                ...state,
                data: action.payload,
                loading: false
            }
        }
        case PAGINATION_NEWS: {
            return {
                ...state,
                page: action.payload.page,
                pageCount: action.payload.pageCount
            }
        }
        case LOADING_NEWS: {
            return {
                ...state,
                loading: true,
                data: null
            }
        }
        case RESET_NEWS: {
            return {
                data: null,
                loading: false,
                page: 1,
                take: 5,
                pageCount: 1,
                order: 'ASC'
            }
        }
        default:{
            return state
        }
    }
}

export default NewsReducer
