// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/bg-header-home.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_notifHeader__631j4 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: -1rem;
  margin-left: -1rem;
  z-index: 100;
}

.style_notifContent__2BYFX {
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -230px;
  margin-bottom: 100px;
}
.style_notifContent__box__fIDoo {
  background: rgb(255, 255, 255);
  border-radius: 6px;
  border: 1px solid #ededed;
  overflow: hidden;
  padding: 1.5rem 2rem;
}
.style_notifContent__box__fIDoo .style_backButtonWrapper__oBOgz {
  border-bottom: 1px solid rgb(211, 211, 211);
  padding-bottom: 0.5rem;
}
.style_notifContent__box__fIDoo .style_backButtonWrapper__oBOgz a {
  color: #00a758;
}
.style_notifContent__title__hH7xM {
  font-size: 30px;
  font-weight: 300;
  text-align: center;
  color: #fff;
  margin-bottom: 1.5rem;
}
.style_notifContent__title__hH7xM span {
  font-family: "Manulife JH Serif", sans-serif !important;
  font-style: italic;
}
.style_notifContent__2BYFX .style_notifTitle__N5trP {
  font-size: 1.5rem;
  font-weight: 600;
}
.style_notifContent__2BYFX .style_notifKlaim__CtFT3 {
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/views/Notification/Detail/style.module.scss"],"names":[],"mappings":"AACA;EACI,yDAAA;EACA,aAAA;EACA,sBAAA;EACA,4BAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;AAAJ;;AAGA;EACI,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,oBAAA;AAAJ;AAEI;EACI,8BAAA;EACA,kBAAA;EACA,yBAAA;EACA,gBAAA;EACA,oBAAA;AAAR;AAEQ;EACE,2CAAA;EACA,sBAAA;AAAV;AACU;EACE,cAAA;AACZ;AAII;EACI,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;EACA,qBAAA;AAFR;AAIQ;EACI,uDAAA;EACA,kBAAA;AAFZ;AAMI;EACE,iBAAA;EACA,gBAAA;AAJN;AAOI;EACE,eAAA;AALN","sourcesContent":["\n.notifHeader{\n    background-image: url('../../../assets/images/bg-header-home.jpg');\n    height: 300px;\n    background-size: cover;\n    background-repeat: no-repeat;\n    margin-right: -1rem;\n    margin-left: -1rem;\n    z-index: 100;\n}\n\n.notifContent{\n    width: 700px;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: -230px;\n    margin-bottom: 100px;\n\n    &__box{\n        background: rgb(255, 255, 255);\n        border-radius: 6px;\n        border: 1px solid #ededed;\n        overflow: hidden;\n        padding: 1.5rem 2rem;\n\n        .backButtonWrapper{\n          border-bottom: 1px solid rgb(211, 211, 211);\n          padding-bottom: 0.5rem;\n          a{\n            color: #00a758;\n          }\n        }\n    }\n\n    &__title{\n        font-size: 30px;\n        font-weight: 300;\n        text-align: center;\n        color: #fff;\n        margin-bottom: 1.5rem;\n\n        span{\n            font-family: \"Manulife JH Serif\",sans-serif!important;\n            font-style: italic;\n        }\n    }\n\n    .notifTitle{\n      font-size: 1.5rem;\n      font-weight: 600;\n    }\n\n    .notifKlaim{\n      font-size: 12px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notifHeader": `style_notifHeader__631j4`,
	"notifContent": `style_notifContent__2BYFX`,
	"notifContent__box": `style_notifContent__box__fIDoo`,
	"backButtonWrapper": `style_backButtonWrapper__oBOgz`,
	"notifContent__title": `style_notifContent__title__hH7xM`,
	"notifTitle": `style_notifTitle__N5trP`,
	"notifKlaim": `style_notifKlaim__CtFT3`
};
export default ___CSS_LOADER_EXPORT___;
