
export const en = {
    authentication: {
        outService: 'Our Services',
        guide: 'Guidelines',
        sign: {
            signIn: 'Sign In',
            signUp: 'Sign Up'
        }
    }
}

export const id = {
    authentication: {
        outService: 'Layanan Kami',
        guide: 'Panduan',
        sign: {
            signIn: 'Masuk',
            signUp: 'Daftar'
        }
    }
}
