import React from 'react'
import {
    Col,
    Row,
    Card,
    ListGroup,
    OverlayTrigger,
    Popover,
    Form as FormBootstrap,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Form, Badge, StatusText, Button, Pagination, TableWrapper } from 'components'
import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import dateConvert from 'utils/dateConvert'
import { useTranslation } from 'react-i18next'

import { postListParticipantAction } from 'store/actions/employer/attendeeActions'

const ListParticipant = (props) => {

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { register, errors, setValue, handleSubmit } = useForm()
    const dataState = useSelector(state => state.employerAttendee)
    const [status, setStatus] = React.useState('ACTIVE')

    const selectHandler = React.useCallback((e) => {
        setStatus(e)
        setValue('status', e)
    },[setStatus, setValue])

    const onSubmit = React.useCallback(async(data) => {
        await dispatch(postListParticipantAction(data))
            .then(
                res => {
                    if(res.success){
                        props.setRefreshHistory(prevState => prevState + 1)
                        toast.success(res.message, {
                            position: "top-right",
                            autoClose: true,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                    }
                }
            )
    },[props.setRefreshHistory])

    React.useEffect(async() => {
        register('status', { required: t("employerAttende.tab.participantRequired") })
        setValue('status', status)
    },[])

    return (
        <Row className={clsx('py-4 mt-3')}>
            <Col md={6} className={clsx('my-3 my-md-0')}>
                <Card body style={{ height: '100%' }}>
                    <FormBootstrap onSubmit={handleSubmit(onSubmit)}>
                        <Row style={{ height: '100%' }}>
                            <Col
                                md={{ span: 5, offset: 1 }}
                                className="d-flex flex-column align-items-start justify-content-between mb-2 mb-md-0"
                            >
                                <Form.Select
                                    value={status}
                                    onChange={selectHandler}
                                    label={t('employerAttende.tab.filter.participant_status')}
                                    formGroupProps={{ className: clsx('w-100') }}
                                    menuProps={{ className: clsx('w-100') }}
                                    items={props.listStatus}
                                    error={Object.keys(errors).length > 0 && errors.status ? true : false}
                                    errorMsg={Object.keys(errors).length > 0 && errors?.status &&  errors.status.message}
                                    underlined
                                />
                            </Col>
                            <Col md={{ span: 5, offset: 1 }}>
                                <span className="font-weight-light d-block">{t('employerAttende.tab.filter.docType')}</span>
                                {Object.keys(errors).length > 0 && errors.doc_type &&  (
                                    <span style={{ color: '#ec6453' }}>{errors.doc_type.message}</span>
                                )}
                                <fieldset className={clsx('d-flex d-md-block')}>
                                    <Form.Radio
                                        name="doc_type"
                                        value="XLS"
                                        label="XLS"
                                        checked={true}
                                        ref={register({ required: 'harus di pilih' })}
                                        formGroupProps={{ className: clsx('mt-2', 'mt-md-3') }}
                                    />
                                    <Form.Radio
                                        name="doc_type"
                                        value="PDF"
                                        label="PDF"
                                        ref={register({ required: 'harus di pilih' })}
                                        className={clsx('ml-4 ml-md-0')}
                                        formGroupProps={{ className: clsx('mt-2', 'mt-md-3') }}
                                    />
                                </fieldset>
                            </Col>
                            <Col md={{ span: 5, offset: 1 }} className={clsx('d-flex align-items-end')}>
                                <Button loading={dataState.loading} type="submit" variant="coral" block>
                                    {t('employerAttende.tab.filter.btnProcess')}
                                </Button>
                            </Col>
                        </Row>
                    </FormBootstrap>
                </Card>
            </Col>
            <Col md={6} className={clsx('my-3 my-md-0')}>
                <Card>
                    <Card.Header>
                        <span className="d-flex">
                        {t('employerAttende.tab.filter.docHistory.listParticipant.label')}
                        <OverlayTrigger
                            trigger={['hover', 'focus']}
                            placement="top"
                            overlay={
                                <Popover>
                                    <Popover.Content>
                                    {t('employerAttende.tab.filter.docHistory.listParticipant.toolTip')}
                                    </Popover.Content>
                                </Popover>
                            }>
                            <i className="mi-info lg ml-1 cursor-pointer"></i>
                        </OverlayTrigger>
                        </span>
                    </Card.Header>
                    <TableWrapper
                        loading={props.loadingHistory}
                    >
                        <ListGroup variant="flush">
                            {props.historyData && !props.loadingHistory && props.historyData.map((it, index) => {
                                return(
                                    <Card key={index} as={ListGroup.Item}>
                                        <Card.Body className={clsx('px-0 py-2')}>
                                            <Button as={Link} to="/" variant="anchor" className={clsx('card-anchor')} />
                                            <h1 className="d-inline-block h6 font-weight-light mb-0 w-75">
                                                <span className="mr-1">{it.name}</span>
                                                <Badge
                                                    pill
                                                    size="xs"
                                                    variant={it.file_type === 'xls' ? "xlsColor" : "pdfColor"}
                                                    className="mt-1 vertical-align-top">
                                                    <span style={{ textTransform: 'uppercase' }}>{it.file_type}</span>
                                                </Badge>
                                            </h1>
                                            <div className="d-flex align-items-center mt-4">
                                                <p className="mb-0 text-sm mr-2">
                                                    <strong>{dateConvert(it.estimate_date)}</strong>
                                                </p>
                                                <StatusText dotSize="sm" type="success" label={t("employerAttende.readyDownload")}  />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                )
                            })}
                            {(!props.historyData || props.historyData.length<1)  && (<p className="mb-0 ml-3 font-weight-light">{t("employerAttende.tab.emptyDocument")}</p>)}
                        </ListGroup>
                        <Col md={12}>
                            <Pagination
                                threeDots={false}
                                totalPages={props.paginationConfig && props.paginationConfig.pageCount > 0 ? props.paginationConfig.pageCount : 0}
                                currentPage={props.paginationConfig ? props.paginationConfig.page : 1}
                                onClick={props.paginationHandler}
                                showMax={3}
                                prevNext
                                activeBgColor="#fff"
                                activeBorderColor="gray"
                            />
                        </Col>
                    </TableWrapper>
                </Card>
            </Col>
            <Col md={12}>
                {/* <Form.DateRangePicker2/> */}
            </Col>
        </Row>
    )
}

export default React.memo(ListParticipant)
