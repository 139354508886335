import * as React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import getDate2 from 'utils/dateConverter2'
import styles from './style.module.scss'
import useAuth from 'hooks/useAuth'

const Loading = () => {
  return(
    <div style={{ minHeight: 300 }} className="w-100 d-flex justify-content-center align-items-center">
        <Spinner animation="border" />
    </div>
  )
}

const NotificationDetail = (props) => {

  const { t } = useTranslation()
  const { roleName } = useAuth()

  return (
    <div>
        <div className={clsx(styles.notifHeader)}></div>
        <div className={clsx(styles.notifContent)}>
            {props.data && !props.loading && (
              <div className={clsx(styles.notifContent__box)}>
                  <div className={clsx(styles.backButtonWrapper, 'mb-4')}>
                    <Link to={
                      roleName === 'EMPLOYEE' ?
                      `/employee/notification/all` :
                      `/employer/notification/all`
                    }>
                      {t('notification.detailNotif.backButton')}
                    </Link>
                  </div>
                  <div>
                    <div className={clsx(styles.notifKlaim)}>{getDate2(props.data.created_at)}</div>
                    <div className={clsx('mb-2')}>
                      <h1 className={clsx(styles.notifTitle)}>{props.data.subject}</h1>
                    </div>
                    <div>
                    {props.data.style&&props.data.style.italic?
                      <div style={{fontStyle:'italic'}} dangerouslySetInnerHTML={{ __html: props.data.msg }} />
                      : <div  dangerouslySetInnerHTML={{ __html: props.data.msg }} />
                      }
                    </div>
                  </div>
              </div>
            )}
            {props.loading && (
              <Loading/>
            )}
        </div>
    </div>
  )
}

export default NotificationDetail
