
export const en = {
    employeeProfil: {
        ecard: {
           btnDownload: 'Download',
           emptyCard:'The card is not yet available because the form is not complete and the dues have not been received'
        },
        profil: {
            personal_data: 'Personal Data',
            heir: 'Beneficiary',
            status_heir: {
                wife: ' Wife',
                husband: ' Husband',
                child: ' Child',
                other: ' Appointed Party',
            },
            address: 'Address',
            phone_email: 'Phone & Email',
            bilboard: {
                text1: 'Data Change',
                text2: 'Submission'
            }
        },
        changePassword: {
            pageTitle: {
                text1: 'Change',
                text2: 'Password'
            },
            desc: 'Please complete the following fields to change your EB<i>Click</i> account password',
            form: {
                one: {
                    label: 'Old Password',
                },
                two: {
                    label: 'New Password',
                },
                three: {
                    label: 'Repeat New Password',
                },
                btnDone: 'Done'
            }
        },
        me:{
            dropHere:'Drop the photo here'
        },
        noticeProfile: {
            desc: 'In order to enhance the quality of our services and provide a safer and more convenient transaction experience to our valued customers, '
        },
        employeeProfil_text_MI: {
            text: 'Manulife Indonesia '
        },
        employeeProfil4: {
            desc_c: 'kindly requests all customers to update their customer data by completing the downloadable form available at '
        },
        noticeProfile1: {
            link: 'Customer Data Update | Manulife Indonesia'
        },
        noticeProfile2: {
            desc2: ' and submitting it via email to '
        },
        noticeProfile3: {
            email: 'CustomerServiceID@manulife.com.'
        }
    }
}

export const id = {
    employeeProfil: {
        ecard: {
            btnDownload: 'Unduh',
            emptyCard:'Kartu belum tersedia karena formulir belum lengkap dan iuran belum diterima.'
        },
        profil: {
            personal_data: 'Data Pribadi',
            heir: 'Penerima Manfaat',
            status_heir: {
                wife: ' Istri',
                husband: ' Suami',
                child: ' Anak',
                other: ' Pihak yang di Tunjuk',
            },
            address: 'Alamat',
            phone_email: 'Telepon & Email',
            bilboard: {
                text1: 'Pengajuan',
                text2: 'Perubahan Data'
            }
        },
        changePassword: {
            pageTitle: {
                text1: 'Ubah',
                text2: 'Kata Sandi'
            },
            desc: 'Harap lengkapi isian berikut untuk mengganti kata sandi akun EB<i>Click</i> Anda',
            form: {
                one: {
                    label: 'Kata Sandi Lama',
                },
                two: {
                    label: 'Kata Sandi Baru',
                },
                three: {
                    label: 'Ulangi Kata Sandi Baru',
                },
                btnDone: 'Selesai'
            }
        },
        me:{
            dropHere:'Letakan fotonya disini'
        },
        noticeProfile: {
            desc: 'Untuk meningkatkan kualitas layanan menjadi lebih baik serta mewujudkan pengalaman transaksi nasabah yang lebih aman dan nyaman, '
        },
        employeeProfil_text_MI: {
            text: 'Manulife Indonesia '
        },
        employeeProfil4: {
            desc_c: 'menghimbau seluruh nasabah untuk segera melakukan Pengkinian Data Nasabah dengan melengkapi formulir yang dapat diunduh pada '
        },
        noticeProfile1: {
            link: 'Pengkinian Data Nasabah | Manulife Indonesia'
        },
        noticeProfile2: {
            desc2: ' dan kirim melalui email ke '
        },
        noticeProfile3: {
            email: 'CustomerServiceID@manulife.com.'
        }
    }
}
