
export const en = {
    employerInvest: {
        direction: {
            title: {
                text1: "Risk",
                text2: "Profile"
            },
            minmax: {
                min: "Min :",
                max: "Max :",
                percent: "%"
            }
        },
        unitPrice: {
            title: {
                text1: "Unit",
                text2: "Price"
            },
            tab: {
                label1: 'Unit Price'
            }
        }
    },
    employerFundFactSheet: {
        title: "Fund Fact Sheet",
        desc: "You will be connected to the Manulife Indonesia website by clicking the button below.",
        buttonText: "Connect"
    },
    employerMontlyMarketReview: {
        title: "Monthly Market Review",
        desc: "You will be connected to the Manulife Indonesia website by clicking the button below.",
        buttonText: "Connect"
    },
    employerUpr: {
        title: "Unit Price",
        desc: "You will be connected to the Manulife Indonesia website by clicking the button below.",
        buttonText: "Connect"
    }
}

export const id = {
    employerInvest: {
        direction: {
            title: {
                text1: "Profil",
                text2: "Risiko"
            },
            minmax: {
                min: "Min :",
                max: "Max :",
                percent: "%"
            }
        },
        unitPrice: {
            title: {
                text1: "Harga",
                text2: "Unit"
            },
            tab: {
                label1: 'Harga Unit'
            }
        }
    },
    employerFundFactSheet: {
        title: "Fund Fact Sheet",
        desc: "Dengan menekan tombol berikut, Anda akan terhubung dengan situs web Manulife Indonesia",
        buttonText: "Hubungkan"
    },
    employerMontlyMarketReview: {
        title: "Monthly Market Review",
        desc: "Dengan menekan tombol berikut, Anda akan terhubung dengan situs web Manulife Indonesia",
        buttonText: "Hubungkan"
    },
    employerUpr: {
        title: "Harga Unit",
        desc: "Dengan menekan tombol berikut, Anda akan terhubung dengan situs web Manulife Indonesia",
        buttonText: "Hubungkan"
    }
}