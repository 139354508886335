// @flow
import * as React from 'react'
import { Redirect } from 'react-router'
import { Loadable } from 'components'

const Me = Loadable(React.lazy(() => import('./Me')))
const GroupInfo = Loadable(React.lazy(() => import('./GroupInfo')))
const Contact = Loadable(React.lazy(() => import('./Contact')))
const ChangePassword = Loadable(React.lazy(() => import('./ChangePassword')))

export const pathname = '/profile'
export const route = {
  routes: {
    '/company': {
      component: Me,
    },
    '/group': {
      component: GroupInfo,
    },
    '/contact': {
      component: Contact,
    },
    '/change-password': {
      component: ChangePassword
    },
    '/': {
      component: ({ layoutPath }) => <Redirect to={`${layoutPath}profile/company`} />,
    },
  },
}

const profile = {
  pathname,
  route
}

export default profile
