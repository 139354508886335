// @flow
import * as React from 'react'
import clsx from 'clsx'
import { ReactComponent as LogoIcon } from 'assets/images/svgs/logo/icon.svg'
import { ReactComponent as LogoBrand } from 'assets/images/svgs/logo/brand.svg'
import { Container, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Form } from 'components'
import { settingLanguageActions } from 'store/actions/languageSettingActions'
import useAuth from 'hooks/useAuth'

// Styles
import styles from './style.module.scss'

// Sub-Components
import Mobile from './Mobile'


// eslint-disable-next-line no-empty-pattern
const Header = ({
  langSelector,
  appendToNav,
  logoIcon,
  fluid,
  headerRef,
  hideBrand,
  mode,
  logoIconColor,
  ...rest
}) => {

  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const langId = useSelector(state => state.language.lang)

  const settingLanguage = React.useCallback((val) => {
    dispatch(settingLanguageActions(val))
    i18n.changeLanguage(val)
  },[])



  React.useEffect(() => {
    i18n.changeLanguage(langId)
  },[])

  return (
    <header {...rest} ref={headerRef} className={clsx(styles.header, rest.className)}>
      <Nav className="navbar navbar-light px-0">
        <Container fluid={fluid}>
          {!hideBrand ? (
            <Link className={clsx(['navbar-brand', styles.header__brand])} to={`/${mode}`}>
              {logoIcon && (
                <div className={clsx(styles.header__brand__icon)}>
                  <LogoIcon fill={logoIconColor} />
                </div>
              )}
              <LogoBrand fill="#000" />
            </Link>
          ) : (
            <div></div>
          )}
          <Nav className={clsx('d-flex', 'align-items-center')}>
            {langSelector && (
              <Form.Select
                name="date"
                value={langId}
                underlined={false}
                onChange={(e) => settingLanguage(e)}
                formGroupProps={{ className: 'm-0' }}
                inputGroupProps={{
                  className: clsx(styles.header__langDropdown),
                  formControlProps: { className: clsx('text-center') },
                }}
                items={[
                  { key: 'id', value: 'ID' },
                  { key: 'en', value: 'EN' },
                ]}
              />
            )}
            {appendToNav}
          </Nav>
        </Container>
      </Nav>
    </header>
  )
}

Header.Mobile = Mobile

export default Header
