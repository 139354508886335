
export const INVESTMENT_SET_DATA = 'INVESTMENT_SET_DATA'
export const INVESTMENT_LOADING = 'INVESTMENT_LOADING'
export const INVESTMENT_RESET = 'INVESTMENT_RESET'
export const INVESTMENT_PAGINATION = 'INVESTMENT_PAGINATION'
export const INVESTMENT_SET_DATE = 'INVESTMENT_SET_DATE'

const initialState = {
    data: null,
    loading: false,
    page: 1,
    take: 10,
    order: 'ASC',
    pageCount: 0,
    start: '',
    end: '',
    performanceDate: 12
}

const InvesmentReducer = (state = initialState, action) => {
    switch(action.type){
        case INVESTMENT_SET_DATA: {
            return{
                ...state,
                data: action.payload,
                loading: false
            }
        }
        case INVESTMENT_PAGINATION: {
            return{
                ...state,
                page: action.payload.page,
                take: action.payload.take,
                order: action.payload.order,
                pageCount: action.payload.pageCount,
                performanceDate: action.payload.performanceDate
            }
        }
        case INVESTMENT_SET_DATE: {
            return{
                ...state,
                start: action.payload.start,
                end: action.payload.end
            }
        }
        case INVESTMENT_LOADING: {
            return{
                ...state,
                data: null,
                loading: true
            }
        }
        case INVESTMENT_RESET: {
            return{
                data: null,
                loading: false,
                page: 1,
                take: 10,
                order: 'ASC',
                pageCount: 1,
                start: '',
                end: '',
                performanceDate: 12
            }
        }
        default:{
            return state
        }
    }
}

export default InvesmentReducer
