
export const en = {
    footer: {
        termOfUse: 'Terms of Use',
        privacy: 'Privacy Policy',
        contactUs: 'Contact Us',
        copyright:'PT Asurasi Jiwa Manulife Indonesia licensed and supervised by the Financial Services Authority'
    }
}

export const id = {
    footer: {
        termOfUse: 'Syarat Penggunaan',
        privacy: 'Kebijakan Privasi',
        contactUs: 'Hubungi Kami',
        copyright:'PT Asurasi Jiwa Manulife Indonesia berizin dan diawasi oleh Otoritas Jasa Keuangan'
    }
}
