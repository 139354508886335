import React from 'react'
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Legend } from 'recharts';

import styles from './style.module.scss'

const PieCharts = ({
    data
}) => {
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const renderLegend = (props) => {
        const { payload } = props;
        return (
          <ul className={`${styles.legendWrapper}`}>
            {
              payload.map((entry, index) => {
                return (
                    <li className="mb-2" key={`item-${index}`}>
                        <div className="d-flex justify-content-start align-items-start">
                            <div
                                style={{ backgroundColor: `${entry.color}` }}
                                className={`${styles.legendBoxColor}`}
                            ></div>
                            <div className={`${styles.legendText}`}>{entry.value}</div>
                        </div>
                    </li>
                )
              })
            }
          </ul>
        );
    }

    return (
        <div className="w-100" style={{ height: 250 }}>
            <ResponsiveContainer width={'100%'} height={'100%'}>
              <PieChart id="chart">
                <Legend
                    align="right"
                    verticalAlign="top"
                    height={16}
                    layout="vertical"
                    content={renderLegend}
                />
                <Pie
                  data={data.data}
                  cx="70%"
                  cy="50%"

                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={80}
                  fill="#8884d8"
                  style={{maxWidth:'100px'}}
                  dataKey="value"
                >
                  {data.color.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={data.color[index % data.color.length]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
        </div>
    )
}

export default PieCharts
