
export const en = {
    dayNameAbbv: {
        su: 'SU',
        mo: 'MO',
        tu: 'TU',
        we: 'WE',
        th: 'TH',
        fr: 'FR',
        sa: 'SA'
    }
}

export const id = {
    dayNameAbbv: {
        su: 'MG',
        mo: 'SN',
        tu: 'SL',
        we: 'RB',
        th: 'KM',
        fr: 'JM',
        sa: 'SB'
    }
}