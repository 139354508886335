
export const en = {
    faq: {
       title: 'Related FAQ'
    }
}

export const id = {
    faq: {
        title: 'FAQ Terkait'
    }
}