import * as React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { InitialPageLoading } from "components";

import useAuth from "hooks/useAuth";
import { authDetectorTrueAction } from "store/actions/authDetectorActions";

const AuthGuardEmployer = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const { employerIsAuthenticated, loading, stillLogin } = useAuth();

    const authDetector = useSelector((state) => state.authDetector);

    React.useEffect(() => {
        if (!authDetector.auth && authDetector.message) {
            toast.error(authDetector.message, {
                position: "top-center",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }, [authDetector.message, authDetector.auth]);

    React.useEffect(() => {
        dispatch(authDetectorTrueAction());
        stillLogin();
    }, []);

    if (loading) {
        return <InitialPageLoading />;
    } else {
        return (
            <>
                {employerIsAuthenticated
                    ? props.children
                    : history.push("/#/signin/employer")}
            </>
        );
    }
};

export default AuthGuardEmployer;
