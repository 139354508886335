// @flow
import clsx from "clsx";
import * as React from "react";
import { Form } from "react-bootstrap";

export const Checkbox = React.forwardRef(
    (
        { id, className, formGroupProps, label, name, variant, ...restProps },
        ref
    ) => {
        return (
            <Form.Group {...formGroupProps}>
                <Form.Check.Label
                    htmlFor={id}
                    className={clsx(!!variant && `-${variant}`, className)}
                >
                    <Form.Check.Input
                        {...restProps}
                        id={id}
                        ref={ref}
                        name={name}
                        type="checkbox"
                    />
                    <span className="indicator"></span>
                    <span className="indicator__back"></span>
                    {!!label && (
                        <span className={clsx("description")}>{label}</span>
                    )}
                </Form.Check.Label>
            </Form.Group>
        );
    }
);

export default Checkbox;
