
export const en = {
    guides: {
        title: 'EB<i>Click</i> Guide',
        about: {
            text1: 'About',
            text2: 'EBClick',
            panduan:'EBClick Account Guide',
            desc:'Simple Description'
        },
        features: {
            text1: 'Feature',
            text2: 'EBClick',
            desc:'Simple Description'
        },
        tab: {
            about: 'About EB<i>Click</i>',
            feature: 'EB<i>Click</i> Features',
            faq: 'Questions (FAQ)',
            formDownload: 'Download Form'
        }
    }
}

export const id = {
    guides: {
        title: 'Panduan EB<i>Click</i>',
        about: {
            text1: 'Tentang',
            text2: 'EBClick',
            panduan:'Panduan Akun EBClick',
            desc:'Deskripsi singkat'
        },
        features: {
            text1: 'Fitur',
            text2: 'EBClick',
            desc:'Deskripsi singkat'
        },
        tab: {
            about: `Tentang EB<i>Click</i>`,
            feature: 'Fitur EB<i>Click</i>',
            faq: 'Pertanyaan (FAQ)',
            formDownload: 'Unduh Formulir'
        },
        contentAbout: {
            aboutContent: 'Manulife Indonesia dengan bangga mempersembahkan EBClick, layanan daring program Dana Pensiun dan Group Savings untuk Karyawan dan Pemberi Kerja.Fasilitas ini dapat memudahkan Anda untuk mengakses informasi kepesertaan Anda di mana saja dan kapan saja. Informasi yang dapat diperoleh melalui EBClick antara lain: Saldo, Klaim, Iuran, Investasi, e-card, dan informasi lainnya.'
        }
    }
}
