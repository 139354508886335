/* eslint-disable no-unused-vars */
// @flow
import * as React from "react";
import clsx from "clsx";
import { Form as FormComponent } from "components";
import {
    Button,
    Dropdown,
    OverlayTrigger,
    Popover,
    Table,
    Form,
} from "react-bootstrap";
import { has, isArray, isEqual, isFunction, keys } from "lodash";
import { useMediaQuery } from "react-responsive";

import DataTableMobile from "./Mobile";

import styles from "./style.module.scss";

export const Check = React.forwardRef((props, ref) => (
    <div className="d-flex justify-content-center pr-1">
        <FormComponent.Checkbox
            {...props}
            ref={ref}
            className={clsx("mt-0 w-auto pl-4", props.className)}
            formGroupProps={{ ...props.formGroupProps, className: "my-0" }}
        />
    </div>
));

export const CheckableRow = ({ children }) => {
    const [checked, setChecked] = React.useState(false);

    return children({
        checked,
        setChecked,
        component:
            (onLoad = (setter) => {}) =>
            () => {
                React.useEffect(() => {
                    onLoad(setChecked);
                }, []);

                return (
                    <Check
                        checked={checked}
                        onChange={({ currentTarget: { checked: chck } }) =>
                            setChecked(chck)
                        }
                    />
                );
            },
    });
};

export const CheckableTable = ({ children }) => {
    const [checked, setChecked] = React.useState(false);
    const [setters, setSetters] = React.useState({});

    React.useEffect(() => {
        keys(setters).forEach((name) => setters[name](checked));
    }, [checked, setters]);

    return children({
        assignSetter: (setter, name) => {
            const newSetters = { ...setters };
            if (!has(setters, name) || !isFunction(setters[name])) {
                newSetters[name] = setter;
                !isEqual(setters, newSetters) && setSetters(newSetters);
            }
        },
        Controller: React.forwardRef((props, ref) => (
            <Check
                ref={ref}
                checked={checked}
                onChange={({ currentTarget: { checked: chck } }) =>
                    setChecked(chck)
                }
            />
        )),
    });
};

const DataTableDesktop = (props) => {
    const {
        checkable,
        data: dataAtProp,
        dataActions,
        emptyData,
        headers,
        rowsOptions,
        renderActions,
        ...restProps
    } = props;

    const [sorted, setSorted] = React.useState(false);
    const [checkedAll, setCheckedAll] = React.useState(false);
    const [data, setData] = React.useState([]);

    const checkedPerItem = React.useCallback(
        (index, value) => {
            const exData = [...data];
            const findIndexFn = (it) => it.name === "checked";
            const findIndex = exData[index].findIndex(findIndexFn);
            exData[index][findIndex].content = value;
            setData(exData);
        },
        [data]
    );

    const checkedAllItem = React.useCallback(
        (value) => {
            setCheckedAll(value);
            const exData = [...data];
            const findIndexFn = (it) => it.name === "checked";
            const result = exData.map((it) => {
                const findIndex = it.findIndex(findIndexFn);
                it[findIndex].content = value;
                return it;
            });
            setData(result);
        },
        [data]
    );

    const getComparrison = (by = "ASC") => {
        switch (by) {
            case "DESC":
                return (a, b) => {
                    if (!isNaN(parseFloat(a)) && !isNaN(parseFloat(b)))
                        return b.toString() - a.toString();

                    return a > b ? -1 : a < b ? 1 : 0;
                };

            default:
                return (a, b) => {
                    if (!isNaN(parseFloat(a)) && !isNaN(parseFloat(b)))
                        return a.toString() - b.toString();

                    return a < b ? -1 : a > b ? 1 : 0;
                };
        }
    };

    const getNextOrder = () => {
        let orderBy = sorted;
        switch (sorted) {
            case "ASC":
                orderBy = "DESC";
                break;

            case "DESC":
                orderBy = true;
                break;

            default:
                orderBy = "ASC";
                break;
        }

        return orderBy;
    };

    const handleSort =
        (name, dataType = "string", sortFunction = null) =>
        () => {
            const findValueOnArr = (arr) =>
                arr.find((item) => item.name === name);

            const findValue = (dataItem) => {
                const { content } = dataItem;

                if (!!content) {
                    if (typeof content === dataType) {
                        return content;
                    } else if (typeof content === "object" && !!content.props) {
                        const props = content.props;

                        if (isArray(props.children)) {
                            if (typeof props.children[0] === dataType) {
                                return props.children[0];
                            }
                        } else if (typeof props.children === dataType) {
                            return props.children;
                        } else if (
                            typeof props.children === "string" &&
                            dataType !== "string"
                        ) {
                            return parseFloat(props.children).toPrecision(6);
                        }
                    } else if (
                        typeof content === "string" &&
                        dataType !== "string"
                    ) {
                        return parseFloat(content).toPrecision(6);
                    }
                }

                return "";
            };

            if (!!name) {
                const orderBy = getNextOrder();
                setSorted(orderBy);
                setData([
                    ...(typeof orderBy === "boolean"
                        ? dataAtProp
                        : data.sort((a, b) => {
                              let valA = findValue(findValueOnArr(a) || {});
                              let valB = findValue(findValueOnArr(b) || {});

                              valA =
                                  dataType === "string"
                                      ? valA.toUpperCase()
                                      : valA;
                              valB =
                                  dataType === "string"
                                      ? valB.toUpperCase()
                                      : valB;

                              return typeof sortFunction === "function"
                                  ? sortFunction(valA, valB, orderBy)
                                  : getComparrison(orderBy)(valA, valB);
                          })),
                ]);
            }
        };

    const renderSortable = (name, dataType = "string") => {
        let icon = "sort";
        const orderBy = getNextOrder();

        switch (orderBy) {
            case "DESC":
                icon = "arrow-up";
                break;

            case "ASC":
                icon = "arrow-down";
                break;

            default:
                icon = "sort";
                break;
        }

        // return (
        //   <OverlayTrigger
        //     trigger={['hover', 'focus']}
        //     placement="top"
        //     overlay={
        //       <Popover>
        //         <Popover.Content>
        //           Set to {typeof orderBy === 'boolean' ? 'Default' : orderBy} Order
        //         </Popover.Content>
        //       </Popover>
        //     }></OverlayTrigger>
        // )

        return (
            <Button
                variant="icon"
                className={clsx(`mi-${icon} md`)}
                onClick={handleSort(name, dataType)}
            />
        );
    };

    const renderHead = (tableHeadProps = {}, addOn = null) => (
        <thead {...tableHeadProps}>
            <tr
                height={tableHeadProps.height || 61}
                className={clsx(styles.table__row, styles.head)}
            >
                {addOn}
                {headers.map((headItem) => {
                    const {
                        title,
                        name,
                        attrs,
                        sortable,
                        sortType,
                        dataActions,
                    } = headItem;
                    return (
                        <th {...attrs} key={name}>
                            {title}
                            {!!sortable && renderSortable(name, sortType)}
                            {!!dataActions &&
                                renderActions(headItem, dataActions)}
                        </th>
                    );
                })}
                {!!dataActions && !!dataActions.menu.length && <th />}
            </tr>
        </thead>
    );

    const renderRow = (col, key, tableRowProps = {}, addOn = null) => {
        let rowProps = tableRowProps;
        if (typeof rowsOptions === "function") {
            const options = rowsOptions(col);

            rowProps = {
                ...tableRowProps,
                ...options,
            };

            if (has(options, "className"))
                rowProps.className = clsx(
                    tableRowProps.className,
                    options.className
                );
        }

        return (
            <tr
                {...rowProps}
                key={key}
                height={rowProps.height || 61}
                className={clsx(styles.table__row, rowProps.className)}
            >
                {typeof addOn === "function" ? addOn({ col, key }) : addOn}
                {headers
                    .map(
                        (head) =>
                            col.find((c) => c.name === head.name) || {
                                content: null,
                                name: head.name,
                                attrs: null,
                            }
                    )
                    .map(({ content, name, ...itemData }) => (
                        <td
                            {...itemData.attrs}
                            // className={clsx(styles.table__cell, itemData.attrs.className)}
                            key={name}
                        >
                            {content}
                        </td>
                    ))}
                {!!dataActions && !!dataActions.menu.length && (
                    <td
                        {...dataActions.attrs}
                        // width={dataActions.attrs.width || 100}
                        className={clsx(
                            styles.table__cell,
                            styles.table__action
                            // dataActions.attrs.className
                        )}
                    >
                        {renderActions(col, dataActions)}
                    </td>
                )}
            </tr>
        );
    };

    const renderTableBody = (
        addOn = null,
        assignSetter = (setter, name) => {},
        tableHeadProps = {},
        tableBodyProps = {}
    ) => (
        <>
            {renderHead(tableHeadProps, addOn)}
            <tbody {...tableBodyProps}>
                {!!data.length ? (
                    data.map((col, key) => {
                        let findChecked;
                        if (checkable) {
                            findChecked = col.find(
                                (fl) => fl.name === "checked"
                            );
                        }
                        return checkable ? (
                            <>
                                {/* <CheckableRow key={key}>
                  {({ checked, setChecked, component }) =>
                    renderRow(
                      col,
                      key,
                      {
                        className: clsx(checked && styles.table__row__checked),
                      },
                      ({ col, key }) => {
                        const Controller = component(setter =>{
                          assignSetter(setter, key.toString())
                        })

                        return (
                          <td className={styles.table__cell} width={50}>
                            <Controller />
                          </td>
                        )
                      }
                    )
                  }
                </CheckableRow> */}
                                {renderRow(
                                    col,
                                    key,
                                    {
                                        className: clsx(
                                            findChecked.content &&
                                                styles.table__row__checked
                                        ),
                                    },
                                    () => {
                                        return (
                                            <td
                                                className={styles.table__cell}
                                                width={50}
                                            >
                                                <Check
                                                    checked={
                                                        findChecked.content
                                                    }
                                                    onChange={(e) =>
                                                        checkedPerItem(
                                                            key,
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            </td>
                                        );
                                    }
                                )}
                            </>
                        ) : (
                            renderRow(col, key)
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={headers.length + (checkable ? 1 : 0)}>
                            {emptyData}
                        </td>
                    </tr>
                )}
            </tbody>
        </>
    );

    React.useEffect(() => {
        if (checkable) {
            const dtTrans = dataAtProp.map((it) => {
                const xxx = [
                    ...it,
                    {
                        content: false,
                        name: "checked",
                    },
                ];
                return xxx;
            });
            setData(dtTrans);
        } else {
            setData(dataAtProp);
        }
    }, [dataAtProp]);

    return (
        <Table
            {...restProps}
            className={clsx(styles.table, restProps.className)}
            hover
        >
            {checkable ? (
                <>
                    {/* <CheckableTable>
            {({ assignSetter, Controller }) =>
              renderTableBody(
                <th width={50}>
                  <Controller />
                </th>,
                assignSetter
              )
            }
          </CheckableTable> */}
                    {renderTableBody(
                        <th width={50}>
                            <Check
                                checked={checkedAll}
                                onChange={({
                                    currentTarget: { checked: chck },
                                }) => checkedAllItem(chck)}
                            />
                        </th>
                    )}
                </>
            ) : (
                renderTableBody()
            )}
        </Table>
    );
};

const DataTable = (props) => {
    const { responsiveMode, ...restProps } = props;
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });
    const [tableScrolled, setTableScrolled] = React.useState(false);

    const handleTableScrollX = (e) => {
        if (e.target.scrollLeft >= 10) {
            setTableScrolled(true);
        } else {
            setTableScrolled(false);
        }
    };

    const renderActions = (itemData, dataActions) => (
        <Dropdown
            className={clsx(styles.table__action__wrapper)}
            onSelect={(name, event) => {
                if (
                    !!dataActions.handler &&
                    typeof dataActions.handler.onFireEvent === "function"
                ) {
                    dataActions.handler.onFireEvent(name, event, itemData);
                }
            }}
        >
            <Dropdown.Toggle
                {...dataActions.props}
                as={Button}
                variant="icon"
                // className={clsx(styles.table__action__button, dataActions.props.className)}
            >
                <span className={clsx(styles.dots)}>•••</span>
            </Dropdown.Toggle>

            <Dropdown.Menu className={clsx(styles.table__action__menu)}>
                <Dropdown.Item
                    eventKey="close"
                    className={clsx(
                        styles.table__action__button,
                        styles.table__action__button__shown
                    )}
                >
                    <span className={clsx(styles.dots)}>•••</span>
                </Dropdown.Item>
                {dataActions.menu.map(({ name, content }, index) => {
                    return(
                        <Dropdown.Item
                            key={name.concat(index.toString())}
                            eventKey={name}
                        >
                            {content}
                        </Dropdown.Item>
                    )
                })}
            </Dropdown.Menu>
        </Dropdown>
    );

    const renderMobileTable = () => {
        switch (responsiveMode) {
            case "list":
                return (
                    <DataTableMobile {...restProps} {...{ renderActions }} />
                );

            case "table":
                return (
                    <div
                        className={clsx(
                            styles.mobile,
                            tableScrolled && styles.scrolled
                        )}
                        onScroll={handleTableScrollX}
                    >
                        <DataTableDesktop
                            {...restProps}
                            {...{ renderActions }}
                        />
                    </div>
                );

            default:
                return null;
        }
    };

    return isTabletOrMobile ? (
        renderMobileTable()
    ) : (
        <DataTableDesktop {...restProps} {...{ renderActions }} />
    );
};

export default DataTable;
