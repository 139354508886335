// @flow
import React, { Fragment } from "react";
import clsx from "clsx";
import axios from "utils/axios";
import { Tabs, Accordion as AccordianComponent } from "components";
import {
    Tab,
    Row,
    Accordion as AccrodianBootstrap,
    Col,
    Button,
    ListGroup,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getFaqDataActions } from "../../store/actions/faqActions";
import { getFeaturesDataActions } from "../../store/actions/featuresActions";
import { useTranslation } from "react-i18next";
import { API_LIST_FORMULIR } from "utils/api.constant";
import AboutEBClick from "./AboutEBClick";

import styles from "./style.module.scss";

const FeaturesEBClick = ({ data }) => {
    const guide = data.map(({ icon, title, subtitle, ...rest }) => ({
        name: title.toLowerCase().split(" ").join("-"),
        title: (
            <>
                <i className={clsx(`${icon}`, "xl", "ml-2")}></i>
                <span className="d-block font-weight-light h4 mb-0">
                    {title}
                </span>
                <span className="d-block font-weight-light text-md">
                    {subtitle}
                </span>
                <i aria-hidden className="accordion-chevron -right"></i>
            </>
        ),
        content: rest.content,
    }));

    return (
        <>
            {/* <p>
        Magna nostrud adipisicing reprehenderit cupidatat occaecat dolor do
        aliquip sit. Mollit elit deserunt sit exercitation culpa et. Consequat
        sunt aute proident Lorem anim adipisicing consequat id mollit. Do aute
        aliqua ad nisi deserunt duis dolor ex pariatur culpa ipsum. Tempor
        ullamco non elit pariatur et mollit. Cupidatat est adipisicing culpa
        occaecat officia irure.
      </p> */}
            <AccordianComponent
                cols={2}
                items={guide}
                itemsPerRow={((guide.length + 1) / 2).toFixed()}
                buttonIcon="element-inside"
                className={clsx("gap-x-lg")}
            />
        </>
    );
};

const EBClickGuide = ({ layoutPath }) => {
    const dispatch = useDispatch();
    const [dataAbout, setDataAbout] = React.useState([]);
    const [dataFeatures, setDataFeatures] = React.useState([]);
    const [dataFormDownload, setDataFormDownload] = React.useState(null);
    const [keyState, setKeyState] = React.useState(null);
    const [keyStateChildOne, setKeyStateChildOne] = React.useState(null);
    const language = useSelector((state) => state.language.lang);
    const { t } = useTranslation();
    const [mobile, setMobile] = React.useState(window.innerWidth <= 992);
    const getData = React.useCallback(async () => {
        const config = {
            headers: {
                "x-accept-lang": language,
            },
        };

        await dispatch(getFaqDataActions("tentang"))
            .then((res) => {
                const dtTransform = res.data.map((it, index) => {
                    return {
                        id: index,
                        title: it.title,
                        content: it.content,
                        name: it.name,
                    };
                });
                setDataAbout(dtTransform);
            })
            .catch((err) => {
                console.log(err);
            });

        await dispatch(getFeaturesDataActions())
            .then((res) => {
                setDataFeatures(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

        await axios
            .get(API_LIST_FORMULIR, config)
            .then((response) => {
                const responseData = response.data;
                if (responseData.statusCode === 200) {
                    const resp = responseData.data.map((it, index) => {
                        return {
                            ...it,
                            id: index,
                        };
                    });
                    const lengthData = Math.ceil(resp.length / 2);
                    if (lengthData > 1) {
                        const data1 = resp.slice(0, lengthData);
                        const data2 = resp.slice(lengthData, resp.length);
                        setDataFormDownload({
                            data1,
                            data2,
                        });
                    } else {
                        const data1 = resp.slice(0, lengthData);
                        const data2 = null;
                        setDataFormDownload({
                            data1,
                            data2,
                        });
                    }
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }, [dispatch, language]);

    React.useEffect(() => {
        getData();
    }, [language]);

    const handleClickDownload = (url) => {
        window.open(url, "_blank");
    };

    const tabs = [
        {
            name: "about",
            title: <span dangerouslySetInnerHTML={{ __html: t("guides.tab.about")+'' }}></span>,
            content: <AboutEBClick data={dataAbout} />,
        },
        {
            name: "feature",
            title: <span dangerouslySetInnerHTML={{ __html: t("guides.tab.feature")+'' }}></span>,
            content: <FeaturesEBClick data={dataFeatures} />,
        },
        {
            name: "faq",
            title: t("guides.tab.faq"),
            redirect: "",
            content: <div></div>,
        },
        {
            name: "form",
            title: t("guides.tab.formDownload"),
            content: (
                <AccrodianBootstrap
                    // {...restProps}
                    as={Row}
                    activeKey={keyState}
                    onSelect={setKeyState}
                    className={clsx("align-items-start", "mt-4")}
                >
                    <Col as={Row} noGutters md={6}>
                        {dataFormDownload &&
                            dataFormDownload.data1 &&
                            dataFormDownload.data1.map(
                                (dataForm, indexForm) => (
                                    <Col
                                        key={indexForm}
                                        sm={12}
                                        className="accordion-item"
                                    >
                                        <AccrodianBootstrap.Toggle
                                            as={Button}
                                            variant="accordion"
                                            eventKey={dataForm.id + 1}
                                            className={clsx(
                                                "chevron",
                                                keyState === dataForm.id + 1 &&
                                                    "collapsed"
                                            )}
                                        >
                                            {dataForm.title}
                                        </AccrodianBootstrap.Toggle>

                                        <AccrodianBootstrap.Collapse
                                            eventKey={dataForm.id + 1}
                                        >
                                            <ListGroup variant="flush">
                                                {dataForm.sub.map(
                                                    (sub, indexSubOne) => {
                                                        return (
                                                            <Fragment
                                                                key={
                                                                    indexSubOne
                                                                }
                                                            >
                                                                {sub.link && (
                                                                    <ListGroup.Item
                                                                        onClick={() =>
                                                                            handleClickDownload(
                                                                                sub.link
                                                                            )
                                                                        }
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className={clsx(
                                                                                "mi-download lg",
                                                                                "vertical-align-text-bottom"
                                                                            )}
                                                                        ></i>{" "}
                                                                        {
                                                                            sub.title
                                                                        }
                                                                    </ListGroup.Item>
                                                                )}

                                                                {!sub.link && (
                                                                    <AccrodianBootstrap
                                                                        activeKey={
                                                                            keyStateChildOne
                                                                        }
                                                                        onSelect={
                                                                            setKeyStateChildOne
                                                                        }
                                                                    >
                                                                        <Col
                                                                            key={
                                                                                indexSubOne
                                                                            }
                                                                            sm={
                                                                                12
                                                                            }
                                                                            className="accordion-item pr-0"
                                                                        >
                                                                            <AccrodianBootstrap.Toggle
                                                                                as={
                                                                                    Button
                                                                                }
                                                                                variant="accordion"
                                                                                eventKey={
                                                                                    indexSubOne +
                                                                                    1
                                                                                }
                                                                                className={clsx(
                                                                                    "chevron",
                                                                                    keyStateChildOne ===
                                                                                        indexSubOne +
                                                                                            1 &&
                                                                                        "collapsed"
                                                                                )}
                                                                            >
                                                                                {
                                                                                    sub.title
                                                                                }
                                                                            </AccrodianBootstrap.Toggle>

                                                                            {sub.sub.map(
                                                                                (
                                                                                    subTwo,
                                                                                    indexSubTwo
                                                                                ) => {
                                                                                    return (
                                                                                        <AccrodianBootstrap.Collapse
                                                                                            key={
                                                                                                indexSubTwo
                                                                                            }
                                                                                            eventKey={
                                                                                                indexSubOne +
                                                                                                1
                                                                                            }
                                                                                        >
                                                                                            <ListGroup variant="flush">
                                                                                                <ListGroup.Item
                                                                                                    onClick={() =>
                                                                                                        handleClickDownload(
                                                                                                            subTwo.link
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <i
                                                                                                        className={clsx(
                                                                                                            "mi-download lg",
                                                                                                            "vertical-align-text-bottom"
                                                                                                        )}
                                                                                                    ></i>{" "}
                                                                                                    {
                                                                                                        subTwo.title
                                                                                                    }
                                                                                                </ListGroup.Item>
                                                                                            </ListGroup>
                                                                                        </AccrodianBootstrap.Collapse>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </Col>
                                                                    </AccrodianBootstrap>
                                                                )}
                                                            </Fragment>
                                                        );
                                                    }
                                                )}
                                            </ListGroup>
                                        </AccrodianBootstrap.Collapse>
                                    </Col>
                                )
                            )}
                    </Col>
                    <Col as={Row} noGutters md={6}>
                        {dataFormDownload &&
                            dataFormDownload.data2 &&
                            dataFormDownload.data2.map(
                                (dataForm, indexForm) => (
                                    <Col
                                        key={indexForm + 500}
                                        sm={12}
                                        className="accordion-item"
                                    >
                                        <AccrodianBootstrap.Toggle
                                            as={Button}
                                            variant="accordion"
                                            eventKey={dataForm.id + 1}
                                            className={clsx(
                                                "chevron",
                                                keyState === dataForm.id + 1 &&
                                                    "collapsed"
                                            )}
                                        >
                                            {dataForm.title}
                                        </AccrodianBootstrap.Toggle>

                                        <AccrodianBootstrap.Collapse
                                            eventKey={dataForm.id + 1}
                                        >
                                            <ListGroup variant="flush">
                                                {dataForm.sub.map(
                                                    (sub, indexSubOne) => {
                                                        return (
                                                            <Fragment
                                                                key={
                                                                    indexSubOne
                                                                }
                                                            >
                                                                {sub.link && (
                                                                    <ListGroup.Item
                                                                        onClick={() =>
                                                                            handleClickDownload(
                                                                                sub.link
                                                                            )
                                                                        }
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className={clsx(
                                                                                "mi-download lg",
                                                                                "vertical-align-text-bottom"
                                                                            )}
                                                                        ></i>{" "}
                                                                        {
                                                                            sub.title
                                                                        }
                                                                    </ListGroup.Item>
                                                                )}

                                                                {!sub.link && (
                                                                    <AccrodianBootstrap
                                                                        activeKey={
                                                                            keyStateChildOne
                                                                        }
                                                                        onSelect={
                                                                            setKeyStateChildOne
                                                                        }
                                                                    >
                                                                        <Col
                                                                            key={
                                                                                indexSubOne
                                                                            }
                                                                            sm={
                                                                                12
                                                                            }
                                                                            className="accordion-item pr-0"
                                                                        >
                                                                            <AccrodianBootstrap.Toggle
                                                                                as={
                                                                                    Button
                                                                                }
                                                                                variant="accordion"
                                                                                eventKey={
                                                                                    indexSubOne +
                                                                                    1
                                                                                }
                                                                                className={clsx(
                                                                                    "chevron",
                                                                                    keyStateChildOne ===
                                                                                        indexSubOne +
                                                                                            1 &&
                                                                                        "collapsed"
                                                                                )}
                                                                            >
                                                                                {
                                                                                    sub.title
                                                                                }
                                                                            </AccrodianBootstrap.Toggle>

                                                                            {sub.sub.map(
                                                                                (
                                                                                    subTwo,
                                                                                    indexSubTwo
                                                                                ) => {
                                                                                    return (
                                                                                        <AccrodianBootstrap.Collapse
                                                                                            key={
                                                                                                indexSubTwo
                                                                                            }
                                                                                            eventKey={
                                                                                                indexSubOne +
                                                                                                1
                                                                                            }
                                                                                        >
                                                                                            <ListGroup variant="flush">
                                                                                                <ListGroup.Item
                                                                                                    onClick={() =>
                                                                                                        handleClickDownload(
                                                                                                            subTwo.link
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <i
                                                                                                        className={clsx(
                                                                                                            "mi-download lg",
                                                                                                            "vertical-align-text-bottom"
                                                                                                        )}
                                                                                                    ></i>{" "}
                                                                                                    {
                                                                                                        subTwo.title
                                                                                                    }
                                                                                                </ListGroup.Item>
                                                                                            </ListGroup>
                                                                                        </AccrodianBootstrap.Collapse>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </Col>
                                                                    </AccrodianBootstrap>
                                                                )}
                                                            </Fragment>
                                                        );
                                                    }
                                                )}
                                            </ListGroup>
                                        </AccrodianBootstrap.Collapse>
                                    </Col>
                                )
                            )}
                    </Col>
                </AccrodianBootstrap>
            ),
        },
    ];

    const goTo = (eventKey) => {
        if (eventKey === "https://www.manulife.co.id/id/layanan/faq.html") {
            window.open(eventKey);
        }
    };
    const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 992);
    }

    React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
    }, []);
    return (
        <section className={clsx("container", "py-4", styles.container)}>
            <h1 className="display-1" dangerouslySetInnerHTML={{ __html: t("guides.title") }}></h1>
            <Tabs
                id="guides"
                className={clsx("mt-3", styles.tabs)}
                responsiveMode="dropdown"
                onSelect={(eventKey) => goTo(eventKey)}
            >
                {tabs.map(({ content, title, name }) => {
                    return (
                        <Tab
                            key={name}
                            eventKey={
                                name === "faq"
                                    ? "https://www.manulife.co.id/id/layanan/faq.html"
                                    : name
                            }
                            title={mobile?title.props?title.props.dangerouslySetInnerHTML.__html.replace("<i>Click</i>","Click"):title:title}
                        >
                            <div className={clsx("my-4", "font-weight-light")}>
                                {content}
                            </div>
                        </Tab>
                    );
                })}
            </Tabs>
        </section>
    );
};

export default EBClickGuide;
