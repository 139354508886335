// @flow
// eslint-disable-next-line no-unused-vars
import * as React from 'react'
import Guest from 'layouts/Guest'
import Calculator from './Calculator'

export const pathname = '/calculator'
export const route = {
  component: Guest,
  asLayout: true,
  routes: {
    '/': {
      component: Calculator,
    },
  },
}