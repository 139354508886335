
export const en = {
    employeeReportDocTable: {
        nameDoc: 'Document Name',
        submissionTime: 'Request Date',
        estimateComplete: 'Estimated Completion Date',
        status: 'Status',
    },
    employeeReportDocTableNullLable: 'There are no documents for you yet. Please check again later.'
}

export const id = {
    employeeReportDocTable: {
        nameDoc: 'Nama Dokumen',
        submissionTime: 'Tanggal Pengajuan',
        estimateComplete: 'Estimasi Tanggal Selesai',
        status: 'Status',
    },
    employeeReportDocTableNullLable: 'Belum ada dokumen untuk Anda. Silahkan periksa lagi nanti.'
}