

export const en = {
    employeeChangePassword: {
        form: {
            validation: {
                text1: 'Minimum 8 characters',
                text2: 'Use capital & lowercase letters (example: Aa)',
                text3: 'Use numbers (example: 1234)',
                text4: 'Use symbols (example: !@#$)',
                text5: 'Repeat Password must be same as Password.'
            }
        },
        goodPassword:'Password meets all criteria'
    }
}

export const id = {
    employeeChangePassword: {
        form: {
            validation: {
                text1: 'Minimal 8 karakter',
                text2: 'Gunakan huruf kapital & kecil (contoh: Aa)',
                text3: 'Gunakan angka (contoh: 1234)',
                text4: 'Gunakan simbol (contoh: !@#$)',
                text5: 'Ulangi Kata Sandi harus sama dengan Kata Sandi.'
            }
        },
        goodPassword:'Kata sandi memenuhi semua kriteria'
    }
}