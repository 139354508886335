import React from 'react'
import { SpinnerCircular } from 'spinners-react'

import styles from './style.module.scss'

const AuthWrapper = (props) => {

    const { children, loading } = props
   
    return (
        <div className={`${styles.wrapper} w-100 position-relative`}>
            {children}
            {loading && (
                <div className={`${styles.loading} position-absolute w-100 d-flex justify-content-center align-items-center`}>
                    <SpinnerCircular thickness={180} size={40} />
                </div>
            )}
        </div>
    )
}

export default React.memo(AuthWrapper)