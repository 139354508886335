import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { SpinnerCircular } from 'spinners-react'
import clsx from 'clsx'

import styles from './style.module.scss'

const TableWrapper = (props) => {

    const { children, loading } = props

    return (
        <Row
            className={`${styles.tableWrapper} position-relative`}
        >
            {Array.isArray(children) ? children.map((Component, index) => {
                return <Col key={index} className={index === 1 && `d-flex justify-content-end align-items-center mt-3 position-relative ${styles.pagination}`} sm={12}>{Component}</Col>
            }) : (
                <Col xs={12}>{children}</Col>
            )}
            {loading && (
                <Col xs={12}
                    className={clsx(
                        "position-absolute z-10 d-flex justify-content-center align-items-center py-5",
                        styles.loading
                    )}
                >
                    <SpinnerCircular thickness={180} size={40} />
                </Col>
            )}
        </Row>
    )
}

export default React.memo(TableWrapper)
