// @flow
import  React from 'react'
import { Redirect } from 'react-router'

import About from './About'
import Features from './Features'
import Download from './Download'

export const pathname = '/guide'
export const route = {
  routes: {
    '/about': {
      component: About,
    },
    '/features': {
      component: Features,
    },
    '/form': {
      component: Download,
    },
    '/': {
      component: ({ layoutPath }) => <Redirect to={`${layoutPath}guide/about`} />,
    },
  },
}

const guide = {
  pathname,
  route
}

export default guide
